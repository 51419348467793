import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'react-i18next';
// import useCheckTransition from './useCheckTransition';
import { newGroup, transGroupDetail } from '../utils/group/groupAdd';
import { groupCheck } from '../utils/group/groupCheck';

import type { GroupAddInput } from '../types/inputs/groupAdd';
// import useAccessKey from './useAccessKey';
import type { GroupAddResult } from '../types/Group/groupAdd';
import {
  GROUP_ADD_API_ERROR_TYPE,
  GROUP_ADD_API_RESULT_CODE,
} from '../apis/callGroupAddApi';
import {
  GroupCheckApiParams,
  GroupCheckApiUtilResponse,
} from '../types/apis/groupCheckApi.d';
import useAccountInfo from './useAccountInfo';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
// import useRedirectDialog from './useRedirectDialog';
// import useLogout from './useLogout';
import {
  // GROUP_CHECK_API_ERROR_TYPE,
  GROUP_CHECK_API_RESULT_CODE,
} from '../apis/callGroupCheckApi';
import { SERVER_MESSAGE } from '../constants/constants';

export type UseGroupAddValue = {
  errorType: GROUP_ADD_API_ERROR_TYPE | null;
  setErrorType: (
    value: React.SetStateAction<GROUP_ADD_API_ERROR_TYPE | null>,
  ) => void;
  // errorMessage: string;
  isCallingApi: boolean;
  t: TFunction;
  onClickGroupAdd: (inputData: string) => void;
  onClickGroupValidationCheck: (inputData: string) => void;
  groupCheckApiErrorType: boolean;
  setGroupCheckApiErrorType: (val: boolean) => void;
};

const useGroupAdd = (): UseGroupAddValue => {
  // const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();

  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<GROUP_ADD_API_ERROR_TYPE | null>(
    null,
  );
  const [groupCheckApiErrorType, setGroupCheckApiErrorType] =
    useState<boolean>(false);
  // navigate
  const navigate = useNavigate();
  // const { accessKey } = useAccessKey();
  const { accountInfo } = useAccountInfo();
  const { displaySnackbar } = useSnackbar();
  const { t } = useSwitchLocaleLanguage();
  // const { allowTransition } = useCheckTransition();

  const onClickGroupValidationCheck = (groupName: string): void => {
    const requestParams: GroupCheckApiParams = {
      groupName,
      corpId: accountInfo.corpId,
    };
    groupCheck(requestParams)
      .then((result: GroupCheckApiUtilResponse) => {
        if (
          result.resultCode ===
          GROUP_CHECK_API_RESULT_CODE.WARN_GROUP_NAME_EXIST
        ) {
          setGroupCheckApiErrorType(true);

          return;
        }
        setGroupCheckApiErrorType(false);
      })

      .finally(() => {
        // Loading End
        // setIsCallingApi(false);
      });
  };

  const onClickGroupAdd = (groupName: string): void => {
    const requestParam: GroupAddInput = {
      groupName,
      corpId: accountInfo.corpId,
    };
    // loading Start
    setIsCallingApi(true);
    // メール送信
    /*eslint-disable*/
    newGroup(requestParam)
      .then((result: GroupAddResult) => {
        setErrorType(result.resultCode);
        // API Success
        if (
          result.resultCode === GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST
        ) {
          setErrorType(result.resultCode);
          setIsCallingApi(false);
          return;
        }
        if (
          result.resultCode === GROUP_ADD_API_RESULT_CODE.WARN_INVALID_AUTH ||
          result.resultCode === GROUP_ADD_API_RESULT_CODE.AUTH_NOT_ALLOWED
        ) {
          // displayRedirectDialog({
          //   open: true,
          //   title: 'Not authorized',
          //   message: 'Your role has been changed. Please re-login.',
          //   routePath: onClickLogoff,
          // });
          setErrorType(result.resultCode);
          setIsCallingApi(false);
          return;
        }
        if (result.resultCode === GROUP_ADD_API_RESULT_CODE.INFO_NOTHING_KEY) {
          navigate('/');
          return;
        }
        if (
          result.resultCode === GROUP_ADD_API_RESULT_CODE.SUCCESSFULLY_CREATED
        ) {
          displaySnackbar({ message: t('groupAdd.message.addSuccess') });
          const groupId = result.groupId;
          // Transition to the [Group Detail] screen (passing a success message)
          transGroupDetail(navigate, groupId);
          setErrorType(result.resultCode);
          setIsCallingApi(false);
          return;
        }
        if (
          result.resultCode === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
          result.resultCode === SERVER_MESSAGE.NO_INTERNET ||
          result.resultCode === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode === SERVER_MESSAGE.USER_ROLE_CHANGED ||
          result.resultCode === SERVER_MESSAGE.INVALID_TOKEN ||
          result.resultCode === SERVER_MESSAGE.EXPIRED_TOKEN
        ) {
          return;
        }
        setIsCallingApi(false);
        displaySnackbar({
          message: t('common.error.serverErr'),
          type: 'error',
        });
        // 失敗
        // setUserPreAddErrorType(result.resultCode);
      })
      .finally(() => {
        // Loading End
        setIsCallingApi(false);
      });
  };

  // const errorMessage = useMemo((): string => {
  //   if (!errorType) {
  //     return ''; // エラーコンポーネント自体非表示にする
  //   }
  //   switch (errorType) {
  //     case GROUP_ADD_API_RESULT_CODE.INFO_INVALID:
  //       return 'Already Exist';
  //     case GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST:
  //       return 'WARN_GROUP_NAME_EXIST';
  //     default:
  //       return 'Unknown Error';
  //   }
  // }, [errorType]);

  return {
    errorType,
    // errorMessage,
    isCallingApi,
    t,
    onClickGroupAdd,
    setErrorType,
    onClickGroupValidationCheck,
    groupCheckApiErrorType,
    setGroupCheckApiErrorType,
  };
};

export default useGroupAdd;
