import { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { GroupInfo } from '../types/Group/groupList.d';

import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import {
  API_CALL_STATE,
  DIRECTION_TYPE,
  DisplayContentState,
  FILTERING_STATE,
  SortConfig,
  TableHeaderData,
  TableRowData,
  GROUP_LIST_TABLE_DATA_KEY,
} from '../types/datatable/dataTable.d';
import {
  sortedItems,
  updateSortConfig,
} from '../utils/datatable/dataTableUtil';
import { GroupListResult } from '../types/Group/groupList.d';
import { fetchGroupList } from '../utils/group/groupList';
import {
  GROUP_LIST_API_ERROR_TYPE,
  GROUP_LIST_API_RESULT_CODE,
} from '../apis/callGroupListApi';
import { convertGroupListArrayToTableDataArray } from '../utils/datatable/dataTableUtil';
import useFilter from './useFilter';
import {
  ALL_ERROR_TYPE,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../constants/constants';
import usePagination from './usePagination';
import { isGroupListErrorType } from '../types/apis/apiErrorTypeGuard';
import { PAGE_PATH_NAME } from '../constants/constants';
import { SERVER_MESSAGE } from '../constants/constants';
import { sendGAEvent } from './useGAEvent';

import useAccountInfo from './useAccountInfo';
import { AppDispatch, RootState, useAppDispatch } from '../state/store';
import { GroupListState, groupListSlice } from '../state/slice/groupListSlice';
import useRefreshRedux from './useRefreshRedux';
import useGetUpdateUserInfo from './useGetUpdateUserInfo';

/**
 * ページネーション情報
 */
export type PaginationValue = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // ページネーションの[前へ]ボタンがクリックされた場合の処理
  onClickPrevPage: () => void;
  // ページネーションの[次へ]ボタンがクリックされた場合の処理
  onClickNextPage: () => void;
  onClickFirstPage: () => void;
  onClickLastPage: () => void;
  // 選択中の1ページごとの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された場合の処理
  onChangeRowNumber: (selectedRowNumber: number) => void;
  onClickAddGroupPage: () => void;
};

type Props = {
  searchText: string;
  locationKey: string;
};

const useGroupList = ({ searchText, locationKey }: Props) => {
  const navigate = useNavigate();
  const { accountInfo } = useAccountInfo();
  const groupListStateInfo = useSelector<RootState, GroupListState>(
    (state) => state.groupListState,
  );
  const { refreshRedux } = useRefreshRedux();
  // update user info hook
  const { callGetUpdateUserInfo } = useGetUpdateUserInfo();

  // console.log(groupListStateInfo);
  // filter hooks
  const { onClickFilterSearch, filterInputData } = useFilter('');
  // const isFirstRenderingRef = useRef(true);
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]);
  const [errorType, setErrorType] = useState<ALL_ERROR_TYPE | null>(null);
  const filterGroupInfos = useRef<GroupInfo[]>([]);
  const originalGroupInfos = useRef<GroupInfo[]>([]);
  const totalData = useRef<number>(0);
  const { t } = useSwitchLocaleLanguage();
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);
  const [groupCode, setGroupCode] = useState<number>(0);
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending, // API call status (before call)
      filteringState: FILTERING_STATE.inactive, // Filtering applied state (filtering off)
    });
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none,
  });

  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage,
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 1);

  const [tableDataPayload, setTableDataPayload] = useState({
    searchText: '',
    sortDirection:
      groupListStateInfo.fromGroupDetailsPage &&
      groupListStateInfo.tableSortData &&
      groupListStateInfo.tableSortData?.direction !== DIRECTION_TYPE.none
        ? groupListStateInfo.tableSortData.direction
        : '',
    colName:
      groupListStateInfo.fromGroupDetailsPage &&
      groupListStateInfo.tableSortData?.key
        ? groupListStateInfo.tableSortData.key
        : '',
    rowLimitPerPage: 100,
    currentPageNumber: 1,
  });
  // duplicate payload check to prevent duplicate api calling
  const [previousPayload, setPreviousPayload] = useState({});
  const [previousSearchText, setPreviousSearchText] = useState<string>();
  // reset after location key change
  useEffect(() => {
    // sort reset
    setSortConfig(
      groupListStateInfo.fromGroupDetailsPage &&
        groupListStateInfo.tableSortData &&
        groupListStateInfo.tableSortData?.direction !== DIRECTION_TYPE.none
        ? groupListStateInfo.tableSortData
        : { key: '', direction: DIRECTION_TYPE.none },
    );
    // page number reset
    changeCurrentPage(1);
    // row number reset
    onChangeRowNumber(TABLE_ROW_NUMBER_PER_PAGE[2]);
    // table data payload reset
    setTableDataPayload({
      searchText: '',
      sortDirection:
        groupListStateInfo.fromGroupDetailsPage &&
        groupListStateInfo.tableSortData &&
        groupListStateInfo.tableSortData?.direction !== DIRECTION_TYPE.none
          ? groupListStateInfo.tableSortData.direction
          : '',
      colName:
        groupListStateInfo.fromGroupDetailsPage &&
        groupListStateInfo.tableSortData?.key
          ? groupListStateInfo.tableSortData.key
          : '',
      rowLimitPerPage: 100,
      currentPageNumber: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKey, changeCurrentPage, onChangeRowNumber]);

  const onClickAddGroupPage = () => {
    refreshRedux();
    callGetUpdateUserInfo();
    // GAEventTracker('btn_name', 'new_group');
    sendGAEvent('press_btn', 'btn_name', 'new_group');
    navigate(PAGE_PATH_NAME.ADD_GROUP);
  };

  const headerColumns = useMemo(
    (): TableHeaderData[] => [
      {
        key: GROUP_LIST_TABLE_DATA_KEY.GROUP_NAME,
        value: t('groupList.header.groupName'),
        isSort: true,
      },
      {
        key: GROUP_LIST_TABLE_DATA_KEY.NUMBER_OF_USERS,
        value: t('groupList.header.noOfUsers'),
        isSort: true,
      },
      {
        key: GROUP_LIST_TABLE_DATA_KEY.NUMBER_OF_DEVICE,
        value: t('groupList.header.noOfDevice'),
        isSort: true,
      },
      {
        key: GROUP_LIST_TABLE_DATA_KEY.STATUS_OF_REMOTE_SETTINGS,
        value: t('groupList.header.statusOfRS'),
        isSort: true,
      },

      {
        key: GROUP_LIST_TABLE_DATA_KEY.LAST_UPDATED,
        value: t('groupList.header.lastUpdated'),
        isSort: true,
      },
    ],
    [t],
  );

  const createDisplayDataApiFulfilled = useCallback(() => {
    let list: GroupInfo[] = [];
    if (displayContentState.filteringState === FILTERING_STATE.inactive) {
      list = originalGroupInfos.current;
    }
    // with filter
    else if (displayContentState.filteringState === FILTERING_STATE.active) {
      list = filterGroupInfos.current;
    }
    let tableRowDataArray: TableRowData[] =
      convertGroupListArrayToTableDataArray(list);

    // apply sort
    if (sortConfig.direction !== DIRECTION_TYPE.none) {
      tableRowDataArray = sortedItems(tableRowDataArray, sortConfig);
    }
    setCurrentPageDataRows(tableRowDataArray);
  }, [sortConfig, displayContentState.filteringState]);

  const callGroupListApi = useCallback(
    () => {
      if (
        JSON.stringify(previousPayload) ===
        JSON.stringify({
          ...tableDataPayload,
          rowLimitPerPage: currentRowNumberPerPage,
          currentPageNumber:
            searchText !== previousSearchText ? 1 : currentPage,
          searchText,
          sortDirection: sortConfig.direction.toLowerCase(),
        })
      ) {
        return;
      }
      setIsCallingApi(true);
      fetchGroupList({
        ...tableDataPayload,
        rowLimitPerPage: currentRowNumberPerPage,
        currentPageNumber: searchText !== previousSearchText ? 1 : currentPage,
        searchText,
      })
        .then((result: GroupListResult) => {
          if (
            result.resultCode ===
              GROUP_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
            result.resultCode === GROUP_LIST_API_RESULT_CODE.AUTH_NOT_ALLOWED ||
            result.resultCode === SERVER_MESSAGE.NO_INTERNET
          ) {
            totalData.current = 0;
            setErrorType(result.resultCode);

            return;
          }
          if (
            result.resultCode === GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN &&
            !accountInfo.subcriptionChange
          ) {
            navigate('/');
          }
          if (
            result.resultCode !==
            GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
          ) {
            // Failed to acquire information
            originalGroupInfos.current = [];
            setIsCallingApi(false);
            throw result.resultCode;
          }

          setErrorType(result.resultCode);
          originalGroupInfos.current = result.groupList;
          changeTotalPages(
            Math.ceil(result.totalCount / currentRowNumberPerPage),
          );
          /*eslint-disable*/
          totalData.current = result.totalCount;
          setPageDataIndexFrom(
            ((searchText !== previousSearchText ? 1 : currentPage) - 1) *
              currentRowNumberPerPage +
              1,
          );
          setPageDataIndexTo(
            (searchText !== previousSearchText ? 1 : currentPage) *
              currentRowNumberPerPage >
              totalData.current
              ? totalData.current
              : (searchText !== previousSearchText ? 1 : currentPage) *
                  currentRowNumberPerPage,
          );
          if (result.groupList.length === 1) {
            setGroupCode(result.groupList[0].groupCode);
          }
          setDisplayContentState({
            ...displayContentState,
            apiCallState: API_CALL_STATE.fulfilled,
          });
          createDisplayDataApiFulfilled();
          setIsCallingApi(false);
        })
        .catch((error: GROUP_LIST_API_ERROR_TYPE | unknown) => {
          if (isGroupListErrorType(error)) {
            setErrorType(error);
            if (error === GROUP_LIST_API_RESULT_CODE.WARN_INVALID_AUTH) {
              // displayRedirectDialog({
              //   open: true,
              //   title: 'Not authorized',
              //   message: 'Your role has been changed. Please re-login.',
              //   routePath: onClickLogoff,
              // });
            }
            if (error === GROUP_LIST_API_RESULT_CODE.INFO_NO_USER) {
              setErrorType(GROUP_LIST_API_RESULT_CODE.INFO_NO_USER);
            }
          } else {
            setErrorType(GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN);
          }
        })
        .finally(() => {
          setIsCallingApi(false);
        });
      // setting previous api payload
      setPreviousPayload({
        ...tableDataPayload,
        rowLimitPerPage: currentRowNumberPerPage,
        currentPageNumber: searchText !== previousSearchText ? 1 : currentPage,
        searchText,
        sortDirection: sortConfig.direction.toLowerCase(),
      });
      setPreviousSearchText(searchText);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // accessKey,
      tableDataPayload,
      currentRowNumberPerPage,
      currentPage,
      searchText,
      sortConfig,
      previousPayload,
    ],
  );
  const dispatch: AppDispatch = useAppDispatch();
  const { addTableSortData } = groupListSlice.actions;

  useEffect(() => {
    changeCurrentPage(1);
    setPageDataIndexFrom(1);
    // GA event tag
    sendGAEvent('search_kw', 'txt_search_kw', searchText);
  }, [searchText]);

  // useEffect for api calling
  useEffect(() => {
    callGroupListApi();
  }, [currentRowNumberPerPage, currentPage, sortConfig, searchText]);
  /**
   * Sort button clicked
   */
  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort || isCallingApi) {
        // If the column does not allow sorting, do nothing
        return;
      }
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      setSortConfig(sortConfigUpdate);
      dispatch(addTableSortData(sortConfigUpdate));
      changeCurrentPage(1);
      setTableDataPayload({
        ...tableDataPayload,
        sortDirection: sortConfigUpdate.direction.toLowerCase(),
        colName: data.key,
      });

      setDisplayContentState({
        ...displayContentState,
        apiCallState: API_CALL_STATE.pending,
      });

      // GA event tag
      switch (data.key) {
        case GROUP_LIST_TABLE_DATA_KEY.GROUP_NAME:
          sendGAEvent('press_btn', 'btn_name', 'list_group_name_sort');
          sendGAEvent(
            'press_btn',
            'list_group_name_sort_value',
            sortConfigUpdate.direction,
          );
          break;
        case GROUP_LIST_TABLE_DATA_KEY.NUMBER_OF_USERS:
          sendGAEvent('press_btn', 'btn_name', 'list_group_user_count_sort');
          sendGAEvent(
            'press_btn',
            'list_group_user_count_sort',
            sortConfigUpdate.direction,
          );
          break;
        case GROUP_LIST_TABLE_DATA_KEY.NUMBER_OF_DEVICE:
          sendGAEvent('press_btn', 'btn_name', 'list_group_device_count_sort');
          sendGAEvent(
            'press_btn',
            'list_group_device_count_sort',
            sortConfigUpdate.direction,
          );
          break;
        case GROUP_LIST_TABLE_DATA_KEY.LAST_UPDATED:
          sendGAEvent('press_btn', 'btn_name', 'list_group_last_dt_sort');
          sendGAEvent(
            'press_btn',
            'list_group_last_dt_sort',
            sortConfigUpdate.direction,
          );
      }
    },
    [sortConfig, displayContentState, tableDataPayload, isCallingApi],
  );

  return {
    currentPageDataRows,
    headerColumns,
    t,
    isCallingApi,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    filterValue: {
      onClickFilterSearch,
      filterInputData,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onChangeRowNumber,
      pageDataIndexFrom,
      pageDataIndexTo,
      onClickFirstPage,
      onClickLastPage,
      totalData: totalData.current,
    },
    onClickAddGroupPage,
    errorType,
    groupCode,
  };
};

export default useGroupList;
