/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Typography, Grid, Skeleton, Box } from '@mui/material';
import useGroupTopUser from '../../hooks/useGroupTopUser';
// assets
import { ReactComponent as UsersIcon } from '../../images/icon/manage-user.svg';
import { ReactComponent as OutLightIcon } from '../../images/icon/Out_light.svg';

type Props = {
  handleClick?: React.MouseEventHandler<HTMLElement>;
  isUserUpdate: boolean;
  disabledGroupUser?: boolean;
  disabledUnGroupUser?: boolean;
};

const GroupFeatureHeaderRight = React.memo<Props>((props): JSX.Element => {
  const [isHover, setIsHover] = React.useState(false);
  const { isCallingApi, groupTopUserName, t, groupUserCount, groupCode } =
    useGroupTopUser(props.isUserUpdate);
  const { disabledGroupUser, disabledUnGroupUser } = props;
  // const topUsersCount = groupTopUser.current?.topUsers.length;
  const colorsArray = ['#D9D9D9', '#D9D9D9', '#7FCEF0', '#46DAAA', '#878787'];
  let colors: string[] = [];

  if (groupUserCount === undefined) {
    colors = colorsArray.slice(-0);
  } else {
    colors = colorsArray.slice(-groupUserCount);
  }

  return (
    <Grid
      container
      alignItems="flex-end"
      direction="column"
      justifyContent="flex-end"
      className="group-detail-users"
    >
      {isCallingApi ? (
        <Skeleton variant="text" sx={{ fontSize: '14', width: '91px' }} />
      ) : (
        <Typography
          sx={{
            color: '#313133',
            fontSize: '14px',
            lineHeight: '20px',
            pb: '8px',
          }}
          className="group-detail-users-text"
        >
          {t('userListModal.label.total')}{' '}
          {groupUserCount > 0 ? groupUserCount : 0}{' '}
          {t('userListModal.label.users')}
        </Typography>
      )}
      <Box
        sx={{
          textAlign: 'right',
          '& .MuiAvatarGroup-root': {
            // '& img': {
            //   boxShadow: isHover ? '0px 3px 4px #0000001A' : 'none',
            // },
            '& .MuiAvatar-root': {
              boxShadow: isHover ? '0px 3px 4px #0000001A' : 'none',
            },
          },
          '& .MuiAvatar-root': {
            boxShadow: isHover ? '0px 3px 4px #0000001A' : 'none',
          },
          '& .MuiButtonBase-root': {
            color: isHover ? '#007EB4' : '#009DE1',
            '& svg': {
              '& path': {
                fill: isHover && groupCode !== 0 ? '#007EB4' : null,
                stroke: isHover && groupCode === 0 ? '#007EB4' : null,
              },
              '& g': {
                '& g': {
                  '& path': {
                    fill: isHover && groupCode === 0 ? '#007EB4' : null,
                    stroke:
                      isHover && groupCode === 0 ? 'none !important' : null,
                  },
                },
              },
            },
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AvatarGroup
            onClick={props.handleClick}
            onMouseOver={() => {
              if (disabledGroupUser && groupCode !== 0) {
                setIsHover(true);
              }
              if (disabledUnGroupUser && groupCode === 0) {
                setIsHover(true);
              }
            }}
            onMouseOut={() => {
              setIsHover(false);
            }}
            sx={{
              cursor:
                (disabledGroupUser && groupCode !== 0) ||
                (disabledUnGroupUser && groupCode === 0)
                  ? 'pointer'
                  : 'default',
              '& .MuiAvatar-root': {
                border: '2px solid #FFFFFF',
                width: 30,
                height: 30,
                fontSize: 14,
              },
            }}
          >
            {isCallingApi && (
              <Avatar>
                <Skeleton variant="circular" width={30} height={30} />
              </Avatar>
            )}

            {!isCallingApi && groupTopUserName ? (
              groupTopUserName.map((item, i) => {
                const nameWord = item.username?.trim().charAt(0).toUpperCase();

                return item.imageUrl?.length > 0 ? (
                  // <Avatar key={i} alt="pic" src={item.imageUrl} />
                  <Avatar key={i}>
                    <img
                      alt="pic"
                      src={item.imageUrl}
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                  </Avatar>
                ) : (
                  <Avatar key={i} sx={{ bgcolor: colors[i] }}>
                    {nameWord}
                  </Avatar>
                );
              })
            ) : (
              <Avatar>
                <Skeleton variant="circular" width={30} height={30} />
              </Avatar>
            )}
          </AvatarGroup>
          {!isCallingApi && groupUserCount > 5 && (
            <Avatar
              onClick={props.handleClick}
              onMouseOver={() => {
                if (disabledGroupUser && groupCode !== 0) {
                  setIsHover(true);
                }
                if (disabledUnGroupUser && groupCode === 0) {
                  setIsHover(true);
                }
              }}
              onMouseOut={() => {
                setIsHover(false);
              }}
              sx={{
                cursor:
                  (disabledGroupUser && groupCode !== 0) ||
                  (disabledUnGroupUser && groupCode === 0)
                    ? 'pointer'
                    : 'default',
                bgcolor: '#D9D9D9',
                color: '#313133',
                width: 34,
                height: 34,
                fontSize: 11,
                border: '2px solid #FFFFFF',
                marginLeft: '-10px',
              }}
            >
              +{groupUserCount - 5}
            </Avatar>
          )}
        </Box>
        {isCallingApi ? (
          <Skeleton variant="text" sx={{ fontSize: '12', width: '91px' }} />
        ) : (
          groupCode !== undefined && (
            <IconButton
              className="group-detail-users-button"
              type="button"
              aria-label="view user"
              sx={{
                p: '0px',
                mt: '8px',
                borderRadius: '30%',
                color: '#009DE1',
                cursor:
                  (disabledGroupUser && groupCode !== 0) ||
                  (disabledUnGroupUser && groupCode === 0)
                    ? 'pointer'
                    : 'default',
              }}
              onClick={props.handleClick}
              onMouseOver={() => {
                if (disabledGroupUser && groupCode !== 0) {
                  setIsHover(true);
                }
                if (disabledUnGroupUser && groupCode === 0) {
                  setIsHover(true);
                }
              }}
              onMouseOut={() => {
                setIsHover(false);
              }}
              disableRipple
              disabled={
                !(
                  (disabledGroupUser && groupCode !== 0) ||
                  (disabledUnGroupUser && groupCode === 0)
                )
              }
            >
              {groupCode !== 0 ? (
                <UsersIcon
                  style={{
                    width: '13.01px',
                    height: '15.01px',
                    marginBottom: '2px',
                  }}
                />
              ) : (
                <OutLightIcon style={{ width: '11.01px', height: '11.01px' }} />
              )}
              <Typography
                fontSize="12px"
                lineHeight="17px"
                pl="5px"
                sx={{
                  color: !(
                    (disabledGroupUser && groupCode !== 0) ||
                    (disabledUnGroupUser && groupCode === 0)
                  )
                    ? '#009DE1'
                    : 'inherit',
                }}
              >
                {groupCode !== 0
                  ? t('userListModal.label.manageUsers')
                  : t('userListModal.label.viewUsers')}
              </Typography>
            </IconButton>
          )
        )}
      </Box>
    </Grid>
  );
});

export default GroupFeatureHeaderRight;
