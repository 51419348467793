import type {  GetLostModePinResponse } from '../types/apis/getLostModePinApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';

export const GET_LOST_MODE_PIN_API_RESULT_CODE = {
  OK: 'OK',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  NO_INTERNET: 'NO_INTERNET',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN'
} as const;

export type GET_LOST_MODE_PIN_ERROR_TYPE =
  typeof GET_LOST_MODE_PIN_API_RESULT_CODE[keyof typeof GET_LOST_MODE_PIN_API_RESULT_CODE];

const callGetLostModePin = (
  // accessKey: string,
  imei: string,
): Promise<GetLostModePinResponse> => {
  // It is an error if you do not pass an empty request parameter

  // Send request (POST)

  const response = callApiUtil
    .get<GetLostModePinResponse>(
      `${API_URL.GET_LOST_MODE_PIN_API}?imei=${imei}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callGetLostModePin;
