export const translationsOptions = [
  'All',
  'Previous Week',
  'This Week',
  'Previous Month',
  'This Month',
  'Previous Year',
  'This Year',
];

export const translationsOptionsForAreaChart = [
  'Previous Week',
  'This Week',
  'Previous Month',
  'This Month',
  'Previous Year',
  'This Year',
];

export const translationsOptionsObject = {
  ALL: 'All',
  PREVIOUS_WEEK: 'Previous Week',
  THIS_WEEK: 'This Week',
  PREVIOUS_MONTH: 'Previous Month',
  THIS_MONTH: 'This Month',
  PREVIOUS_YEAR: 'Previous Year',
  THIS_YEAR: 'This Year',
};

export const translationsOptionsObjectForAreaChart = {
  PREVIOUS_WEEK: 'Previous Week',
  THIS_WEEK: 'This Week',
  PREVIOUS_MONTH: 'Previous Month',
  THIS_MONTH: 'This Month',
  PREVIOUS_YEAR: 'Previous Year',
  THIS_YEAR: 'This Year',
};

export const labelsMonth = [
  '   Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec  ',
];

export const labelsMonthListTooltip = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec  ',
];

export const weekDaysShortName = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export const labelsMonthFullName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const labelsWeekDay = [
  '          Sun, 02',
  'Mon, 03',
  'Tue, 04',
  'Wed, 05',
  'Thu, 06',
  'Fri, 07',
  'Sat, 08        ',
];

export const labelsDate = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31   ',
];

export const translationLanguageData = [
  {
    language: 'Japanese',
    value: 450,
    color: '#428BFF',
  },
  {
    language: 'English',
    value: 345,
    color: '#69B6F8',
  },
  {
    language: 'Spanish',
    value: 200,
    color: '#B27EEF',
  },
  {
    language: 'French',
    value: 75,
    color: '#FF9964',
  },
  {
    language: 'Russian',
    value: 50,
    color: '#5BDFB4',
  },
  {
    language: 'Others',
    value: 30,
    color: '#D9DBE6',
  },
];
