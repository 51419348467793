/* eslint-disable import/prefer-default-export */
import callManageGroupDeviceListApi, {
  MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE,
  MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE,
} from '../../apis/callManageGroupDeviceList';

import callManageGroupDeviceListUpdate, {
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE,
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE,
} from '../../apis/callManageGroupDeviceListUpdate';

import {
  ManageGroupDeviceListApiResponse,
  ManageGroupDeviceListUpdateApiResponse,
} from '../../types/apis/manageGroupDeviceListApi.d';
import {
  ManageGroupDeviceListResult,
  ManageGroupDeviceListUpdateResult,
} from '../../types/Group/manageGroupDeviceList.d';
import {
  isGroupDeviceListUpdateErrorType,
  // isGroupListErrorType,
  isGroupDeviceListErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import { SERVER_MESSAGE } from '../../constants/constants';

export const fetchManageGroupDeviceList = async (
  // accessKey: string,
  groupId: number,
  pageNumber: number,
  searchtext: string,
): Promise<ManageGroupDeviceListResult> => {
  // Device list acquisition API call
  try {
    const apiResponse: ManageGroupDeviceListApiResponse =
      await callManageGroupDeviceListApi(
        // accessKey,
        groupId,
        pageNumber,
        searchtext,
      );
    switch (apiResponse.message) {
      // success
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode:
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          devices: apiResponse.details.devices,
          totalCount: apiResponse.details.totalCount,
        };
      // Failure
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_DEVICE:
        return {
          resultCode: MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_DEVICE,
          devices: apiResponse.details.devices,
          totalCount: apiResponse.details.totalCount,
        };
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH;
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP:
        throw MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP;
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_USER:
        throw MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_USER;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE =
      MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGroupDeviceListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      devices: [],
      totalCount: 0,
    };
  }
};

export const updateManageGroupDeviceList = async (
  // accessKey: string,
  groupId: number,
  addedDeviceListPayload: number[],
  removedDeviceListPayload: number[],
): Promise<ManageGroupDeviceListUpdateResult> => {
  // Device list Update API call
  try {
    const apiResponse: ManageGroupDeviceListUpdateApiResponse =
      await callManageGroupDeviceListUpdate(
        // accessKey,
        groupId,
        addedDeviceListPayload,
        removedDeviceListPayload,
      );
    switch (apiResponse.message) {
      // success
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED:
        return {
          resultCode:
            MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED,
        };
      // Failure
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_DEVICE:
        return {
          resultCode:
            MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_DEVICE,
        };
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP:
        throw MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP;
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM;
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH;
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED:
        throw MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED;
      default:
        throw MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    let resultCode: MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE =
      MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGroupDeviceListUpdateErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
    };
  }
};
