import React from 'react';
import {
  Box,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as WarningIcon } from '../../images/icon/warning.svg';
import Loader from './Loader';

type Props = {
  open: boolean;
  isCallingApi: boolean;
  onClose: () => void;
  onClickYesBtn: () => void;
};

const SecurityToggleDialog = React.memo<Props>((props): JSX.Element => {
  const { open, isCallingApi, onClose, onClickYesBtn } = props;

  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();

  return (
    <Dialog open={open} className={classes.securityToggleDialog}>
      <Box className={`${classes.dialogBox} res-dialog`}>
        <Box className={`${classes.dialogIcon} res-dialog-icon`}>
          <WarningIcon />
        </Box>
        <Box sx={{ width: '100%' }}>
          <DialogTitle className="res-dialog-title">
            <Box className="res-dialog-title-text">
              {t('SecuritySettings.dialog.confirmation')}
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                // GA tag
                sendGAEvent('press_btn', 'btn_name', 'exit');
                onClose();
              }}
              className={classes.securityDialogCloseIcon}
              disabled={isCallingApi}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={`${classes.securityDialogContent} res-dialog-text`}
          >
            <Typography
              variant="body2"
              className={classes.securityDialogSubContent}
            >
              {t('SecuritySettings.dialog.content')}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{ marginTop: '42px !important' }}
            className={`${classes.dialogActionsCommon} res-dialog-btns`}
          >
            <Button
              className={classes.btnAction}
              onClick={() => {
                // GA tag
                sendGAEvent('press_btn', 'btn_name', 'no');
                onClose();
              }}
              disabled={isCallingApi}
              disableRipple
              sx={{ minWidth: '82px', padding: '10px 29.5px !important' }}
            >
              {t('SecuritySettings.dialog.button.no')}
            </Button>
            <Button
              autoFocus
              className={`${classes.btnAction} ${classes.btnActionPrimary}`}
              onClick={onClickYesBtn}
              disabled={isCallingApi}
              disableRipple
              sx={{ minWidth: '84px' }}
            >
              {isCallingApi ? (
                <Loader colorCode="#fff" />
              ) : (
                t('SecuritySettings.dialog.button.yes')
              )}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
});

export default SecurityToggleDialog;
