/* eslint-disable import/prefer-default-export */
import callGetLostModePin, {
  GET_LOST_MODE_PIN_API_RESULT_CODE,
  GET_LOST_MODE_PIN_ERROR_TYPE,
} from '../../apis/callGetLostModePin';

import {
  GetLostModePinApiResponse,
  GetLostModePinResponse,
} from '../../types/apis/getLostModePinApi.d';

import { SERVER_MESSAGE } from '../../constants/constants';

export const getLostPin = async (
  imei: string,
): Promise<GetLostModePinApiResponse> => {
  try {
    const apiResponse: GetLostModePinResponse = await callGetLostModePin(imei);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_FETCHED':
        return {
          resultCode: GET_LOST_MODE_PIN_API_RESULT_CODE.OK,
          currentPin: apiResponse.details.currentPin,
        };
      // Failure
      case GET_LOST_MODE_PIN_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.WARN_INPUT_PARAM;
      case GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NO_DEVICE:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NO_DEVICE;
      case GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NOTHING_KEY;
      case GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_INVALID:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_INVALID;
      case GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_EXPIRED:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_EXPIRED;
      case GET_LOST_MODE_PIN_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.WARN_INVALID_AUTH;
        case SERVER_MESSAGE.NO_INTERNET:
          throw SERVER_MESSAGE.NO_INTERNET;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      default:
        throw GET_LOST_MODE_PIN_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    let resultCode: GET_LOST_MODE_PIN_ERROR_TYPE;
    if (error === GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NO_DEVICE) {
      resultCode = GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NO_DEVICE;
    } else if (error === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
      error === SERVER_MESSAGE.USER_ROLE_CHANGED ||
      error === SERVER_MESSAGE.INVALID_TOKEN ||
      error === SERVER_MESSAGE.EXPIRED_TOKEN ||
      error === SERVER_MESSAGE.NO_INTERNET) {
      resultCode = error;
    }
    else {
      resultCode = GET_LOST_MODE_PIN_API_RESULT_CODE.ERR_UNKNOWN;
    }

    //   if (isGroupListErrorType(error)) {
    //     resultCode = error;
    //   }

    return {
      resultCode,
      currentPin: '',
    };
  }
};
