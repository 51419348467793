import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { FilterInput } from '../types/inputs/filterInput';

/**
 * 本カスタムフックからの返却値
 */
export type UseFilterValue = {
  // ［検索］ボタンがクリックされた
  onClickFilterSearch: (inputData: FilterInput) => void;
  // 入力されたフィルター内容
  filterInputData: FilterInput;
};

/**
 * フィルタリング カスタムフック
 *
 * @returns
 */
const useFilter = (defaultSearchText: string): UseFilterValue => {
  // 入力されたフィルター内容 get/set
  const [filterInputData, setFilterInputData] = useState<FilterInput>({
    searchId: uuidv4(),
    searchText: defaultSearchText,
  });

  /**
   * ［検索］ボタンクリックがクリックされた
   */
  const onClickFilterSearch = useCallback((inputData: FilterInput): void => {
    // searchIdにUUIDセット
    // 検索ワードだけだとフィルタリング時に連続で同じ文言を検索したときにuseEffectにひっかからないので
    // 同じ文言が指定された場合でもIDを常に更新してフィルタリングできるようにする
    const newInput: FilterInput = {
      searchId: uuidv4(),
      searchText: inputData.searchText,
    };
    // 入力されたフィルター内容更新
    setFilterInputData(newInput);
  }, []);

  return {
    onClickFilterSearch,
    filterInputData,
  };
};

export default useFilter;
