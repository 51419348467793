import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { GroupDetailApiResponse } from '../types/apis/groupDetailApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

export const GROUP_DETAIL_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',
  CORPORATION_ACCESS_DENIED: 'CORPORATION_ACCESS_DENIED',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Unknown error
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
} as const;

/**
 * Group Add acquisition error type
 */
export type GROUP_DETAIL_API_ERROR_TYPE =
  (typeof GROUP_DETAIL_API_RESULT_CODE)[keyof typeof GROUP_DETAIL_API_RESULT_CODE];

const callGroupDetailApi = (
  // accessKey: string,
  groupId: string,
): Promise<GroupDetailApiResponse> => {
  // It is an error if you do not pass an empty request parameter

  // Send request (POST)

  const response = callApiUtil
    .get<GroupDetailApiResponse>(
      `${API_URL.GROUP_DETAIL_API}?groupId=${groupId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
      details: {
        groupId: 0,
        groupName: '',
        groupCode: 0,
        createdDt: '',
        createdUserId: 0,
        updatedDt: '',
        updatedUserId: 0,
        totalDeviceCount: 0,
      },
    }));

  return response;
};

export default callGroupDetailApi;
