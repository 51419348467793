/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useState } from 'react';
import { FETCH_PERSONAL_SETTINGS_ERROR_TYPE } from '../apis/callPersonalSettingsFetchApi';
import { UPDATE_PERSONAL_SETTINGS_ERROR_TYPE } from '../apis/callPersonalSettingsUpdateApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import { FetchPersonalSettingsApiResponse } from '../types/apis/personalSettingsApi.d';
import type { ModifiedLanguageInfo } from '../types/apis/languageListApi';
import {
  fetchPersonalSettingsUtil,
  updatePersonalSettingsUtil,
} from '../utils/user/personalSettingsUtil';
import useAccountInfo from './useAccountInfo';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { sendGAEvent } from './useGAEvent';
import useLocaleList from './useLocaleList';

export type UsePersonalSettings = {
  setSelectedFile: (changeValue: File | null) => void;
  selectedFile: File | null;
  handleOnChangeUserName: (changeValue: string) => void;
  handleOnChangeLanguage: (changeValue: string) => void;
  isChangeUserName: boolean;
  isChangeLanguage: boolean;
  onClickSaveBtn: (inputData: any) => void;
  isCallingApi: boolean;
  isCallingUpdateApi: boolean;
  personalSettingsData: {
    extension: string;
    imageUrl: string;
    userName: string;
    roleName: string;
    mailAddress: string;
    registeredDt: string;
    invitedDt: string;
    localeCode: string;
  };
  groupNameList: string[];
  updateSuccess: boolean;
  setApiImageRemoved: (changeValue: boolean) => void;
  localeList: ModifiedLanguageInfo[];
  isCallingLocaleListApi: boolean;
};

type UserData = {
  userName: string;
  localeCode: string;
  oldPassword: string | undefined;
  newPassword: string | undefined;
};

const usePersonalSettings = (): UsePersonalSettings => {
  const { saveUserName, saveLocale, saveImageUrl, accountInfo } =
    useAccountInfo();
  const { displaySnackbar } = useSnackbar();
  const { t } = useSwitchLocaleLanguage();

  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isChangeUserName, setIsChangeUserName] = useState<boolean>(false);
  const [isChangeLanguage, setIsChangeLanguage] = useState<boolean>(false);
  const [groupNameList, setGroupNameList] = useState<string[]>([]);
  const [apiImageRemoved, setApiImageRemoved] = useState<boolean>(false);
  const [personalSettingsData, setPersonalSettingsData] = useState<{
    extension: string;
    imageUrl: string;
    userName: string;
    roleName: string;
    mailAddress: string;
    registeredDt: string;
    invitedDt: string;
    localeCode: string;
  }>({
    extension: '',
    imageUrl: '',
    userName: '',
    roleName: '',
    mailAddress: '',
    registeredDt: '',
    invitedDt: '',
    localeCode: '',
  });

  // call localeList api hook
  const { localeList, isCallingLocaleListApi, callLocaleList } =
    useLocaleList();

  const handleOnChangeUserName = (changedValue: string): void => {
    if (personalSettingsData.userName !== changedValue?.trim()) {
      setIsChangeUserName(true);

      return;
    }

    setIsChangeUserName(false);
  };
  const handleOnChangeLanguage = (changedValue: string): void => {
    if (personalSettingsData.localeCode !== changedValue) {
      setIsChangeLanguage(true);

      return;
    }

    setIsChangeLanguage(false);
  };

  const fetchPersonalSettingsData = useCallback(() => {
    setIsCallingApi(true);

    fetchPersonalSettingsUtil()
      .then((result: FetchPersonalSettingsApiResponse) => {
        if (
          result.message !== SERVER_MESSAGE.FETCH_OK &&
          result.message !== SERVER_MESSAGE.USER_ROLE_CHANGED &&
          result.message !== SERVER_MESSAGE.USER_CONSENT_REQUIRED &&
          result.message !== SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED &&
          result.message !== SERVER_MESSAGE.INVALID_TOKEN &&
          result.message !== SERVER_MESSAGE.EXPIRED_TOKEN &&
          result.message !== SERVER_MESSAGE.USER_DEACTIVATED
        ) {
          displaySnackbar({
            message:
              result.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED
                ? t('common.error.accessDenied')
                : t('common.error.serverErr'),
            timeout: 3005,
            type: 'error',
          });
        }
        if (result.message === SERVER_MESSAGE.FETCH_OK) {
          setPersonalSettingsData(result.details);
          setGroupNameList(result.details.groupNameList);
          saveUserName(result.details.userName && result.details.userName);
          saveLocale(result.details.localeCode && result.details.localeCode);
          saveImageUrl(result.details.imageUrl && result.details.imageUrl);
        }
      })
      .catch((error: FETCH_PERSONAL_SETTINGS_ERROR_TYPE | unknown) => {
        if (error === SERVER_MESSAGE.USER_ROLE_CHANGED) {
          // console.log(error)
        }
      })
      .finally(() => {
        setIsCallingApi(false);
      });
    // eslint-disable-next-line
  }, []);

  const callUpdatePersonalSettingsApi = useCallback(
    (formData: FormData, localeCode: string) => {
      setIsCallingUpdateApi(true);
      setUpdateSuccess(false);
      updatePersonalSettingsUtil(formData)
        .then((result: any) => {
          if (result.message === SERVER_MESSAGE.UPDATE_OK) {
            saveLocale(localeCode && localeCode);
            setUpdateSuccess(true);
            setIsChangeUserName(false);
            setIsChangeLanguage(false);
            displaySnackbar({
              message: t('personalSetting.message.userInformationUpdated'),
              timeout: 3006,
            });
            setApiImageRemoved(false);
            callLocaleList();
            fetchPersonalSettingsData();
            setSelectedFile(null);
          }
          if (result.message === SERVER_MESSAGE.WRONG_CREDENTIALS) {
            displaySnackbar({
              message: t('personalSetting.message.oldPassNotCorrect'),
              timeout: 3002,
              type: 'error',
            });
          }
          if (result.message === SERVER_MESSAGE.WARN_INPUT_PARAM) {
            displaySnackbar({
              message: t('personalSetting.message.imgType'),
              timeout: 3002,
              type: 'error',
            });
          }
          if (result.message === SERVER_MESSAGE.OLD_AND_NEW_PASSWORD_SAME) {
            displaySnackbar({
              message: t('common.validationError.password.samePass'),
              timeout: 3005,
              type: 'error',
            });
          }
          
        })
        .catch((error: ALL_ERROR_TYPE) => {
          setUpdateSuccess(false);
          console.log(error);
          displaySnackbar({
            message: t('common.error.serverErr'),
            timeout: 3005,
            type: 'error',
          });
        })
        .finally(() => {
          setIsCallingUpdateApi(false);
        });
    },
    // eslint-disable-next-line
    [fetchPersonalSettingsData],
  );

  const onClickSaveBtn = (inputData: UserData) => {
    const formData = new FormData();
    formData.append('userName', inputData.userName);
    formData.append('localeCode', inputData.localeCode);
    if (inputData.oldPassword && inputData.newPassword) {
      formData.append('oldPassword', inputData.oldPassword);
      formData.append('newPassword', inputData.newPassword);
    }
    // GA tags
    sendGAEvent('press_btn', 'btn_name', 'save');
    sendGAEvent('press_btn', 'save_value', accountInfo.userId.toString());
    sendGAEvent('press_btn', 'save_value', accountInfo.email);
    // setting fileflag for deleting file, flag 1 means delete the file.
    if (
      selectedFile ||
      !personalSettingsData.imageUrl ||
      (personalSettingsData.imageUrl && !apiImageRemoved)
    ) {
      formData.append('fileFlag', '0');
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
    } else {
      formData.append('fileFlag', '1');
    }

    callUpdatePersonalSettingsApi(formData, inputData.localeCode);
  };

  useEffect(() => {
    fetchPersonalSettingsData();
  }, [fetchPersonalSettingsData]);

  return {
    setSelectedFile,
    selectedFile,
    handleOnChangeUserName,
    handleOnChangeLanguage,
    isChangeUserName,
    isChangeLanguage,
    onClickSaveBtn,
    isCallingApi,
    isCallingUpdateApi,
    personalSettingsData,
    groupNameList,
    updateSuccess,
    setApiImageRemoved,
    localeList,
    isCallingLocaleListApi,
  };
};

export default usePersonalSettings;
