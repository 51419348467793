import React, { useState, useEffect } from 'react';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useStyles } from '../Dialog/DialogStyle';
import { ReactComponent as FilterIcon } from '../../images/icon/Filter_alt_light.svg';

type Props = {
  placeholder?: string;
  clickFilterSearch: (val: string | '') => void;
  clickIcon: (val: boolean) => void;
};

const ManageGroupSearch = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.clickFilterSearch(searchText);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <FormControl variant="outlined">
      <OutlinedInput
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        className={classes.searchInputFilter}
        value={searchText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchText(e.target.value)
        }
        placeholder={props.placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                props.clickIcon(false);
              }}
              sx={{
                marginLeft: '2px',
                p: '6px',
              }}
              aria-label="search"
            >
              <FilterIcon />
            </IconButton>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{ 'aria-label': 'search', maxLength: 256 }}
        autoFocus
      />
    </FormControl>
  );
});

export default ManageGroupSearch;
