/* eslint-disable */

import _dayjs from "dayjs";

import  en from 'dayjs/locale/en';

import _weekOfYear from "dayjs/plugin/weekOfYear";

import dates from "./dates";

import _cx from "./cx";

_dayjs.locale({
  ...en,
  weekStart: 1,
});
_dayjs.extend(_weekOfYear);



var unitType = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
  YEAR: "YEAR",
};
var interval = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};
var positions = {
  LEFT: "left",
  RIGHT: "right",
};

function getMonthNamesForLocale(locale) {
  var format = new Intl.DateTimeFormat(locale, {
    month: "long",
  });
  var months = [];

  for (var month = 0; month < 12; month++) {
    var testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
    months.push(format.format(testDate));
  }

  return months;
}

function getNavigatorLanguage() {
  const userLanguage = document.documentElement.lang
return userLanguage
  // return navigator.languages && navigator.languages.length
  //   ? navigator.languages[0]
  //   : navigator.userLanguage ||
  //       navigator.language ||
  //       navigator.browserLanguage ||
  //       "en";
}

var getDefaultState = function getDefaultState() {
  var calendar = (0, _dayjs)();
  var startOfWeek = calendar.startOf("week");
  var endOfWeek = calendar.endOf("week");
  var startDate = undefined;
  var endDate = undefined;
  var range = endDate || startDate;
  var minDate = calendar.clone().subtract(50, "year");
  var maxDate = calendar.clone().add(50, "year");
  var maxSpan = {
    years: 5,
  };
  var autoApply = false;
  var singleDatePicker = false;
  var showDropdowns = true;
  var closedOrOpen = interval.CLOSED;
  var minYear = calendar.clone().subtract(70, "year").format("YYYY");
  var maxYear = calendar.clone().add(70, "year").format("YYYY");
  var showWeekNumbers = false;
  var showISOWeekNumbers = false;
  var showCustomRangeLabel = false;
  var timePicker = false;
  var timePicker24Hour = false;
  var timePickerIncrement = 1;
  var timePickerSeconds = false;
  var alwaysShowCalendars = false;
  var ranges = {};
  var opens = "left";
  var drops = "down";
  var showPicker="none";
  var buttonClasses = "btn btn-sm";
  var applyButtonClasses = "btn-primary";
  var cancelButtonClasses = "btn-default";

  var isInvalidDate = function isInvalidDate() {
    return false;
  };

  var isCustomDate = function isCustomDate() {
    return [];
  };

  var weekLabel = "W";
  var linkedCalendars = true;
  var weekNames = [];

  for (var s = startOfWeek; s <= endOfWeek; ) {
    weekNames.push(s.format("dd"));
    s = s.add(1, "day");
  }

  var language = getNavigatorLanguage();
  var monthNames = getMonthNamesForLocale(language);
  var sY = parseInt(minYear);
  var eY = parseInt(maxYear);
  var yearNames = [];

  for (; sY < eY; sY++) {
    yearNames.push(sY);
  }
  const  weekNamesEn = [ "MON", "TUE", "WED", "THU", "FRI", "SAT","SUN"]
  const weekNamesJa = ["月", "火", "水", "木", "金", "土", "日"]; 
const weekNamesFr = ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"];
const weekNamesDe = ["MO", "DI", "MI", "DO", "FR", "SA", "SO"];

const localeWeekNames = language ==='ja'? weekNamesJa
                        :language ==='fr'?weekNamesFr 
                        :language ==='de'?weekNamesDe 
                        :weekNamesEn
  var locale = {
    monthNames: monthNames,
    weekNames: localeWeekNames,
    language: language,
    weekLabel: weekLabel,
    yearNames: yearNames,
    format: "YYYY/MM/DD",
    firstDay: 1,
  };
  return {
    locale: locale,
    calendar: calendar,
    startDate: startDate,
    endDate: endDate,
    minDate: minDate,
    maxDate: maxDate,
    maxSpan: maxSpan,
    showDropdowns: showDropdowns,
    minYear: minYear,
    maxYear: maxYear,
    showWeekNumbers: showWeekNumbers,
    showISOWeekNumbers: showISOWeekNumbers,
    timePicker: timePicker,
    timePickerIncrement: timePickerIncrement,
    timePicker24Hour: timePicker24Hour,
    timePickerSeconds: timePickerSeconds,
    ranges: ranges,
    showCustomRangeLabel: showCustomRangeLabel,
    alwaysShowCalendars: alwaysShowCalendars,
    opens: opens,
    drops: drops,
    singleDatePicker: singleDatePicker,
    linkedCalendars: linkedCalendars,
    buttonClasses: buttonClasses,
    applyButtonClasses: applyButtonClasses,
    cancelButtonClasses: cancelButtonClasses,
    autoApply: autoApply,
    isInvalidDate: isInvalidDate,
    isCustomDate: isCustomDate,
    weekLabel: weekLabel,
    closedOrOpen: closedOrOpen,
    range: range,
    showPicker:showPicker
  };
};


const totalExport={
  getMonthNamesForLocale,
  getNavigatorLanguage,
  dayjs:_dayjs,
  cx:_cx,
  dates:dates,
  positions:positions,
  interval:interval,
  unitType:unitType,
  getDefaultState:getDefaultState
}
export default totalExport;