import React, { useState } from 'react';
import { Typography, Box, Grid, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import {
  FEATURE_PERMISSION,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../../constants/constants';
import useTransHistoryList from '../../hooks/useTransHistoryList';
import { useStyles } from '../Templates/CommonStyle';
import tablestyles from '../Templates/GroupDeviceListTable.module.scss';
import TableTemplateContainer from '../Templates/TableTemplateContainer';
import { ReactComponent as NoDataIcon } from '../../images/icon/reports-no-data-icon.svg';
import { ReactComponent as ExportIcon } from '../../images/icon/export.svg';
import { ReactComponent as CalenderLogo } from '../../images/icon/calender.svg';
import DateRangeReact from '../Atoms/DateRangeReact';
import SearchField from '../Modecules/SearchField';
import Loader from '../Atoms/Loader';
import useAccountInfo from '../../hooks/useAccountInfo';
/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // 端末の翻訳履歴を表示したIMEI
  imei: string;
};

/**
 * 翻訳履歴画面 コンポーネント
 */
const TransHistoryList = React.memo<Props>(({ imei }): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  const [rangePickerOpen, setRangePickerOpen] = useState(false);
  const { accountInfo } = useAccountInfo();

  // 翻訳履歴画面 hooks
  const {
    t,
    isCallingApi,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    clickDownload,
    sortValue,
    filterValue,
    paginationValue,
    // dateRangeValue,
    // downloadDate,
    // dateRangeErrorValue,
    targetImei,
    // startDatePickerClosed,
    // endDatePickerClosed,
    // datePickerInputCompleted,
    isCallingReportApi,
    dateRangeValue,
    isFirstRenderingRef,
    simpleBarRef,
  } = useTransHistoryList(imei);

  const [searchText, setSearchText] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>(
    `${dayjs().startOf('month').format('YYYY/MM/DD')}-${dayjs().format(
      'YYYY/MM/DD',
    )}`,
  );
  // Reg Date selection
  const handleDate = (data: string) => {
    setSelectedDate(data);
    setSelectedDate(data);
    const dateArray = data.split('-');
    const startDate = dateArray[0];
    const endDate = dateArray[1];
    dateRangeValue.changeStartDate(new Date(startDate));
    dateRangeValue.changeEndDate(new Date(endDate));
  };

  return (
    <Box
      sx={{
        marginTop: '49px',
      }}
    >
      <Box className={`${classes.transHistorySearchCard} trans-history-card`}>
        <SearchField
          clickFilterSearch={(val) => {
            const tempVal = decodeURIComponent(val).trim();
            setSearchText(encodeURIComponent(tempVal));
            filterValue.onClickFilterSearch({
              searchId: uuidv4(),
              searchText: encodeURIComponent(tempVal),
            });
          }}
          placeholder={t('transHistoryList.placeholder.search')}
          responsiveClass="w-full"
        />
        <Box sx={{ display: 'flex' }} className="trans-history-date">
          <Box
            className={`${classes.transHistoryFilterCard} trans-history-date-row`}
          >
            <Typography
              className={`${classes.historyFormText} trans-history-date-row-text`}
            >
              {t('transHistoryList.label.historyFrom')}
            </Typography>
            <Box
              sx={{ width: '230px' }}
              className="trans-history-date-row-input"
            >
              <Box
                className={`${classes.dateRangeBox} trans-history-date-row-input-control`}
                sx={{
                  border: rangePickerOpen
                    ? '1px solid #939598'
                    : '1px solid #D9D9D9',
                }}
                onClick={() => {
                  setRangePickerOpen(!rangePickerOpen);
                  isFirstRenderingRef.current = true;
                }}
              >
                <CalenderLogo className={classes.calenderLogo} />
                <DateRangeReact
                  parentElementState={setRangePickerOpen}
                  state={handleDate}
                  value={selectedDate}
                  maxDate={new Date()}
                />
              </Box>
            </Box>
          </Box>
          {/* eslint-disable-next-line */}
          {accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICETRANSLATIONHISTORY?.includes(
            FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICETRANSLATIONHISTORY.EXPORT,
          ) && (
            <Button
              aria-label="ExportData"
              disabled={
                !targetImei ||
                dateRangeValue?.startDate?.toDateString() === 'Invalid Date' ||
                dateRangeValue?.endDate?.toDateString() === 'Invalid Date' ||
                isCallingReportApi
              }
              className={classes.exportDataBtn}
              disableRipple
              onClick={clickDownload}
            >
              {isCallingReportApi ? (
                <Box className="export-data-btn-box">
                  <Loader colorCode="#fff" />
                  <Typography
                    className={`${classes.exportDataText} export-data-text`}
                  >
                    {t('reportDownloadButton')}
                  </Typography>
                </Box>
              ) : (
                <Box className="export-data-btn-box">
                  <ExportIcon />
                  <Typography
                    className={`${classes.exportDataText} export-data-text`}
                  >
                    {t('reportDownloadButton')}
                  </Typography>
                </Box>
              )}
            </Button>
          )}
        </Box>
      </Box>

      {totalListCount === 0 && (
        <Box component="main" className={classes.transHistoryCard}>
          <Typography
            className={`${classes.tableTransHistoryTitleText} text-small-center`}
          >
            {t('transHistoryList.label.tranHistoryList')} (0)
          </Typography>

          <Grid
            container
            style={{ height: '100%' }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <NoDataIcon />
            <Typography
              className={`${classes.tableTransHistoryNoDataTextOne} no-trans-text text-small-center`}
            >
              {t('common.error.noData')}
            </Typography>
            {searchText && (
              <Typography
                className={`${classes.tableTransHistorySearchText} no-trans-text text-small-center`}
              >
                {t('common.error.tryAgain')}
              </Typography>
            )}
          </Grid>
        </Box>
      )}
      {totalListCount > 0 && (
        <Box className={classes.tableTransHistoryCard}>
          <div className={tablestyles['device-table-container']}>
            <TableTemplateContainer
              simpleBarRef={simpleBarRef}
              headerColumns={headerColumns}
              dataRows={currentPageDataRows}
              totalDataRowsLabel="TranslationHistoryList"
              totalDataRows={totalListCount}
              clickSortButton={sortValue.onClickSortButton}
              sortConfig={sortValue.sortConfig}
              currentPage={paginationValue.currentPage}
              totalPages={paginationValue.totalPages}
              clickPrevPageButton={paginationValue.onClickPrevPage}
              clickNextPageButton={paginationValue.onClickNextPage}
              clickFirstPageButton={paginationValue.onClickFirstPage}
              clickLastPageButton={paginationValue.onClickLastPage}
              rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
              currentRowNumberPerPage={paginationValue.currentRowNumberPerPage}
              changeRowNumber={paginationValue.onChangeRowNumber}
              pageDataIndexFrom={paginationValue.pageDataIndexFrom}
              pageDataIndexTo={paginationValue.pageDataIndexTo}
              isCallingApi={isCallingApi}
              tableSrcPage="TransHistory"
              containFirstAndLastButton
            />
          </div>
        </Box>
      )}
    </Box>
  );
});

export default TransHistoryList;
