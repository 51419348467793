import type { FromLangInfo } from '../apis/fromLangListApi';
import { FROM_LANG_LIST_ERROR_TYPE } from '../../apis/callFromLangListApi';
import type { TableHeaderData } from '../datatable/dataTable.d';

/**
 * 翻訳元言語一覧の取得結果 の型
 */
export type FromLangListResult = {
  resultCode: FROM_LANG_LIST_ERROR_TYPE;
  fromLangInfos: FromLangInfo[] | null;
  tableHeader: TableHeaderData[];
  viewType: REPORT_VIEW_TYPE;
};

/**
 * テーブルデータの表示幅
 */
export const FROM_LANG_WIDTH_ARRAY: string[] = [
  '190px', // 言語
  '110px', // 利用回数
];
export type REPORT_VIEW_TYPE = 'd' | 'w' | 'm' | '';
