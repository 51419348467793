import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';

import { API_URL } from '../constants/constants';
import {
  CorporateDetailsApiRequestParameter,
  CorporateDetailsApiResponse,
} from '../types/apis/corporateDetailsApi.d';

/**
 * Corporate Details Acquisition API Result Code
 */
export const CORPORATE_DETAILS_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  INFO_NO_CORPORATION_DETAIL: 'INFO_NO_CORPORATION_DETAIL',
  CORP_DETAIL_NOT_FOUND: 'CORP_DETAIL_NOT_FOUND',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
} as const;

/**
 * Corporate profile acquisition error type
 */
export type CORPORATE_DETAILS_API_ERROR_TYPE =
  (typeof CORPORATE_DETAILS_API_RESULT_CODE)[keyof typeof CORPORATE_DETAILS_API_RESULT_CODE];

/**
 * Corporate Details acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the Corporate Details acquisition API
 */

const callGetCorporateDetailsApi = (
  requestParams: CorporateDetailsApiRequestParameter,
): Promise<CorporateDetailsApiResponse> => {
  // Send request (GET)
  const response = callApiUtil
    .get<CorporateDetailsApiResponse>(
      `${API_URL.GET_CORPORATE_DETAILS}?corpId=${requestParams.corpId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
      details: {
        corporateContactResponse: {
          corpId: '',
          email: '',
        },
        corporateInfoResponse: { corporateName: 'Name' },
        subscriptionInfoResponse: {
          subscriptionLink: '',
          currentPlan: '',
          expiredDate: '',
          nextBillingDate: '',
          trialFlag: false,
        },
        orderInfoResponse: { orderHistoryLink: '' },
        paymentInfoResponse: { paymentInfoLink: '' },
      },
    }));

  return response;
};

export default callGetCorporateDetailsApi;
