/* eslint-disable  */
import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import {
  DownloadDate,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../../types/download/downloadDate';
import { UseDateRangeValue } from '../../hooks/useDateRange';
import { useStyles } from './CommonStyle';

import { ReactComponent as ExportIcon } from '../../images/icon/exportDataIcon.svg';
import { ReactComponent as CloseGray } from '../../images/icon/close-gray.svg';

import useSnackbar from '../../hooks/useSnackbar';
import Loader from '../Atoms/Loader';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import MonthRangePickerInput from '../Modecules/monthRangePickerInput';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // ダイアログ(モーダル)を表示するか否か
  isModalOpen: boolean;
  // ダイアログを閉じる
  closeModal: () => void;
  // ダウンロード処理
  onClickDownload: () => void;
  // 日付範囲に関する値
  dateRangeValue: UseDateRangeValue;
  // ダウンロード日付選択に関する値
  downloadDate: DownloadDate;
  // エラータイプ
  alertType?: DOWNLOAD_DATE_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage?: string;
  isCallingReportApi: boolean;
};

/**
 * ログデータダウンロードダイアログ コンポーネント
 * ローカルでは動作確認できない(ステージングで動作確認済み)
 *
 * @param param0
 * @returns
 */
const LogDataDownloadDialog = ({
  isModalOpen,
  closeModal,
  dateRangeValue,
  downloadDate,
  errorMessage,
  alertType,
  onClickDownload,
  isCallingReportApi,
}: Props): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();
  const [rangePickerOpen, setRangePickerOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState('');
  const { displaySnackbar } = useSnackbar();

  React.useEffect(() => {
    if (errorMessage && alertType !== null) {
      displaySnackbar({ message: errorMessage, type: 'error' });
    }
  }, [errorMessage, alertType]);

  const closeHandler = () => {
    closeModal();
    setSelectedDate('');
  };

  React.useEffect(() => {
    setSelectedDate('');
  }, [isModalOpen]);

  const dateRangeHandleChange = (date: Date[]) => {
    const startDate = date[0];
    const endDate = date[1];
    dateRangeValue.changeStartDate(new Date(startDate));
    dateRangeValue.changeEndDate(new Date(endDate));
  };

  return (
    <Dialog
      open={isModalOpen}
      PaperProps={{
        style: {
          borderRadius: '12px',
          backgroundColor: '#ffffff',
          width: '500px',
          overflow: 'unset',
        },
      }}
    >
      <Box className={classes.exportLogDataMainBox}>
        <Box
          onClick={() => {
            closeHandler();
            // GA tag
            sendGAEvent('press_btn', 'btn_name', 'exit');
          }}
          className={classes.exportLogDataCloseIcon}
        >
          <CloseGray />
        </Box>
        <Box height="72px" width="72px">
          <ExportIcon />
        </Box>
        <Box className={classes.exportLogDataDetailsBox}>
          <Typography className={classes.exportLogDataTitleText}>
            {t('logDataDownloadDialog.label.message1')}
          </Typography>
          <Typography className={classes.exportLogDataText}>
            {t('logDataDownloadDialog.label.message2')}
          </Typography>

          <Box sx={{ width: '249px' }} className="trans-count-filter-item-date">
            <Box className="trans-count-filter-item-date-control">
              <MonthRangePickerInput onApply={dateRangeHandleChange} />
            </Box>
          </Box>
          <Box className={classes.exportLogDataBtnBox}>
            <Button
              variant="contained"
              className={classes.exportLogDataCancleBtn}
              onClick={() => {
                closeHandler();
                // GA tag
                sendGAEvent('press_btn', 'btn_name', 'cancel');
              }}
            >
              {t('logDataDownloadDialog.label.cancelButton')}
            </Button>

            <Button
              variant="contained"
              disabled={isCallingReportApi || alertType !== null}
              className={classes.exportLogDataExportBtn}
              sx={{
                '&.Mui-disabled': {
                  color: isCallingReportApi ? 'white' : 'rgba(0, 0, 0, 0.26)',
                  backgroundColor: isCallingReportApi
                    ? '#009DE1'
                    : 'rgba(0, 0, 0, 0.12)',
                  opacity: isCallingReportApi ? 0.5 : 'unset',
                },
              }}
              onClick={() => {
                // GA tag
                sendGAEvent('press_btn', 'btn_name', 'export');

                onClickDownload();
              }}
            >
              {isCallingReportApi ? (
                <Box className={classes.exportLogDataExportBtnBox}>
                  <Loader colorCode="#fff" />{' '}
                  {t('logDataDownloadDialog.label.downloadButton')}
                </Box>
              ) : (
                t('logDataDownloadDialog.label.downloadButton')
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LogDataDownloadDialog;
