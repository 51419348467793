import React, { useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as ConsoleSetting } from '../../images/icon/console-setting.svg';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  role: string;
  groupNameList: string[];
  isCallingApi: boolean;
};
const PersonalConsoleSettingCard = React.memo<Props>(
  ({ role, groupNameList, isCallingApi }): JSX.Element => {
    // common styles
    const classes = useStyles();

    const { t } = useSwitchLocaleLanguage();

    const [tooltipEnabledGroupName, setTooltipEnabledGroupName] =
      useState(false);
    const handleShouldShowGroupNameTooltip = (
      event: React.MouseEvent<Element>,
    ) => {
      const element = event.currentTarget as HTMLElement;
      const clientWidth = element.getBoundingClientRect().width;

      // Set the element's overflow to visible
      element.style.overflow = 'visible';
      element.style.maxWidth = 'fit-content';

      // Get the scroll width
      const scrollWidth = element.getBoundingClientRect().width;

      // Set the element's overflow back to hidden
      element.style.overflow = 'hidden';
      element.style.maxWidth = '142px';

      if (scrollWidth > clientWidth) {
        setTooltipEnabledGroupName(true);
      } else {
        setTooltipEnabledGroupName(false);
      }
    };
    // const finalGroupName = (name: any) => {
    //   let decodedGroupName: string;
    //   try {
    //     decodedGroupName = decodeURIComponent(
    //       JSON.stringify(name)?.slice(1, -1),
    //     );
    //   } catch (err) {
    //     decodedGroupName = JSON.stringify(name)?.slice(1, -1);
    //   }

    //   return decodedGroupName;
    // };

    return (
      <Box className={classes.personalConsoleSettingCard}>
        <Box className={classes.personalConsoleSettingTitleCard}>
          <ConsoleSetting />
          <Typography className={classes.personalConsoleSettingCardTitle}>
            {t('personalSetting.label.consoleSetting')}
          </Typography>
        </Box>
        <Box
          className={`${classes.personalConsoleSettingRoleCard} personal-console-setting`}
        >
          <Typography className={classes.personalConsoleSettingRoleText}>
            {t('personalSetting.label.role')}:
          </Typography>
          {isCallingApi && (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{
                p: '0px',
                height: '20px',
                width: '60px',
              }}
            />
          )}
          {!isCallingApi && (
            <Typography
              sx={{
                fontSize: '14px',
              }}
            >
              {role}
            </Typography>
          )}
        </Box>
        <Box className="personal-console-setting">
          <Typography
            className={`${classes.personalConsoleSettingGroupTitle} personal-console-setting`}
          >
            {t('personalSetting.label.groups')}:
          </Typography>
          <Box
            sx={{
              width: '100%',
            }}
          >
            {isCallingApi && (
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  p: '0px',
                  height: '20px',
                  width: '60px',
                }}
              />
            )}
            {!isCallingApi && (
              <SimpleBarReact
                style={{
                  maxHeight: '258px',
                  marginRight: '-5px',
                }}
              >
                <Box className={classes.personalConsoleSettingCardGroupBox}>
                  {groupNameList?.map((name) => (
                    <Box
                      className={classes.personalConsoleSettingCardGroupNameBox}
                    >
                      <Tooltip
                        placement="top"
                        title={name}
                        onMouseEnter={handleShouldShowGroupNameTooltip}
                        disableHoverListener={!tooltipEnabledGroupName}
                        arrow
                        sx={{ marginTop: 0 }}
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [-7, -7],
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            className: classes.tooltipStyle,
                          },
                        }}
                      >
                        <Typography
                          className={classes.consoleSettingCardSingleGroupName}
                        >
                          {name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ))}
                </Box>
              </SimpleBarReact>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

export default PersonalConsoleSettingCard;
