/* eslint-disable import/prefer-default-export */
import callManageUnGroupUserListApi, {
  MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE,
  MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE,
} from '../../apis/callManageUnGroupUserList';
import { SERVER_MESSAGE } from '../../constants/constants';
import { isManageUnGroupUserListErrorType } from '../../types/apis/apiErrorTypeGuard';

import { ManageGroupUserListApiResponse } from '../../types/apis/manageGroupUserListApi.d';
import { ManageGroupUserListResult } from '../../types/Group/manageGroupUserList.d';

export const fetchManageUnGroupUserList = async (
  // accessKey: string,
  groupId: number,
  pageNumber: number,
  searchtext: string,
): Promise<ManageGroupUserListResult> => {
  // User list acquisition API call
  try {
    const apiResponse: ManageGroupUserListApiResponse =
      await callManageUnGroupUserListApi(
        // accessKey,
        groupId,
        pageNumber,
        searchtext,
      );
    switch (apiResponse.message) {
      // success
      case MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode:
            MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          users: apiResponse.details.users,
          totalCount: apiResponse.details.totalCount,
        };
      // Failure
      case MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER:
        return {
          resultCode: MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER,
          users: apiResponse.details.users,
          totalCount: apiResponse.details.totalCount,
        };
      case MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP:
        throw MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP;
      case MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
        throw MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED;
      case MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;

      default:
        throw MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE =
      MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isManageUnGroupUserListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      users: [],
      totalCount: 0,
    };
  }
};
