import { AxiosError } from 'axios';
import { API_URL } from '../constants/constants';
import { NotificationReadApiResponse } from '../types/apis/notificationReadApi.d';
import callApiUtil from '../utils/callApiUtil';

const callNotificatioReadApi = (
  requestParams: number,
): Promise<NotificationReadApiResponse> => {
  // Send request (POST)
  const jsonData = {
    notificationDetailId: requestParams,
  };
  const response = callApiUtil
    .post<NotificationReadApiResponse>(
      API_URL.NOTIFICATION_READ_API,
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
    }));

  return response;
};

export default callNotificatioReadApi;
