/* eslint-disable */
export enum EapMethodEnum {
  EapType = 'eapType',
  EapMethod = 'eapMethod',
  PEAP = 'peap',
  TLS = 'tls',
  AnonymousIdentity = 'anonymousIdentity',
}

export enum ProxyEnum {
  ProxyHostName = 'proxyHostName',
  ProxyPort = 'proxyPort',
  BypassProxy = 'bypassProxy',
  PacUrl = 'pacUrl',
}

export enum SettingTypeEnum {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum WifiSettingTypeEnum {
  IpAddress = 'ipAddress',
  Gateway = 'gateway',
  NetworkPrefixLength = 'networkPrefixLength',
  DNS1 = 'dns1',
  DNS2 = 'dns1',
}
