import { useForm } from 'react-hook-form';
import {
  Box,
  Container,
  Button,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';
import PageTemplate from '../Templates/PageTemplate';
import { useStyles } from '../Templates/CommonStyle';
import '../../locales/locales';
import styles from './DeviceDeregisterComplete.module.scss';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useDeviceDeregisterComplete from '../../hooks/useDeviceDeregisterComplete';

/**
 * 端末登録解除完了画面 コンポーネント
 *
 * @returns
 */
const DeviceDeregisterComplete = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  const { onClickGoBack, simInfo } = useDeviceDeregisterComplete();

  // バリデーションフォーム作成
  const { handleSubmit } = useForm({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  return (
    <PageTemplate title={t('deviceDeregister.complete.viewName')}>
      <Container>
        <form
          className={styles['device-deregister-complete-form']}
          onSubmit={handleSubmit(onClickGoBack)}
        >
          <div>
            <div className={styles['device-deregister-complete-message']}>
              <p>{t('deviceDeregister.complete.label.message')}</p>
            </div>

            <div>
              <Table className={classes.deviceDeregisterTable}>
                <tbody>
                  <TableRow>
                    <TableCell variant="head">
                      {t('deviceDeregister.complete.label.deviceName')} :
                    </TableCell>
                    <TableCell id="deviceName">
                      {simInfo ? simInfo.deviceName : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      {t('deviceDeregister.complete.label.productType')} :
                    </TableCell>
                    <TableCell id="productType">
                      {simInfo ? simInfo.productType : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      {t('deviceDeregister.complete.label.imei')} :
                    </TableCell>
                    <TableCell id="imei">
                      {simInfo ? simInfo.imei : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      {t('deviceDeregister.complete.label.iccid')} :
                    </TableCell>
                    <TableCell id="iccid">
                      {simInfo ? simInfo.iccid : ''}
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </div>
          </div>

          <Box sx={{ pt: 5 }}>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              style={{ textTransform: 'none' }}
              className={classes.deviceDetailButton}
            >
              {t('deviceDeregister.complete.label.goBackButton')}
            </Button>
          </Box>
        </form>
      </Container>
    </PageTemplate>
  );
};

export default DeviceDeregisterComplete;
