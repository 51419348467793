/* eslint-disable */
"use strict";
import  dayjs from"dayjs";

import _classnames  from "classnames";

import dates from "./dates"

const cx=(props)=> {
  var day = props.day,
      calendar = props.calendar,
      startDate = props.startDate,
      endDate = props.endDate,
      range = props.range,
      maxDate = props.maxDate,
      minDate = props.minDate;
  var weekend = dates.isWeekend(day);
  var inRange = dates.isBetweenExclusive(startDate, endDate, day) || dates.isBetweenExclusive(startDate, range, day);
  var isEndDate = endDate && day.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD");
  var isStartDate = startDate && day.format("YYYY-MM-DD") === startDate.format("YYYY-MM-DD");
  var active = isStartDate || isEndDate;
  var off = calendar.month() !== day.month() || dates.isNotBetween(minDate, maxDate, day);
  var disabled = dates.isNotBetween(minDate, maxDate, day);
  var available = !disabled;
  const today=dayjs().format("DD/MM/YYYY");
  let isToday;
  if (day.format("DD/MM/YYYY")===today) {
    isToday=true;
  }
  return (0, _classnames["default"])({
    weekend: weekend,
    "in-range": inRange,
    "end-date": isEndDate,
    "start-date": isStartDate,
    active: active,
    off: off,
    disabled: disabled,
    available: available,
    today: isToday && "today"
  });
}

export default cx;