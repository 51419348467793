import type { TransCountInfo } from '../apis/transCountListApi.d';
import { TRANS_COUNT_LIST_ERROR_TYPE } from '../../apis/callTransCountListApi';
import { TableHeaderData } from '../datatable/dataTable.d';

/**
 * 翻訳回数一覧の取得結果 の型
 */
export type TransCountListResult = {
  resultCode: TRANS_COUNT_LIST_ERROR_TYPE;
  transCountInfos: TransCountInfo[] | null;
  tableHeader: TableHeaderData[];
  viewType: string;
};

/**
 * テーブルデータの表示幅
 */
export const TRANS_COUNT_WIDTH_ARRAY: string[] = [
  '170px', // IMEI
  '190px', // ICCID
  '230px', // 端末名
  '165px', // 機種名
  '150px', // 翻訳回数(期間内合計)
];
