import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
  LoginApiRequestParameter,
  LoginApiResponse,
} from '../../types/apis/loginApi';
/* eslint-disable-next-line */
import callLoginApi, { LOGIN_API_RESULT_CODE } from '../../apis/callLoginApi';
import { WEB_STORAGE, ACCTION_NAMR } from '../../constants/constants';
import { encryptText } from '../../utils/utility';

const date = new Date();
date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 Hours

/**
 * アクセスキーState
 */
type AccessKeyState = {
  iotAccessKey: string;
};

/**
 * 初期State
 */
const initialState: AccessKeyState = {
  iotAccessKey: '',
};

const callLoginApiAsyncThunk = createAsyncThunk<
  LoginApiResponse, // 返却値
  LoginApiRequestParameter // 引数
>(ACCTION_NAMR.CALL_LOGIN_API, async (args, { rejectWithValue }) => {
  // Specify the action name as the first argument
  try {
    const response = await callLoginApi(args);

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
/* eslint-disable */
/**
 * アクセスキー管理 Slice
 */
const accessKeySlice = createSlice({
  name: WEB_STORAGE.IOT_ACCESS_KEY,
  initialState,
  reducers: {
    // reset
    resetAccessKey: (state) => ({ ...state, iotAccessKey: '' }),
    updateToken: (
      state,
      action: PayloadAction<string | AccessKeyState | any>,
    ) => ({
      ...state,
      iotAccessKey: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      callLoginApiAsyncThunk.fulfilled, // If succeed
      (state, action) => {
        if (action.payload.message !== LOGIN_API_RESULT_CODE.LOGIN_SUCCESS) {
          return; // do nothing
        }

        // Save access key to session storage
        state.iotAccessKey = encryptText(action.payload.details.accessKey);
      },
    );
  },
});

export {
  // AsyncThunk
  callLoginApiAsyncThunk,
  // Slice
  accessKeySlice,
};
export type { AccessKeyState };
