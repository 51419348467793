import React from 'react';
import { Typography, Container, CssBaseline, Box } from '@mui/material';
import { useStyles } from './CommonStyle';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // Mainコンテンツ内に表示する内容
  children: React.ReactNode;
  // 画面名
  title: string;
};

/**
 * 一覧画面用ページテンプレート コンポーネント
 */
const ReportListPageTemplate = React.memo<Props>((props): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();

  return (
    <div
      id="pageTemplateRoot"
      className={classes.rootList}
      style={{ position: 'relative' }}
    >
      <CssBaseline />

      <main className={classes.listContent}>
        <Container
          maxWidth="lg"
          className={`${classes.deviceContainer} res-container`}
        >
          <Typography
            fontWeight="700"
            fontSize="20px"
            lineHeight="29px"
            color="textPrimary"
            noWrap
            className={classes.deviceListPageTitle}
          >
            {props.title}
          </Typography>

          <Box
            component="main"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              minHeight: '100%',
              paddingTop: '25px',
            }}
          >
            {props.children}
          </Box>
        </Container>
      </main>
    </div>
  );
});

export default ReportListPageTemplate;
