import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SnackbarState {
  open?: boolean;
  type?: AlertColor;
  message?: string;
  timeout?: number;
}

export const snackbarInitialState: SnackbarState = {
  open: false,
  type: 'success',
  message: '',
  timeout: 3000,
};

export const SnackbarSlice = createSlice({
  name: 'snackbar',
  initialState: snackbarInitialState,
  reducers: {
    addSnackbar: (_state, action: PayloadAction<SnackbarState>) => ({
      ...snackbarInitialState,
      ...action.payload,
      open: true,
    }),
    clearSnackbar: (state) => ({ ...state, open: false }),
  },
});

export const SnackbarActions = SnackbarSlice.actions;
export const SnackbarReducer = SnackbarSlice.reducer;
