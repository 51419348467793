import { useState } from 'react';

/**
 * 本カスタムフックからの返却値
 */
export type UseDateRangeValue = {
  // 開始日
  startDate: Date | null;
  // 終了日
  endDate: Date | null;
  // 開始日を変更
  changeStartDate: (newDateValue: Date | null) => void;
  // 終了日を変更
  changeEndDate: (newDateValue: Date | null) => void;
};

/**
 * 日付範囲コンポーネント hooks
 *
 * @param defaultStateDate 開始日のデフォルト値
 * @param defaultEndDate 終了日のデフォルト値
 * @returns
 */
const useDateRange = (
  defaultStateDate: Date,
  defaultEndDate: Date,
): UseDateRangeValue => {
  // 開始日
  const [startDate, setStartDate] = useState<Date | null>(defaultStateDate);
  // 終了日
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  /**
   * 開始日を変更
   *
   * @param newDateValue 新しい開始日
   */
  const changeStartDate = (newDateValue: Date | null) => {
    setStartDate(newDateValue);
  };

  /**
   * 終了日を変更
   *
   * @param newDateValue 新しい終了日
   */
  const changeEndDate = (newDateValue: Date | null) => {
    setEndDate(newDateValue);
  };

  return {
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
  };
};

export default useDateRange;
