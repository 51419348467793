/* eslint-disable import/prefer-default-export */
import callNotificationGetApi from '../../apis/callNotificationGetApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
import { isErrorType } from '../../types/apis/apiErrorTypeGuard';

import { NotificationGetApiResponse } from '../../types/apis/notificationGetApi';

export const fetchNotificationList = async (
  rowNumber: number,
  pageNumber: number,
  searchtext: string,
  readFlag: boolean,
): Promise<NotificationGetApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: NotificationGetApiResponse =
      await callNotificationGetApi(rowNumber, pageNumber, searchtext, readFlag);
    switch (apiResponse.message) {
      // success
      case SERVER_MESSAGE.FETCH_OK:
        return {
          message: SERVER_MESSAGE.FETCH_OK,
          statusCode: apiResponse.statusCode,
          details: apiResponse.details,
        };
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;

      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      statusCode: 400,
      details: { notificationDetails: [] },
    };
  }
};
