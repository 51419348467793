import React, { useEffect, useState } from 'react';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { ReactComponent as SearchIcon } from '../../images/icon/search.svg';
// common style
import { useStyles } from '../Templates/CommonStyle';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  disable?: boolean;
  locationKey?: string;
  placeholder?: string;
  defaultValue?: string;
  clickFilterSearch: (val: string) => void;
  responsiveClass?: string;
  // autoFocusValue?: boolean;
};

const SearchField = React.memo<Props>((props): JSX.Element => {
  // common styles
  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();

  const [placeholder, setPlaceholder] = useState<string | undefined>(
    props.placeholder ? props.placeholder : '',
  );

  useEffect(() => {
    // Function to update placeholder based on screen width
    const setPlaceholderForScreenWidth = () => {
      if (window.matchMedia('(max-width: 370px)').matches) {
        setPlaceholder(t('filter.placeholder'));
      } else {
        setPlaceholder(props.placeholder);
      }
    };

    // Run the function initially to set the placeholder based on the current screen width
    setPlaceholderForScreenWidth();

    // Add an event listener to change the placeholder dynamically when the screen width changes
    window.addEventListener('resize', setPlaceholderForScreenWidth);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', setPlaceholderForScreenWidth);
    };
  }, [props.placeholder, props.responsiveClass, t]);

  const [searchText, setSearchText] = useState<string>(
    props.defaultValue ? props.defaultValue : '',
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.clickFilterSearch(encodeURIComponent(searchText));
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Special characters need to be encoded in order to pass through params
        props.clickFilterSearch(encodeURIComponent(searchText));
      }}
    >
      <FormControl variant="outlined" className="filter-box-form-control">
        <OutlinedInput
          disabled={props.disable ? props.disable : false}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          className={`${classes.searchInputFilter} ${
            props.responsiveClass || ''
          } ${props.disable ? classes.disableSearch : ''}`}
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
            // GA event tag
            sendGAEvent('search_kw', 'txt_search_kw', 'input_search');
          }}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type="submit"
                sx={{ p: '8px', marginLeft: '8px' }}
                className="search-input-icon"
                aria-label="search"
              >
                <SearchIcon
                  style={{ width: '12.01px', height: '12.01px' }}
                  onClick={() => {
                    // GA event tag
                    sendGAEvent('press_btn', 'btn_name', 'search_icon');
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'search', maxLength: 256 }}
          autoFocus
        />
      </FormControl>
    </form>
  );
});

export default SearchField;
