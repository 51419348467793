/* eslint-disable import/prefer-default-export */
import CryptoJS from 'crypto-js';
import type { AccountRegistedPageState } from '../hooks/useAccount';
import type { ResetSendMailCompleteState } from '../hooks/usePasswordResetSendMail';
import type { PasswordSetCompleteState } from '../hooks/usePasswordSet';
import { UserDetailState } from '../types/user/userDetail.d';
import type { UserListTransState } from '../types/user/userList';
import type { UserAddState } from '../types/user/userAdd';

const secretKey = 'pocketalk-secret-key';
export const encryptText = (text: any) => {
  const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();

  return ciphertext;
};

export const decryptText = (ciphertext: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedText;
};
/**
 * 指定されたオブジェクトの型が AccountRegistedPageState かチェック
 * @param target AccountRegistedPageState(［アカウント登録完了］画面遷移時に渡す値)
 * @returns true=AccountRegistedPageState型 / false=左記以外の型
 */
export const isAccountRegistedPageStateType = (
  target: AccountRegistedPageState | unknown,
): target is AccountRegistedPageState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }
  if (
    typeof (target as AccountRegistedPageState).iotAccountMailAddress !==
    'string'
  ) {
    return false;
  }
  if (
    typeof (target as AccountRegistedPageState).iotAccountCorpId !== 'string'
  ) {
    return false;
  }
  if (
    typeof (target as AccountRegistedPageState).iotAccountCompany !== 'string'
  ) {
    return false;
  }
  if (
    typeof (target as AccountRegistedPageState).iotAccountAddress !== 'string'
  ) {
    return false;
  }
  if (typeof (target as AccountRegistedPageState).iotAccountTel !== 'string') {
    return false;
  }

  return true;
};

/**
 * 指定されたオブジェクトの型が ResetSendMailCompleteState かチェック
 * @param target ResetSendMailCompleteState(［パスワード再設定メール送信完了］画面遷移時に渡す値)
 * @returns true=ResetSendMailCompleteState型 / false=左記以外の型
 */
export const isResetSendMailCompleteStateType = (
  target: ResetSendMailCompleteState | unknown,
): target is ResetSendMailCompleteState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }
  if (typeof (target as ResetSendMailCompleteState).mailAddress !== 'string') {
    return false;
  }

  return true;
};

export const isAccountCreateSendMailCompleteStateType = (
  target: ResetSendMailCompleteState | unknown,
): target is ResetSendMailCompleteState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }
  if (typeof (target as ResetSendMailCompleteState).mailAddress !== 'string') {
    return false;
  }

  return true;
};

/**
 * 指定されたオブジェクトの型が PasswordSetCompleteState かチェック
 * @param target PasswordSetCompleteState(［パスワード設定完了］画面遷移時に渡す値)
 * @returns true=PasswordSetCompleteState型 / false=左記以外の型
 */
export const isPasswordSetCompleteStateType = (
  target: PasswordSetCompleteState | unknown,
): target is PasswordSetCompleteState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }
  if (
    typeof (target as PasswordSetCompleteState).iotAccountMailAddress !==
    'string'
  ) {
    return false;
  }

  return true;
};

/**
 * 指定されたオブジェクトの型が UserListState かチェック
 * @param target UserListState(［ユーザー管理］画面遷移時に渡す値)
 * @returns true=UserListState型 / false=左記以外の型
 */
export const isUserListCompleteStateType = (
  target: UserListTransState | unknown,
): target is UserListTransState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }

  const state = target as UserListTransState;

  if (typeof state.userListState.transMethod !== 'string') {
    return false;
  }

  if (
    state.userInfoProcState &&
    typeof state.userInfoProcState.userInfoProcessResult !== 'string'
  ) {
    return false;
  }

  return true;
};

/**
 * 指定されたオブジェクトの型が UserDetailState かチェック
 * @param target UserDetailState(［ユーザー情報編集］画面遷移時に渡す値)
 * @returns true=UserDetailState型 / false=左記以外の型
 */
export const isUserDetailStateType = (
  target: UserDetailState | unknown,
): target is UserDetailState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }
  if (typeof (target as UserDetailState).mailAddress !== 'string') {
    return false;
  }

  return true;
};

/**
 * 指定されたオブジェクトの型が UserAddState かチェック
 * @param target UserAddState(［ユーザー招待メール送信(ユーザー追加)］画面遷移時に渡す値)
 * @returns true=UserAddState型 / false=左記以外の型
 */
export const isUserAddStateType = (
  target: UserAddState | unknown,
): target is UserAddState => {
  if (!target) {
    return false;
  }
  if (target === undefined) {
    return false;
  }
  if (typeof (target as UserAddState).transViewName !== 'string') {
    return false;
  }

  return true;
};

/**
 * 指定されたリストをもとに、ページ総数を計算して返却
 *
 * @param originalPageList 元となるリスト
 * @param maxRomNumber 1ページに表示する最大行数
 * @returns ページ総数
 */
export const createTotalPageNumber = (
  originalPageList: number,
  maxRomNumber: number,
): number => {
  const rowLength: number = originalPageList;
  if (maxRomNumber > rowLength) {
    return 1;
  }

  const pageNumber: number = Math.ceil(rowLength / maxRomNumber);

  return pageNumber;
};

/**
 * ダウンロード用リンクを作成して自動でクリック
 *
 * @param downloadUrl ダウンロード用リンク
 */
export const createDonwloadLinkAndClick = (downloadUrl: string): void => {
  // aタグを作成
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.target = '_self';

  // ダウンロード実行
  link.click();
};

/**
 * 指定された値が Object か否か
 *
 * @param value
 * @returns
 */
export const isObject = (value: unknown): value is Record<string, unknown> =>
  value !== null && typeof value === 'object';

/**
 * 指定された値が プリミティブ型 か否か
 * @param value
 * @returns
 */
export const isPrimitive = (
  value: unknown,
): value is string | boolean | number | symbol | bigint =>
  typeof value === 'string' ||
  typeof value === 'boolean' ||
  typeof value === 'number' ||
  typeof value === 'symbol' ||
  typeof value === 'bigint';

/**
 * Does the specified primitive object include the target character string?
 *
 * @param target プリミティブ型のオブジェクト
 * @param targetText 検索対象の文字列
 * @returns
 */
export const containStringInPrimitive = (
  target: string | boolean | number | symbol | bigint,
  targetText: string,
): boolean => {
  if (typeof target === 'string') {
    return (
      target
        .toLowerCase()
        .indexOf(decodeURIComponent(targetText).toLowerCase()) !== -1
    );
  }

  return target.toString().indexOf(targetText) !== -1;
};

/**
 * Does the specified object (array or object) include the target character string
 *
 * @param target オブジェクト
 * @param searchText 検索対象の文字列
 * @param filterOutKeys フィルタリング対象外のkey配列
 * @returns
 */
export const containString = (
  target: unknown,
  searchText: string,
  filterOutKeys: string[],
): boolean => {
  // If it was an array
  if (Array.isArray(target)) {
    let result = false;
    const targetUnknown: unknown[] = target;
    for (let i = 0; i < targetUnknown.length; i += 1) {
      const value = targetUnknown[i];
      if (isPrimitive(value)) {
        result = containStringInPrimitive(value, searchText);
      } else {
        result = containString(value, searchText, filterOutKeys);
      }
      if (result) {
        return true;
      }
    }
  }
  // objectだった場合
  if (isObject(target)) {
    let result = false;

    Object.entries(target).some((entry) => {
      const [key, value] = entry;

      // フィルタリング対象外のkeyの場合はスキップ
      if (filterOutKeys.includes(key)) {
        // 結果がtrueならループから抜ける
        return result === true;
      }

      if (isPrimitive(value)) {
        result = containStringInPrimitive(value, searchText);
      } else {
        result = containString(value, searchText, filterOutKeys);
      }

      // 結果がtrueならループから抜ける
      return result === true;
    });

    return result;
  }

  if (isPrimitive(target)) {
    return containStringInPrimitive(target, searchText);
  }

  return false;
};
