import type {
  DeviceLostModeEnableApiRequestParam,
  DeviceLostModeEnableResponse,
} from '../types/apis/deviceLostModeEnableApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';

export const DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE = {
  OK: 'OK',
  DEVICE_IN_LOST_MODE: 'DEVICE_IN_LOST_MODE',
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
} as const;

export type DEVICE_LOST_MODE_ENABLE_ERROR_TYPE =
  (typeof DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE)[keyof typeof DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE];

const callDeviceLodtModeEnableApi = (
  // accessKey: string,
  requestParams: DeviceLostModeEnableApiRequestParam,
): Promise<DeviceLostModeEnableResponse> => {
  /*eslint-disable*/
  const jsonParams = {
    imei: requestParams.imei,
    pin: requestParams.pin,
    lockStatus: requestParams.lockStatus,
  };

  const response = callApiUtil
    .post<DeviceLostModeEnableResponse>(
      API_URL.DEVICE_LOST_MODE_ENABLE,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callDeviceLodtModeEnableApi;
