import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TABLE_ROW_NUMBER_PER_PAGE,
  WEB_STORAGE,
} from '../../constants/constants';

type PageRestoreState = {
  // 遷移時に表示していたページ番号
  currentPage: number;
  // 遷移時に選択されていた表示件数
  rowNumber: number;
  // 遷移時に入力されていた検索ワード
  searchText: string;
  // 遷移時に指定されていた開始日(from) 翻訳回数画面のみ
  fromDate: string;
  // 遷移時に指定されていた終了日(to) 翻訳回数画面のみ
  toDate: string;
  // 遷移時に指定されていたビュー(日、週、月) 翻訳回数画面のみ
  viewSwitch: string;
};

/**
 * 初期State
 */
const initialState: PageRestoreState = {
  currentPage: 1, // 1ページ目表示時のcurrentPageが1のため
  rowNumber: TABLE_ROW_NUMBER_PER_PAGE[0],
  searchText: '',
  fromDate: '', // ReduxはDate型をシリアライズできないため日付はstringで扱う
  toDate: '', // ReduxはDate型をシリアライズできないため日付はstringで扱う
  viewSwitch: '',
};

const pageRestoreStateSlice = createSlice({
  name: WEB_STORAGE.PAGE_RESTORE_STATE,
  initialState,
  reducers: {
    saveCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    saveRowNumber: (state, action: PayloadAction<number>) => {
      state.rowNumber = action.payload;
    },
    saveSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    saveFromDate: (state, action: PayloadAction<string>) => {
      state.fromDate = action.payload;
    },
    saveToDate: (state, action: PayloadAction<string>) => {
      state.toDate = action.payload;
    },
    saveViewSwitch: (state, action: PayloadAction<string>) => {
      state.viewSwitch = action.payload;
    },
  },
});

export { pageRestoreStateSlice };
export type { PageRestoreState };
