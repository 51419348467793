import { Checkbox, ListItemButton, Skeleton } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useStyles } from './DialogStyle';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';

const ManageUserSkeleton = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {Array.from({ length: 6 }, (_, k) => (
        <ListItemButton
          key={k}
          className={`${classes.listItemButton} manage-group-device-skeleton`}
          disableRipple
          sx={{
            height: '77px !important',
            padding: '0px',
          }}
        >
          <ListItem
            sx={{
              p: '3px 16px',
            }}
          >
            <Checkbox
              icon={
                <CheckBox
                  style={{
                    marginTop: '2px',
                    marginRight: '-2px',
                  }}
                />
              }
              sx={{
                p: '0px',
                mr: '16px',
                color: '#D9D9D9',
              }}
            />
            <ListItemAvatar sx={{ padding: '0px' }}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '10', width: '100px' }}
                />
              }
              secondary={
                <>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '8', width: '165px' }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '7', width: '50px' }}
                  />
                </>
              }
            />
          </ListItem>
          {/* {k !== 4 && <Divider light sx={{ mr: '10px', color: '#D9D9D9' }} />} */}
        </ListItemButton>
      ))}
    </>
  );
};

export default ManageUserSkeleton;
