import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as SortUpIcon } from '../../images/icon/sort-up.svg';
import { ReactComponent as SortDownIcon } from '../../images/icon/sort-down.svg';
import { ReactComponent as SortUpSelectedIcon } from '../../images/icon/sort-up-selected.svg';
import { ReactComponent as SortDownSelectedIcon } from '../../images/icon/sort-down-selected.svg';
import {
  TableHeaderData,
  SortConfig,
  DIRECTION_TYPE,
} from '../../types/datatable/dataTable.d';
import styles from './TableHeaderContainer.module.scss';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // テーブルのヘッダーに表示する行
  columns: TableHeaderData[];
  // テーブルの[ソート]ボタンがクリックされた
  clickSortButton: (data: TableHeaderData) => void;
  // ソート設定
  sortConfig: SortConfig;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  columnsWidthSum?: number;
};

const groupListWidth: { [key: string | number]: string | number } = {
  groupName: 20,
  numberOfAccount: 20,
  numberOfDevice: 22,
  statusOfRemoteSettings: 23,
  modifiedDt: 15,
};

const deviceWidth: { [key: string | number]: string | number } = {
  createdDt: 18,
  deviceName: 31,
  productType: 23,
  imei: 28,
};

const userWidth: { [key: string | number]: string | number } = {
  userName: 23,
  mailAddress: 23,
  groupName: 15,
  roleName: 15,
  dateAdded: 13,
  registrationStatus: 11,
};

const deviceListWidth: { [key: string | number]: string | number } = {
  groupName: 13,
  imei: 12,
  deviceName: 14,
  productType: 11,
  mode: 14,
  status: 16,
  simExp: 10,
  lastUsed: 10,
};

const translationHistoryListWidth: { [key: string | number]: string | number } =
  {
    transdt: 44,
    fromText: 14,
    toText: 14,
    fromLang: 14,
    toLang: 14,
  };

export const deviceTableGAtag: {
  [key: string]: string;
} = {
  groupName: 'list_group_name_sort',
  imei: 'list_imei_sort',
  deviceName: 'list_device_name_sort',
  productType: 'list_device_model_sort',
  mode: 'list_device_mode_sort',
  status: 'list_device_status_sort',
  simExp: 'list_sim_exp_sort',
  lastUsed: 'list_last_used_sort',
};

export const transCountListGAtag: {
  [key: string]: string;
} = {
  imei: 'list_imei_sort',
  iccid: 'list_iccid_sort',
  deviceName: 'list_device_name_sort',
  productType: 'list_device_model_sort',
  transCount: 'list_time_translated_sort',
};

export const transFromListGAtag: {
  [key: string]: string;
} = {
  lang: 'list_language_sort',
  useCount: 'list_time_translated_sort',
};

const triggerGAEvent = (key: string, srcPage: string) => {
  if (srcPage === 'Device') {
    // GA tag
    sendGAEvent('press_btn', 'btn_name', deviceTableGAtag[key]);
  }

  if (srcPage === 'TransCountListDay' || srcPage === 'TransCountList') {
    // GA tag
    sendGAEvent('press_btn', 'btn_name', deviceTableGAtag[key]);
  }

  if (
    srcPage === 'TransFromDay' ||
    srcPage === 'TransFrom' ||
    srcPage === 'TransToDay' ||
    srcPage === 'TransTo'
  ) {
    // GA tag
    sendGAEvent('press_btn', 'btn_name', deviceTableGAtag[key]);
  }
};

/**
 * テーブルに表示するヘッダー コンポーネント
 */
/* eslint-disable */
const TableHeader = React.memo<Props>((props): JSX.Element => {
  const { localeLanguage } = useLocale();

  const matches = useMediaQuery('(max-width:1820px)');

  const isFirefox = /firefox|fxios/i.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const width = (pageSrc: string, match: boolean) => {
    if (pageSrc === 'Device' && match === true) {
      return '1818px';
    }

    if (
      [
        'TransCountList',
        'TransCountListDay',
        'TransFromDay',
        'TransToDay',
        'TransFrom',
        'TransTo',
      ].includes(props.tableSrcPage as string) &&
      !props.isCallingApi
    ) {
      return props.columns.length < 5
        ? ''
        : props.columnsWidthSum! > 1820
        ? `${props.columnsWidthSum! - 2}px`
        : '1818px';
    }

    return '';
  };

  return (
    <thead>
      <tr
        className={
          props.tableSrcPage === 'Group'
            ? `${styles['table-header']} ${styles.groupListTable}`
            : props.tableSrcPage === 'Device'
            ? `${styles['device-table-header']} ${
                styles.groupDeviceTable
              } ${'device-table-header-res'}`
            : props.tableSrcPage === 'TransHistory'
            ? `${styles['table-header']} ${styles.translatioHistoryTable}`
            : `${styles['table-header']} ${styles.groupDeviceTable}`
        }
        style={{
          paddingRight: ['Device', 'TransCountList', 'Users'].includes(
            props.tableSrcPage as string,
          )
            ? '20px'
            : '',
          minWidth: width(props.tableSrcPage as string, matches),
          height:
            props.tableSrcPage === 'Device' && localeLanguage === LOCALE_CODE.FR
              ? '62px'
              : '',
        }}
      >
        {props.columns.map((data: TableHeaderData, index: number) => (
          <th
            scope="col"
            key={data.key}
            style={{
              width:
                // eslint-disable-next-line no-nested-ternary
                props.tableSrcPage === 'Group'
                  ? `${groupListWidth[data.key]}%`
                  : props.tableSrcPage === 'TransCountListDay' &&
                    props.isCallingApi
                  ? ``
                  : ['Device'].includes(props.tableSrcPage as string)
                  ? `${deviceListWidth[data.key]}%`
                  : ['TransCountListDay'].includes(props.tableSrcPage as string)
                  ? ``
                  : props.tableSrcPage === 'Users'
                  ? `${userWidth[data.key]}%`
                  : props.tableSrcPage === 'TransHistory'
                  ? `${translationHistoryListWidth[data.key]}%`
                  : props.tableSrcPage === 'GroupDevice'
                  ? `${deviceWidth[data.key]}%`
                  : '',
              minWidth: [
                'TransCountList',
                'TransCountListDay',
                'TransFromDay',
                'TransToDay',
                'TransFrom',
                'TransTo',
              ].includes(props.tableSrcPage as string)
                ? data?.width
                : '',
            }}
          >
            <div
              style={{
                paddingRight:
                  props.tableSrcPage === 'Users'
                    ? '37px'
                    : props.tableSrcPage === 'Device'
                    ? '30px'
                    : ['TransCountListDay'].includes(
                        props.tableSrcPage as string,
                      )
                    ? '0'
                    : props.tableSrcPage === 'TransCountList'
                    ? '0'
                    : '',
              }}
            >
              <div className={styles['table-header-title']}>
                <div
                  style={{
                    cursor:
                      data.isSort && data.key !== 'statusOfRemoteSettings'
                        ? 'pointer'
                        : 'default',
                    whiteSpace:
                      (data.key === 'transDt' &&
                        localeLanguage !== LOCALE_CODE.FR &&
                        localeLanguage !== LOCALE_CODE.DE) ||
                      localeLanguage === LOCALE_CODE.EN
                        ? 'pre'
                        : 'pre-wrap',
                    wordBreak: 'break-word',
                    fontSize:
                      (isFirefox || isSafari) &&
                      (localeLanguage === LOCALE_CODE.FR ||
                        localeLanguage === LOCALE_CODE.DE) &&
                      ['Group', 'Device'].includes(props.tableSrcPage as string)
                        ? isSafari
                          ? '12px'
                          : '13px'
                        : '',
                    minWidth:
                      props.tableSrcPage === 'GroupDevice' &&
                      data.key === 'createdDt' &&
                      (localeLanguage === LOCALE_CODE.FR ||
                        localeLanguage === LOCALE_CODE.DE)
                        ? '180px'
                        : props.tableSrcPage === 'TransHistory' &&
                          data.key === 'fromText' &&
                          localeLanguage === LOCALE_CODE.FR &&
                          (isFirefox || isSafari)
                        ? '137px'
                        : '',
                  }}
                  className={styles['table-header-title-text']}
                  // className={`${styles['table-header-title-text']} ${
                  //   [
                  //     'TransCountList',
                  //     'TransCountListDay',
                  //     'TransTo',
                  //     'TransToDay',
                  //     'TransFromDay',
                  //     'TransFrom',
                  //   ].includes(props.tableSrcPage as string)
                  //     ? styles['report-list-header']
                  //     : ''
                  // }`}                  
                  aria-hidden
                  onClick={() => {
                    if (data.key === 'statusOfRemoteSettings') {
                      return;
                    }
                    props.clickSortButton(data);
                    triggerGAEvent(
                      data.key,
                      props.tableSrcPage ? props.tableSrcPage : '',
                    );
                  }}
                >
                  {data.value}
                </div>
                <div
                  className={styles['table-header-icon']}
                  aria-hidden
                  onClick={() => {
                    props.clickSortButton(data);
                    triggerGAEvent(
                      data.key,
                      props.tableSrcPage ? props.tableSrcPage : '',
                    );
                  }}
                  style={{
                    zIndex: 1,
                    display:
                      data.key === 'statusOfRemoteSettings' ? 'none' : '',
                    left:
                      isSafari &&
                      localeLanguage === LOCALE_CODE.EN &&
                      props.tableSrcPage === 'TransHistory' &&
                      data.key === 'transDt'
                        ? '192px'
                        : '',
                  }}
                >
                  {props.sortConfig &&
                    data.isSort &&
                    props.sortConfig.key === data.key &&
                    props.sortConfig.direction === DIRECTION_TYPE.ascending && (
                      <div className={styles['table-header-icon-container']}>
                        <SortUpSelectedIcon />
                        <SortDownIcon />
                      </div>
                    )}
                  {props.sortConfig &&
                    data.isSort &&
                    props.sortConfig.key === data.key &&
                    props.sortConfig.direction ===
                      DIRECTION_TYPE.descending && (
                      <div className={styles['table-header-icon-container']}>
                        <SortUpIcon />
                        <SortDownSelectedIcon />
                      </div>
                    )}
                  {props.sortConfig &&
                    data.isSort &&
                    (props.sortConfig.key !== data.key ||
                      props.sortConfig.direction === DIRECTION_TYPE.none) && (
                      <div className={styles['table-header-icon-container']}>
                        <SortUpIcon />
                        <SortDownIcon />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
});

export default TableHeader;
