import callApiUtil from '../utils/callApiUtil';
import type {
  RemoteFeatureSetupApiRequestParam,
  RemoteFeatureSetupResponse,
} from '../types/apis/remoteFeatureSetupApi.d';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * User List Acquisition API Result Code
 */
export const REMOTE_FEATURE_SETUP_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // Failed to get authorization list
  WARN_NO_AUTH_LIST: 'WARN_NO_AUTH_LIST',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // No Access Key
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // No user Found
  INFO_NO_USER: 'INFO_NO_USER',
  // Invalid remote setting mode
  INFO_NO_REMOTE_SETTING_MODE: 'INFO_NO_REMOTE_SETTING_MODE',
  INFO_NO_ACCOUNT: 'INFO_NO_ACCOUNT',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  WARN_INPUT_PARAM:'WARN_INPUT_PARAM',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * User list acquisition error type
 */
export type REMOTE_FEATURE_SETUP_API_ERROR_TYPE =
  typeof REMOTE_FEATURE_SETUP_API_RESULT_CODE[keyof typeof REMOTE_FEATURE_SETUP_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callRemoteFeatureSetupApi = (
  // accessKey: string,
  requestParams: RemoteFeatureSetupApiRequestParam,
): Promise<RemoteFeatureSetupResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};

  // Send request (GET)
  const response = callApiUtil
    .post<RemoteFeatureSetupResponse>(
      API_URL.REMOTE_FEATURE_SETUP_API,
      requestParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callRemoteFeatureSetupApi;
