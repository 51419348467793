import { Skeleton } from '@mui/material';
import styles from './TableBodyContainer.module.scss';

// const TableDataRow = (): JSX.Element => (
//   <>
//     {
//       Array.from({ length: 4 }, (_, k) => (
//         <tr key={k} className={styles.tableDataRow}>
//           {Array.from({ length: 4 }, (__, key) => (
//             <td key={key}>
//               <Skeleton
//                 variant="rectangular"
//                 sx={{ borderRadius: '3px' }}
//                 width={160}
//                 height={26}
//               />
//             </td>
//           ))}
//         </tr>
//       ))
//     }
//   </>
// );
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const TableDataRow = (): JSX.Element => (
  <>
    {Array.from({ length: 7 }, (_, k) => (
      <tr key={k} className={styles.tableDataRowSkeleton}>
        <td style={{ width: '160px' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '80%' }}
            height={20}
          />
        </td>
        <td style={{ width: '160px' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '35%' }}
            height={20}
          />
        </td>
        <td style={{ width: '160px' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '35%' }}
            height={20}
          />
        </td>
        <td style={{ width: '27%', paddingRight: isSafari ? '115px' : '90px' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '78px' }}
            height={20}
          />
        </td>
        <td style={{ width: '160px', paddingRight: '40px' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '40%' }}
            height={20}
          />
        </td>
      </tr>
    ))}
  </>
);

export default TableDataRow;
