/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callRemoteFeatureSetup, {
  REMOTE_FEATURE_SETUP_API_ERROR_TYPE,
  REMOTE_FEATURE_SETUP_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureSetupPostApi';
// import { PAGE_PATH_NAME } from '../../constants/constants';
import { isRemoteFeatureSetupPostErrorType } from '../../types/apis/apiErrorTypeGuard';
import {
  RemoteFeatureSetupApiRequestParam,
  RemoteFeatureSetupApiResponse,
  RemoteFeatureSetupResponse,
} from '../../types/apis/remoteFeatureSetupApi.d';

// import { GroupDetailResult } from '../../types/Group/groupDetail.d';

export const featureSetupPost = async (
  requestParam: RemoteFeatureSetupApiRequestParam,
): Promise<RemoteFeatureSetupApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: RemoteFeatureSetupResponse =
      await callRemoteFeatureSetup(requestParam);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_UPDATED':
        return {
          resultCode: REMOTE_FEATURE_SETUP_API_RESULT_CODE.OK,
        };
      // Failure
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NOTHING_KEY;
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_ACCOUNT:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_ACCOUNT;
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_INVALID_AUTH;
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_NO_AUTH_LIST;
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_GROUP:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_GROUP;
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      //   case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
      //     throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      default:
        throw REMOTE_FEATURE_SETUP_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: REMOTE_FEATURE_SETUP_API_ERROR_TYPE =
      REMOTE_FEATURE_SETUP_API_RESULT_CODE.ERR_UNKNOWN;
    if (isRemoteFeatureSetupPostErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
    };
  }
};

// .catch((error: GROUP_DETAIL_API_ERROR_TYPE | unknown) => {
//   if (isGroupDetailErrorType(error)) {
//     setGroupDetailErrorType(error);
//   } else {
//     setGroupDetailErrorType(GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN);
//   }

export const transToGroups = (navigate: NavigateFunction): void => {
  // Invalid groupId forward to Group list page
  navigate(`/groups`, { replace: true });
};
