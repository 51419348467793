import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PAGE_PATH_NAME } from '../constants/constants';
import useCheckTransition from './useCheckTransition';
import { DeviceDeregisterCompleteState } from './useDeviceDeregister';

/**
 * 本カスタムフックからの返却値
 */
export type UseDeviceDeregisterCompleteValue = {
  // [戻る]ボタン
  onClickGoBack: () => void;
  // 登録解除した端末情報
  simInfo: DeviceDeregisterCompleteState;
};

/**
 * 端末登録解除完了画面 hooksクラス
 *
 * @returns
 */
const useDeviceDeregisterComplete = (): UseDeviceDeregisterCompleteValue => {
  // navigate(画面遷移)
  const navigate = useNavigate();
  // ロケーション情報
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { pathname, state } = useLocation();
  // [端末登録解除]画面から渡されたstate
  const [pageState] = useState<DeviceDeregisterCompleteState>(
    state as DeviceDeregisterCompleteState,
  );
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  /**
   * ［戻る］ボタンクリック処理
   */
  const onClickGoBack = (): void => {
    if (!pageState) {
      navigate(PAGE_PATH_NAME.DEVICE_LIST);
    } else {
      // stateで受け取った戻り先画面名に遷移
      navigate(pageState.transViewName);
    }
  };

  /**
   * ブラウザバック制御
   */
  const browserBackBlockFunction = (): void => {
    navigate(pathname, {
      state: pageState,
    });
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return () => ({});
    }

    // ブラウザバックで［端末登録解除完了］画面にとどまるように制御
    window.history.pushState(null, '', pathname);
    window.addEventListener('popstate', browserBackBlockFunction);

    return () => {
      window.removeEventListener('popstate', browserBackBlockFunction); // クリーンアップ
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onClickGoBack,
    simInfo: pageState,
  };
};

export default useDeviceDeregisterComplete;
