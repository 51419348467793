/* eslint-disable import/prefer-default-export */
import { TFunction } from 'react-i18next';
import { DATE_FORMAT, DATE_FORMAT_YYYYMMDD } from '../../constants/constants';
import {
  DOWNLOAD_DATE_ERROR_CODE,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../../types/download/downloadDate';
import {
  convertISO8601DateToString0100,
  convertISO8601StringToDate,
} from '../dateUtil';

/**
 * 選択された日付がfrom <= toであることをチェックする
 * 比較対象は「年」と「月」のみとする
 *
 * @param from 期間開始日
 * @param to 期間終了日
 * @returns true: OK / false: NG
 */
export const compareDonwloadDate = (from: Date, to: Date): boolean => {
  const fromTime = from.getTime();
  const toTime = to.getTime();

  return fromTime <= toTime;
};

/**
 * 入力された日付をyyyyMM方式の文字列に変換して返す
 *
 * @param date datePickerで入力された文字列
 * @returns yyyyMM形式の文字列
 */
export const formatDownloadDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = `00${date.getMonth() + 1}`.slice(-2);
  const day = `00${date.getDate()}`.slice(-2);

  return year.toString() + month + day;
};

/**
 * 入力された日付が無効な形式かどうか判定する
 *
 * @param date 入力された日付
 * @returns true: 有効な日付形式 false: 無効な日付形式
 */
export const isInvalidDownloadDate = (date: Date): boolean =>
  !Number.isNaN(date.getTime());

/**
 * 入力された日付がminDate～maxDate内であるかどうか判定する
 * @param minDate 最小の日付
 * @param maxDate 最大の日付
 * @param date 入力された日付
 * @returns true: 範囲内 false: 範囲外
 */
export const isWithinRange = (
  minDate: Date,
  maxDate: Date,
  date: Date,
): boolean => {
  const minDateTime = minDate.getTime();
  const maxDateTime = maxDate.getTime();
  const targetDateTime = date.getTime();

  if (targetDateTime < minDateTime) {
    return false;
  }

  if (maxDateTime < targetDateTime) {
    return false;
  }

  return true;
};

/**
 * ダウンロード開始日/終了日のバリデーションチェック
 *
 * @param startDate ダウンロード開始日(from)
 * @param endDate ダウンロード終了日(to)
 * @param minDate 最小の日時
 * @param maxDate 最大の日時
 * @param dateFormat 日付フォーマット
 * @returns エラータイプ / null(バリエーションエラーなし)
 */
export const validateDownloadDate = (
  startDate: Date | null,
  endDate: Date | null,
  minDate: Date,
  maxDate: Date,
  dateFormat: string,
): DOWNLOAD_DATE_ERROR_TYPE | null => {
  // ダウンロード期間が指定されているか
  if (!startDate || !endDate) {
    return DOWNLOAD_DATE_ERROR_CODE.WARN_UNSPECIFIED;
  }

  // 日付形式が正しいかどうか
  if (!isInvalidDownloadDate(startDate) || !isInvalidDownloadDate(endDate)) {
    return DOWNLOAD_DATE_ERROR_CODE.ERR_FORMAT;
  }

  // 日付フォーマットが「yyyy/MM」形式の場合は指定された文字列を「yyyy/MM/01 00:00:00」に変換
  // DatePicker上で日付を選択した場合に選択した日付がminDate ~ maxDate範囲を満たさないケースがあるため対応
  let formatStartDate = startDate;
  let formatEndDate = endDate;
  if (dateFormat === DATE_FORMAT) {
    formatStartDate = convertISO8601StringToDate(
      convertISO8601DateToString0100(startDate),
    );
    formatEndDate = convertISO8601StringToDate(
      convertISO8601DateToString0100(endDate),
    );
  }

  // from <= toであるかどうか
  if (!compareDonwloadDate(formatStartDate, formatEndDate)) {
    return DOWNLOAD_DATE_ERROR_CODE.WARN_PERIOD_FRAUD;
  }

  // 入力された日付がminDate～maxDate内であるかどうか
  if (
    !isWithinRange(minDate, maxDate, formatStartDate) ||
    !isWithinRange(minDate, maxDate, formatEndDate)
  ) {
    return DOWNLOAD_DATE_ERROR_CODE.WARN_OUT_OF_RANGE;
  }

  return null;
};

/**
 * エラー文言内の文字列「minDate/maxDate」を日付文字列に置き換える
 * @param dateFormat
 * @param minDate
 * @param maxDate
 * @param errMessage
 * @returns
 */
const replaceOutOfRangeTxt = (
  dateFormat: string,
  minDate: Date,
  maxDate: Date,
  errMessage: string,
): string => {
  let minDateStr = dateFormat
    .replace('yyyy', minDate.getFullYear().toString())
    .replace('MM', `00${minDate.getMonth() + 1}`.slice(-2).toString());

  let maxDateStr = dateFormat
    .replace('yyyy', maxDate.getFullYear().toString())
    .replace('MM', `00${maxDate.getMonth() + 1}`.slice(-2).toString());

  if (dateFormat === DATE_FORMAT_YYYYMMDD) {
    minDateStr = minDateStr.replace(
      'dd',
      `00${minDate.getDate()}`.slice(-2).toString(),
    );
    maxDateStr = maxDateStr.replace(
      'dd',
      `00${maxDate.getDate()}`.slice(-2).toString(),
    );
  }

  const msg = errMessage
    .replace('minDate', minDateStr)
    .replace('maxDate', maxDateStr);

  return msg;
};

/**
 * 言語ごとのダウンロードエラーメッセージ作成
 *
 * @param errorType エラータイプ
 * @param t 言語
 * @returns 言語ごとのダウンロードエラーメッセージ
 */
export const createDonwloadErrorMessage = (
  errorType: DOWNLOAD_DATE_ERROR_TYPE,
  t: TFunction,
  dateFormat: string,
  minDate: Date,
  maxDate: Date,
): string => {
  switch (errorType) {
    // case DOWNLOAD_DATE_ERROR_CODE.ERR_FORMAT:
    //   if (dateFormat === DATE_FORMAT_YYYYMMDD) {
    //     return t('fileDownloadError.formatYYYYMMDD');
    //   }

    //   return t('fileDownloadError.format');
    case DOWNLOAD_DATE_ERROR_CODE.WARN_PERIOD_FRAUD:
      return t('fileDownloadError.periodFraud');
    case DOWNLOAD_DATE_ERROR_CODE.WARN_UNSPECIFIED:
      return t('fileDownloadError.unspecified');
    case DOWNLOAD_DATE_ERROR_CODE.WARN_OUT_OF_RANGE:
      return replaceOutOfRangeTxt(
        dateFormat,
        minDate,
        maxDate,
        t('fileDownloadError.outOfRange'),
      );
    default:
      return '';
  }
};

/**
 * 指定された日付文字列の正規表現チェック
 *
 * @param regexStr 正規表現
 * @param targetDateStr チェック対象の文字列
 * @returns true=OK / false=形式不正
 */
export const checkDateRegex = (
  regexStr: string,
  targetDateStr: string,
): boolean => {
  const regex = new RegExp(regexStr);
  if (!regex.test(targetDateStr)) {
    return false;
  }

  return true;
};
