import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { ManageGroupDeviceListApiResponse } from '../types/apis/manageGroupDeviceListApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * device List Acquisition API Result Code
 */
export const MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // No device Found
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  // No device Found
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  INFO_NO_USER: 'INFO_NO_USER',
  ERR_UPDATE_DENIED: 'ERR_UPDATE_DENIED',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED',
  NO_INTERNET: 'NO_INTERNET',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
} as const;

/**
 * device list acquisition error type
 */
export type MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE =
  (typeof MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE)[keyof typeof MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE];

/**
 * device list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the device list acquisition API
 */
const callManageGroupDeviceListApi = (
  // accessKey: string,
  groupId: number,
  pageNumber: number,
  searchText: string,
): Promise<ManageGroupDeviceListApiResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};

  // Send request (GET)
  const response = callApiUtil
    .get<ManageGroupDeviceListApiResponse>(
      `${
        API_URL.MANAGE_GROUP_DEVICE_LIST_API
      }?groupId=${groupId}&currentPageNumber=${
        pageNumber - 1
      }&searchText=${searchText}&rowLimitPerPage=10`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => {
      if (error?.response) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          statusCode: error.response?.data?.statusCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          message: error.response?.data?.message,
          details: {
            totalCount: 0,
            devices: [],
          },
        };
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        statusCode: 400,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        message: error?.message,
        details: {
          totalCount: 0,
          devices: [],
        },
      };
    });

  return response;
};

export default callManageGroupDeviceListApi;
