import { AxiosError } from 'axios';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';
import type {
  GroupDeleteApiParameter,
  GroupDeleteApiResponse,
} from '../types/apis/groupDeleteApi';

/**
 *
 * Group Delete Result Code
 */
export const GROUP_DELETE_API_RESULT_CODE = {
  SUCCESSFULLY_DELETED: 'SUCCESSFULLY_DELETED',
  // Group Deleted
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // Ungrouped delete is not allowed
  WARN_UNGROUPED_NOT_DELETABLE: 'WARN_UNGROUPED_NOT_DELETABLE',
  // User does not have access
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Input parameter not correct
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // No corporation found with the ID
  INFO_NO_CORPORATION: 'INFO_NO_CORPORATION',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Internal server side error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
} as const;

/**
 * Group Delete Error Code
 */
export type GROUP_DELETE_ERROR_TYPE =
  typeof GROUP_DELETE_API_RESULT_CODE[keyof typeof GROUP_DELETE_API_RESULT_CODE];

/**
 * Group deletion API call
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns ユーザ削除APIからのレスポンス
 */
const callGroupDeleteApi = (
  requestParams: GroupDeleteApiParameter,
  // accessKey: string,
): Promise<GroupDeleteApiResponse> => {
  // リクエスト送信(POST)
  const response = callApiUtil
    .delete<GroupDeleteApiResponse>(
      `${API_URL.GROUP_DELETE_API}?groupId=${requestParams.groupId}&corp_id=${requestParams.corpId}`,
      {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError)=> ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          statusCode:  error.response?.data?.statusCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          message: error.response?.data?.message
        })
    );

  return response;
};

export default callGroupDeleteApi;
