/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import './monthRangePicker.css';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { ReactComponent as ArrowLeft } from '../../images/icon/calender-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../images/icon/calender-arrow-right.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type propsType = {
  setRangeState: (data: Date[]) => void;
  setOpenState: (open: boolean) => void;
  onApply: (data: Date[]) => void;
  selectedDate: Date[];
};
const MonthRangePicker = ({
  setRangeState,
  setOpenState,
  onApply,
  selectedDate,
}: propsType) => {
  const compareMonth = (
    firstMonth: number,
    firstYear: number,
    lastMonth: number,
    lastYear: number,
  ) => {
    if (lastYear > firstYear) {
      return true;
    }
    if (lastYear === firstYear) {
      if (lastMonth >= firstMonth) {
        return true;
      }

      return false;
    }

    return false;
  };

  const { t } = useSwitchLocaleLanguage();
  const months = [
    t('month.jan'),
    t('month.feb'),
    t('month.mar'),
    t('month.apr'),
    t('month.may'),
    t('month.jun'),
    t('month.jul'),
    t('month.aug'),
    t('month.sep'),
    t('month.oct'),
    t('month.nov'),
    t('month.dec'),
  ];
  const [year1, setYear1] = useState<number>(new Date().getFullYear() - 1);
  const [year2, setYear2] = useState<number>(new Date().getFullYear());

  const [hoverMonth, setHoverMonth] = useState<number | null>(null);
  const [hoverYear, setHoverYear] = useState<number | null>(null);

  const [selectedFirstMonth, setSelectedFirstMonth] = useState<number | null>(
    null,
  );
  const [selectedFirstYear, setSelectedFirstYear] = useState<number | null>(
    null,
  );

  const [selectedLastMonth, setSelectedLastMonth] = useState<number | null>(
    null,
  );
  const [selectedLastYear, setSelectedLastYear] = useState<number | null>(null);

  const getColor = (month: number, year: number) => {
    if (selectedFirstMonth === month && selectedFirstYear === year) {
      return 'startMonthCell';
    }
    if (selectedLastMonth === month && selectedLastYear === year) {
      return 'endMonthCell';
    }
    if (selectedFirstMonth !== null && selectedLastMonth !== null) {
      if (
        compareMonth(selectedFirstMonth, selectedFirstYear!, month, year) &&
        compareMonth(month, year, selectedLastMonth, selectedLastYear!)
      ) {
        return 'hoverCell';
      }
    }
    if (selectedFirstMonth !== null && selectedLastMonth === null) {
      if (
        compareMonth(selectedFirstMonth, selectedFirstYear!, month, year) &&
        compareMonth(month, year, hoverMonth!, hoverYear!)
      ) {
        return 'hoverCell';
      }
    }

    return 'normalCell';
  };

  useEffect(() => {
    const dateString1 = selectedDate[0];
    const dateString2 = selectedDate[1];
    const firstDate = new Date(dateString1);
    const secondDate = new Date(dateString2);

    setSelectedFirstMonth(firstDate.getMonth()); // Returns the month as a number (0-11)
    setSelectedFirstYear(firstDate.getFullYear());
    setSelectedLastMonth(secondDate.getMonth()); // Returns the month as a number (0-11)
    setSelectedLastYear(secondDate.getFullYear());
    // eslint-disable-next-line
  }, []);

  const calenderClick = (month: number, year: number) => {
    if (selectedLastYear !== null && selectedLastMonth !== null) {
      setSelectedLastYear(null);
      setSelectedLastMonth(null);
      setSelectedFirstYear(year);
      setSelectedFirstMonth(month);

      // GA tag
      sendGAEvent('select_txt', 'txt_name', 'input_search_from');
      sendGAEvent(
        'select_txt',
        'input_search_from_value',
        `${year}-${month + 1}`,
      );

      return;
    }

    if (selectedFirstYear !== null && selectedFirstMonth !== null) {
      if (month === selectedFirstMonth && year === selectedFirstYear) {
        return;
      }

      // Click Date > Start Date
      if (compareMonth(selectedFirstMonth, selectedFirstYear, month, year)) {
        setSelectedLastMonth(month);
        setSelectedLastYear(year);

        // GA tag
        sendGAEvent('select_txt', 'txt_name', 'input_search_to');
        sendGAEvent(
          'select_txt',
          'input_search_to_value',
          `${year}-${month + 1}`,
        );
      } else {
        setSelectedFirstMonth(month);
        setSelectedFirstYear(year);
      }

      return;
    }
    setSelectedFirstMonth(month);
    setSelectedFirstYear(year);
    // GA tag
    sendGAEvent('select_txt', 'txt_name', 'input_search_from');
    sendGAEvent(
      'select_txt',
      'input_search_from_value',
      `${year}-${month + 1}`,
    );
  };

  const resetAll = () => {
    setSelectedFirstMonth(null);
    setSelectedFirstYear(null);
    setSelectedLastMonth(null);
    setSelectedLastYear(null);
    setHoverMonth(null);
    setHoverYear(null);
    setOpenState(false);
  };

  let endDate: Date;
  if (selectedLastYear !== null && selectedLastMonth !== null) {
    endDate = new Date(selectedLastYear, selectedLastMonth, 1);
  } else {
    endDate = new Date(selectedFirstYear!, selectedFirstMonth!, 1);
  }
  const startDate = new Date(selectedFirstYear!, selectedFirstMonth!);

  const clickApplyButton = () => {
    setRangeState([startDate, endDate]);
    onApply([startDate, endDate]);
    resetAll();

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'calender_apply');
  };

  const clickCancelButton = () => {
    resetAll();

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'calender_cancel');
  };

  return (
    <div className="monthRangeParent">
      <div className="monthRange">
        <div className="leftCalender">
          <div className="headerCalenderLeft">
            <button
              type="button"
              className="changeYearButton leftButtonChange"
              onClick={() => {
                setYear1(year1 - 1);
                setYear2(year2 - 1);
              }}
            >
              <ArrowLeft />
            </button>
            <p className="calenderYearRight">{year1}</p>
          </div>
          <div className="calenderBar"> </div>
          <div className="calenderContainer">
            {months.map((month, i) => (
              <div
                className="cellBasic"
                onMouseEnter={() => {
                  setHoverYear(year1);
                  setHoverMonth(i);
                }}
                onMouseLeave={() => {
                  setHoverYear(null);
                  setHoverMonth(null);
                }}
                onClick={() => {
                  if (new Date(year1, i, 1) <= new Date()) {
                    calenderClick(i, year1);
                  }
                }}
              >
                <div
                  className={
                    new Date(year1, i, 1) <= new Date()
                      ? `${getColor(i, year1)} cellInside`
                      : 'cellInside_strick'
                  }
                >
                  {month}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="rightCalender">
          <div className="headerCalenderRight">
            <p className="calenderYearLeft">{year2}</p>
            <button
              type="button"
              className="changeYearButton rightButtonChange"
              onClick={() => {
                setYear1(year1 + 1);
                setYear2(year2 + 1);
              }}
            >
              <ArrowRight />
            </button>
          </div>
          <div className="calenderBar"> </div>
          <div className="calenderContainer">
            {months.map((month, i) => (
              <div
                onMouseEnter={() => {
                  if (
                    i <= new Date().getMonth() &&
                    year2 <= new Date().getFullYear()
                  ) {
                    setHoverYear(year2);
                    setHoverMonth(i);
                  }
                }}
                onMouseLeave={() => {
                  setHoverYear(null);
                  setHoverMonth(null);
                }}
                onClick={() => {
                  if (new Date(year2, i, 1) <= new Date()) {
                    calenderClick(i, year2);
                  }
                }}
                className="cellBasic"
              >
                <div
                  className={
                    new Date(year2, i, 1) <= new Date()
                      ? `${getColor(i, year2)} cellInside`
                      : 'cellInside_strick'
                  }
                >
                  {month}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="accessoriesCalenderBar"> </div>
      <div className="accessories">
        <button
          type="button"
          className="cancelButton"
          onClick={clickCancelButton}
        >
          {t('common.calenderBtn.cancel')}
        </button>
        <button
          type="button"
          className="applyButton"
          onClick={clickApplyButton}
          disabled={
            new Date(selectedDate[0]).getMonth() === startDate.getMonth() &&
            new Date(selectedDate[0]).getFullYear() ===
              startDate.getFullYear() &&
            new Date(selectedDate[1]).getMonth() === endDate.getMonth() &&
            new Date(selectedDate[1]).getFullYear() === endDate.getFullYear()
          }
        >
          {t('common.calenderBtn.apply')}
        </button>
      </div>
    </div>
  );
};

export default MonthRangePicker;
