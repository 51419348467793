import callApiUtil from '../utils/callApiUtil';
import type { AuthListApiResponse } from '../types/apis/authListApi';
import { API_URL } from '../constants/constants';
// import { Error, Response } from '../helper/error';

/**
 * Authority list API result code
 */
export const AUTH_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  // Only Auth user
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
};

/**
 * 権限一覧取得エラータイプ
 */
export type AUTH_LIST_API_ERROR_TYPE =
  typeof AUTH_LIST_API_RESULT_CODE[keyof typeof AUTH_LIST_API_RESULT_CODE];

/**
 * 権限一覧取得API 呼び出し
 *
 * @param accessKey アクセスキー
 * @returns 権限一覧取得APIからのレスポンス
 */

/*eslint-disable*/
const callAuthListApi = (): Promise<AuthListApiResponse> => {
  const response = callApiUtil
    .get<AuthListApiResponse>(API_URL.AUTH_LIST_API, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callAuthListApi;
