/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callGroupAddApi, {
  GROUP_ADD_API_ERROR_TYPE,
  GROUP_ADD_API_RESULT_CODE,
} from '../../apis/callGroupAddApi';
import { isGroupAddErrorType } from '../../types/apis/apiErrorTypeGuard';
// import { PAGE_PATH_NAME } from '../../constants/constants';

import {
  GroupAddApiResponse,
  GroupAddApiRequestParameter,
} from '../../types/apis/groupAddApi.d';
import { GroupAddResult } from '../../types/Group/groupAdd.d';
import { SERVER_MESSAGE } from '../../constants/constants';

export const newGroup = async (
  // accessKey: string,
  requestParams: GroupAddApiRequestParameter,
): Promise<GroupAddResult> => {
  // User list acquisition API call
  try {
    const apiResponse: GroupAddApiResponse = await callGroupAddApi(
      // accessKey,
      requestParams,
    );
    switch (apiResponse.message) {
      // success
      case GROUP_ADD_API_RESULT_CODE.SUCCESSFULLY_CREATED:
        return {
          resultCode: GROUP_ADD_API_RESULT_CODE.SUCCESSFULLY_CREATED,
          groupId: apiResponse.details.groupId,
          groupName: apiResponse.details.groupName,
          corpId: apiResponse.details.corpId,
        };
      case GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST:
        return {
          resultCode: GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST,
          /*eslint-disable*/
          groupId: 0,
          groupName: '',
          corpId: '',
        };
      // Failure
      // case GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST:
      //   throw GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST;
      case GROUP_ADD_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        throw GROUP_ADD_API_RESULT_CODE.WARN_NO_AUTH_LIST;
      case GROUP_ADD_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw GROUP_ADD_API_RESULT_CODE.INFO_NOTHING_KEY;
      case GROUP_ADD_API_RESULT_CODE.INFO_INVALID:
        throw GROUP_ADD_API_RESULT_CODE.INFO_INVALID;
      case GROUP_ADD_API_RESULT_CODE.INFO_EXPIRED:
        throw GROUP_ADD_API_RESULT_CODE.INFO_EXPIRED;
      case GROUP_ADD_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GROUP_ADD_API_RESULT_CODE.WARN_INVALID_AUTH;
      case GROUP_ADD_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GROUP_ADD_API_RESULT_CODE.WARN_INVALID_AUTH;
      case GROUP_ADD_API_RESULT_CODE.AUTH_NOT_ALLOWED:
        throw GROUP_ADD_API_RESULT_CODE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
          throw SERVER_MESSAGE.EXPIRED_TOKEN;
      default:
        throw GROUP_ADD_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: GROUP_ADD_API_ERROR_TYPE =
      GROUP_ADD_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGroupAddErrorType(error)) {
      resultCode = error;
    }
    return {
      resultCode,
      groupId: 0,
      groupName: '',
      corpId: '',
    };
  }
};
export const transGroupDetail = (navigate: NavigateFunction, id: any): void => {
  // [ユーザー管理]画面に遷移
  navigate(`/groups/groupdetail/${id}`, { replace: true });
};
