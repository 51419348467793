import { useSelector } from 'react-redux';
import {
  accountInfoSlice,
  AccountInfoState,
} from '../state/slice/accountInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '../state/store';
import { FeaturePermission } from '../types/apis/loginApi.d';

/**
 * Store(Redux)へのログイン後のアカウント情報保存 hook
 *
 * @returns
 */
// 返す型が複雑すぎるため、ルールを無効化。（長すぎて、逆に読みにくいため）
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAccountInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const accountInfo = useSelector<RootState, AccountInfoState>(
    (state) => state.accountInfo,
  );

  const reLoginEula = useSelector<RootState, boolean>(
    (state) => state.accountInfo.reloginEulaState,
  );

  // console.log(accountInfo, 'accountInfo');
  const {
    saveLocale,
    saveEmail,
    saveCorpId,
    saveCompany,
    saveCorpCreatedDt,
    saveRoleCode,
    saveRoleName,
    saveUserName,
    saveUserId,
    saveTotalGroups,
    saveFeaturePermissions,
    saveRoleChange,
    saveSubcriptionChange,
    saveImageUrl,
    savePlanName,
    saveLatestEulaConsented,
    saveReLoginEulaState,
    saveEulaConsentedPreviously,
    savePlanCode,
    saveShopifyFeatureEnabled,
    saveNotificationApiCallDone,
    saveUserRoleChangeApiCall,
  } = accountInfoSlice.actions;

  /**
   * Account information reset Dispatch
   */
  const accountoInfoReset = () => {
    dispatch(saveLocale(''));
    dispatch(saveEmail(''));
    dispatch(saveCorpId(''));
    dispatch(saveCompany(''));
    dispatch(saveCorpCreatedDt(''));
    dispatch(saveRoleCode(0));
    dispatch(saveRoleName(''));
    dispatch(saveUserName(''));
    dispatch(saveImageUrl(''));
    dispatch(savePlanName(''));
    dispatch(savePlanCode(0));
    dispatch(saveShopifyFeatureEnabled(false));

    dispatch(saveRoleChange(false));
    dispatch(saveSubcriptionChange(false));
    dispatch(saveLatestEulaConsented(true));
    dispatch(saveReLoginEulaState(false));
    dispatch(saveEulaConsentedPreviously(false));
    dispatch(saveUserId(0));
    dispatch(saveTotalGroups(0));
    dispatch(
      saveFeaturePermissions({
        GROUPMANAGEMENT: {
          GROUPNAME: [],
          GROUPDEVICE: [],
          GROUPUSER: [],
          GROUPUSERMODAL: [],
          GROUPBASIC: [],
          REMOTECONTROLWIFI: [],
          GROUPDEVICEMODAL: [],
          REMOTECONTROLFEATURELIMIT: [],
          REMOTECONTROLDATAPROTECTION: [],
        },
        DEVICEMANAGEMENT: {
          DEVICEBASIC: [],
          DEVICELOSTMODE: [],
          DEVICENAME: [],
          DEVICEINITIALIZATION: [],
          DEVICETRANSLATIONHISTORY: [],
        },
        CORPORATEMANAGEMENT: {
          CORPORATEPROFILE: [],
          CORPORATEPLAN: [],
        },
        DASHBOARDMANAGEMENT: {
          DASHBOARDPERIODSWITCHING: [],
        },
        NOTIFICATIONMANAGEMENT: {
          NOTIFICATIONBASIC: [],
        },
        REPORTING: {
          TRANSLATIONCOUNTTO: [],
          TRANSLATIONCOUNT: [],
          TRANSLATIONCOUNTFROM: [],
          LOGDATA: [],
        },
        DICTIONARYMANAGEMENT: {
          DICTIONARYBASIC: [],
        },
        PROFILEMANAGEMENT: {
          USERPASSWORD: [],
          USERPROFILE: [],
          USERNAME: [],
        },
        ACCOUNTMANAGEMENT: {
          USERBASIC: [],
          USERINVITE: [],
          USERROLE: [],
        },
        SECURITYMANAGEMENT: {
          TRANSLATIONHISTORYCONTROL: [],
        },
      }),
    );
    dispatch(saveNotificationApiCallDone(false));
    dispatch(saveUserRoleChangeApiCall(false));
  };

  return {
    accountInfo,
    reLoginEula,
    saveLocale: (state: string) => dispatch(saveLocale(state)),
    saveEmail: (state: string) => dispatch(saveEmail(state)),
    saveCorpId: (state: string) => dispatch(saveCorpId(state)),
    saveCompany: (state: string) => dispatch(saveCompany(state)),
    saveCorpCreatedDt: (state: string) => dispatch(saveCorpCreatedDt(state)),
    saveRoleCode: (state: number) => dispatch(saveRoleCode(state)),
    saveRoleName: (state: string) => dispatch(saveRoleName(state)),
    saveUserName: (state: string) => dispatch(saveUserName(state)),
    saveImageUrl: (state: string) => dispatch(saveImageUrl(state)),
    savePlanName: (state: string) => dispatch(savePlanName(state)),
    saveUserId: (state: number) => dispatch(saveUserId(state)),
    saveRoleChange: (state: boolean) => dispatch(saveRoleChange(state)),
    saveSubcriptionChange: (state: boolean) => dispatch(saveRoleChange(state)),
    savePlanCode: (state: number) => dispatch(savePlanCode(state)),
    saveShopifyFeatureEnabled: (state: boolean) =>
      dispatch(saveShopifyFeatureEnabled(state)),

    saveLatestEulaConsented: (state: boolean) =>
      dispatch(saveLatestEulaConsented(state)),
    saveReLoginEulaState: (state: boolean) =>
      dispatch(saveReLoginEulaState(state)),
    saveEulaConsentedPreviously: (state: boolean) =>
      dispatch(saveEulaConsentedPreviously(state)),
    saveTotalGroups: (state: number) => dispatch(saveTotalGroups(state)),
    saveFeaturePermissions: (state: FeaturePermission) =>
      dispatch(saveFeaturePermissions(state)),
    saveNotificationApiCallDone: (state: boolean) =>
      dispatch(saveNotificationApiCallDone(state)),
    saveUserRoleChangeApiCall: (state: boolean) =>
      dispatch(saveUserRoleChangeApiCall(state)),

    accountoInfoReset,
  };
};

export default useAccountInfo;
