/**
 * ダウンロード日付選択に関する値
 */
export type DownloadDate = {
  // 選択可能な最小日付
  minDate: Date;
  // 選択可能な最大日付
  maxDate: Date;
};

/**
 * ダウンロード期間エラーコード
 */
export const DOWNLOAD_DATE_ERROR_CODE = {
  // 期間の指定不正エラー(期間開始日 > 期間終了日)
  WARN_PERIOD_FRAUD: 'WARN_PERIOD_FRAUD',
  // 未指定エラー
  WARN_UNSPECIFIED: 'WARN_UNSPECIFIED',
  // 指定期間がminDate～maxDateの範囲外エラー
  WARN_OUT_OF_RANGE: 'WARN_OUT_OF_RANGE',
  // 形式不正エラー
  ERR_FORMAT: 'ERR_FORMAT',
} as const;

/**
 * ダウンロード期間エラータイプ
 */
export type DOWNLOAD_DATE_ERROR_TYPE =
  typeof DOWNLOAD_DATE_ERROR_CODE[keyof typeof DOWNLOAD_DATE_ERROR_CODE];
