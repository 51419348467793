import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { SecuritySettingsUpdateApiResponse } from '../types/apis/securitySettingsApi.d';

const callSecuritySettingsUpdateApi =
  (
    requestParams: boolean,
  ): Promise<SecuritySettingsUpdateApiResponse> => {
    const jsonData = {
        preventStatus : requestParams,
      };
    /* eslint-disable */
    const response = callApiUtil
      .post<SecuritySettingsUpdateApiResponse>(API_URL.CHANGE_TRANS_HISTORY_PREVENTION_API, jsonData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((apiResponse) => apiResponse.data)
      .catch((error) => error.response.data);

    return response;
  };

export default callSecuritySettingsUpdateApi;