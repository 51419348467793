import { useSelector } from 'react-redux';

import { AppDispatch, RootState, useAppDispatch } from '../state/store';
import {
  accessKeySlice,
  callLoginApiAsyncThunk,
} from '../state/slice/accessKeySlice';
import type { LoginApiRequestParameter } from '../types/apis/loginApi';
/**
 * WEBストレージ(セッションストレージ)へのアクセスキー保存 hooks
 * ※APIが呼び出されたことをdispatchで検知してセッションストレージにアクセスキーを保存
 *
 * @returns
 */
// 返す型が複雑すぎるため、ルールを無効化。（長すぎて、逆に読みにくいため）
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAccessKey = () => {
  const accessKeyRedux = useSelector<RootState, string>(
    (state) => state.iotAccessKey.iotAccessKey,
  );

  const dispatch: AppDispatch = useAppDispatch();

  const { resetAccessKey } = accessKeySlice.actions;

  /**
   * ログインAPI呼び出しdispatch
   *
   * @param params
   * @returns
   */
  const callLoginApi = (params: LoginApiRequestParameter) =>
    dispatch(callLoginApiAsyncThunk(params));

  /**
   * アクセスキーリセットdispatch
   *
   * @returns
   */
  const reset = () => dispatch(resetAccessKey());

  return {
    accessKey: accessKeyRedux,
    callLoginApi,
    reset,
    accessKeyRedux,
  };
};

export default useAccessKey;
