import React from 'react'
/* eslint-disable react/self-closing-comp */
import styles from './Loader.module.scss';

type Props = {
    colorCode: string;
    size?: "medium";
};

const Loader = React.memo<Props>((props): JSX.Element => (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <div className={`${styles.loaderWrapper} ${props?.size && styles[`${props?.size}`]}`}>
        <div className={styles.loaderInner}>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
            <div style={{ background: props.colorCode }}></div>
        </div>
    </div>
    ),
);
  

// const Loader = React.memo<Props>((props): JSX.Element => (
    
// ));

export default Loader;
