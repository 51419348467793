import { Dispatch, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Typography } from '@mui/material';
import { ReactComponent as BG } from '../../images/icon/bg.svg';
import { ReactComponent as Icon } from '../../images/icon.svg';
import useAccessKey from '../../hooks/useAccessKey';
import { ReactComponent as DashboardIconWhite } from '../../images/icon/dashboard-white.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { useStyles } from '../Templates/CommonStyle';

type IProp = {
  setShowNav: Dispatch<SetStateAction<boolean>>;
};
const NotFoundPage = ({ setShowNav }: IProp) => {
  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();
  const { accessKey } = useAccessKey();

  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <div className={classes.appBar}>
        <AppBar position="fixed" elevation={0}>
          <Toolbar className={classes.appbarToolbar}>
            {!accessKey ? (
              <Icon className={classes.loginScreenLogo} />
            ) : (
              <Link to="/dashboard">
                <Icon className={classes.logo} />
              </Link>
            )}
          </Toolbar>
        </AppBar>
      </div>
      <Box
        style={{
          background: '#F8F8F8',
          width: '100%',
          minHeight: 'calc(100vh - 62px)',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <Box
          style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <BG
            style={{
              width: '100%',
              height: '100%',
              // display: 'flex',
            }}
          />
          <Box className={classes.errorPageTextBox}>
            <Typography className={classes.errorPageTitleText}>
              {t('notFoundPage.title')}
            </Typography>
            <Typography className={classes.errorPageSubTitleText}>
              {t('notFoundPage.text')}
            </Typography>
            <Button
              id="errorPageButton"
              variant="contained"
              className={classes.backDashboardBtn}
              component={Link}
              to="/dashboard"    
            >
              <DashboardIconWhite className={classes.backDashboardBtnIcon} />
              <Typography className={classes.backDashboardBtnText}>
                {t('notFoundPage.btn')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default NotFoundPage;
