import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import useLogout from '../../hooks/useLogout';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { useStyles } from './CommonStyle';

type Props = {
  // ダイアログ(モーダル)を表示するか否か
  isModalOpen: boolean;
  // ［ログアウト］ボタンクリック処理
  logoutModal: () => void;
  // ダイアログ内に表示するメッセージ
  message: string;
};

/**
 * ログアウトダイアログ コンポーネント
 */
const LogoutDialog = ({
  isModalOpen,
  logoutModal,
  message,
}: Props): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // ログアウト hooks
  const { onClickLogoff } = useLogout();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  return (
    <Dialog
      open={isModalOpen}
      PaperProps={{
        style: { borderRadius: '1rem', backgroundColor: '#f0f0f0' },
      }}
    >
      <DialogContent>
        <DialogContentText
          id="logoutDialogMessage"
          style={{ whiteSpace: 'pre-line' }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <div className={classes.dialogButtonContainer}>
        <DialogActions className={classes.cancelDialogButton}>
          <Button
            color="primary"
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={() => {
              logoutModal();
              onClickLogoff();
            }}
            className={classes.deviceDetailButton}
          >
            {t('logoutModal.label.logoutButton')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default LogoutDialog;
