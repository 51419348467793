import { useEffect } from 'react';
import { Typography } from '@mui/material';
import SimpleBarReact from 'simplebar-react';
// import useMediaQuery from '@mui/material/useMediaQuery';
import DataTableContainer from '../Modecules/DataTableContainer';
import type {
  TableHeaderData,
  TableRowData,
  SortConfig,
} from '../../types/datatable/dataTable.d';
import styles from './TableTemplateContainer.module.scss';
import PaginationContainer from '../Modecules/PaginationContainer';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // テーブルヘッダーに表示する内容
  headerColumns: TableHeaderData[];
  // テーブルデータ行に表示する内容
  dataRows: TableRowData[];
  // テーブル下に表示するテーブルの行の総数のラベル
  totalDataRowsLabel: string;
  // テーブル下に表示するテーブルの行の総数
  totalDataRows: number;
  // テーブルの[ソート]ボタンがクリックされた
  clickSortButton: (data: TableHeaderData) => void;
  // ソート設定
  sortConfig: SortConfig;
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // [前へ]ボタンがクリックされた
  clickPrevPageButton: () => void;
  // [次へ]ボタンがクリックされた
  clickNextPageButton: () => void;
  // 表示件数リスト
  rowNumberPerPages: number[];
  // 選択中の1ページの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された
  changeRowNumber: (selectedRowNumber: number) => void;
  // メールアドレスリンクがクリックされた
  // mailAddressLinkClicked?: ((email: string) => void) | undefined;
  // IMEIリンクがクリックされた
  // imeiLinkClicked?: ((imei: string) => void) | undefined;
  imeiLinkClicked?: ((imei: string) => void) | undefined;
  clickFirstPageButton?: () => void;
  clickLastPageButton?: () => void;
  containFirstAndLastButton?: boolean;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  clickDisable?: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
};

/**
 * ページネーションを持つデータテーブル コンポーネント
 *
 * @returns
 */
const DataTableTemplate = ({
  headerColumns,
  dataRows,
  totalDataRows,
  clickSortButton,
  sortConfig,
  currentPage,
  totalPages,
  clickPrevPageButton,
  clickNextPageButton,
  rowNumberPerPages,
  currentRowNumberPerPage,
  changeRowNumber,
  // mailAddressLinkClicked,
  imeiLinkClicked,
  // imeiLinkClicked,
  // totalDataRowsLabel,
  pageDataIndexFrom,
  pageDataIndexTo,
  simpleBarRef,
  ...props
}: Props): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();

  useEffect(() => {
    const tableBody = document.getElementsByClassName(
      'table-body-simplebar',
    )[0];
    if (tableBody) {
      const scrollContainerClass = tableBody.querySelector(
        '.simplebar-content-wrapper',
      );
      if (scrollContainerClass) {
        scrollContainerClass.scrollTop = 0;
      }
    }
  }, [dataRows]);

  return (
    <>
      <div>
        {props.tableSrcPage === 'Users' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('userList.label.totalUsers')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'Group' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('groupList.label.title')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'Device' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('deviceList.label.deviceList')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'GroupDevice' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('deviceList.label.deviceList')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransHistory' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('transHistoryList.label.tranHistoryList')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransFrom' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('fromLangList.tableCaption')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransFromDay' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('fromLangList.tableCaption')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransCountList' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('transCountList.tableCaption')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransCountListDay' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('transCountList.tableCaption')}({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransTo' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('toLangList.tableCaption')} ({totalDataRows})
          </Typography>
        )}
        {props.tableSrcPage === 'TransToDay' && (
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {t('toLangList.tableCaption')} ({totalDataRows})
          </Typography>
        )}
      </div>
      <div
        className={styles['data-table-wrap']}
        // style={{
        //   overflowX: scroll(props.tableSrcPage as string, matches),
        // }}
      >
        <DataTableContainer
          simpleBarRef={simpleBarRef}
          headerColumns={headerColumns}
          dataRows={dataRows}
          clickSortButton={clickSortButton}
          sortConfig={sortConfig}
          tableSrcPage={props.tableSrcPage}
          isCallingApi={props.isCallingApi}
          imeiLinkClicked={imeiLinkClicked}
          clickDisable={props.clickDisable}
        />
      </div>
      <div
        className={styles['data-pagination']}
        style={{
          backgroundColor: '#F9FAFB',
        }}
      >
        <PaginationContainer
          currentPage={currentPage}
          totalPages={totalPages}
          clickPrevPageButton={clickPrevPageButton}
          clickNextPageButton={clickNextPageButton}
          rowNumberPerPages={rowNumberPerPages}
          currentRowNumberPerPage={currentRowNumberPerPage}
          changeRowNumber={changeRowNumber}
          containFirstAndLastButton={props.containFirstAndLastButton}
          clickFirstPageButton={props.clickFirstPageButton}
          clickLastPageButton={props.clickLastPageButton}
          pageDataIndexFrom={pageDataIndexFrom}
          pageDataIndexTo={pageDataIndexTo}
          totalDataRows={totalDataRows}
        />
      </div>
    </>
  );
};

DataTableTemplate.defaultProps = {
  clickFirstPageButton: () => undefined,
  clickLastPageButton: () => undefined,
  containFirstAndLastButton: false,
  tableSrcPage: '',
  isCallingApi: false,
  imeiLinkClicked: () => undefined,
  clickDisable: false,
};

export default DataTableTemplate;
