import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { Tooltip } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import * as React from 'react';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useAccountInfo from '../../hooks/useAccountInfo';
// mui icons
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
// mui custom style
import { useStyles } from '../Templates/CommonStyle';
import useLogout from '../../hooks/useLogout';
import { FEATURE_PERMISSION, PAGE_PATH_NAME } from '../../constants/constants';
import useRefreshRedux from '../../hooks/useRefreshRedux';
import useGetUpdateUserInfo from '../../hooks/useGetUpdateUserInfo';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // account info email
  accountInfo: string;
  accountImage: string;
};

const Account = React.memo<Props>((props): JSX.Element => {
  // const navigate = useNavigate();
  const { onClickLogoff } = useLogout();
  const { t } = useSwitchLocaleLanguage();
  const { localeLanguage } = useLocale();
  const { accountInfo } = useAccountInfo();
  const { refreshRedux } = useRefreshRedux();
  // update user info hook
  const { callGetUpdateUserInfo } = useGetUpdateUserInfo();
  // common style
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    refreshRedux();
    callGetUpdateUserInfo();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTooltipOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = isMenuOpen ? 'simple-popper' : undefined;
  const getFaqUrl = (): string => {
    if (localeLanguage === LOCALE_CODE.FR)
      return 'https://www.pocketalk.com/fr_fr/pocketalk-faq';

    if (localeLanguage === LOCALE_CODE.DE)
      return 'https://www.pocketalk.com/de_de/pocketalk-faq';

    return 'https://pocketalk.link/EyNxZm';
  };

  const faqUrl = getFaqUrl();

  const onClickProfile = (link: string) => {
    window.open(link, '_blank', 'noreferrer');
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        <Tooltip
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [
                    accountInfo.featurePermissions?.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
                      FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE
                        .VIEWDETAILS,
                    )
                      ? -22
                      : 0,
                    -4,
                  ],
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              className: classes.headerAccountTooltipStyle,
            },
          }}
          title={
            accountInfo.featurePermissions?.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
              FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE
                .VIEWDETAILS,
            )
              ? t('profileMenu.userNCorp')
              : t('profileMenu.userProfile')
          }
          placement="bottom"
          sx={{ marginTop: 0, marginRight: '20px' }}
          arrow
          open={tooltipOpen}
          disableHoverListener
          disableFocusListener
        >
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-describedby={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            disableRipple
            sx={{
              padding: '0',
              borderRadius: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                '& img': {
                  boxShadow: ' 0px 0px 6px 2px #00000029',
                },
              },
            }}
          >
            {props.accountImage ? (
              <img
                src={props.accountImage}
                alt="avatar"
                style={{ background: 'transparent', borderRadius: '50%' }}
                className={classes.avatar}
              />
            ) : (
              <Avatar
                className={classes.avatar}
                sx={{
                  '&:hover': {
                    boxShadow: '0px 0px 6px 2px #00000029',
                  },
                }}
              >
                {props.accountInfo[0]?.toUpperCase()}
              </Avatar>
            )}
            <ArrowDown className={classes.avatarArrow} />
          </IconButton>
        </Tooltip>
      </Box>
      <Popper
        placement="bottom-end"
        id={menuId}
        open={isMenuOpen}
        anchorEl={anchorEl}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [18, 14],
              },
            },
          ],
        }}
        className={classes.accountMenu}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <List>
            <ListItemButton
              onClick={() => {
                handleMenuClose();
                onClickProfile(PAGE_PATH_NAME.PERSONAL_SETTING);
                // navigate(PAGE_PATH_NAME.PERSONAL_SETTING);
              }}
            >
              {t('profileMenu.personalProfile')}
            </ListItemButton>
            {accountInfo.featurePermissions?.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
              FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE
                .VIEWDETAILS,
            ) && (
              <ListItemButton
                onClick={() => {
                  handleMenuClose();
                  onClickProfile(PAGE_PATH_NAME.CORPORATE_PROFILE);
                  // navigate(PAGE_PATH_NAME.CORPORATE_PROFILE);
                }}
              >
                {t('profileMenu.corporateProfile')}
              </ListItemButton>
            )}
            <ListItemButton
              onClick={() => {
                handleMenuClose();
                window.open(faqUrl, '_blank', 'noopener,noreferrer');
              }}
            >
              {t('profileMenu.faq')}
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                onClickLogoff();
              }}
            >
              {t('profileMenu.logOut')}
            </ListItemButton>
          </List>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
});

export default Account;
