/**
 * ビュー切替タイプ
 */
export type ViewType = { [key: string]: string };

/**
 * ビュー切替キー
 */
export const VIEW_KEY = {
  // 日
  d: 'd',
  // 週
  w: 'w',
  // 月
  m: 'm',
};
export type VIEW_KEY = typeof VIEW_KEY[keyof typeof VIEW_KEY];
