// import { useState, useEffect } from 'react';
import { useState } from 'react';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import { fetchUpdateUserInfo } from '../utils/user/updateUserInfoUtil';
import type { GetUpdateUserInfoApiResponse } from '../types/apis/getUpdateUserInfoApi';
import useAccountInfo from './useAccountInfo';

export type UseGetUpdateUserInfo = {
  isCallingGetUpdateUserInfo: boolean;
  getUpdateUserInfoApiError: ALL_ERROR_TYPE | null;
  callGetUpdateUserInfo: () => void;
};
const useGetUpdateUserInfo = (): UseGetUpdateUserInfo => {
  const [isCallingGetUpdateUserInfo, setIsCallingGetUpdateUserInfo] =
    useState<boolean>(false);
  const [getUpdateUserInfoApiError, setGetUpdateUserInfoError] =
    useState<ALL_ERROR_TYPE | null>(null);

  const { accountInfo, saveLocale, saveCompany, saveImageUrl } =
    useAccountInfo();

  const callGetUpdateUserInfo = (): void => {
    setIsCallingGetUpdateUserInfo(true);
    setGetUpdateUserInfoError(null);

    fetchUpdateUserInfo()
      .then((result: GetUpdateUserInfoApiResponse) => {
        if (result.message === SERVER_MESSAGE.FETCH_OK) {
          if (
            accountInfo.locale &&
            result.details.locale &&
            result.details.locale !== accountInfo.locale
          ) {
            saveLocale(result.details.locale);
          }
          if (
            accountInfo.company &&
            result.details.company &&
            result.details.company !== accountInfo.company
          ) {
            saveCompany(result.details.company);
          }
          if (
            accountInfo.imageUrl &&
            result.details.imageUrl &&
            result.details.imageUrl !== accountInfo.imageUrl
          ) {
            saveImageUrl(result.details.imageUrl);
          }

          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGetUpdateUserInfoError(result.message);
      })
      .finally(() => {
        setIsCallingGetUpdateUserInfo(false);
      });
  };

  return {
    callGetUpdateUserInfo,
    isCallingGetUpdateUserInfo,
    getUpdateUserInfoApiError,
  };
};

export default useGetUpdateUserInfo;
