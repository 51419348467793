import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// import useAccessKey from './useAccessKey';
// import useAccountInfo from './useAccountInfo';
import { fetchGroupTopUser } from '../utils/group/groupTopUser';
import {
  GROUP_TOP_USER_ERROR_TYPE,
  GROUP_TOP_USER_API_RESULT_CODE,
} from '../apis/callTopUserListApi';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useRedirectDialog from './useRedirectDialog';
// import useLogout from './useLogout';
import { isGroupTopUserErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import { groupTopUserResult } from '../types/Group/groupTopUserApi.d';

// import { useLocation } from 'react-router-dom';

// type GroupTopUser = {
//   count: number;
//   topUsers: [string];
//   group_code: number;
// };
type IDParams = {
  groupId: string;
};

const useGroupTopUser = (isUserUpdate: boolean) => {
  // const { accessKey } = useAccessKey();
  // const { accountInfo } = useAccountInfo();
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();
  const { displaySnackbar } = useSnackbar();

  const { groupId } = useParams<IDParams>();
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [groupTopUserName, setGroupTopUserName] =
    useState<{ username: string; imageUrl: string }[]>();
  const [groupUserCount, setGroupUserCount] = useState(0);
  const [groupCode, setGroupCode] = useState<number | undefined>(undefined);
  const { t } = useSwitchLocaleLanguage();
  // navigate
  /*eslint-disable*/
  const groupIdd = parseInt(groupId!);
  const callGroupTopUserApi = useCallback(() => {
    setIsCallingApi(true);

    fetchGroupTopUser(groupIdd)
      .then((result: groupTopUserResult) => {
        switch (result.resultCode) {
          case GROUP_TOP_USER_API_RESULT_CODE.SUCCESSFULLY_FETCHED: {
            setGroupTopUserName(result.users);
            setGroupUserCount(result.numberOfUsers);
            setGroupCode(result.groupCode);
            break;
          }
          case GROUP_TOP_USER_API_RESULT_CODE.GROUP_ACCESS_DENIED:
          case GROUP_TOP_USER_API_RESULT_CODE.CORPORATION_ACCESS_DENIED: {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess'),
            });
            break;
          }
          default:
            throw result.resultCode;
        }
        // loadingBarHide
        setIsCallingApi(false);
      })
      .catch((error: GROUP_TOP_USER_ERROR_TYPE | unknown) => {
        if (isGroupTopUserErrorType(error)) {
          if (
            error === GROUP_TOP_USER_API_RESULT_CODE.WARN_INVALID_AUTH ||
            GROUP_TOP_USER_API_RESULT_CODE.USER_ROLE_CHANGED
          ) {
            // displayRedirectDialog({
            //   open: true,
            //   title: 'Not authorized',
            //   message: 'Your role has been changed. Please re-login.',
            //   routePath: onClickLogoff,
            // });
          } else if (error === GROUP_TOP_USER_API_RESULT_CODE.INFO_NO_GROUP) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup'),
            });
          } else if (
            error === GROUP_TOP_USER_API_RESULT_CODE.GROUP_ACCESS_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess'),
            });
          } else if (
            error === GROUP_TOP_USER_API_RESULT_CODE.CORPORATION_ACCESS_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess'),
            });
          } else {
            displaySnackbar({
              message: t('common.error.serverErr'),
              type: 'error',
            });
          }
        }
      })
      .finally(() => {
        setIsCallingApi(false);

        // Do nothing (if you do not add finally, an error will occur)
      });
  }, [groupIdd]);

  /*eslint-disable*/
  useEffect(() => {
    callGroupTopUserApi();
  }, []);

  useEffect(() => {
    if (isUserUpdate) {
      callGroupTopUserApi();
    }
  }, [isUserUpdate]);

  return {
    isCallingApi,
    groupUserCount,
    t,
    groupTopUserName,
    groupCode,
  };
};

export default useGroupTopUser;
