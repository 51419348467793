/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Typography, IconButton, Button } from '@mui/material';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { useStyles } from './DialogStyle';
import Loader from '../Atoms/Loader';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import { ReactComponent as CloseWhiteIcon } from '../../images/icon/cross-white.svg';
import ManageDeviceDialog from './ManageDeviceDialog';
import ManageUserDialog from './ManageUserDialog';
import useAccountInfo from '../../hooks/useAccountInfo';
import { FEATURE_PERMISSION } from '../../constants/constants';
import useManageGroupDeviceUserUpdate from '../../hooks/useManageGroupDeviceNUserUpdate';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { LOCALE_CODE } from '../../hooks/useLocale';

type Props = {
  open: boolean;
  onClose: () => void;
  groupId: number;
};

const ManageDialog = React.memo<Props>((props): JSX.Element => {
  const { open, onClose, groupId } = props;

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();
  const { accountInfo } = useAccountInfo();
  const [isUpdateError, setIsUpdateError] = useState(false);
  // manage user api call handle (call after manage user btn click)
  const [isFirstManageUserRender, setIsFirstManageUserRender] = useState(false);
  // handle toggle
  const [isManageDeviceActive, setIsManageDeviceActive] = useState(true);
  // payload state
  const [addedDeviceListPayload, setAddedDeviceListPayload] = useState<
    number[]
  >([]);
  const [removedDeviceListPayload, setRemovedDeviceListPayload] = useState<
    number[]
  >([]);
  const [addedUserListPayload, setAddedUserListPayload] = useState<number[]>(
    [],
  );
  const [removedUserListPayload, setRemovedUserListPayload] = useState<
    number[]
  >([]);
  const {
    onClickManageGroupUpdate,
    isCallingManageUpdateApi,
    groupUpdateError,
    errorMessage,
    isGroupUpdate,
  } = useManageGroupDeviceUserUpdate();

  const onClickManageDeviceBtn = () => {
    setIsManageDeviceActive(true);

    // GA tag
    sendGAEvent('select_tab', 'txt_name', 'manage_group_device_tab');
  };

  const onClickManageUserBtn = () => {
    setIsManageDeviceActive(false);
    setIsFirstManageUserRender(true);
    sendGAEvent('select_tab', 'txt_name', 'manage_group_user_tab');
  };

  // feature permission
  useEffect(() => {
    if (
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.VIEWLIST,
      )
    ) {
      setIsManageDeviceActive(false);
      setIsFirstManageUserRender(true);
    }
  }, [accountInfo]);

  const handleUpdate = () => {
    onClickManageGroupUpdate(
      groupId,
      addedUserListPayload,
      removedUserListPayload,
      addedDeviceListPayload,
      removedDeviceListPayload,
    );
  };

  useEffect(() => {
    if (groupUpdateError) {
      setIsUpdateError(true);
    }
  }, [groupUpdateError]);

  useEffect(() => {
    if (isGroupUpdate) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupUpdate]);

  return (
    <Dialog open={open} className={`${classes.manageDialog} manage-dialog`}>
      <Box sx={{ position: 'relative' }}>
        {isUpdateError && (
          <Box
            className={classes.manageGroupErrorCard}
            sx={{ top: '47px !important' }}
          >
            <Box className={classes.manageGroupErrorCardBox}>
              <ErrorSign />
              <Typography className={classes.manageGroupErrorCardBoxText}>
                {errorMessage}
              </Typography>
            </Box>

            <IconButton
              sx={{ m: '0', height: '28px' }}
              onClick={() => setIsUpdateError(false)}
            >
              <CloseWhiteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box className={`${classes.manageDialogSwapBox} manage-dialog-swap-box`}>
        <Box
          className="manage-dialog-swap-box-sub"
          sx={{ width: '466px', display: 'flex' }}
        >
          <Box sx={{ position: 'relative' }}>
            <Button
              type="button"
              aria-label="activity"
              className={classes.manageSwapBtn}
              sx={{
                color: isManageDeviceActive ? '#009DE1' : '#939598',
                '&.Mui-disabled': {
                  color: isManageDeviceActive ? '#009DE1' : '#939598',
                },
              }}
              onClick={onClickManageDeviceBtn}
              disabled={
                isCallingManageUpdateApi ||
                !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                  FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.VIEWLIST,
                )
              }
              disableRipple
            >
              <Typography
                sx={{
                  fontWeight: isManageDeviceActive ? '700' : '500',
                }}
                className={classes.manageSwapBtnText}
              >
                {t('manageDevices.title')}
              </Typography>
            </Button>

            {isManageDeviceActive && (
              // eslint-disable-next-line react/self-closing-comp
              <hr
                style={{
                  width:
                    accountInfo.locale === LOCALE_CODE.JA
                      ? '221px'
                      : accountInfo.locale === LOCALE_CODE.FR
                      ? isSafari
                        ? '322px'
                        : '293px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? isSafari
                        ? '285px'
                        : '262px'
                      : '242px',
                }}
                className={classes.activeBar}
              ></hr>
            )}
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Button
              type="button"
              aria-label="activity"
              className={classes.manageSwapBtn}
              sx={{
                color: !isManageDeviceActive ? '#009DE1' : '#939598',
                '&.Mui-disabled': {
                  color: !isManageDeviceActive ? '#009DE1' : '#939598',
                },
              }}
              onClick={onClickManageUserBtn}
              disabled={
                isCallingManageUpdateApi ||
                !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                  FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.VIEWLIST,
                )
              }
              disableRipple
            >
              <Typography
                sx={{
                  fontWeight: !isManageDeviceActive ? '700' : '500',
                }}
                className={classes.manageSwapBtnText}
              >
                {t('userListModal.label.manageGroupUsers')}
              </Typography>
            </Button>

            {!isManageDeviceActive && (
              // eslint-disable-next-line react/self-closing-comp
              <hr
                style={{
                  width:
                    accountInfo.locale === LOCALE_CODE.JA
                      ? '256px'
                      : accountInfo.locale === LOCALE_CODE.FR
                      ? isSafari
                        ? '342px'
                        : '310px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? isSafari
                        ? '310px'
                        : '285px'
                      : '224px',
                }}
                className={`${classes.activeBar} manage-dialog-active-bar`}
              ></hr>
            )}
          </Box>
        </Box>
        <IconButton
          className={`${classes.closeIconBtn} manage-dialog-close-icon`}
          disabled={isCallingManageUpdateApi}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <ManageDeviceDialog
        onClose={onClose}
        isManageDeviceActive={isManageDeviceActive}
        setAddedDeviceListPayload={setAddedDeviceListPayload}
        setRemovedDeviceListPayload={setRemovedDeviceListPayload}
      />

      <ManageUserDialog
        onClose={onClose}
        isManageUserActive={!isManageDeviceActive}
        setAddedUserListPayload={setAddedUserListPayload}
        setRemovedUserListPayload={setRemovedUserListPayload}
        isFirstManageUserRender={isFirstManageUserRender}
      />

      {/* Cancel & Update Button */}
      <Box
        className={`${
          classes.manageDialogFooterBox
        } ${'manage-group-device-submit-btn'}`}
      >
        {/* Cancel Button */}
        <Button
          aria-label="Cancel"
          onClick={() => {
            onClose();
          }}
          disabled={isCallingManageUpdateApi}
          className={classes.manageDialogCancelBtn}
          disableRipple
        >
          <Typography className={classes.manageDialogCancelBtnText}>
            {t('manageDevices.button.cancel')}
          </Typography>
        </Button>
        {/* Update Button */}
        <Button
          aria-label="Update"
          disabled={
            !(
              addedDeviceListPayload.length > 0 ||
              removedDeviceListPayload.length > 0 ||
              addedUserListPayload.length > 0 ||
              removedUserListPayload.length > 0
            ) || isCallingManageUpdateApi
          }
          className={classes.manageDialogUpdateBtn}
          disableRipple
          onClick={handleUpdate}
        >
          {isCallingManageUpdateApi ? (
            <Typography className={classes.manageDialogUpdateBtnTextUpdating}>
              {t('manageDevices.label.updating')}
              <Loader colorCode="#fff" />
            </Typography>
          ) : (
            <Typography className={classes.manageDialogUpdateBtnText}>
              {t('manageDevices.button.update')}
            </Typography>
          )}
        </Button>
      </Box>
    </Dialog>
  );
});

export default ManageDialog;
