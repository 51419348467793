import React, { useRef, useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import { Box, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import SimpleBarReact from 'simplebar-react';
import pixelWidth from 'string-pixel-width';
import ViewUserSearchField from '../Modecules/ViewUserSearch';
import { useStyles } from './DialogStyle';
// assets
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as GroupUser } from '../../images/GroupUser.svg';
import useUnGroupUserList from '../../hooks/useUnGroupUserList';
import UserDialogListSkeleton from './UserDialogListSkeleton';
import { UNGROUP_USER_LIST_API_RESULT_CODE } from '../../apis/callUnGroupUserListApi';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { colorsArray, regexJapanese } from '../../constants/constants';
import { ReactComponent as GroupUserIcon } from '../../images/icon/Group_user.svg';

type Props = {
  open: boolean;
  onClose: () => void;
};
type TooltipProps = {
  title: string;
  children: React.ReactElement;
  dataWidth: number;
  divWidth: number;
};
const StyledTooltip = React.memo<TooltipProps>((props): JSX.Element => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={props.title}
      disableHoverListener={props.dataWidth <= props.divWidth}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          className: classes.tooltipStyle,
        },
      }}
    >
      {props.children}
    </Tooltip>
  );
});

const UserDataDialog = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  const { open, onClose } = props;
  // Infinite Scroll

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');

  const {
    originalUserList,
    // moreUser,
    // setOriginalUserList,
    // callUngroupUserListApi,
    t,
    errorType,
    isCallingApi,
    totalUser,
  } = useUnGroupUserList(pageNumber, searchText);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastUserElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          totalUser > originalUserList.length &&
          !isCallingApi
        ) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (node) observer.current.observe(node);
    },

    [totalUser, originalUserList.length, isCallingApi],
  );

  const handleSubmit = (e: string) => {
    setPageNumber(1);
    setSearchText(e);

    // GA event tag
    sendGAEvent('filter_user_txt', 'filter_txt', 'input_user');
    sendGAEvent('filter_user_txt', 'input_user_value', e);
  };

  return (
    <Dialog open={open} className={classes.userDialog}>
      <Box mr="32px" sx={{ position: 'relative' }}>
        <IconButton className={classes.CloseIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GroupUser />
        </Box>
        <DialogTitle className={classes.DialogTitle}>
          {t('userListModal.label.usersText')}
        </DialogTitle>
        <ViewUserSearchField
          clickFilterSearch={(val) => handleSubmit(val)}
          placeholder={t('userListModal.label.searchUser')}
          responsiveClass="w-full"
        />
      </Box>
      <List className={classes.UserList}>
        <SimpleBarReact style={{ height: '366px', marginRight: '4px' }}>
          {/* fetching Data */}
          {!(isCallingApi && pageNumber === 1) &&
            originalUserList.map((user, i) => {
              const colorIndex = i % 16;
              /*eslint-disable*/
              const nameWord = user.userName?.trim().charAt(0).toUpperCase();
              const nameWidth = pixelWidth(user.userName, {
                font: 'open sans',
                size: 14,
              });
              const emailWidth = pixelWidth(user.mailAddress, { size: 14 });

              const isJapanese = regexJapanese.exec(user.userName);

              return (
                <React.Fragment key={user.mailAddress}>
                  <ListItem
                    ref={
                      originalUserList.length === i + 1
                        ? lastUserElementRef
                        : null
                    }
                    key={user.mailAddress}
                  >
                    <ListItemAvatar
                      sx={{
                        minWidth: '55px',
                      }}
                    >
                      {user.imageUrl ? (
                        <img
                          alt="pic"
                          src={user.imageUrl}
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                          }}
                        />
                      ) : (
                        <Avatar
                          className={classes.ListAvatar}
                          sx={{ bgcolor: colorsArray[colorIndex] }}
                        >
                          {nameWord}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '341px',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemText
                        primary={
                          <StyledTooltip
                            title={user.userName}
                            dataWidth={nameWidth}
                            // divWidth={isJapanese ? 120 : 240}
                            divWidth={isJapanese ? 124 : 240}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: '#313133',
                                // cursor: 'pointer',
                                // maxWidth: '85%',
                                maxWidth: '240px',
                              }}
                              className={
                                // nameWidth > (isJapanese ? 120 : 240)
                                nameWidth > (isJapanese ? 124 : 240)
                                  ? classes.textEllipse
                                  : classes.textOverflow
                              }
                            >
                              {user.userName}
                            </Typography>
                          </StyledTooltip>
                        }
                        secondary={
                          <StyledTooltip
                            title={user.mailAddress}
                            dataWidth={emailWidth}
                            divWidth={228}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px !important',
                                color: '#313133 !important',
                                fontWeight: '300 !important',
                                // maxWidth: '88%',
                                maxWidth: '228px',
                              }}
                              className={
                                emailWidth > 228
                                  ? classes.textEllipse
                                  : classes.textOverflow
                              }
                            >
                              {user.mailAddress}
                            </Typography>
                          </StyledTooltip>
                        }
                      />
                      <Typography
                        fontSize="12px"
                        lineHeight="24px"
                        color="#313133"
                      >
                        {user.roleName}
                      </Typography>
                    </Box>
                  </ListItem>
                  {originalUserList.length !== i + 1 && (
                    <Divider
                      sx={{ mr: '28px', color: '#D9D9D9', opacity: 0.6 }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          {/* End fetching Data */}
          {/* skeleton loading  */}
          {isCallingApi && <UserDialogListSkeleton />}
          {/* no User & server error */}
          {!isCallingApi &&
            (errorType === UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER ||
              errorType === UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN ||
              originalUserList.length === 0) && (
              <Box
                display="flex"
                sx={{
                  // mt: '8px',
                  width: '396px',
                  height: '366px',
                }}
              >
                <Box
                  sx={{
                    width: '396px',
                    height: '366px',
                    border: '1px solid #D9D9D9',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {errorType !==
                    UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN && (
                    <>
                      <GroupUserIcon />
                      <Typography
                        sx={{
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: '#313133',
                        }}
                      >
                        {t('userListModal.message.noUser')}
                      </Typography>
                    </>
                  )}
                  {errorType ===
                    UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN && (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#313133',
                      }}
                    >
                      {t('common.error.noData')}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          {/* End no User & server error */}
        </SimpleBarReact>
      </List>
    </Dialog>
  );
});

export default UserDataDialog;
