/* eslint-disable radix */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SimpleBarReact from 'simplebar-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableHeaderContainer from '../Atoms/TableHeaderContainer';
import TableBodyContainer from '../Atoms/TableBodyContainer';
import TableBodyContainerSkeleton from '../Atoms/TableBodyContainerSkeleton';
import TableBodyDeviceSkeleton from '../Atoms/TableBodyDeviceSkeleton';
import {
  TableHeaderData,
  TableRowData,
  SortConfig,
} from '../../types/datatable/dataTable.d';
import styles from './DataTableContainer.module.scss';
import TableBodyUserSkeleton from '../Atoms/TableBodyUserSkeleton';
import TableBodyTransHistorySkeleton from '../Atoms/TableBodyTransHistorySkeleton';
import DeviceListBodySkeleton from '../Atoms/DeviceListBodySkeleton';
import TableBodyReportListSkeleton from '../Atoms/TableBodyReportListSkeleton';

import Loader from '../Atoms/Loader';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // テーブルヘッダー行に表示する内容
  headerColumns: TableHeaderData[];
  // テーブルデータ行に表示する内容
  dataRows: TableRowData[];
  // テーブルの[ソート]ボタンがクリックされた
  clickSortButton: (data: TableHeaderData) => void;
  // ソート設定
  sortConfig: SortConfig;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  imeiLinkClicked?: ((imei: string) => void) | undefined;
  clickDisable?: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
};

/**
 * データテーブル コンポーネント
 * @returns
 */
const DataTableContainer = ({
  headerColumns,
  dataRows,
  clickSortButton,
  sortConfig,
  imeiLinkClicked,
  simpleBarRef,
  ...props
}: // mailAddressLinkClicked,
// imeiLinkClicked,
Props): JSX.Element => {
  const matches = useMediaQuery('(max-width:1820px)');
  const [columnsWidthSum, setColumnsWidthSum] = useState<number>(0);

  useEffect(() => {
    let allColumnsSum = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < headerColumns.length; i++) {
      let subStringWidth = parseInt(
        (headerColumns[i].width ?? '').split('px')[0],
      );
      allColumnsSum += Number(subStringWidth);
    }
    setColumnsWidthSum(allColumnsSum);
  }, [headerColumns]);

  const width = (pageSrc: string, match: boolean) => {
    if (pageSrc === 'Device' && match === true) {
      return '1820px';
    }

    if (
      [
        'TransCountList',
        'TransCountListDay',
        'TransFromDay',
        'TransToDay',
        'TransFrom',
        'TransTo',
      ].includes(props.tableSrcPage as string) &&
      dataRows?.length !== 0
    ) {
      // eslint-disable-next-line no-nested-ternary
      return headerColumns.length < 5
        ? ''
        : columnsWidthSum > 1820
        ? `${columnsWidthSum + 60}px`
        : '1880px';
    }

    return '';
  };
  // this states are for controlling previous data blink for few milisecond situation after skeleton loading finished
  const [dataShow, setDataShow] = useState(true);
  useEffect(() => {
    if (props.isCallingApi === true) {
      setDataShow(false);
    }
  }, [props.isCallingApi]);

  useEffect(() => {
    setDataShow(true);
  }, [dataRows]);
  // end

  return (
    <Box
      sx={{
        pointerEvents: props.isCallingApi && !dataShow ? 'none' : '',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display:
            props.isCallingApi && !dataShow && dataRows.length !== 0
              ? ''
              : 'none',
          width: '40px',
          height: '40px',
          position: 'absolute',
          top: 'calc(50% + 15px)',
          left: '50%',
          zIndex: '1',
        }}
      >
        <Loader colorCode="#939598" />
      </Box>
      <SimpleBarReact style={{ maxWidth: '100%' }}>
        <table
          className={`${styles['data-table']} table table-hover  ${
            ['TransFromDay', 'TransToDay', 'TransFrom', 'TransTo'].includes(
              props.tableSrcPage as string,
            )
              ? styles['report-table']
              : ''
          } ${
            ['TransCountList', 'TransCountListDay'].includes(
              props.tableSrcPage as string,
            )
              ? styles['report-table-transCount']
              : ''
          } ${
            ['Group', 'Users', 'GroupDevice', 'TransHistory'].includes(
              props.tableSrcPage as string,
            )
              ? 'group-list-responsive-table'
              : ''
          } ${
            [
              'TransCountList',
              'TransCountListDay',
              'TransFromDay',
              'TransToDay',
              'TransFrom',
              'TransTo',
            ].includes(props.tableSrcPage as string) && headerColumns.length < 5
              ? 'table-body-report-res'
              : ''
          }`}
          style={{
            minWidth: width(props.tableSrcPage as string, matches),
          }}
        >
          <TableHeaderContainer
            columns={headerColumns}
            clickSortButton={clickSortButton}
            sortConfig={sortConfig}
            tableSrcPage={props.tableSrcPage}
            isCallingApi={dataRows?.length === 0}
            columnsWidthSum={columnsWidthSum}
          />
          <tbody
            className={
              [
                'Device',
                'TransCountList',
                'TransCountListDay',
                'TransFromDay',
                'TransToDay',
              ].includes(props.tableSrcPage as string)
                ? styles['device-data-table-body']
                : styles['data-table-body']
            }
          >
            {dataRows?.length === 0 && props.tableSrcPage === 'Group' && (
              <TableBodyContainerSkeleton />
            )}
            {dataRows?.length === 0 && props.tableSrcPage === 'Users' && (
              <TableBodyUserSkeleton />
            )}
            {dataRows?.length === 0 && props.tableSrcPage === 'GroupDevice' && (
              <TableBodyDeviceSkeleton />
            )}
            {props.tableSrcPage === 'Device' && dataRows?.length === 0 && (
              <DeviceListBodySkeleton />
            )}
            {dataRows?.length === 0 &&
              props.tableSrcPage === 'TransHistory' && (
                <TableBodyTransHistorySkeleton />
              )}
            {dataRows?.length === 0 && props.tableSrcPage === 'TransFrom' && (
              <TableBodyReportListSkeleton numberOfColumns={2} />
            )}
            {dataRows?.length === 0 &&
              props.tableSrcPage === 'TransFromDay' && (
                <TableBodyReportListSkeleton numberOfColumns={5} />
              )}

            {dataRows?.length === 0 &&
              props.tableSrcPage === 'TransCountList' && (
                <TableBodyReportListSkeleton numberOfColumns={5} />
              )}
            {dataRows?.length === 0 &&
              props.tableSrcPage === 'TransCountListDay' && (
                <TableBodyReportListSkeleton numberOfColumns={5} />
              )}

            {dataRows?.length === 0 && props.tableSrcPage === 'TransTo' && (
              <TableBodyReportListSkeleton numberOfColumns={2} />
            )}
            {dataRows?.length === 0 && props.tableSrcPage === 'TransToDay' && (
              <TableBodyReportListSkeleton numberOfColumns={5} />
            )}

            <TableBodyContainer
              simpleBarRef={simpleBarRef}
              rows={dataRows}
              columnLength={headerColumns.length}
              tableSrcPage={props.tableSrcPage}
              isCallingApi={props.isCallingApi}
              imeiLinkClicked={imeiLinkClicked}
              clickDisable={props.clickDisable}
              columnsWidthSum={columnsWidthSum}
              columns={headerColumns}
              dataShow={dataShow}
            />
          </tbody>
        </table>
      </SimpleBarReact>
    </Box>
  );
};

DataTableContainer.defaultProps = {
  tableSrcPage: '',
  isCallingApi: false,
  imeiLinkClicked: () => undefined,
  clickDisable: false,
};

export default DataTableContainer;
