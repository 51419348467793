import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import useAccessKey from './useAccessKey';
import {
  DEVICE_LOST_MODE_DISABLE_ERROR_TYPE,
  DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE,
} from '../apis/callDeviceLostModeDisable';
import { lostModeDisable } from '../utils/devicelist/deviceLostModeDisable';
import {
  DeviceLostModeDisableApiRequestParam,
  DeviceLostModeDisableApiResponse,
} from '../types/apis/deviceLostModeDisableApi.d';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import { PAGE_PATH_NAME } from '../constants/constants';
// import useDeviceDetail from './useDeviceDetail';

const useDeviceLostModeDisable = () => {
  const [isCallingDisableApi, setIsCallingDisableApi] =
    useState<boolean>(false);
  const [errorTypeDisable, setErrorTypeDisable] =
    useState<DEVICE_LOST_MODE_DISABLE_ERROR_TYPE | null>(null);
  // const { accessKey } = useAccessKey();
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();
  const { saveUpdateState } = useDeviceDetailUpdateState();
  const navigate = useNavigate();
  const onClickDeviceLostModeDisable = (
    imei: string,
    lockStatus: boolean,
  ): void => {
    const requestParam: DeviceLostModeDisableApiRequestParam = {
      imei,
      lockStatus,
    };
    // loading Start
    setIsCallingDisableApi(true);

    /*eslint-disable*/
    lostModeDisable(requestParam).then(
      (result: DeviceLostModeDisableApiResponse) => {
        setErrorTypeDisable(result.resultCode);
        if (result.resultCode === DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.OK) {
          setIsCallingDisableApi(false);
          displaySnackbar({
            message: t('lostMode.message.deactivationSuccess'),
            timeout: 3100,
          });
          saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.SUCCESS);

          return;
        }
        if (
          result.resultCode ===
          DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.LOST_MODE_ALREADY_DISABLED
        ) {
          setIsCallingDisableApi(false);
          displaySnackbar({
            message: t('lostMode.message.alreadyDisabled'),
            timeout: 3200,
          });
          saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.SUCCESS);

          return;
        }
        if (
          result.resultCode ===
          DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED
        ) {
          setIsCallingDisableApi(false);
          displaySnackbar({
            message: t('deviceInfo.devicePermissionApiError'),
            type: 'error',
          });
          navigate(PAGE_PATH_NAME.DEVICE_LIST);

          return;
        }

        setErrorTypeDisable(result.resultCode);
        setIsCallingDisableApi(false);
        displaySnackbar({
          message: t('lostMode.message.deactivationFailed'),
          type: 'error',
          timeout: 3100,
        });
      },
    );
  };
  return {
    isCallingDisableApi,
    onClickDeviceLostModeDisable,
    errorTypeDisable,
  };
};

export default useDeviceLostModeDisable;
