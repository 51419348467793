import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type {
  GroupNameEditApiResponse,
  GroupNameEditApiRequestParameter,
} from '../types/apis/groupNameEditApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * Group Add Acquisition API Result Code
 */
export const GROUP_NAME_EDIT_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_UPDATED: 'SUCCESSFULLY_UPDATED',
  // No group found with the ID
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // Ungrouped delete is not allowed
  WARN_UNGROUPED_NOT_DELETABLE: 'WARN_UNGROUPED_NOT_DELETABLE',
  // User does not have access
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Input parameter not correct
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // No corporation found with the ID
  INFO_NO_CORPORATION: 'INFO_NO_CORPORATION',
  // Internal server side error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  // Already group name exist
  WARN_GROUP_NAME_EXIST: 'WARN_GROUP_NAME_EXIST',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
} as const;

/**
 * Group Add acquisition error type
 */
export type GROUP_NAME_EDIT_API_ERROR_TYPE =
  (typeof GROUP_NAME_EDIT_API_RESULT_CODE)[keyof typeof GROUP_NAME_EDIT_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callGroupNameEditApi = (
  requestParams: GroupNameEditApiRequestParameter,
): Promise<GroupNameEditApiResponse> => {
  // It is an error if you do not pass an empty request parameter

  // Send request (POST)

  const response = callApiUtil
    .put<GroupNameEditApiResponse>(API_URL.GROUP_NAME_EDIT_API, requestParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
      details: {
        groupName: '',
        groupId: 10,
      },
    }));

  return response;
};

export default callGroupNameEditApi;
