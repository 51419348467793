/* eslint-disable import/prefer-default-export */
import callManageGroupUserListApi, {
  MANAGE_GROUP_USER_LIST_API_ERROR_TYPE,
  MANAGE_GROUP_USER_LIST_API_RESULT_CODE,
} from '../../apis/callManageGroupUserList';
import callManageGroupUserListUpdate, {
  MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE,
  MANAGE_GROUP_USER_LIST_UPDATE_API_ERROR_TYPE,
} from '../../apis/callManageGroupUserListUpdate';
import { SERVER_MESSAGE } from '../../constants/constants';
import {
  isManageGroupUserListUpdateErrorType,
  isManageGroupUserListErrorType,
} from '../../types/apis/apiErrorTypeGuard';

import {
  ManageGroupUserListApiResponse,
  ManageGroupUserListUpdateApiResponse,
} from '../../types/apis/manageGroupUserListApi.d';
import {
  ManageGroupUserListResult,
  ManageGroupUserListUpdateResult,
} from '../../types/Group/manageGroupUserList.d';

// Manage Group User List API call
export const fetchManageGroupUserList = async (
  // accessKey: string,
  groupId: number,
  pageNumber: number,
  searchtext: string,
): Promise<ManageGroupUserListResult> => {
  // User list acquisition API call
  try {
    const apiResponse: ManageGroupUserListApiResponse =
      await callManageGroupUserListApi(
        // accessKey,
        groupId,
        pageNumber,
        searchtext,
      );
    switch (apiResponse.message) {
      // success
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode:
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          users: apiResponse.details.users,
          totalCount: apiResponse.details.totalCount,
        };
      // Failure
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED;
      case MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_ROLE_CHANGED;

      default:
        throw MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: MANAGE_GROUP_USER_LIST_API_ERROR_TYPE =
      MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isManageGroupUserListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      users: [],
      totalCount: 0,
    };
  }
};
// Manage Group User Post API call
export const updateManageGroupUserList = async (
  // accessKey: string,
  groupId: number,
  addedUserListPayload: number[],
  removedUserListPayload: number[],
): Promise<ManageGroupUserListUpdateResult> => {
  // User list Update API call
  try {
    const apiResponse: ManageGroupUserListUpdateApiResponse =
      await callManageGroupUserListUpdate(
        // accessKey,
        groupId,
        addedUserListPayload,
        removedUserListPayload,
      );
    switch (apiResponse.message) {
      // success
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED:
        return {
          resultCode:
            MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED,
        };
      // Failure
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_USER:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_USER;
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP;
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH;
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_ACCESS_KEY:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_ACCESS_KEY;
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM;
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      default:
        throw MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    let resultCode: MANAGE_GROUP_USER_LIST_UPDATE_API_ERROR_TYPE =
      MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN;
    if (isManageGroupUserListUpdateErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
    };
  }
};
