import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RedirectDialogState {
  open: boolean;
  title?: string;
  message?: string;
  btnTitle?: string;
  routePath?: () => void;
}

export const redirecDialogInitialState: RedirectDialogState = {
  open: false,
  message: '',
};

export const RedirectDialogSlice = createSlice({
  name: 'RedirectDialog',
  initialState: redirecDialogInitialState,
  reducers: {
    openRedirectDialog: (
      _state,
      action: PayloadAction<RedirectDialogState>,
    ) => ({
      ...redirecDialogInitialState,
      ...action.payload,
      open: true,
    }),
    closeRedirectDialog: (state) => ({ ...state, open: false }),
  },
});

export const RedirectDialogActions = RedirectDialogSlice.actions;
export const RedirectDialogReducer = RedirectDialogSlice.reducer;
