import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import {
  TRANS_HISTORY_LIST_API_RESULT_CODE,
  TRANS_HISTORY_LIST_ERROR_TYPE,
} from '../apis/callTransHistoryListApi';
import type { TransHistoryInfo } from '../types/apis/transHistoryListApi.d';
import {
  API_URL,
  CSV_DOWNLOAD_KEY,
  DATE_FORMAT_YYYYMMDD,
  TABLE_ROW_NUMBER_PER_PAGE,
  DATE_REGEX_YYYYMMDD,
  SERVER_MESSAGE,
  PAGE_PATH_NAME,
} from '../constants/constants';
import {
  SortConfig,
  TableHeaderData,
  TableRowData,
  DIRECTION_TYPE,
  API_CALL_STATE,
  DisplayContentState,
  FILTERING_STATE,
} from '../types/datatable/dataTable.d';
import {
  DownloadDate,
  DOWNLOAD_DATE_ERROR_CODE,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../types/download/downloadDate';
import { TransHistoryListResult } from '../types/history/transHistoryList.d';
import { FilterInput } from '../types/inputs/filterInput.d';
import {
  convertTransHistoryArrayToTableDataArray,
  filterArray,
  sortedItems,
  updateSortConfig,
} from '../utils/datatable/dataTableUtil';
import {
  checkDateRegex,
  // formatDownloadDate,
  createDonwloadErrorMessage,
  validateDownloadDate,
} from '../utils/donwload/downloadDateUtil';
import { fetchTransHistoryList } from '../utils/history/transHistoryListUtil';
import {
  // createDonwloadLinkAndClick,
  createTotalPageNumber,
} from '../utils/utility';
import {
  fetchTodayUTCyyyyMMddT00,
  convertDateToStringyyyyMMddT235959,
  convertDateToStringyyyyMMddT00,
  convertStrToDateyyyyMMddT00,
} from '../utils/dateUtil';
import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import useDateRange, { UseDateRangeValue } from './useDateRange';
import useFilter from './useFilter';
import usePagination from './usePagination';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import callReportDownload, { UrlList } from '../apis/callReportApi';
import useSnackbar from './useSnackbar';
import useViewSwitchDropDown from './useViewSwitchDropDown';
import { VIEW_KEY } from '../types/viewswitch/viewSwitch.d';

/**
 * ソート情報
 */
export type SortValue = {
  // ソート設定
  sortConfig: SortConfig;
  // テーブルの[ソート]ボタンがクリックされた場合の処理
  onClickSortButton: (data: TableHeaderData) => void;
};

/**
 * フィルタリング情報
 */
export type FilterValue = {
  // フィルタリングの[検索]ボタンがクリックされた
  onClickFilterSearch: (inputData: FilterInput) => void;
  // 入力中の検索ワード
  filterInputData: FilterInput;
};

/**
 * ページネーション情報
 */
export type PaginationValue = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // ページネーションの[前へ]ボタンがクリックされた場合の処理
  onClickPrevPage: () => void;
  // ページネーションの[次へ]ボタンがクリックされた場合の処理
  onClickNextPage: () => void;
  // 選択中の1ページごとの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された場合の処理
  onChangeRowNumber: (selectedRowNumber: number) => void;
  onClickFirstPage: () => void;
  onClickLastPage: () => void;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
};

/**
 * 日付指定エラー情報
 */
export type DateRangeErrorValue = {
  // エラータイプ
  dateRangeAlertType: DOWNLOAD_DATE_ERROR_TYPE | null;
  // エラーメッセージ
  dateRangeErrorMessage: string;
};

/**
 * 翻訳履歴取得に関するエラー情報
 */
export type TransHistoryError = {
  // 翻訳履歴取得エラータイプ
  errorType: TRANS_HISTORY_LIST_ERROR_TYPE | null;
  // 翻訳履歴取得エラーメッセージ
  errorMessage: string;
  // アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理(翻訳履歴取得APIからログイン認証エラーが返却された場合に表示)
  onClickLogoutModal: () => void;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseTransHistoryListValue = {
  // 翻訳履歴取得に関するエラー情報
  transHistoryError: TransHistoryError;
  // APIコール中か否か(true=コール中)
  isCallingApi: boolean;
  // テーブルヘッダーに表示する内容
  headerColumns: TableHeaderData[];
  // 表示中のページに表示するテーブル内容
  currentPageDataRows: TableRowData[];
  // 一覧総数
  totalListCount: number;
  // [一括ダウンロード]ボタンクリック
  clickDownload: () => void;
  // ソート情報
  sortValue: SortValue;
  // フィルタリング情報
  filterValue: FilterValue;
  // ページネーション情報
  paginationValue: PaginationValue;
  // 日付範囲に関する値
  dateRangeValue: UseDateRangeValue;
  // ダウンロード日付選択に関する値
  downloadDate: DownloadDate;
  // 日付指定エラー情報
  dateRangeErrorValue: DateRangeErrorValue;
  // 翻訳履歴画面の表示時に受け取ったIMEI
  targetImei: string;
  // 開始日DatePickerが閉じた場合の処理
  startDatePickerClosed: (selectedStartDate: Date | null) => void;
  // 終了日DatePickerが閉じた場合の処理
  endDatePickerClosed: (selectedEndDate: Date | null) => void;
  // DatePicker上で日付が直接入力された場合の処理
  datePickerInputCompleted: (inputValue: string) => void;
  t: TFunction;
  isCallingReportApi: boolean;
  isFirstRenderingRef: React.MutableRefObject<boolean>;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
};

/**
 * 翻訳履歴画面 hooks
 *
 * @param targetImei 翻訳履歴一覧を表示したい端末のIMEI
 * @returns
 */
const useTransHistoryList = (targetImei: string): UseTransHistoryListValue => {
  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);

  // 翻訳履歴APIから取得した一覧 get/set
  const originalHistoryInfos = useRef<TransHistoryInfo[]>([]);
  // フィルタリング用一覧
  const filterHistoryInfos = useRef<TransHistoryInfo[]>([]);
  const { displaySnackbar } = useSnackbar();
  const { viewSwitchInputData } = useViewSwitchDropDown(VIEW_KEY.d);
  const [isCallingReportApi, setIsCallingReportApi] = useState<boolean>(false);

  // テーブルのヘッダーに表示する内容
  const [headerColumns, setHeaderColumns] = useState<TableHeaderData[]>([]);
  // テーブルに表示中のページデータ get/set
  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]);

  // 画面の表示状態を管理
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending, // API呼び出し状態(呼び出し前)
      filteringState: FILTERING_STATE.inactive, // フィルタリング適用状態(フィルタリング解除)
    });
  const navigate = useNavigate();
  const isFirstRenderingRef = useRef(true);

  // 翻訳履歴取得エラータイプ get/set
  const [errorType, setErrorType] =
    useState<TRANS_HISTORY_LIST_ERROR_TYPE | null>(null);
  // APIコール中か否か(true=コール中)
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // 一覧に表示中のデータ総数(データ総数, フィルタリング結果数) get/set
  const [totalListCount, setTotalListCount] = useState<number>(0);

  // ソート状態 get/set
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none,
  });
  // 日付指定アラート状態
  const [dateRangeAlertType, setDateRangeAlertType] =
    useState<DOWNLOAD_DATE_ERROR_TYPE | null>(null);

  // アクセスキーhooks
  const { accessKey } = useAccessKey();
  // アカウント情報hooks
  const { accountInfo } = useAccountInfo();
  const [firstRenderingLocale, setFirstRenderingLocale] = useState<string>(
    accountInfo.locale,
  );
  // フィルターhooks
  const { onClickFilterSearch, filterInputData } = useFilter('');
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState<number>(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);
  // ページネーション hooks
  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    changeCurrentPage,
    onClickFirstPage,
    onClickLastPage,
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 1);

  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  // 選択できる最小値(法人登録日時)
  const minDate = useMemo(
    (): Date => convertStrToDateyyyyMMddT00(accountInfo.corpCreatedDt),
    [accountInfo.corpCreatedDt],
  );
  // 選択できる最大値(現在日)
  const maxDate = useMemo((): Date => fetchTodayUTCyyyyMMddT00(), []);
  // 日付範囲 hooks
  const { startDate, endDate, changeStartDate, changeEndDate } = useDateRange(
    dayjs().startOf('month').toDate(),
    new Date(),
  );

  /**
   * 日付指定エラーメッセージ
   */
  const dateRangeErrorMessage = useMemo((): string => {
    if (!dateRangeAlertType) {
      return '';
    }

    return createDonwloadErrorMessage(
      dateRangeAlertType,
      t,
      DATE_FORMAT_YYYYMMDD,
      minDate,
      maxDate,
    );
  }, [dateRangeAlertType, maxDate, minDate, t]);

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!errorType) {
      return ''; // エラーコンポーネント自体非表示にする
    }
    switch (errorType) {
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_INVALID:
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_EXPIRED:
      case TRANS_HISTORY_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
      case TRANS_HISTORY_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
      case TRANS_HISTORY_LIST_API_RESULT_CODE.NO_INTERNET:
      case TRANS_HISTORY_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        return ''; // ログイン認証はエラーはエラーダイアログを出すのでメッセージなし
      default:
        return t('transHistoryList.transHistoryListApiError.unknown');
    }
  }, [errorType, t]);

  /**
   * [ソート]ボタンがクリックされた
   */
  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort) {
        // ソートが許可されていない列だった場合は何もしない
        return;
      }

      // ソート情報作成
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      setSortConfig(sortConfigUpdate);
    },
    [sortConfig],
  );

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutModal = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setErrorType(null);
  };

  /**
   * フィルタリング実行
   */
  const performFilter = (): void => {
    // フィルタリング再実行(フィルタリング中にビュー切替が行われたときに実行されることを想定)
    if (filterInputData.searchText !== '') {
      // 必要な内部処理が同じため、ボタンクリックイベントではないがここで呼び出す
      onClickFilterSearch({
        searchId: filterInputData.searchText, // 一意のキーを指定すればいいので検索ワードを指定
        searchText: filterInputData.searchText,
      });
    }
  };

  /**
   * 画面の状態を管理して表示データを作成
   *
   * 状態
   * ・翻訳履歴取得API呼び出し状態（前/済/取得失敗）
   *
   * 1.翻訳履歴取得API呼び出し前
   *   ・翻訳履歴取得API呼び出し
   * 2.翻訳履歴取得API呼び出し後
   *   ・フィルター適用あり/なしで表示用データ作成
   *   ・ソート適用
   */
  useEffect(() => {
    // API呼び出し前
    if (
      displayContentState.apiCallState === API_CALL_STATE.pending &&
      isFirstRenderingRef.current
    ) {
      isFirstRenderingRef.current = false;
      setFirstRenderingLocale(accountInfo.locale);
      // loadingBar表示
      setIsCallingApi(true);
      // エラータイプ初期化
      setErrorType(null);
      // ここにきた時点でnullになることはありえないが、Date型を確定させるために処理入れる
      if (startDate === null || endDate === null) {
        return;
      }

      if (
        startDate.toDateString() === 'Invalid Date' ||
        endDate.toDateString() === 'Invalid Date'
      ) {
        return;
      }

      // 翻訳履歴一覧取得
      fetchTransHistoryList(
        // accessKey,
        targetImei,
        convertDateToStringyyyyMMddT00(startDate),
        convertDateToStringyyyyMMddT235959(endDate),
      )
        .then((result: TransHistoryListResult) => {
          if (result.resultCode === TRANS_HISTORY_LIST_API_RESULT_CODE.OK) {
            // APIから返却された一覧
            originalHistoryInfos.current = result.dataList;
            // APIから返却されたヘッダ
            setHeaderColumns(result.titleList);
            // state更新
            setDisplayContentState({
              ...displayContentState,
              apiCallState: API_CALL_STATE.fulfilled,
            });

            return;
          }
          if (
            result.resultCode ===
            TRANS_HISTORY_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED
          ) {
            displaySnackbar({
              message: t('deviceInfo.devicePermissionApiError'),
              type: 'error',
            });
            navigate(PAGE_PATH_NAME.DEVICE_LIST);
          }
          // エラー
          originalHistoryInfos.current = [];
          setErrorType(result.resultCode);
          // state更新
          setDisplayContentState({
            ...displayContentState,
            apiCallState: API_CALL_STATE.rejected,
          });
        })
        .finally(() => {
          // 一覧情報が揃ったタイミングでフィルタリング再実行
          performFilter();
        });
    }
    // API呼び出し成功
    else if (displayContentState.apiCallState === API_CALL_STATE.fulfilled) {
      // loadingBar非表示(このタイミングで非表示にしないとWarning: Can't perform a React state update on an unmounted component. This is a no-opエラーになる)
      setIsCallingApi(false);

      const firstPageIndex = (currentPage - 1) * currentRowNumberPerPage;
      const lastPageIndex = firstPageIndex + currentRowNumberPerPage;

      let list: TransHistoryInfo[] = [];
      // フィルターなし
      if (displayContentState.filteringState === FILTERING_STATE.inactive) {
        list = originalHistoryInfos.current;
      }
      // フィルターあり
      else if (displayContentState.filteringState === FILTERING_STATE.active) {
        list = filterHistoryInfos.current;
      }

      // 一覧をもとに表示用データに変換
      let tableRowDataArray: TableRowData[] =
        convertTransHistoryArrayToTableDataArray(list);

      // ソート適用
      if (sortConfig.direction !== DIRECTION_TYPE.none) {
        tableRowDataArray = sortedItems(tableRowDataArray, sortConfig);
      }
      // 1ページ分の表示内容に分割
      tableRowDataArray = tableRowDataArray.slice(
        firstPageIndex,
        lastPageIndex,
      );

      setCurrentPageDataRows(tableRowDataArray); // 表示用データセット
      setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
      setPageDataIndexTo(
        currentPage * currentRowNumberPerPage > list.length
          ? list.length
          : currentPage * currentRowNumberPerPage,
      );
      changeTotalPages(
        createTotalPageNumber(list.length, currentRowNumberPerPage),
      ); // ページ総数設定セット
      setTotalListCount(list.length); // 件数セット
      // after pagination or sorting the scroll bar will be on top
      if (simpleBarRef.current) {
        simpleBarRef.current.getScrollElement().scrollTop = 0;
      }
    }
    // API呼び出し失敗
    else {
      // loadingBar非表示(このタイミングで非表示にしないとWarning: Can't perform a React state update on an unmounted component. This is a no-opエラーになる)
      setIsCallingApi(false);

      setHeaderColumns([]); // テーブルヘッダーセット
      setCurrentPageDataRows([]); // 表示用データセット
      changeTotalPages(1); // ページ総数設定セット
      setTotalListCount(0); // 件数セット
      changeCurrentPage(1); // 1ページ目を表示
    }
    // startDate, endDateは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessKey,
    changeTotalPages,
    currentPage,
    currentRowNumberPerPage,
    displayContentState,
    sortConfig,
  ]);

  /**
   * フィルタリングが更新された場合の処理
   */
  useEffect(() => {
    // フィルタリング実行直後は1ページ目に移動
    changeCurrentPage(1);

    if (!filterInputData.searchText) {
      // フィルターなし
      filterHistoryInfos.current = [];
      // state更新
      setDisplayContentState({
        ...displayContentState,
        filteringState: FILTERING_STATE.inactive,
      });

      return;
    }
    // 検索文字列
    const searchText: string = filterInputData?.searchText;
    // フィルター適用
    filterHistoryInfos.current = filterArray(
      originalHistoryInfos.current,
      searchText,
      [], // フィルタリング対象外のkey
    );
    // state更新
    setDisplayContentState({
      ...displayContentState,
      filteringState: FILTERING_STATE.active,
    });

    // displayContentStateは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCurrentPage, filterInputData]);

  /**
   * 日付バリデーションチェック
   *
   * @param targetStartDate チェック対象の開始日
   * @param targetEndDate チェック対象の終了日
   * @returns true=OK / false=バリデーションエラー
   */
  const validateDate = useCallback(
    (targetStartDate: Date | null, targetEndDate: Date | null): boolean => {
      // 日付指定アラート状態初期化
      setDateRangeAlertType(null);
      // バリエーションチェック
      const result: DOWNLOAD_DATE_ERROR_TYPE | null = validateDownloadDate(
        targetStartDate,
        targetEndDate,
        minDate,
        maxDate,
        DATE_FORMAT_YYYYMMDD,
      );
      if (result !== null) {
        // エラー結果を日付指定アラート状態に設定
        setDateRangeAlertType(result);

        return false;
      }

      return true;
    },
    [maxDate, minDate],
  );

  /**
   * 期間開始日または期間終了日が変更された時点で、
   * 日付バリデーションチェック実行
   */
  useEffect(() => {
    validateDate(startDate, endDate);
  }, [startDate, endDate, validateDate]);

  /**
   * 指定された日付範囲内の翻訳履歴を再取得
   *
   * @returns
   */
  const refetchTransHistory = () => {
    // 再検索後は1ページ目に移動
    changeCurrentPage(1);
    // stateを更新して、一覧を再取得
    setDisplayContentState({
      ...displayContentState,
      apiCallState: API_CALL_STATE.pending,
    });
    isFirstRenderingRef.current = true;
  };

  /**
   * 開始日DatePickerが閉じた場合の処理
   * 正しい日付の場合は一覧を再取得する
   *
   * @param selectedStartDate 新しく選択された開始日
   * @returns
   */
  const startDatePickerClosed = (selectedStartDate: Date | null): void => {
    // 日付バリデーションチェック
    if (!validateDate(selectedStartDate, endDate)) {
      return;
    }
    // 一覧再取得
    refetchTransHistory();
  };

  /**
   * 終了日DatePickerが閉じた場合の処理
   * 正しい日付の場合は一覧を再取得する
   *
   * @param selectedEndDate 新しく選択された終了日
   * @returns
   */
  const endDatePickerClosed = (selectedEndDate: Date | null): void => {
    // 日付バリデーションチェック
    if (!validateDate(startDate, selectedEndDate)) {
      return;
    }
    // 一覧再取得
    refetchTransHistory();
  };

  /**
   * DatePicker上で日付が直接入力された場合の処理
   * 正しい日付の場合は一覧を再取得する
   *
   * @param inputValue 直接入力された日付
   * @returns
   */
  const datePickerInputCompleted = (inputValue: string) => {
    // 日付バリデーションチェック
    if (!validateDate(startDate, endDate)) {
      return;
    }

    // 入力形式チェック(直接入力された場合、yyyy/MM/d形式の場合もDatePickerでは正しい日付として扱われるのでyyyy/MM/dd形式かチェックする)
    if (!checkDateRegex(DATE_REGEX_YYYYMMDD, inputValue)) {
      setDateRangeAlertType(DOWNLOAD_DATE_ERROR_CODE.ERR_FORMAT);

      return;
    }
    // 一覧再取得
    refetchTransHistory();
  };

  /**
   * [一括ダウンロード]ボタンクリック
   *
   * @returns
   */
  const clickDownload = (): void => {
    setIsCallingReportApi(true);
    // すでに上でバリデーションチェックを行っているためnullになることはありえないが、Date型を確定させるために処理入れる
    if (startDate == null || endDate == null) {
      return;
    }
    if (
      startDate?.toDateString() === 'Invalid Date' ||
      endDate?.toDateString() === 'Invalid Date'
    ) {
      displaySnackbar({
        message: t('report.apiError.validRange'),
        type: 'error',
      });

      return;
    }

    const formattedStartDate = convertDateToStringyyyyMMddT00(startDate);
    const formattedEndDate = convertDateToStringyyyyMMddT235959(endDate);
    callReportDownload(
      accountInfo.corpId,
      formattedStartDate,
      formattedEndDate,
      viewSwitchInputData.viewKey,
      UrlList.TransHistory,
      targetImei,
    )
      .then((response) => {
        if (response?.message === SERVER_MESSAGE.FETCH_OK) {
          displaySnackbar({
            message: t('transHistoryList.transHistoryListApiError.success'),
          });
        } else if (
          response?.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED
        ) {
          displaySnackbar({
            message: t('deviceInfo.devicePermissionApiError'),
            type: 'error',
          });
          navigate(PAGE_PATH_NAME.DEVICE_LIST);
        } else {
          displaySnackbar({
            message: t('transHistoryList.transHistoryListApiError.failed'),
            type: 'error',
          });
        }
        setIsCallingReportApi(false);
      })
      .catch(() => {
        displaySnackbar({
          message: t('transHistoryList.transHistoryListApiError.failed'),
          type: 'error',
        });
        setIsCallingReportApi(false);
      });
  };

  useEffect(() => {
    changeCurrentPage(1);
    if (
      startDate?.toDateString() === 'Invalid Date' ||
      endDate?.toDateString() === 'Invalid Date'
    ) {
      return;
    }
    setDisplayContentState({
      ...displayContentState,
      apiCallState: API_CALL_STATE.pending,
    });

    // eslint-disable-next-line
  }, [startDate, viewSwitchInputData.viewKey]);

  // if locale value change fetchTransHistoryList api call
  useEffect(() => {
    if (firstRenderingLocale !== accountInfo.locale) {
      refetchTransHistory();
    }
    // eslint-disable-next-line
  }, [accountInfo.locale]);

  return {
    transHistoryError: {
      errorType,
      errorMessage,
      onClickLogoutModal,
    },
    t,
    isCallingApi,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    clickDownload,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    filterValue: {
      onClickFilterSearch,
      filterInputData,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onChangeRowNumber,
      pageDataIndexFrom,
      pageDataIndexTo,
      onClickFirstPage,
      onClickLastPage,
    },
    dateRangeValue: {
      startDate,
      endDate,
      changeStartDate,
      changeEndDate,
    },
    downloadDate: {
      minDate,
      maxDate,
    },
    dateRangeErrorValue: {
      dateRangeAlertType,
      dateRangeErrorMessage,
    },
    targetImei,
    startDatePickerClosed,
    endDatePickerClosed,
    datePickerInputCompleted,
    isCallingReportApi,
    isFirstRenderingRef,
    simpleBarRef,
  };
};

export default useTransHistoryList;
