import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, Button, Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import AddUserDialog from '../Dialog/AddUserDialog';
import useUserList from '../../hooks/useUserList';
import {
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../../constants/constants';
import { useStyles } from '../Templates/CommonStyle';
import styles from './UserList.module.scss';
import TableTemplateContainer from '../Templates/TableTemplateContainer';
import HomeBreadcrumbTemplate from '../Templates/HomeBreadcrumbTemplate';
import SearchField from '../Modecules/SearchField';
import useAccountInfo from '../../hooks/useAccountInfo';
import { ReactComponent as AddUserIcon } from '../../images/icon/add-user.svg';
import { ReactComponent as GroupUserIcon } from '../../images/icon/Group_user.svg';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
/**
 * ユーザー管理画面 コンポーネント
 *
 * @returns
 */
const UserList = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  const { localeLanguage } = useLocale();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // 言語切り替えhooks
  // ユーザー管理画面 hooks
  const {
    isCallingApi,
    t,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    sortValue,
    filterValue,
    paginationValue,
    // onClickAddUserButton
    // callUserListApi,
    displayContentState,
    setDisplayContentState,
    userListError,
  } = useUserList();
  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);
  const location = useLocation();
  const locationKey = location.key;
  const { accountInfo } = useAccountInfo();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [userAddedSuccessfully, setUserAddedSuccessfully] = useState(false);
  const handleAddUserModal = () => {
    setOpenAddUserModal(true);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'add_user');
  };

  useEffect(() => {
    // table scroll to top after location key changes
    // get specific table scroll body
    const tableBody = document.getElementsByClassName(
      'simplebar-content-wrapper',
    )[1];
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  }, [paginationValue]);

  useEffect(() => {
    // callUserListApi();
    if (userAddedSuccessfully) {
      setDisplayContentState({
        ...displayContentState,
        apiCallState: 'pending',
      });
      setUserAddedSuccessfully(false);
    }
    // eslint-disable-next-line
  }, [userAddedSuccessfully]);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
        FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.VIEWLIST,
      ) &&
      !accountInfo.subcriptionChange
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <HomeBreadcrumbTemplate titleText={t('userList.viewName')}>
      <Container
        style={{
          paddingRight: '0',
          paddingLeft: '0',
          maxWidth: '100%',
        }}
      >
        <div
          className={`${styles['user-list-flex-box']} ${'user-list-for-res'}`}
        >
          <SearchField
            disable={
              // eslint-disable-next-line
              !accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
                FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.SEARCH,
              )
            }
            locationKey={locationKey}
            clickFilterSearch={(val) => {
              filterValue.onClickFilterSearch({
                searchId: uuidv4(),
                searchText: decodeURIComponent(val.toString()).trim(),
              });
            }}
            placeholder={t('userList.placeholder.search')}
            responsiveClass="search-input-list"
          />
          {/* eslint-disable-next-line */}
          {accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERINVITE?.includes(
            FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERINVITE.ADD,
          ) && (
            <Button
              id="addGroupButton"
              variant="contained"
              sx={{
                padding: '9px 15px',
                height: '38px',
                '@media screen and (min-width: 600px) and (max-width: 680px)': {
                  fontSize: localeLanguage === LOCALE_CODE.EN ? '10px' : '10px',
                },
                textTransform: 'none',
                color: 'white',
                background: '#009DE1',
                boxShadow: 'none',
                borderRadius: '6px',
                '&:hover': {
                  backgroundColor: '#007EB4',
                },
              }}
              onClick={handleAddUserModal}
            >
              <AddUserIcon
                style={{ marginRight: '6px', width: '18px', height: '16px' }}
              />
              {t('userList.addUser')}
            </Button>
          )}
        </div>
        <div>
          <Box
            className={classes.tableCard}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {!isCallingApi &&
              ((totalListCount === 0 &&
                filterValue.filterInputData.searchText !== '') ||
                userListError.errorType === SERVER_MESSAGE.NO_INTERNET) && (
                <div>
                  <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                    {t('userList.label.totalUsers')} (0)
                  </Typography>
                </div>
              )}
            {/* no user found */}
            {!isCallingApi &&
              ((totalListCount === 0 &&
                filterValue.filterInputData.searchText !== '') ||
                userListError.errorType === SERVER_MESSAGE.NO_INTERNET) && (
                <div className={classes.tableCardInner}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <GroupUserIcon style={{ width: '67px', height: '40px' }} />
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 'medium',
                        lineHeight: '29px',
                        fontSize: '20px',
                        marginTop: '13.4px',
                        marginBottom: '2px',
                        color:
                          userListError.errorType === SERVER_MESSAGE.NO_INTERNET
                            ? '#313133'
                            : '',
                      }}
                    >
                      {userListError.errorType === SERVER_MESSAGE.NO_INTERNET
                        ? t('common.error.noData')
                        : t('userList.errors.noUsers')}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        // color: '#939598',
                        color:
                          userListError.errorType === SERVER_MESSAGE.NO_INTERNET
                            ? '#313133'
                            : '#939598',
                      }}
                    >
                      {userListError.errorType === SERVER_MESSAGE.NO_INTERNET
                        ? ''
                        : t('common.error.tryAgain')}
                    </Typography>
                  </div>
                </div>
              )}

            {!(
              (filterValue.filterInputData.searchText !== '' &&
                totalListCount === 0) ||
              userListError.errorType === SERVER_MESSAGE.NO_INTERNET
            ) && (
              <TableTemplateContainer
                simpleBarRef={simpleBarRef}
                headerColumns={headerColumns}
                dataRows={currentPageDataRows}
                totalDataRowsLabel="Users"
                totalDataRows={totalListCount}
                clickSortButton={sortValue.onClickSortButton}
                sortConfig={sortValue.sortConfig}
                currentPage={paginationValue.currentPage}
                totalPages={paginationValue.totalPages}
                clickPrevPageButton={paginationValue.onClickPrevPage}
                clickNextPageButton={paginationValue.onClickNextPage}
                clickFirstPageButton={paginationValue.onClickFirstPage}
                clickLastPageButton={paginationValue.onClickLastPage}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={
                  paginationValue.currentRowNumberPerPage
                }
                changeRowNumber={paginationValue.onChangeRowNumber}
                containFirstAndLastButton
                pageDataIndexFrom={paginationValue.pageDataIndexFrom}
                pageDataIndexTo={paginationValue.pageDataIndexTo}
                isCallingApi={isCallingApi}
                clickDisable={
                  // eslint-disable-next-line
                  !accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
                    FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.VIEWDETAILS,
                  )
                }
                tableSrcPage="Users"
              />
            )}
          </Box>
        </div>
      </Container>
      <Box>
        {openAddUserModal && (
          <AddUserDialog
            userAddedSuccessfully={userAddedSuccessfully}
            setUserAddedSuccessfully={setUserAddedSuccessfully}
            open={openAddUserModal}
            onClose={() => {
              setOpenAddUserModal(false);
            }}
          />
        )}
      </Box>
    </HomeBreadcrumbTemplate>
  );
};

export default UserList;
