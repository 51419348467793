import { useState } from 'react';

export type UseDeviceListValue = {
  selectOpen: boolean;
  selectAnchor: null | HTMLElement;
  selectedDate: Date[];
  handleSelectBoxClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSelectGroup: (event: MouseEvent | TouchEvent) => void;
  setSelectedDate: (date: Date[]) => void;
  setSelectOpen: (open: boolean) => void;
};
const useMonthRangePicker = (): UseDeviceListValue => {
  const [selectAnchor, setSelectAnchor] = useState<null | HTMLElement>(null);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const handleSelectBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSelectAnchor(event.currentTarget);
    if (selectOpen) {
      setSelectOpen(false);
    } else {
      setSelectOpen(true);
    }
    // GA tag
    // sendGAEvent('select_list', 'txt_name', 'select_group_list');
  };
  const handleSelectGroup = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    if (selectOpen) {
      setSelectOpen(false);
    } else {
      setSelectOpen(true);
    }
  };

  return {
    selectOpen,
    selectAnchor,
    selectedDate,
    handleSelectBoxClick,
    handleSelectGroup,
    setSelectedDate,
    setSelectOpen,
  };
};

export default useMonthRangePicker;
