import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WelcomeDialogState {
  open: boolean;
}

export const welcomeDialogInitialState: WelcomeDialogState = {
  open: false,
};

export const WelcomeDialogSlice = createSlice({
  name: 'WelcomeDialog',
  initialState: welcomeDialogInitialState,
  reducers: {
    openWelcomeDialog: (
      _state,
      action: PayloadAction<WelcomeDialogState>,
    ) => ({
      ...welcomeDialogInitialState,
      ...action.payload,
      open: true,
    }),
    closeWelcomeDialog: (state) => ({ ...state, open: false }),
  },
});

export const WelcomeDialogActions = WelcomeDialogSlice.actions;
export const WelcomeDialogReducer = WelcomeDialogSlice.reducer;
