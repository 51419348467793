import React from 'react';
import { Box, Typography, Skeleton, IconButton } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ExportCorporate } from '../../images/icon/export_corporate.svg';
import { ReactComponent as SubscriptionIcon } from '../../images/icon/subscription.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  currentPlan: string;
  trialEnd: string;
  onClickBtn: () => void;
  isCallingApi: boolean;
  trialFlag: boolean;
  shopifyFeatureEnabled: boolean;
};

const CorporatePageSubscriptionCard = React.memo<Props>(
  ({
    currentPlan,
    trialEnd,
    onClickBtn,
    isCallingApi,
    trialFlag,
    shopifyFeatureEnabled,
  }): JSX.Element => {
    // common style
    const classes = useStyles();
    const { t } = useSwitchLocaleLanguage();

    return (
      <Box
        className={classes.corporateProfilePageCard}
        sx={{
          height: shopifyFeatureEnabled ? '207px' : '163px',
        }}
      >
        <Box className={classes.corporateProfilePageCardHeader}>
          <SubscriptionIcon />
          <Typography className={classes.corporateProfilePageCardHeaderTitle}>
            {t('corporateProfile.label.subscription')}
          </Typography>
        </Box>
        <Box className={classes.subscriptionDataCard}>
          <Box>
            <Typography className={classes.subscriptionCardTitle}>
              {t('corporateProfile.label.currentPlan')}
            </Typography>
            {isCallingApi && (
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  p: '0px',
                  height: '20px',
                  width: '90px',
                }}
              />
            )}
            {!isCallingApi && (
              <Typography className={classes.subscriptionCardText}>
                {currentPlan}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: shopifyFeatureEnabled ? '' : 'none' }}>
            <Typography className={classes.subscriptionCardTitle}>
              {isCallingApi && (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{
                    p: '0px',
                    height: '20px',
                    width: '90px',
                  }}
                />
              )}
              {!isCallingApi &&
                (trialFlag === true ? 'Trial End' : 'Next Billing Date')}
            </Typography>
            {isCallingApi && (
              <Skeleton
                variant="text"
                animation="wave"
                sx={{
                  p: '0px',
                  height: '20px',
                  width: '90px',
                }}
              />
            )}
            {!isCallingApi && (
              <Typography className={classes.subscriptionCardText}>
                {trialEnd}
              </Typography>
            )}
          </Box>
        </Box>
        {isCallingApi && (
          <Skeleton
            variant="text"
            animation="wave"
            sx={{
              display: shopifyFeatureEnabled ? '' : 'none',
              p: '0px',
              height: '20px',
              width: '200px',
            }}
          />
        )}
        {!isCallingApi && (
          <IconButton
            type="button"
            aria-label="view user"
            className={classes.corporatePageIconBtn}
            sx={{
              display: shopifyFeatureEnabled ? '' : 'none',

              '&:hover': {
                color: '#007EB4',
                '& svg': {
                  '& path': {
                    stroke: '#007EB4',
                  },
                },
              },
            }}
            onClick={onClickBtn}
            disableRipple
          >
            <Typography className={classes.corporatePageBtnCardBtnText}>
              {t('corporateProfile.label.viewChange')}
            </Typography>
            <ExportCorporate style={{ width: '13.01px', height: '14.01px' }} />
          </IconButton>
        )}
      </Box>
    );
  },
);

export default CorporatePageSubscriptionCard;
