import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type {
  GroupAddApiResponse,
  GroupAddApiRequestParameter,
} from '../types/apis/groupAddApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * Group Add Acquisition API Result Code
 */
export const GROUP_ADD_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  SUCCESSFULLY_CREATED: 'SUCCESSFULLY_CREATED',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',
  // Failed to get authorization list
  WARN_NO_AUTH_LIST: 'WARN_NO_AUTH_LIST',
  // No access key (*Code returned from the login authentication API called in the user
  // list acquisition API)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // Unregistered access key (* code returned from the login authentication API called
  // in the user list acquisition API)
  INFO_INVALID: 'INFO_INVALID',
  // Expired (*Code returned from the login authentication API called in the user list acquisition API)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  // Already group name exist
  WARN_GROUP_NAME_EXIST: 'WARN_GROUP_NAME_EXIST',
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED',
  NO_INTERNET: 'NO_INTERNET',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN'
} as const;

/**
 * Group Add acquisition error type
 */
export type GROUP_ADD_API_ERROR_TYPE =
  (typeof GROUP_ADD_API_RESULT_CODE)[keyof typeof GROUP_ADD_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callGroupAddApi = (
  // accessKey: string,
  requestParams: GroupAddApiRequestParameter,
): Promise<GroupAddApiResponse> => {
  // It is an error if you do not pass an empty request parameter

  // Send request (POST)

  const response = callApiUtil
    .post<GroupAddApiResponse>(API_URL.GROUP_ADD_API, requestParams, {
      headers: {
        'Content-Type': 'application/json',
        // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => {
      if (error?.response) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          statusCode: error.response?.data?.statusCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          message: error.response?.data?.message,
          details: {
            groupName: '',
            groupId: 10,
            groupCode: 10,
            corpId: '',
          },
        };
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        statusCode: 400,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        message: error.message,
        details: {
          groupName: '',
          groupId: 10,
          groupCode: 10,
          corpId: '',
        },
      };
    });

  return response;
};

export default callGroupAddApi;
