import type {
  DeviceUpdateApiRequestParam,
  DeviceUpdateResponse,
} from '../types/apis/deviceUpdateApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';

/**
 * 端末情報更新API 結果コード
 */
export const DEVICE_UPDATE_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 指定された端末が見つからない
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  // アクセスキー無し(※端末情報更新API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※端末情報更新API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※端末情報更新API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 権限不正エラー
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * 端末情報更新エラータイプ
 */
export type DEVICE_UPDATE_ERROR_TYPE =
  typeof DEVICE_UPDATE_API_RESULT_CODE[keyof typeof DEVICE_UPDATE_API_RESULT_CODE];

/**
 * 端末情報更新API呼び出し
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 端末情報更新APIからのレスポンス
 */
const callDeviceUpdateApi = (
  requestParams: DeviceUpdateApiRequestParam,
  // accessKey: string,
): Promise<DeviceUpdateResponse> => {
  const jsonParams = {
    imei: requestParams.imei,
    deviceName: requestParams.deviceName,
  };

  const response = callApiUtil
    .put<DeviceUpdateResponse>(API_URL.DEVICE_UPDATE_API, jsonParams, {
      headers: {
        'Content-Type': 'application/json',
        // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callDeviceUpdateApi;
