import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import jaCommon from './ja/translation.json';
import enCommon from './en/translation.json';
import deCommon from './de/translation.json';
import frCommon from './fr/translation.json';

const resources = {
  // 英語
  en: {
    translation: enCommon,
  },
  // 日本語
  ja: {
    translation: jaCommon,
  },
  // German
  de: {
    translation: deCommon,
  },
  // French
  fr: {
    translation: frCommon,
  },
};

/**
 * セットアップ
 */
void i18next.use(initReactI18next).init({
  resources,
  lng: 'en', // 初期表示する言語
  fallbackLng: 'en', // 選択した言語に関する辞書情報がない場合にかわりに表示する言語
  debug: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
