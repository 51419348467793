import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { DashboardRemoteSettingApiResponse } from '../types/apis/dashboardRemoteSetting.d';

const callDashboardRemoteSettingApi =
  (): Promise<DashboardRemoteSettingApiResponse> => {
    /* eslint-disable */
    const response = callApiUtil
      .get<DashboardRemoteSettingApiResponse>(
        API_URL.DASHBOARD_REMOTE_SETTING,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then((apiResponse) => apiResponse.data)
      .catch((error) => error.response.data);

    return response;
  };

export default callDashboardRemoteSettingApi;
