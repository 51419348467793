import React, { useState, useEffect, useRef } from 'react';
import { TFunction } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from '../components/Templates/CommonStyle';
import {
  PAGE_PATH_NAME,
  MENU_VALUE,
  FEATURE_PERMISSION,
} from '../constants/constants';
import { ReactComponent as ArrowDownIcon } from '../images/icon/down-arrow.svg';
import { ReactComponent as ArrowRightIcon } from '../images/icon/right-arrow.svg';
import {
  UserInfoProsessResultState,
  UserListState,
  UserListTransState,
  USER_INFO_PROSESS_RESULT_CODE,
  USER_LIST_TRANS_METHOD_CODE,
} from '../types/user/userList.d';
import useDrawerInfo from './useDrawerInfo';
import { sendGAEvent, useGAEvent } from './useGAEvent';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useAccountInfo from './useAccountInfo';

type SubMenu = {
  name: string;
  value: string;
  link: string;
  activeIndex: number;
  selectedIndex: number;
};
/**
 * 本カスタムフックからの返却値
 */
export type UseMainMenuValue = {
  // ドロワーメニューを表示するか否か
  isDrawerMenuOpen: boolean;
  sidebarRef: React.RefObject<HTMLDivElement>;
  // ドロワーメニューの表示/非表示を切り替える
  onClickMenuButton: () => void;
  // レポートのリストメニューをOPENするか否か(true=OPEN / false=CLOSE)
  isReportListMenuOpen: boolean;
  isGroupListMenuOpen: boolean;
  isSettingMenuOpen: boolean;
  isAccountListMenuOpen: boolean;
  // [レポート]メニューがクリックされた
  onClickReportMenu: () => void;
  // [設定]メニューがクリックされた
  onClickSettingMenu: () => void;
  // [端末一覧]メニューがクリックされた
  onClickDeviceListMenu: () => void;
  // [翻訳回数]メニューがクリックされた
  onClickTransCountListMenu: () => void;
  // [翻訳元言語]メニューがクリックされた
  onClickFromLangListMenu: () => void;
  // [翻訳後言語]メニューがクリックされた
  onClickToLangListMenu: () => void;
  // 管理のリストメニューをOPENにするか否か
  isManageListMenuOpen: boolean;
  // [管理]メニューがクリックされた
  onClickManageMenu: () => void;
  // [ユーザー管理]メニューがクリックされた
  onClickUserListMenu: () => void;
  // [Group] menu is clicked
  onClickGroupMenu: () => void;
  // [Setting] menu is clicked
  onClickSettingMenuList: (value: string) => void;
  // SubMenu is clicked and selected
  onClickSubMenu: (value: string) => void;
  // Nagivate according to menu or submenu value
  onClickRoute: (value: string) => void;
  // onClickGroupListMenu: () => void;
  onClickUsersMenu: () => void;

  // For Setting button need to always open Drawer
  onClickOpenDrawer: () => void;
  // For Add Group Page
  onClickAddGroupPage: () => void;
  setIsGroupListMenuOpen: (value: boolean) => void;
  setIsAccountListMenuOpen: (value: boolean) => void;
  setIsReportListMenuOpen: (value: boolean) => void;
  t: TFunction;
  expandIcon: (
    value: SubMenu[],
    value2: boolean,
    value3: boolean,
  ) => JSX.Element;
};

/**
 * メインメニュー hooks
 *
 * @returns
 */
const useMainMenu = (): UseMainMenuValue => {
  // media query
  const matchScreen = useMediaQuery('(max-width: 899.98px)');
  const sidebarRef = useRef<HTMLDivElement>(null);
  // ドロワーメニューを表示するか否か(true=表示 / false=非表示) get/set
  const { isDrawerMenuOpen, setIsDrawerMenuOpen } = useDrawerInfo();

  // レポートのリストメニューをOPENにするか否か(true=OPEN / false=CLOSE) get/set
  const [isReportListMenuOpen, setIsReportListMenuOpen] =
    useState<boolean>(false);
  // 管理のリストメニューをOPENにするか否か(true=OPEN / false=CLOSE) get/set
  const [isManageListMenuOpen, setIsManageListMenuOpen] =
    useState<boolean>(true);
  // Whether to open the Setting list menu (true=OPEN / false=CLOSE) get/set
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState<boolean>(false);
  // Whether to open the Device Manager list menu (true=OPEN / false=CLOSE) get/set
  const [isGroupListMenuOpen, setIsGroupListMenuOpen] =
    useState<boolean>(false);
  // パス名を取得
  const [isAccountListMenuOpen, setIsAccountListMenuOpen] =
    useState<boolean>(false);
  const { pathname } = useLocation();
  // navigate(画面遷移)
  const navigate = useNavigate();
  // GA event tag initialization
  const GAEventTracker = useGAEvent('CreateGroup');
  const { accountInfo } = useAccountInfo();

  const { t } = useSwitchLocaleLanguage();

  /**
   * ドロワーメニューの表示/非表示を切り替える
   */
  const onClickMenuButton = () => {
    setIsDrawerMenuOpen(!isDrawerMenuOpen);
  };

  /**
   * [レポート]メニューがクリックされた
   */
  const onClickReportMenu = () => {
    setIsReportListMenuOpen(!isReportListMenuOpen);
  };

  /**
   * [設定]ボタンが押下された
   */
  const onClickSettingMenu = () => {
    // [設定]画面に遷移
    navigate(PAGE_PATH_NAME.SETTING_INFO, {
      state: { transViewName: pathname },
    });
  };

  /**
   * [端末一覧]ボタンが押下された
   */
  const onClickDeviceListMenu = () => {
    // [端末一覧]画面に遷移

    navigate(PAGE_PATH_NAME.DEVICE_LIST);
  };

  /**
   * [翻訳回数]ボタンが押下された
   */
  const onClickTransCountListMenu = () => {
    // [翻訳回数]画面に遷移
    navigate(PAGE_PATH_NAME.TRANS_COUNT_LIST);
  };

  /**
   * [翻訳元言語]ボタンが押下された
   */
  const onClickFromLangListMenu = () => {
    // [翻訳元言語]画面に遷移
    navigate(PAGE_PATH_NAME.FROM_LANG_LIST);
  };

  /**
   * [翻訳後言語]ボタンが押下された
   */
  const onClickToLangListMenu = () => {
    // [翻訳後言語]画面に遷移
    navigate(PAGE_PATH_NAME.TO_LANG_LIST);
  };

  /**
   * [管理]メニューがクリックされた
   */
  const onClickManageMenu = () => {
    setIsManageListMenuOpen(!isManageListMenuOpen);
  };

  /**
   * [ユーザー管理]ボタンが押下された
   */
  const onClickUserListMenu = () => {
    const userListState: UserListState = {
      transMethod: USER_LIST_TRANS_METHOD_CODE.DRAWER_MENU,
    };

    const userInfoProcState: UserInfoProsessResultState = {
      userInfoProcessResult: USER_INFO_PROSESS_RESULT_CODE.NONE,
    };
    /* eslint-disable */
    const transState: UserListTransState = {
      userListState,
      userInfoProcState,
    };

    // [ユーザー管理]画面に遷移
    navigate(PAGE_PATH_NAME.USER_LIST, {
      state: transState,
    });
  };
  const onClickOpenDrawer = () => {
    setIsDrawerMenuOpen(false);
  };
  const onClickGroupMenu = () => {
    setIsGroupListMenuOpen(false);
  };
  const onClickSettingMenuList = (textButtonName: string) => {
    // setIsSettingMenuOpen(!isSettingMenuOpen);
    if (textButtonName === 'back') {
      setIsGroupListMenuOpen(false);
      setIsAccountListMenuOpen(false);
      setIsSettingMenuOpen(false);
    }
    if (textButtonName === 'setting') {
      setIsSettingMenuOpen(true);
    }
  };

  const onClickUsersMenu = () => {
    if (
      accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
        FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.VIEWLIST,
      )
    ) {
      navigate(PAGE_PATH_NAME.USER_LIST);
    } else if (accountInfo.featurePermissions?.SECURITYMANAGEMENT) {
      navigate(PAGE_PATH_NAME.SECURITY_SETTINGS);
    }
  };
  // const onClickGroupListMenu = () => {
  //   navigate(PAGE_PATH_NAME.GROUPS);
  // };

  const onClickAddGroupPage = () => {
    navigate(PAGE_PATH_NAME.ADD_GROUP);
  };
  // Menu Item Route
  const onClickRoute = (value: string) => {
    if (
      value === MENU_VALUE.DEVICE_LIST &&
      pathname !== PAGE_PATH_NAME.DEVICE_LIST
    ) {
      setIsReportListMenuOpen(false);
      navigate(PAGE_PATH_NAME.DEVICE_LIST);
    }
    if (value === MENU_VALUE.DEVICE) {
      setIsReportListMenuOpen(false);
      setIsAccountListMenuOpen(false);
      navigate(PAGE_PATH_NAME.DEVICE);
    }
    if (
      value === MENU_VALUE.DASHBOARD &&
      pathname !== PAGE_PATH_NAME.DASHBOARD
    ) {
      setIsReportListMenuOpen(false);
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    if (value === MENU_VALUE.GROUPS && pathname !== PAGE_PATH_NAME.GROUPS) {
      setIsReportListMenuOpen(false);
      navigate(PAGE_PATH_NAME.GROUPS);
    }
    if (
      value === MENU_VALUE.LANGUAGE_COUNT &&
      pathname !== PAGE_PATH_NAME.TRANS_COUNT_LIST
    ) {
      navigate(PAGE_PATH_NAME.TRANS_COUNT_LIST);

      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'times_translated_menu');
      sendGAEvent('press_btn_link', 'link_name', 'open_url');
    }
    if (
      value === MENU_VALUE.FROM_LANGUAGE &&
      pathname !== PAGE_PATH_NAME.FROM_LANG_LIST
    ) {
      navigate(PAGE_PATH_NAME.FROM_LANG_LIST);
      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'translation_from_menu');
      sendGAEvent('press_btn_link', 'link_name', 'open_url');
    }
    if (
      value === MENU_VALUE.TO_LANGUAGE &&
      pathname !== PAGE_PATH_NAME.TO_LANG_LIST
    ) {
      navigate(PAGE_PATH_NAME.TO_LANG_LIST);
      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'translation_to_menu');
      sendGAEvent('press_btn_link', 'link_name', 'open_url');
    }
    if (value === MENU_VALUE.USERS && pathname !== PAGE_PATH_NAME.USER_LIST) {
      onClickUserListMenu();
    }
    if (value === MENU_VALUE.REMOTE_SETTINGS) {
      navigate(PAGE_PATH_NAME.REMOTE_SETTINGS);
    }
    if (
      value === MENU_VALUE.ADD_GROUP &&
      pathname !== PAGE_PATH_NAME.ADD_GROUP
    ) {
      setIsAccountListMenuOpen(false);
      GAEventTracker('btn_name', 'new_group');
      navigate(PAGE_PATH_NAME.ADD_GROUP);
    }
    if (
      value === MENU_VALUE.SECURITY &&
      pathname !== PAGE_PATH_NAME.SECURITY_SETTINGS
    ) {
      navigate(PAGE_PATH_NAME.SECURITY_SETTINGS);
    }
  };

  // Menu Item SubMenu Open/Close
  const onClickSubMenu = (value: string) => {
    if (value === 'Device Manager') {
      setIsGroupListMenuOpen(!isGroupListMenuOpen);
    }
    if (value === 'Account Settings') {
      setIsAccountListMenuOpen(!isAccountListMenuOpen);
    }
    if (value === 'Reports') {
      setIsReportListMenuOpen(!isReportListMenuOpen);
    }
  };
  useEffect(() => {
    if (pathname === '/') {
      setIsSettingMenuOpen(false);
    }
  }, [pathname]);

  // for check responsiveness & sidebar menu
  const url = useLocation();
  useEffect(() => {
    matchScreen ? setIsDrawerMenuOpen(true) : setIsDrawerMenuOpen(false);
  }, [matchScreen, url]);

  /**
   *
   * @param arry list of subMenu items
   * @param subMenuOpen boolean
   * @returns an Icon
   */
  const classes = useStyles();

  const expandIcon = (
    arry: SubMenu[],
    subMenuOpen: boolean,
    active: boolean,
  ) => {
    if (arry.length && !subMenuOpen) {
      return (
        <ArrowRightIcon
          className={
            active ? classes.arrowExpandIconActive : classes.arrowExpandIcon
          }
        />
      );
    }

    return (
      <ArrowDownIcon
        className={
          active ? classes.arrowExpandIconActive : classes.arrowExpandIcon
        }
      />
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        if (matchScreen) setIsDrawerMenuOpen(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef, matchScreen]);

  return {
    isDrawerMenuOpen,
    sidebarRef,
    isReportListMenuOpen,
    t,
    isGroupListMenuOpen,
    isSettingMenuOpen,
    isAccountListMenuOpen,
    isManageListMenuOpen,
    onClickMenuButton,
    onClickReportMenu,
    onClickSettingMenu,
    onClickDeviceListMenu,
    onClickTransCountListMenu,
    onClickFromLangListMenu,
    onClickToLangListMenu,
    onClickManageMenu,
    onClickUserListMenu,
    onClickSettingMenuList,
    onClickUsersMenu,
    onClickGroupMenu,
    onClickSubMenu,
    onClickRoute,
    onClickOpenDrawer,
    expandIcon,
    onClickAddGroupPage,
    setIsGroupListMenuOpen,
    setIsAccountListMenuOpen,
    setIsReportListMenuOpen,
  };
};

export default useMainMenu;
