import { useState, useEffect } from 'react';
import { SERVER_MESSAGE } from '../constants/constants';
import callSecuritySettingsGetApi from '../apis/callSecuritySettingsGetApi';
import callSecuritySettingsUpdateApi from '../apis/callSecuritySettingsUpdateApi';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useSnackbar from './useSnackbar';
import { sendGAEvent } from './useGAEvent';
import useAccountInfo from './useAccountInfo';

export type UseSecuritySettingsValue = {
  isToggleActive: boolean;
  isCallingGetApi: boolean;
  isCallingUpdateApi: boolean;
  openSecurityToggleDialog: boolean;
  onClickToggleBtn: () => void;
  onClose: () => void;
  onClickYesBtn: () => void;
};

const useSecuritySettings = (): UseSecuritySettingsValue => {
  const { t } = useSwitchLocaleLanguage();
  const { accountInfo } = useAccountInfo();
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [isCallingGetApi, setIsCallingGetApi] = useState(false);
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState(false);
  const [openSecurityToggleDialog, setOpenSecurityToggleDialog] =
    useState(false);

  const { displaySnackbar } = useSnackbar();

  const securitySettingsGetApi = () => {
    setIsCallingGetApi(true);
    void callSecuritySettingsGetApi()
      .then((apiResponse) => {
        if (apiResponse.message === SERVER_MESSAGE.FETCH_OK) {
          const details = apiResponse.details as { prevented: boolean };
          setIsToggleActive(details.prevented);
        }
      })
      //   .catch((error: ALL_ERROR_TYPE | unknown) => {
      //     console.log(error, 'error');
      //     setIsCallingApi(false);
      //   })
      .finally(() => {
        setIsCallingGetApi(false);
      });
  };

  useEffect(() => {
    securitySettingsGetApi();
  }, []);

  // modal close function
  const onClose = () => {
    securitySettingsGetApi();
    setOpenSecurityToggleDialog(false);
  };

  const securitySettingsUpdateApi = (preventStatus: boolean) => {
    setIsCallingUpdateApi(true);
    callSecuritySettingsUpdateApi(preventStatus)
      .then((apiResponse) => {
        if (apiResponse.message === SERVER_MESSAGE.UPDATE_OK) {
          setIsToggleActive(preventStatus);
          displaySnackbar({
            message: t('SecuritySettings.apiResponse.success'),
          });

          return;
        }
        if (
          apiResponse.message ===
          SERVER_MESSAGE.TRANS_HISTORY_PREVENTION_ALREADY_UPDATED
        ) {
          setIsToggleActive(preventStatus);
          displaySnackbar({
            message: t('SecuritySettings.apiResponse.alreadyUpdated'),
            type: 'error',
          });
        }
      })
      .finally(() => {
        setOpenSecurityToggleDialog(false);
        setIsCallingUpdateApi(false);
      });
  };

  // Toggle
  const onClickToggleBtn = () => {
    if (isToggleActive) {
      setOpenSecurityToggleDialog(true);
      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'record_translation_history_select');
      sendGAEvent('press_btn', 'record_translation_history_select_value', 'ON');
      sendGAEvent('press_btn', 'txt_name', accountInfo.corpId);
    } else {
      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'record_translation_history_select');
      sendGAEvent(
        'press_btn',
        'record_translation_history_select_value',
        'OFF',
      );
      sendGAEvent('press_btn', 'txt_name', accountInfo.corpId);
      securitySettingsUpdateApi(true);
    }
  };

  // click yes btn
  const onClickYesBtn = () => {
    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'yes');
    securitySettingsUpdateApi(false);
  };

  return {
    isToggleActive,
    isCallingGetApi,
    isCallingUpdateApi,
    openSecurityToggleDialog,
    onClickToggleBtn,
    onClose,
    onClickYesBtn,
  };
};

export default useSecuritySettings;
