import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import GroupFeatureTemplate from '../Templates/GroupFeatureTemplate';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import DeviceDetailsCard from '../Modecules/DeviceDetailsCard';
import useAccountInfo from '../../hooks/useAccountInfo';
import { PAGE_PATH_NAME, FEATURE_PERMISSION } from '../../constants/constants';
import TransHistoryList from './TransHistoryList';
import { deviceDetailsStateProps } from '../../hooks/useDeviceList';
import { AppDispatch, useAppDispatch } from '../../state/store';
import { translationListSlice } from '../../state/slice/translationCountSlice';

const DeviceDetailsPage = (): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const locatonState = location.state as deviceDetailsStateProps;
  const { accountInfo } = useAccountInfo();
  const { pathname } = useLocation();

  const dispatch: AppDispatch = useAppDispatch();
  const { isFromDeviceDetailsPage } = translationListSlice.actions;

  useEffect(() => {
    dispatch(isFromDeviceDetailsPage(true));
  }, [dispatch, isFromDeviceDetailsPage]);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEBASIC?.includes(
        FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEBASIC.VIEWDETAILS,
      )
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [accountInfo.featurePermissions.DEVICEMANAGEMENT, pathname]);

  useEffect(() => {
    if (locatonState == null) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let imei;
  if (locatonState !== null) {
    imei = locatonState.imeiNo;
  }

  return (
    <GroupFeatureTemplate
      titleText={t('deviceInfo.viewName')}
      isUserUpdate={false}
    >
      <Box sx={{ width: '100%' }}>
        <DeviceDetailsCard
          imei={imei as string}
          editDisable={
            // eslint-disable-next-line
            !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICENAME?.includes(
              FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICENAME.EDIT,
            )
          }
          lostModeView={
            // eslint-disable-next-line
            !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICELOSTMODE?.includes(
              FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICELOSTMODE.VIEWDETAILS,
            )
          }
          lostModeEdit={
            // eslint-disable-next-line
            !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICELOSTMODE?.includes(
              FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICELOSTMODE.EDIT,
            )
          }
          factoryResetView={
            !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEINITIALIZATION?.includes(
              FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEINITIALIZATION
                .VIEWDETAILS,
            )
          }
          factoryResetEdit={
            !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICEINITIALIZATION?.includes(
              FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICEINITIALIZATION.EDIT,
            )
          }
        />
        {/* eslint-disable-next-line */}
        {accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICETRANSLATIONHISTORY?.includes(
          FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICETRANSLATIONHISTORY.VIEWLIST,
        ) && <TransHistoryList imei={imei as string} />}
      </Box>
    </GroupFeatureTemplate>
  );
};

export default DeviceDetailsPage;
