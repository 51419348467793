import * as React from 'react';
// import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

// icons
import { ReactComponent as Visibility } from '../../images/icon/eye-open.svg';
import { ReactComponent as VisibilityOff } from '../../images/icon/eye-close.svg';

// mui custom style
import { useStyles } from '../Templates/CommonStyle';

type IProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isError: boolean;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  width?: string;
  // eslint-disable-next-line react/require-default-props
  height?: string;
};
const InputPinCodeLostMode = (props: IProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const inputRef = React.useRef<any>(null);
  // custom styles
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  /*eslint-disable*/
  const handleChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == '' || regex.test(e.target.value)) {
      props.setValue(e.target.value);
    }
  };
  React.useEffect(() => {
    // Moving cursor to the end
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(props.value.length, props.value.length);
  }, [showPassword]);

  const handleClickShowPassword = () => {
    // console.log('show password');
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <form autoComplete="off">
      <FormControl variant="outlined">
        <OutlinedInput
          sx={{
            width: props.width ? props.width : '200px',
            height: props.height ? props.height : '38px',
          }}
          className={`${classes.pinInput}`}
          type={showPassword ? 'text' : 'password'}
          inputRef={inputRef}
          value={props.value}
          name="new-password"
          // defaultValue={props.value}
          required
          onChange={handleChange}
          inputProps={{
            maxLength: 6,
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
          autoComplete="off"
          error={props.isError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disableRipple
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="null"
          notched={false}
        />
      </FormControl>
    </form>
  );
};

export default InputPinCodeLostMode;
