import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GET_LOST_MODE_PIN_API_RESULT_CODE,
  GET_LOST_MODE_PIN_ERROR_TYPE,
} from '../apis/callGetLostModePin';
import {
  ALL_ERROR_TYPE,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
} from '../constants/constants';
import { GetLostModePinApiResponse } from '../types/apis/getLostModePinApi.d';
import { getLostPin } from '../utils/devicelist/getLostModePin';
// import useAccessKey from './useAccessKey';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

export const useGetLostModePin = () => {
  const [isCallingGetPinApi, setIsCallingGetPinApi] = useState<boolean>(false);
  // const [currentPin, setCurrentPin] = useState<string>('');
  const currentPin = useRef<string>('');
  const [errorType, setErrorType] = useState<ALL_ERROR_TYPE | null>(null);
  // const { accessKey } = useAccessKey();
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();
  const navigate = useNavigate();
  const onClickGetLostPin = (imei: string): void => {
    // loading Start
    setIsCallingGetPinApi(true);
    getLostPin(imei)
      .then((result: GetLostModePinApiResponse) => {
        if (
          result.resultCode === GET_LOST_MODE_PIN_API_RESULT_CODE.INFO_NO_DEVICE
        ) {
          displaySnackbar({
            message: t('deviceInfo.devicePermissionApiError'),
            type: 'error',
          });
          setErrorType(result.resultCode);
          setIsCallingGetPinApi(false);
          navigate(PAGE_PATH_NAME.DEVICE_LIST);

          return;
        }
        if (
          result.resultCode === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode === SERVER_MESSAGE.USER_ROLE_CHANGED ||
          result.resultCode === SERVER_MESSAGE.INVALID_TOKEN ||
          result.resultCode === SERVER_MESSAGE.EXPIRED_TOKEN ||
          result.resultCode === SERVER_MESSAGE.NO_INTERNET
        ) {
          setIsCallingGetPinApi(false);

          return;
        }
        if (result.resultCode !== GET_LOST_MODE_PIN_API_RESULT_CODE.OK) {
          displaySnackbar({
            message: t('deviceInfo.lostModePinApiError'),
            type: 'error',
          });
          setErrorType(result.resultCode);
          setIsCallingGetPinApi(false);

          return;
        }
        if (result.resultCode === GET_LOST_MODE_PIN_API_RESULT_CODE.OK) {
          // setCurrentPin(result.currentPin);
          currentPin.current = result.currentPin;
          setIsCallingGetPinApi(false);
        }
        setErrorType(result.resultCode);
        setIsCallingGetPinApi(false);
      })
      .finally(() => {
        // Loading End
        setIsCallingGetPinApi(false);
      });
  };

  return {
    currentPin: currentPin.current,
    onClickGetLostPin,
    isCallingGetPinApi,
    errorType,
  };
};

export default useGetLostModePin;
