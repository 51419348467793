import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import { UpdatePersonalSettingsApiResponse } from '../types/apis/personalSettingsApi.d';

/**
 * Update personal Settings API Result Code
 */
export const UPDATE_PERSONAL_SETTINGS_RESULT_CODE = {
  SUCCESSFULLY_UPDATED: 'SUCCESSFULLY_UPDATED',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * Update personal Settings error type
 */
export type UPDATE_PERSONAL_SETTINGS_ERROR_TYPE =
  (typeof UPDATE_PERSONAL_SETTINGS_RESULT_CODE)[keyof typeof UPDATE_PERSONAL_SETTINGS_RESULT_CODE];

/**
 * Update personal Settings API call
 *
 * @param accessKey access key
 * @returns Response from the Update personal Settings API
 */
const callPersonalSettingsUpdateApi = (
  fileData: FormData,
): Promise<UpdatePersonalSettingsApiResponse> => {
  const response = callApiUtil
    .put<UpdatePersonalSettingsApiResponse>(
      `${API_URL.UPDATE_PERSONAL_SETTINGS}`,
      fileData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then(
      (apiResponse) =>
        // console.log(apiResponse, 'error-then');

        apiResponse.data,
    )
    .catch((error: AxiosError) => {
      // console.log(error, 'error-catch');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const errorData: { message: string; statusCode: number } =
        error.response?.data;

      return errorData;
    });

  return response;
};

export default callPersonalSettingsUpdateApi;
