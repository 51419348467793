import callApiUtil from '../utils/callApiUtil';
import type { GetUpdateUserInfoApiResponse } from '../types/apis/getUpdateUserInfoApi';
import { API_URL } from '../constants/constants';


/*eslint-disable*/
const callUpdateUserInfoApi = (): Promise<GetUpdateUserInfoApiResponse> => {
  const response = callApiUtil
    .get<GetUpdateUserInfoApiResponse>(API_URL.UPDATE_USER_INFO_API, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callUpdateUserInfoApi;
