/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import callGroupTopUserApi, {
  GROUP_TOP_USER_ERROR_TYPE,
  GROUP_TOP_USER_API_RESULT_CODE,
} from '../../apis/callTopUserListApi';
import { isGroupTopUserErrorType } from '../../types/apis/apiErrorTypeGuard';

import {
  // GroupTopUserApiRequestParam,
  GroupTopUserApiResponse,
} from '../../types/apis/groupTopUserApi.d';
import { groupTopUserResult } from '../../types/Group/groupTopUserApi.d';

export const fetchGroupTopUser = async (
  // accessKey: string,
  // requestParams: GroupTopUserApiRequestParam,
  groupId: number,
): Promise<groupTopUserResult> => {
  // User list acquisition API call
  try {
    const apiResponse: GroupTopUserApiResponse = await callGroupTopUserApi(
      // accessKey,
      groupId,
    );
    switch (apiResponse.message) {
      // success
      case GROUP_TOP_USER_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode: GROUP_TOP_USER_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          numberOfUsers: apiResponse.details.numberOfUsers,
          users: apiResponse.details.users,
          groupCode: apiResponse.details.groupCode,
        };
      // Failure
      case GROUP_TOP_USER_API_RESULT_CODE.INFO_NO_GROUP:
        throw GROUP_TOP_USER_API_RESULT_CODE.INFO_NO_GROUP;
      case GROUP_TOP_USER_API_RESULT_CODE.USER_ROLE_CHANGED:
        throw GROUP_TOP_USER_API_RESULT_CODE.USER_ROLE_CHANGED;
      case GROUP_TOP_USER_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw GROUP_TOP_USER_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case GROUP_TOP_USER_API_RESULT_CODE.CORPORATION_ACCESS_DENIED:
        throw GROUP_TOP_USER_API_RESULT_CODE.CORPORATION_ACCESS_DENIED;
      case GROUP_TOP_USER_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GROUP_TOP_USER_API_RESULT_CODE.WARN_INVALID_AUTH;
      default:
        throw GROUP_TOP_USER_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: GROUP_TOP_USER_ERROR_TYPE =
      GROUP_TOP_USER_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGroupTopUserErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      numberOfUsers: 0,
      users: [],
      groupCode: 10,
    };
  }
};
