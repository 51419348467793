import React from 'react';
import {
  IconButton,
  Typography,
  Grid,
  // Select,
  // MenuItem,
  Popper,
  ClickAwayListener,
  List,
  ListItem,
  FormControl,
} from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as NextPageIcon } from '../../images/icon/next-page.svg';
import { ReactComponent as PreviousPageIcon } from '../../images/icon/previous-page.svg';
import { ReactComponent as FirstPageIcon } from '../../images/icon/first-page.svg';
import { ReactComponent as LastPageIcon } from '../../images/icon/last-page.svg';
import { ReactComponent as DropDownIcon } from '../../images/icon/avatar-arrow.svg';
import styles from './PaginationContainer.module.scss';

/**
 * Props received when calling this component (required)
 */
type Props = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // [前へ]ボタンがクリックされた
  clickPrevPageButton: () => void;
  // [次へ]ボタンがクリックされた
  clickNextPageButton: () => void;
  // 表示件数リスト
  rowNumberPerPages: number[];
  // 現在選択中の表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された
  changeRowNumber: (selectedRowNumber: number) => void;
  clickFirstPageButton?: () => void;
  clickLastPageButton?: () => void;
  containFirstAndLastButton?: boolean;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
  totalDataRows: number;
};

// const DropIcon = () => <img src={DropDownIcon} alt="dropdownIcon" />;
/**
 * ページネーション コンポーネント
 */
const PaginationContainer = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  const [rowEl, setRowEl] = React.useState<null | HTMLElement>(null);
  const rowSelectMenuOpen = Boolean(rowEl);

  const handleRowMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setRowEl(event.currentTarget);
  };
  const handleRowMenuClose = () => {
    setRowEl(null);
  };

  const rowSelectId = rowSelectMenuOpen ? 'simple-popper' : undefined;

  const renderRowSelectMenu = (
    <Popper
      placement="bottom-end"
      id={rowSelectId}
      open={rowSelectMenuOpen}
      anchorEl={rowEl}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [1, 1],
            },
          },
        ],
      }}
      className={classes.paginationRowMenu}
    >
      <ClickAwayListener onClickAway={handleRowMenuClose}>
        <List>
          {props.rowNumberPerPages.length > 0 &&
            props.rowNumberPerPages.map((rowNumber: number) => (
              <ListItem
                button
                key={rowNumber}
                onClick={() => {
                  props.changeRowNumber(rowNumber);
                  setRowEl(null);
                }}
              >
                {rowNumber}
              </ListItem>
            ))}
        </List>
      </ClickAwayListener>
    </Popper>
  );

  return (
    <div className={`${styles['pagination-button-wrapper']} table-footer`}>
      <Grid container spacing={2} alignItems="center" className='table-footer-item'>
        <Grid item>
          <Typography>{t('pagination.display')}</Typography>
        </Grid>

        <Grid item>
          <FormControl sx={{ marginLeft: '-6px' }}>
            {/* custom select start */}
            <IconButton
              size="large"
              aria-label="3"
              aria-controls={rowSelectId}
              aria-describedby={rowSelectId}
              color="inherit"
              aria-haspopup="true"
              onClick={handleRowMenuOpen}
              className={classes.paginationRowBtn}
            >
              <Typography sx={{ flexGrow: 2, lineHeight: 1 }}>
                {props.currentRowNumberPerPage}
              </Typography>
              <DropDownIcon />
            </IconButton>
            {renderRowSelectMenu}
            {/* custom select end */}
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className='table-footer-item table-pagination'>
        <Grid item>
          <Typography className="pagination-page-info">
            {props.pageDataIndexFrom} - {props.pageDataIndexTo}/
            {props.totalDataRows}
          </Typography>
        </Grid>

        <Grid
          item
          sx={{ display: 'flex' }}
          className={styles['pagination-icon-container']}
        >
          {props.containFirstAndLastButton && (
            <div>
              <IconButton
                onClick={props.clickFirstPageButton}
                disabled={props.currentPage === 1}
                sx={{
                  opacity: props.currentPage === 1 ? '0.5' : '1',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#009de1',
                  },
                }}
              >
                <FirstPageIcon />
              </IconButton>
            </div>
          )}

          <div>
            <IconButton
              onClick={props.clickPrevPageButton}
              disabled={props.currentPage === 1}
              sx={{
                opacity: props.currentPage === 1 ? '0.5' : '1',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#009de1',
                },
              }}
            >
              <PreviousPageIcon />
            </IconButton>
          </div>

          <div>
            <IconButton
              onClick={props.clickNextPageButton}
              disabled={props.currentPage === props.totalPages}
              sx={{
                opacity: props.currentPage === props.totalPages ? '0.5' : '1',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <NextPageIcon />
            </IconButton>
          </div>

          {props.containFirstAndLastButton && (
            <div>
              <IconButton
                onClick={props.clickLastPageButton}
                disabled={props.currentPage === props.totalPages}
                sx={{
                  opacity: props.currentPage === props.totalPages ? '0.5' : '1',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#009de1',
                  },
                }}
              >
                <LastPageIcon />
              </IconButton>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
});

export default PaginationContainer;
