import { AxiosError } from 'axios';
import type { DeviceListApiResponse } from '../types/apis/deviceListApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * 端末一覧取得API 結果コード
 */
export const DEVICE_LIST_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // 端末未登録
  INFO_NO_DATA: 'INFO_NO_DATA',
  // アクセスキー無し(※端末一覧取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※端末一覧取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※端末一覧取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  NO_INTERNET: 'NO_INTERNET',
} as const;

/**
 * 端末一覧取得エラータイプ
 */
export type DEVICE_LIST_ERROR_TYPE =
  (typeof DEVICE_LIST_API_RESULT_CODE)[keyof typeof DEVICE_LIST_API_RESULT_CODE];

/**
 * 端末一覧取得API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 端末一覧取得APIからのレスポンス
 */
const callDeviceListApi = (
  rowLimitPerPage: number,
  currentPageNumber: number,
  sortDirection: string,
  searchText: string,
  colName: string,
  groupId?: number,
): Promise<DeviceListApiResponse> => {
  const response = callApiUtil
    .get<DeviceListApiResponse>(
      `${
        API_URL.DEVICE_LIST_API
      }?rowLimitPerPage=${rowLimitPerPage}&currentPageNumber=${currentPageNumber}&sortDirection=${sortDirection}&searchText=${searchText}&colName=${colName}&groupId=${
        groupId && groupId !== 0 ? groupId : ''
      }`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    /* eslint-disable */
    .catch((error: AxiosError) => {
      if (error.response?.data) {
        return error.response?.data;
      }

      return { message: error.message };
    });

  return response;
};

export default callDeviceListApi;
