import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import type {
  PasswordReminderSendMailApiRequestParam,
  PasswordReminderSendMailAuthApiResponse,
} from '../types/apis/passwordReminderSendMailApi';
import callApiUtil from '../utils/callApiUtil';

// 変数名長くなりすぎるのでPASSWORD -> PWと記載
export const PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_CREATED: 'SUCCESSFULLY_CREATED',
  // 入力パラメータエラー or アカウント未登録
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // アクセスキー無し(※パスワードリマインダーメール送信API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※パスワードリマインダーメール送信API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※パスワードリマインダーメール送信API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 権限不正エラー
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

export type PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE =
  typeof PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE[keyof typeof PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE];

/**
 * パスワードリマインダーメール送信API(認証あり)呼び出し
 * @param requestParams リクエストパラメータ
 * @returns パスワードリマインダーメール送信API(認証あり)からのレスポンス
 */
/*eslint-disable*/
const callPasswordReminderSendMailAuthApi = (
  requestParams: PasswordReminderSendMailApiRequestParam,
  // accessKey: string,
): Promise<PasswordReminderSendMailAuthApiResponse> => {
  const jsonParams = {
    mailAddress: requestParams.mailAddress,
  };

  const response = callApiUtil
    .post<PasswordReminderSendMailAuthApiResponse>(
      API_URL.PASSWORD_REMINDER_SEND_MAIL_AUTH_API,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callPasswordReminderSendMailAuthApi;
