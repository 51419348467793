import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Popper,
  Typography,
} from '@mui/material';
import TableTemplateContainer from '../Templates/TableTemplateContainer';
import useTransCountList from '../../hooks/useTransCountList';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ExportIcon } from '../../images/icon/export.svg';
import { ReactComponent as DropDownIcon } from '../../images/icon/avatar-arrow.svg';
import {
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../../constants/constants';
import styles from './Report.module.scss';
import Breadcrumb from '../Modecules/Breadcrumb';
import ReportListPageTemplate from '../Templates/ReportListTemplate';
import useAccountInfo from '../../hooks/useAccountInfo';
import Loader from '../Atoms/Loader';
import ReportsNoDataCard from '../Atoms/ReportsNoDataCard';
import SearchField from '../Modecules/SearchField';
import MonthRangePickerInput from '../Modecules/monthRangePickerInput';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { REPORT_VIEW_TYPE } from '../../types/report/fromLangList.d';
import { AppDispatch, RootState, useAppDispatch } from '../../state/store';
import {
  DeviceListState,
  translationListSlice,
} from '../../state/slice/translationCountSlice';
/**
 * 翻訳回数画面 コンポーネント
 *
 * @returns
 */
const TransCountList = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  const [searchTextInput, setSearchTextInput] = React.useState<string>('');
  // 翻訳回数画面 hooks
  const {
    isCallingApi,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    viewType,
    clickDownload,
    sortValue,
    paginationValue,
    viewSwitchValue,
    onClickImeiLink,
    dateRangeValue,
    isCallingReportApi,
    t,
    isFirstRenderingRef,
    simpleBarRef,
  } = useTransCountList({ searchTextInput });
  const dispatch: AppDispatch = useAppDispatch();
  const { addView, addSearchText } = translationListSlice.actions;
  const deviceListStateInfo = useSelector<RootState, DeviceListState>(
    (state) => state.deviceListState,
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { accountInfo } = useAccountInfo();

  const dateRangeHandleChange = (date: Date[]) => {
    const startDate = date[0];
    const endDate = date[1];
    dateRangeValue.changeStartDate(new Date(startDate));
    dateRangeValue.changeEndDate(new Date(endDate));
    isFirstRenderingRef.current = true;
  };

  const [rowEl, setRowEl] = React.useState<null | HTMLElement>(null);
  const rowSelectMenuOpen = Boolean(rowEl);

  const handleRowMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setRowEl(event.currentTarget);
    isFirstRenderingRef.current = true;
  };
  const handleRowMenuClose = () => {
    setRowEl(null);
  };
  const rowSelectId = rowSelectMenuOpen ? 'simple-popper' : undefined;

  const renderRowSelectMenu = (
    <Popper
      placement="bottom-end"
      id={rowSelectId}
      open={rowSelectMenuOpen}
      anchorEl={rowEl}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ],
      }}
      className={classes.reportRowMenu}
    >
      <ClickAwayListener onClickAway={handleRowMenuClose}>
        <List>
          {viewSwitchValue.viewMenuContents &&
            Object.keys(viewSwitchValue.viewMenuContents).map((key) => (
              <ListItem
                button
                key={key}
                onClick={() => {
                  viewSwitchValue.clickViewSwitch(key);
                  dispatch(addView(key as REPORT_VIEW_TYPE));
                  setRowEl(null);

                  // GA tag
                  sendGAEvent(
                    'select_list',
                    'txt_name',
                    'select_display_unit_list',
                  );
                  sendGAEvent(
                    'select_list',
                    'select_display_unit_list_value',
                    viewSwitchValue.viewMenuContents[key],
                  );
                }}
              >
                {viewSwitchValue.viewMenuContents[key]}
              </ListItem>
            ))}
        </List>
      </ClickAwayListener>
    </Popper>
  );

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.REPORTING?.TRANSLATIONCOUNT?.includes(
        FEATURE_PERMISSION.REPORTING.TRANSLATIONCOUNT.VIEWLIST,
      ) &&
      !accountInfo.subcriptionChange
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);
  const exportDisable =
    !accountInfo.featurePermissions?.REPORTING?.TRANSLATIONCOUNT?.includes(
      FEATURE_PERMISSION.REPORTING.TRANSLATIONCOUNT.EXPORT,
    );

  return (
    <>
      <Box className={`${classes.breadcrumbBox} ${'res-breadcrumb'}`}>
        <Breadcrumb />
      </Box>
      <ReportListPageTemplate title={t('transCountList.viewName')}>
        <Container disableGutters sx={{ padding: '0px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              columnGap: '8px',
            }}
            className="trans-count-filter"
          >
            <div className={`${styles.filter} trans-count-filter-item`}>
              <SearchField
                defaultValue={deviceListStateInfo.searchText}
                clickFilterSearch={(val) => {
                  const tempVal = decodeURIComponent(val).trim();
                  setSearchTextInput(encodeURIComponent(tempVal));
                  dispatch(addSearchText(tempVal));
                }}
                placeholder={t('filter.placeholder')}
                responsiveClass="search-input"
                // autoFocusValue
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                alignItem: 'center',
                justifyContent: 'center',
                rowGap: '12px',
              }}
              className="trans-count-filter-item"
            >
              <Box
                sx={{ width: '230px' }}
                className="trans-count-filter-item-date"
              >
                <Box className="trans-count-filter-item-date-control">
                  <MonthRangePickerInput
                    onApply={dateRangeHandleChange}
                    translationCountPage
                    fromDate={deviceListStateInfo.fromDate}
                    toDate={deviceListStateInfo.toDate}
                  />
                </Box>
              </Box>

              <div
                className={`${classes.reportDownloadContainer} trans-count-filter-item-export`}
              >
                <Box className="trans-count-filter-item-export-item">
                  <div
                    className={`${styles['view-switch-label']} view-switch-label-responsive`}
                  >
                    <p>{t('transCountList.label.viewSwitch')}</p>
                  </div>
                  <Grid item>
                    <FormControl sx={{ marginLeft: '-6px' }}>
                      {/* custom select start */}
                      <IconButton
                        size="large"
                        aria-label="3"
                        aria-controls={rowSelectId}
                        aria-describedby={rowSelectId}
                        color="inherit"
                        aria-haspopup="true"
                        onClick={handleRowMenuOpen}
                        className={classes.reportRowBtn}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            flexGrow: 2,
                            lineHeight: '20px',
                          }}
                        >
                          {
                            viewSwitchValue.viewMenuContents[
                              viewSwitchValue.viewSwitchInputData.viewKey
                            ]
                          }
                        </Typography>

                        <DropDownIcon />
                      </IconButton>
                      {renderRowSelectMenu}
                      {/* custom select end */}
                    </FormControl>
                  </Grid>
                </Box>

                <Button
                  aria-label="ExportData"
                  className={classes.exportDataBtn}
                  disableRipple
                  onClick={() => clickDownload()}
                  disabled={
                    exportDisable ||
                    dateRangeValue?.startDate?.toDateString() ===
                      'Invalid Date' ||
                    dateRangeValue?.endDate?.toDateString() ===
                      'Invalid Date' ||
                    isCallingReportApi
                  }
                >
                  {isCallingReportApi ? (
                    <Box className="export-data-btn-box">
                      <Loader colorCode="#fff" />
                      <Typography
                        className={`${classes.exportDataText} export-data-text`}
                      >
                        {t('reportDownloadButton')}
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="export-data-btn-box">
                      <ExportIcon />
                      <Typography
                        className={`${classes.exportDataText} export-data-text`}
                      >
                        {t('reportDownloadButton')}
                      </Typography>
                    </Box>
                  )}
                </Button>
              </div>
            </Box>
          </Box>

          {currentPageDataRows.length === 0 && !isCallingApi && (
            <Box className={classes.tableCard}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  lineHeight: '26px',
                }}
              >
                {t('transCountList.tableCaption')} (0)
              </Typography>
              <ReportsNoDataCard searchText={searchTextInput} />
            </Box>
          )}
          {!(currentPageDataRows.length === 0 && !isCallingApi) && (
            <Box
              className={classes.tableCard}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TableTemplateContainer
                simpleBarRef={simpleBarRef}
                headerColumns={headerColumns}
                dataRows={currentPageDataRows}
                totalDataRowsLabel={t('fromLangList.label.totalDataRows')}
                totalDataRows={totalListCount}
                clickSortButton={sortValue.onClickSortButton}
                sortConfig={sortValue.sortConfig}
                currentPage={paginationValue.currentPage}
                totalPages={paginationValue.totalPages}
                clickPrevPageButton={() => {
                  paginationValue.onClickPrevPage();
                }}
                clickNextPageButton={() => {
                  paginationValue.onClickNextPage();
                }}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={
                  paginationValue.currentRowNumberPerPage
                }
                changeRowNumber={paginationValue.onChangeRowNumber}
                isCallingApi={isCallingApi}
                containFirstAndLastButton
                clickFirstPageButton={() => {
                  /* eslint-disable */
                  paginationValue.onClickFirstPage &&
                    paginationValue.onClickFirstPage();
                }}
                clickLastPageButton={() => {
                  paginationValue.onClickLastPage &&
                    paginationValue.onClickLastPage();
                }}
                imeiLinkClicked={onClickImeiLink}
                pageDataIndexFrom={paginationValue.pageDataIndexFrom}
                pageDataIndexTo={paginationValue.pageDataIndexTo}
                tableSrcPage={
                  viewType === 'd' ? 'TransCountListDay' : 'TransCountList'
                }
              />
            </Box>
          )}
        </Container>
      </ReportListPageTemplate>
    </>
  );
};

export default TransCountList;
