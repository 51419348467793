import React, { useEffect, useState } from 'react';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { ReactComponent as SearchIcon } from '../../images/icon/search.svg';
// common style
import { useStyles } from '../Templates/CommonStyle';
import { sendGAEvent } from '../../hooks/useGAEvent';

type Props = {
  locationKey?: string;
  placeholder?: string;
  clickFilterSearch: (val: string) => void;
  responsiveClass?: string;
};

const ViewUserSearchField = React.memo<Props>((props): JSX.Element => {
  // common styles
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');
  const locationKeyProps = props.locationKey ? props.locationKey : 'changeKey';
  useEffect(() => {
    setSearchText('');
  }, [locationKeyProps]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.clickFilterSearch(encodeURIComponent(searchText));
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Special characters need to be encoded in order to pass through params
        props.clickFilterSearch(encodeURIComponent(searchText));
      }}
    >
      <FormControl variant="outlined" className="view-user-search-input">
        <OutlinedInput
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          className={`${classes.searchInputFilter} ${
            props.responsiveClass ?? ''
          }`}
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
            // GA event tag
            sendGAEvent('search_kw', 'txt_search_kw', 'input_search');
          }}
          placeholder={props.placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type="submit"
                sx={{ p: '8px', marginLeft: '8px' }}
                aria-label="search"
              >
                <SearchIcon
                  style={{ width: '12.01px', height: '12.01px' }}
                  onClick={() => {
                    // GA event tag
                    sendGAEvent('press_btn', 'btn_name', 'search_icon');
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'search', maxLength: 256 }}
          autoFocus
        />
      </FormControl>
    </form>
  );
});

export default ViewUserSearchField;
