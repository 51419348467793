// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import SimpleBarReact from 'simplebar-react';
import { Box, Skeleton } from '@mui/material';
import styles from './TableBodyContainer.module.scss';

const deviceListWidth: { [key: string | number]: string | number } = {
  groupName: 13,
  imei: 12,
  deviceName: 14,
  productType: 11,
  mode: 14,
  status: 16,
  simExp: 10,
  lastUsed: 10,
};
const DeviceListBodySkeleton = () => {
  const counter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  return (
    <Box
      sx={{
        pointerEvents: '',
        position: 'relative',
      }}
    >
      <SimpleBarReact
        className="table-body-simplebar"
        style={{
          padding: '0px',
          width: '',
        }}
      >
        <div className={styles.tableRowContainer}>
          {counter.map(() => (
            <tr className={`${styles.userTableDataRow} ${styles.tableDataRow}`}>
              <td
                style={{
                  width: `${deviceListWidth.groupName}%`,
                  paddingRight: '55px',
                }}
              >
                <Box>
                  <div className={styles['link-style-deviceList']}>
                    <span>
                      <Skeleton
                        sx={{
                          height: '20px',
                          marginTop: '8px',
                          marginBottom: '7px',
                        }}
                      />
                    </span>
                  </div>
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.imei}%`,
                  paddingRight: '55px',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      height: '20px',
                      marginTop: '8px',
                      marginBottom: '7px',
                    }}
                  />
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.deviceName}%`,
                  paddingRight: '55px',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      height: '20px',
                      marginTop: '8px',
                      marginBottom: '7px',
                    }}
                  />
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.productType}%`,
                  paddingRight: '55px',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      height: '20px',
                      marginTop: '8px',
                      marginBottom: '7px',
                    }}
                  />
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.mode}%`,
                  paddingRight: '55px',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      height: '20px',
                      marginTop: '8px',
                      marginBottom: '7px',
                    }}
                  />
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.status}%`,
                  paddingRight: '55px',
                }}
              >
                <Box>
                  <Skeleton
                    sx={{
                      height: '20px',
                      marginTop: '8px',
                      marginBottom: '7px',
                    }}
                  />
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.simExp}%`,
                  paddingRight: 0,
                }}
              >
                <Box>
                  <div
                    style={{
                      fontSize: '13px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      {' '}
                      <Skeleton
                        sx={{
                          width: '80px',
                          height: '20px',
                          marginTop: '8px',
                          marginBottom: '7px',
                        }}
                      />
                    </span>
                  </div>
                </Box>
              </td>
              <td
                style={{
                  width: `${deviceListWidth.lastUsed}%`,
                  paddingRight: 0,
                }}
              >
                <Box>
                  <div>
                    <div
                      style={{
                        fontSize: '13px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Skeleton
                        sx={{
                          width: '70px',
                          height: '20px',
                          marginTop: '8px',
                          marginBottom: '7px',
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </td>
            </tr>
          ))}
        </div>
      </SimpleBarReact>
    </Box>
  );
};

export default DeviceListBodySkeleton;
