import { API_URL } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';
import type {
  UserUpdateApiParameter,
  UserUpdateApiResponse,
} from '../types/apis/userUpdateApi';

/**
 * ユーザ更新API 結果コード
 */
export const USER_UPDATE_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_UPDATED: 'SUCCESSFULLY_UPDATED',
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA',
  USER_NOT_FOUND: 'INFO_NO_USER',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
} as const;

/**
 * ユーザ更新エラータイプ
 */
export type USER_UPDATE_ERROR_TYPE =
  (typeof USER_UPDATE_API_RESULT_CODE)[keyof typeof USER_UPDATE_API_RESULT_CODE];

/**
 * ユーザ更新API呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 端末情報取得APIからのレスポンス
 */
/*eslint-disable*/
const callUserUpdateApi = (
  requestParams: UserUpdateApiParameter,
  // accessKey: string,
): Promise<UserUpdateApiResponse> => {
  // (PUT)
  const response = callApiUtil
    .put<UserUpdateApiResponse>(API_URL.USER_UPDATE_API, requestParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return { message: error.message };
    });

  return response;
};

export default callUserUpdateApi;
