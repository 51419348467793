import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';
import type {
  UserInfoApiParameter,
  UserInfoApiResponse,
} from '../types/apis/userInfoApi';

/**
 * User acquisition API result code
 */
export const USER_INFO_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  USER_NOT_FOUND: 'INFO_NO_USER',
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
} as const;

/**
 * ユーザ取得エラータイプ
 */
export type USER_INFO_ERROR_TYPE =
  (typeof USER_INFO_API_RESULT_CODE)[keyof typeof USER_INFO_API_RESULT_CODE];

/**
 * ユーザ取得API呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns ユーザ取得APIからのレスポンス
 */

/*eslint-disable*/
const callUserInfoApi = (
  requestParams: UserInfoApiParameter,
): Promise<UserInfoApiResponse> => {
  // リクエスト送信(POST)
  const response = callApiUtil
    .get<UserInfoApiResponse>(
      `${API_URL.USER_INFO_API}?mailAddress=${
        requestParams.mailAddress
      }&accountStatus=${requestParams.accountStatus.toUpperCase()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callUserInfoApi;
