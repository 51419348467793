import { useSelector } from 'react-redux';
import { RootState, AppDispatch, useAppDispatch } from '../state/store';
import {
  DeviceDetailUpdateState,
  deviceDetailUpdateStateSlice,
} from '../state/slice/deviceDetailUpdateStateSlice';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';

/**
 * 端末情報の更新状態を管理 hooks
 *
 * @returns
 */
// 返す型が複雑すぎるため、ルールを無効化。（長すぎて、逆に読みにくいため）
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDeviceDetailUpdateState = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const updateState = useSelector<RootState, DeviceDetailUpdateState>(
    (state) => state.deviceDetailUpdateState,
  );
  const { saveUpdateState } = deviceDetailUpdateStateSlice.actions;

  const deviceDetailUpdateStateReset = () => {
    dispatch(saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.PENDING));
  };

  return {
    updateState,
    saveUpdateState: (state: DEVICE_DETAIL_UPDATE_STATE) =>
      dispatch(saveUpdateState(state)),
    deviceDetailUpdateStateReset,
  };
};

export default useDeviceDetailUpdateState;
