/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, Box, Typography } from '@mui/material';
import Select, {
  components,
  DropdownIndicatorProps,
  NoticeProps,
  GroupBase,
  OptionProps,
} from 'react-select';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useUserAdd from '../../hooks/useUserAdd';
import usePersonalSettings from '../../hooks/usePersonalSettings';
import type { UserAddInput } from '../../types/inputs/userAddInput';
import { EMAIL_CHECK_PATTERN } from '../../constants/constants';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
import Loader from '../Atoms/Loader';
import { sendGAEvent } from '../../hooks/useGAEvent';
import customStylesMd from '../../utils/customizeReactSelect/customStylesMd';
import type { ModifiedLanguageInfo } from '../../types/apis/languageListApi';

type Props = {
  open: boolean;
  onClose: () => void;
  userAddedSuccessfully: boolean;
  setUserAddedSuccessfully: Dispatch<SetStateAction<boolean>>;
};

const AddUserDialog = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  // ユーザー招待メール送信(ユーザー追加)画面 hooks
  const {
    userAddError,
    isAPISuccess,
    isCallingUserAddApi,
    authList,
    onClickSendButton,
  } = useUserAdd();
  const { localeList } = usePersonalSettings();

  // バリデーションフォーム作成
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<UserAddInput>({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
    mode: 'onChange' || 'onSubmit',
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { open, onClose } = props;

  const emailValue = watch('email')?.toString();
  const userNameValue = watch('userName')?.toString();

  const authListOpt = authList?.map((value) => ({
    value: value.roleCode,
    label: value.roleName,
  }));

  const { setUserAddedSuccessfully } = props;

  useEffect(() => {
    // 画面表示時に権限リストのデフォルト値設定
    let authIdValue = 0;
    if (authList.length > 0) {
      authIdValue = authList[0].roleCode;
    }
    setValue('roleCode', authIdValue);
    // setValueに変更があった場合無限にレンダリングが走ってしまう恐れがあるため除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authList]);

  useEffect(() => {
    if (localeList.length) {
      const localeCode = localeList[0].value;
      setValue('localeCode', localeCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeList]);

  useEffect(() => {
    if (emailValue && userNameValue) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [emailValue, userNameValue]);
  useEffect(() => {
    if (isAPISuccess) {
      setUserAddedSuccessfully(true);
      onClose();
    }
    // eslint-disable-next-line
  }, [isAPISuccess, onClose]);

  const validateUserName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('common.validationError.name.maxLength');
    }

    return true;
  };
  const validateEmail = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('common.validationError.email.maxLength');
    }

    return true;
  };

  const DropdownIndicator = (
    props2: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.DropdownIndicator {...props2}>
      <ArrowDown className={classes.selectArrow} />
    </components.DropdownIndicator>
  );

  const Option = (
    props3: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.Option {...props3}>
      {props3.children}
      {props3.isSelected && <CheckIcon />}
    </components.Option>
  );
  // custom message when there are no options
  const NoOptionsMessage = (
    props4: NoticeProps<unknown, boolean, GroupBase<unknown>>,
  ) => (
    <components.NoOptionsMessage {...props4}>
      {t('common.label.noOptions')}
    </components.NoOptionsMessage>
  );

  return (
    <Dialog
      open={open}
      className={`${classes.userAddDialog} ${'user-add-dialog-res'}`}
    >
      <Box sx={{ margin: '32px' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{
              // width: '88px',
              height: '29px',
              textAlign: 'left',
              fontSize: '20px',
              color: '#313133',
              fontWeight: 'bold',
            }}
          >
            {t('userAdd.viewName')}
          </Typography>

          <IconButton
            disabled={isCallingUserAddApi}
            className={classes.CloseIcon}
            onClick={() => {
              // GA tag
              sendGAEvent('press_btn', 'btn_name', 'exit');

              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onClickSendButton)}>
          <Box
            sx={{
              display: 'flex',
              marginTop: '20px',
            }}
          >
            <Typography
              sx={{
                height: '20px',
                color: '#313133',
                fontSize: '14px',
              }}
            >
              {t('account.label.email')}
            </Typography>
            <Typography
              sx={{
                color: '#E1341E',
                marginLeft: '3px',
              }}
            >
              *
            </Typography>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                validate: validateEmail,
                pattern: {
                  value: EMAIL_CHECK_PATTERN,
                  message: t('common.validationError.email.invalid'),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  value={field.value || ''}
                  fullWidth
                  margin="normal"
                  placeholder={t('userAdd.placeholder.email')}
                  error={Boolean(errors.email)}
                  autoFocus
                  inputProps={{ maxLength: 257 }}
                  // disabled={userAddError.authListErrorType !== null}
                  sx={{
                    marginBottom: '12px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor:
                        errors.email?.types?.required ||
                        errors.email?.types?.pattern ||
                        errors.email?.types?.validate
                          ? '#E1341E !important'
                          : '#939598 !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                      '&.Mui-focused fieldset': {
                        border:
                          errors.email?.types?.required ||
                          errors.email?.types?.pattern ||
                          errors.email?.types?.validate
                            ? '1px solid #E1341E !important'
                            : '1px solid #939598 !important',
                      },
                    },
                  }}
                  className={classes.loginFormInput}
                />
              )}
            />
            {(errors.email?.types?.required ||
              errors.email?.types?.pattern ||
              errors.email?.types?.validate) && (
              <Box
                sx={{
                  bottom: '57px !important',
                }}
                className={classes.errorCard}
              >
                <p style={{ padding: '0', margin: '0' }}>
                  {(errors.email?.types?.required &&
                    t('common.validationError.email.emptyEmail')) ||
                    (errors.email?.types?.validate &&
                      t('common.validationError.email.maxLength')) ||
                    (errors.email?.types?.pattern &&
                      t('common.validationError.email.invalid'))}
                </p>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{
                height: '20px',
                color: '#313133',
                fontSize: '14px',
              }}
            >
              {t('userAdd.label.userName')}
            </Typography>
            <Typography
              sx={{
                color: '#E1341E',
                marginLeft: '3px',
              }}
            >
              *
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Controller
              control={control}
              name="userName"
              rules={{
                required: true,
                minLength: 1,
                validate: validateUserName,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  value={field.value || ''}
                  fullWidth
                  margin="normal"
                  placeholder={t('userAdd.placeholder.userName')}
                  error={Boolean(errors.userName)}
                  // disabled={userAddError.authListErrorType !== null}
                  inputProps={{ maxLength: 257 }}
                  sx={{
                    marginBottom: '12px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor:
                        errors.userName?.types?.required ||
                        errors.userName?.types?.validate
                          ? '#E1341E !important'
                          : '#939598 !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                      '&.Mui-focused fieldset': {
                        border:
                          errors.userName?.types?.required ||
                          errors.userName?.types?.validate
                            ? '1px solid #E1341E !important'
                            : '1px solid #939598 !important',
                      },
                    },
                  }}
                  className={classes.loginFormInput}
                />
              )}
            />
            {(errors.userName?.types?.required ||
              errors.userName?.types?.validate) && (
              <Box
                sx={{
                  bottom: '57px !important',
                }}
                className={classes.errorCard}
              >
                <p style={{ padding: '0', margin: '0' }}>
                  {(errors.userName?.types?.required &&
                    t('common.validationError.name.emptyUserName')) ||
                    (errors.userName?.types?.validate &&
                      errors.userName.message)}
                </p>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', marginBottom: '6px' }}>
            <Typography
              sx={{
                height: '20px',
                color: '#313133',
                fontSize: '14px',
              }}
            >
              {t('userAdd.label.auth')}
            </Typography>
            <Typography
              sx={{
                color: '#E1341E',
                marginLeft: '3px',
              }}
            >
              *
            </Typography>
          </Box>
          <Box sx={{ position: 'relative', marginBottom: '12px' }}>
            <Controller
              name="roleCode"
              render={({ field: { onChange, value, name } }) => (
                <Select
                  placeholder={
                    <Typography sx={{ fontSize: '14px', color: '#939598' }}>
                      {t('common.label.select')}
                    </Typography>
                  }
                  value={authListOpt.find((c) => c.value === value)}
                  name={name}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore:next-line
                  onChange={(selectedOption: ICategory) => {
                    onChange(selectedOption.value);
                    // handleOnChangeAuthId(selectedOption.value);
                    const foundRolePos = authListOpt.findIndex(
                      (obj) => obj.value === selectedOption.value,
                    );

                    // GA tag
                    sendGAEvent(
                      'select_list',
                      'list_number',
                      'select_role_list',
                    );
                    sendGAEvent(
                      'select_list',
                      'select_role_list_value',
                      authListOpt[foundRolePos].label,
                    );
                  }}
                  options={authListOpt}
                  isSearchable={false}
                  components={{ DropdownIndicator, Option, NoOptionsMessage }}
                  className={classes.reactSelect}
                  classNamePrefix="react-select"
                  styles={customStylesMd()}
                />
              )}
              control={control}
            />
            {errors.roleCode?.types?.required && (
              <Box
                sx={{
                  bottom: '45px !important',
                }}
                className={classes.errorCard}
              >
                <p style={{ padding: '0', margin: '0' }}>
                  {errors.roleCode?.types?.required &&
                    t('userAdd.validationError.auth.empty')}
                </p>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', marginBottom: '6px' }}>
            <Typography
              sx={{
                height: '20px',
                color: '#313133',
                fontSize: '14px',
              }}
            >
              {t('personalSetting.label.language')}
            </Typography>
            <Typography
              sx={{
                color: '#E1341E',
                marginLeft: '3px',
              }}
            >
              *
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Controller
              name="localeCode"
              render={({ field: { onChange, value, name } }) => (
                <Select
                  placeholder={
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#939598',
                      }}
                    >
                      {t('common.label.select')}
                    </Typography>
                  }
                  value={localeList.find((c) => c.value === value)}
                  name={name}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore:next-line
                  onChange={(selectedOption: ModifiedLanguageInfo) => {
                    onChange(selectedOption.value);

                    // GA tag
                    sendGAEvent(
                      'select_list',
                      'list_number',
                      'select_language_list',
                    );
                    sendGAEvent(
                      'select_list',
                      'select_language_list_value',
                      selectedOption.value,
                    );
                  }}
                  options={localeList}
                  isSearchable={false}
                  components={{ DropdownIndicator, Option, NoOptionsMessage }}
                  className={classes.reactSelect}
                  classNamePrefix="react-select"
                  styles={customStylesMd()}
                />
              )}
              control={control}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <div className={classes.addUserGoBackButton}>
              <Button
                variant="contained"
                className={classes.userAddButtons}
                style={{ textTransform: 'none' }}
                // disabled={isCallingUserAddApi}
                onClick={() => {
                  // GA tag
                  sendGAEvent('press_btn', 'btn_name', 'back');

                  onClose();
                }}
              >
                {t('userAdd.label.backButton')}
              </Button>
            </div>
            <div className={classes.addUserSubmitButton}>
              <Button
                variant="contained"
                className={classes.userAddButtons}
                style={{
                  textTransform: 'none',
                  opacity:
                    userAddError.authListErrorType !== null ||
                    isCallingUserAddApi ||
                    isButtonDisabled
                      ? '0.5'
                      : '1',
                }}
                type="submit"
                disabled={
                  userAddError.authListErrorType !== null ||
                  isCallingUserAddApi ||
                  isButtonDisabled
                }
              >
                {isCallingUserAddApi ? (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {t('userAdd.label.sendingButton')}
                    <Loader colorCode="#fff" />
                  </Box>
                ) : (
                  t('userAdd.label.sendButton')
                )}
                {/* {t('userAdd.label.sendButton')} */}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
});

export default AddUserDialog;
