import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  fetchManageGroupDeviceList,
  updateManageGroupDeviceList,
} from '../utils/group/manageGroupDeviceList';
import { fetchManageUnGroupDeviceList } from '../utils/group/manageUnGroupDeviceList';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

// import useAccessKey from './useAccessKey';

import {
  DeviceInfo,
  ManageGroupDeviceListResult,
  ManageGroupDeviceListUpdateResult,
} from '../types/Group/manageGroupDeviceList.d';
import {
  MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE,
  MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE,
} from '../apis/callManageGroupDeviceList';
import {
  MANAGE_UNGROUP_DEVICE_LIST_API_ERROR_TYPE,
  MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE,
} from '../apis/callManageUnGroupDeviceList';
import {
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE,
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE,
} from '../apis/callManageGroupDeviceListUpdate';
// import { isUnGroupUserListErrorType } from '../types/apis/apiErrorTypeGuard';
import {
  isUnGroupDeviceListErrorType,
  isGroupDeviceListErrorType,
  isGroupDeviceListUpdateErrorType,
} from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import { SERVER_MESSAGE } from '../constants/constants';
// import useLogout from './useLogout';

type IDParams = {
  groupId: string;
};

const useManageGroupDeviceList = (
  pageNumberForGroup: number,
  searchTextForGroup: '' | string,
  pageNumberForUnGroup: number,
  searchTextForUnGroup: '' | string,
) => {
  // const { accessKey } = useAccessKey();
  const { t } = useSwitchLocaleLanguage();
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();
  // navigate
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState<boolean>(false);
  const [errorTypeUpdate, setErrorTypeUpdate] = useState<string>('');
  const [isCallingGroupDeviceApi, setIsCallingGroupDeviceApi] =
    useState<boolean>(false);
  const [isCallingUnGroupDeviceApi, setIsCallingUnGroupDeviceApi] =
    useState<boolean>(false);

  const [originalDeviceList, setOriginalDeviceList] = useState<DeviceInfo[]>(
    [],
  );
  const [originalUnGroupDeviceList, setOriginalUnGroupDeviceList] = useState<
    DeviceInfo[]
  >([]);

  const [errorTypeGroup, setErrorTypeGroup] = useState<string>('');
  const [errorTypeUnGroup, setErrorTypeUnGroup] = useState<string>('');

  const [moreUser, setMoreUser] = useState<boolean>(false);
  const [moreUnGroupUser, setMoreUnGroupUser] = useState<boolean>(false);
  const [successTypeUpdate, setSuccessTypeUpdate] = useState<string>('');

  const totalGroupDevice = useRef(0);
  const totalUnGroupDevice = useRef(0);
  const { groupId } = useParams<IDParams>();
  const { displaySnackbar } = useSnackbar();
  // Group API Call
  const callManageGroupDeviceListApi = useCallback(() => {
    setIsCallingGroupDeviceApi(true);
    /*eslint-disable*/
    fetchManageGroupDeviceList(
      // accessKey,
      parseInt(groupId!),
      pageNumberForGroup,
      searchTextForGroup,
    )
      .then((result: ManageGroupDeviceListResult) => {
        totalGroupDevice.current = result.totalCount;
        if (
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET ||
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.USER_ROLE_CHANGED
        ) {
          if (
            result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET
          ) {
            setErrorTypeGroup(result.resultCode);
          }
          return;
        }
        if (
          result.resultCode !==
          MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          // Failed to acquire information
          setOriginalDeviceList([]);
          setIsCallingGroupDeviceApi(false);
          setErrorTypeGroup(result.resultCode);
          setMoreUser(false);
          throw result.resultCode;
        }
        if (result.devices != null && originalDeviceList.length === 0) {
          setOriginalDeviceList(result.devices);
          setMoreUser(totalGroupDevice.current < result.devices.length);
          return;
        }
        if (
          result.devices != null &&
          originalDeviceList.length > 0 &&
          !searchTextForGroup &&
          pageNumberForGroup === 1
        ) {
          setOriginalDeviceList(result.devices);
          setMoreUser(totalGroupDevice.current < originalDeviceList.length);

          return;
        }
        if (
          result.devices != null &&
          originalDeviceList.length > 0 &&
          !searchTextForGroup
        ) {
          setOriginalDeviceList((prevDeviceList) => [
            ...prevDeviceList,
            ...result.devices,
          ]);
          setMoreUser(totalGroupDevice.current < originalDeviceList.length);

          return;
        }

        if (searchTextForGroup) {
          if (
            result.devices != null &&
            originalDeviceList.length > 0 &&
            pageNumberForGroup > 1
          ) {
            setOriginalDeviceList((prevDeviceList) => [
              ...prevDeviceList,
              ...result.devices,
            ]);
            setMoreUser(totalGroupDevice.current < originalDeviceList.length);

            return;
          }
          setOriginalDeviceList(result.devices);
          setMoreUser(totalGroupDevice.current < originalDeviceList.length);

          return;
        }
      })
      .catch((error: MANAGE_GROUP_DEVICE_LIST_API_ERROR_TYPE | unknown) => {
        if (isGroupDeviceListErrorType(error)) {
          setErrorTypeGroup(error);
          if (
            error === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH
          ) {
            // displayRedirectDialog({
            //   open: true,
            //   title: t('common.error.notAuthorized'),
            //   message: t('common.error.roleChanged'),
            //   routePath: onClickLogoff,
            // });
          }
          if (
            error ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess'),
            });
          }
          if (
            error === MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup'),
            });
          }
        } else {
          setErrorTypeGroup(
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
      })
      .finally(() => {
        setIsCallingGroupDeviceApi(false);
        setMoreUser(totalGroupDevice.current < originalDeviceList.length);
      });
    /*eslint-disable*/
  }, [pageNumberForGroup, searchTextForGroup]);

  // Group Device Error Handle
  const errorMessageGroupDevice = useMemo((): string => {
    if (!errorTypeGroup) {
      return '';
    }
    switch (errorTypeGroup) {
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_USER:
        return t('manageDevices.message.nUserExist');
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UPDATE_DENIED:
        return t('manageDevices.message.updateFailed');
      case MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP:
        return t('common.error.unavailableGroup');
      default:
        return t('common.error.serverErr');
    }
  }, [errorTypeGroup]);
  // UnGroup API Call
  const callManageUnGroupDeviceListApi = useCallback(() => {
    setIsCallingUnGroupDeviceApi(true);
    /*eslint-disable*/
    fetchManageUnGroupDeviceList(
      // accessKey,
      parseInt(groupId!),
      pageNumberForUnGroup,
      searchTextForUnGroup,
    )
      .then((result: ManageGroupDeviceListResult) => {
        if (
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET ||
          result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.USER_ROLE_CHANGED
        ) {
          if (
            result.resultCode ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET
          ) {
            setErrorTypeUnGroup(result.resultCode);
          }
          return;
        }
        totalUnGroupDevice.current = result.totalCount;
        if (
          result.resultCode !==
          MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          // Failed to acquire information
          setOriginalUnGroupDeviceList([]);
          setIsCallingUnGroupDeviceApi(false);
          setErrorTypeUnGroup(result.resultCode);
          setMoreUnGroupUser(false);
          throw result.resultCode;
        }
        if (result.devices != null && originalUnGroupDeviceList.length === 0) {
          setOriginalUnGroupDeviceList(result.devices);
          setMoreUnGroupUser(
            totalUnGroupDevice.current < result.devices.length,
          );
          return;
        }
        if (
          result.devices != null &&
          originalUnGroupDeviceList.length > 0 &&
          !searchTextForUnGroup &&
          pageNumberForUnGroup === 1
        ) {
          setOriginalUnGroupDeviceList(result.devices);
          setMoreUnGroupUser(
            totalUnGroupDevice.current < originalUnGroupDeviceList.length,
          );

          return;
        }
        if (
          result.devices != null &&
          originalUnGroupDeviceList.length > 0 &&
          !searchTextForUnGroup
        ) {
          setOriginalUnGroupDeviceList((prevUserList) => [
            ...prevUserList,
            ...result.devices,
          ]);
          setMoreUnGroupUser(
            totalUnGroupDevice.current < originalUnGroupDeviceList.length,
          );

          return;
        }

        if (searchTextForUnGroup) {
          if (
            result.devices != null &&
            originalUnGroupDeviceList.length > 0 &&
            pageNumberForUnGroup > 1
          ) {
            setOriginalUnGroupDeviceList((prevUserList) => [
              ...prevUserList,
              ...result.devices,
            ]);
            setMoreUnGroupUser(
              totalUnGroupDevice.current < originalUnGroupDeviceList.length,
            );

            return;
          }
          setOriginalUnGroupDeviceList(result.devices);
          setMoreUnGroupUser(
            totalUnGroupDevice.current < originalUnGroupDeviceList.length,
          );

          return;
        }
      })
      .catch((error: MANAGE_UNGROUP_DEVICE_LIST_API_ERROR_TYPE | unknown) => {
        if (isUnGroupDeviceListErrorType(error)) {
          setErrorTypeUnGroup(error);
        } else {
          setErrorTypeUnGroup(
            MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
      })
      .finally(() => {
        setIsCallingUnGroupDeviceApi(false);
        setMoreUnGroupUser(
          totalUnGroupDevice.current < originalUnGroupDeviceList.length,
        );
      });
    /*eslint-disable*/
  }, [pageNumberForUnGroup, searchTextForUnGroup]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    callManageGroupDeviceListApi();
  }, []);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    callManageUnGroupDeviceListApi();
  }, []);
  // console.log(originalUnGroupDeviceList)

  // call update device api
  const callUpdateGroupDeviceListApi = useCallback(
    (addedUserListPayload, removedUserListPayload) => {
      setIsCallingUpdateApi(true);
      /*eslint-disable*/
      updateManageGroupDeviceList(
        // accessKey,
        parseInt(groupId!),
        addedUserListPayload,
        removedUserListPayload,
      )
        .then((result: ManageGroupDeviceListUpdateResult) => {
          if (
            result.resultCode !==
            MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED
          ) {
            // Failed to acquire information
            setErrorTypeUpdate(result.resultCode);
            throw result.resultCode;
          }
          setErrorTypeUpdate(result.resultCode);
          setSuccessTypeUpdate(result.resultCode);
          displaySnackbar({
            message: t('manageDevices.message.groupDeviceUpdated'),
            timeout: 3002,
          });
        })
        .catch(
          (error: MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE | unknown) => {
            if (isGroupDeviceListUpdateErrorType(error)) {
              setErrorTypeUpdate(error);
              if (
                error ===
                MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH
              ) {
                // displayRedirectDialog({
                //   open: true,
                //   title: t('common.error.notAuthorized'),
                //   message: t('common.error.roleChanged'),
                //   routePath: onClickLogoff,
                // });
              }
              if (
                error ===
                MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED
              ) {
                displayRedirectDialog({
                  open: true,
                  title: t('common.error.notAuthorized'),
                  message: t('common.error.unauthorizedAccess'),
                });
              }
              if (
                error ===
                MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP
              ) {
                displayRedirectDialog({
                  open: true,
                  title: t('common.error.invalidGroup'),
                  message: t('common.error.unavailableGroup'),
                });
              }
            } else {
              setErrorTypeUpdate(
                MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
              );
            }
          },
        )
        .finally(() => {
          setIsCallingUpdateApi(false);
          setMoreUser(totalGroupDevice.current < originalDeviceList.length);
        });
    },
    [],
  );

  // Error handle for Group Device Update API
  const errorMessageGroupDeviceUpdate = useMemo((): string => {
    if (!errorTypeUpdate) {
      return '';
    }
    switch (errorTypeUpdate) {
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM:
        return t('groupDetail.error.invalidURL');
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP:
        return t('common.error.unavailableGroup');
      case MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED:
        return t('manageDevices.message.failedUpdateDevice');
      default:
        return t('common.error.serverErr');
    }
  }, [errorTypeUpdate]);

  return {
    moreUser,
    moreUnGroupUser,
    t,
    totalGroupDevice: totalGroupDevice.current,
    totalUnGroupDevice: totalUnGroupDevice.current,
    isCallingGroupDeviceApi,
    isCallingUnGroupDeviceApi,
    originalDeviceList,
    originalUnGroupDeviceList,
    setOriginalDeviceList,
    setOriginalUnGroupDeviceList,
    callManageUnGroupDeviceListApi,
    callManageGroupDeviceListApi,
    errorTypeGroup,
    errorTypeUnGroup,
    callUpdateGroupDeviceListApi,
    isCallingUpdateApi,
    errorTypeUpdate,
    errorMessageGroupDevice,
    errorMessageGroupDeviceUpdate,
    successTypeUpdate,
  };
};

export default useManageGroupDeviceList;
