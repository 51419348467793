/* eslint-disable import/prefer-default-export */
import callUserListApi, {
  USER_LIST_API_ERROR_TYPE,
  USER_LIST_API_RESULT_CODE,
} from '../../apis/callUserListApi';
import {
  isErrorType,
  // isUserListErrorType,
} from '../../types/apis/apiErrorTypeGuard';
import { UserListApiResponse } from '../../types/apis/userListApi.d';
import {
  UserListResult,
  UserInfoProsessResultState,
  USER_INFO_PROSESS_RESULT_CODE,
  USER_INFO_PROSESS_RESULT_TYPE,
} from '../../types/user/userList.d';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';

/**
 * ユーザー一覧取得APIにリクエスト送信して、一覧を取得
 *
 * @param accessKey アクセスキー
 * @returns
 */
export const fetchUserList = async (
  rowLimitPerPage: number,
  currentPageNumber: number,
  sortDirection: string,
  searchText: string,
  colName: string,
): Promise<UserListResult> => {
  // ユーザー一覧取得API呼び出し
  try {
    const apiResponse: UserListApiResponse = await callUserListApi(
      rowLimitPerPage,
      currentPageNumber,
      sortDirection,
      searchText,
      colName,
    );

    switch (apiResponse.message) {
      /*eslint-disable*/
      // 成功

      case SERVER_MESSAGE.FETCH_OK:
        return {
          message: SERVER_MESSAGE.FETCH_OK,
          details: apiResponse?.details,
        };
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        return {
          message: SERVER_MESSAGE.USER_ROLE_CHANGED,
          details: apiResponse?.details,
        };
      // 失敗
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      // case SERVER_MESSAGE.USER_ROLE_CHANGED:
      //   throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
        throw SERVER_MESSAGE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST、ログイン認証エラー以外はすべてERR_UNKNOWN扱い
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      details: { totalUserCount: 0, totalUserCountPerPage: 0, users: [] },
    };
  }
};

/**
 * 指摘された state からユーザー情報操作結果(招待メール送信 or ユーザー情報更新)を取得
 * 存在しない場合は一律「操作なし」とする
 *
 * @param state
 * @returns
 */
export const fetchUserInfoProcessResultFromState = (
  state: UserInfoProsessResultState | unknown,
): USER_INFO_PROSESS_RESULT_TYPE => {
  if (!state) {
    return USER_INFO_PROSESS_RESULT_CODE.NONE;
  }

  const userInfoProcState = state as UserInfoProsessResultState;

  return userInfoProcState.userInfoProcessResult;
};
