import { AxiosError } from 'axios';

import { API_URL, SERVER_MESSAGE } from '../constants/constants';
import type {
  //   ReInviteApiRequestParam,
  ReInviteApiResponse,
} from '../types/apis/reInviteApi';
import callApiUtil from '../utils/callApiUtil';

/*eslint-disable*/
const callReInviteApi = (mailAddress: string): Promise<ReInviteApiResponse> => {
  // Send request (GET)
  const data = { mailAddress };
  const response = callApiUtil
    .put<ReInviteApiResponse>(`${API_URL.RE_INVITE_API}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => {
      if (error.response?.data) {
        return error.response?.data;
      } else if (error.request) {
        return {
          message: SERVER_MESSAGE.NO_INTERNET,
        };
      }
    });

  return response;
};

export default callReInviteApi;
