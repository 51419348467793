import { Skeleton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

const NotificationsActivitySkeleton = (): JSX.Element => (
  <>
    {Array.from({ length: 5 }, (_, k) => (
      <List
        key={k}
        sx={{
          mr: '15px',
        }}
      >
        <ListItem>
          <ListItemAvatar
            sx={{
              marginTop: '-20px',
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Skeleton
                variant="text"
                sx={{ fontSize: '14', width: '230px' }}
              />
            }
            secondary={
              <>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14', width: '285px' }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: '10',
                    // lineHeight: '24px',
                    width: '56px',
                    // marginBottom: '10px',
                  }}
                />
              </>
            }
          />
        </ListItem>
      </List>
    ))}
  </>
);

export default NotificationsActivitySkeleton;
