import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { NotificationGetApiResponse } from '../types/apis/notificationGetApi';

const callNotificationGetApi = (
  rowNumber: number,
  pageNumber: number,
  searchText: string,
  readFlag: boolean,
): Promise<NotificationGetApiResponse> => {
  const response = callApiUtil
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    .get<NotificationGetApiResponse>(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${API_URL.NOTIFICATION_GET}?rowLimitPerPage=${rowNumber}&currentPageNumber=${pageNumber}&searchText=${searchText}&readFlag=${readFlag}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    /* eslint-disable */
    .catch((error: AxiosError) => error.response?.data);

  return response;
};

export default callNotificationGetApi;
