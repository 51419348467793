import type {
  ExportDataApiRequestParameter,
  ExportDataApiResponse,
} from '../types/apis/exportDataApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * Eula Update Api
 *
 * @param requestParams request parameter
 * @returns
 */
const callExportLogDataApi = (
  requestParams: ExportDataApiRequestParameter,
): Promise<ExportDataApiResponse> => {
  // Request parameter
  const jsonParams = {
    /*eslint-disable*/
    corpId: requestParams.corpId,
    from: requestParams.from,
    to: requestParams.to,
    // eslint-enabled
  };
  // (POST)
  /*eslint-disable*/
  const response = callApiUtil
    .get<ExportDataApiResponse>(
      `${API_URL.LOG_DATA_DOWNLOAD_API}?corp_id=${jsonParams.corpId}&from=${jsonParams.from}&to=${jsonParams.to}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return error.message;
    });

  return response;
};

export default callExportLogDataApi;
