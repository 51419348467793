import type {
  DeviceLostModeDisableApiRequestParam,
  DeviceLostModeDisableResponse,
} from '../types/apis/deviceLostModeDisableApi';
import { API_URL } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';

export const DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE = {
  OK: 'OK',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  LOST_MODE_ALREADY_DISABLED: 'LOST_MODE_ALREADY_DISABLED',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
} as const;

export type DEVICE_LOST_MODE_DISABLE_ERROR_TYPE =
  (typeof DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE)[keyof typeof DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE];

const callDeviceLodtModeDisenableApi = (
  // accessKey: string,
  requestParams: DeviceLostModeDisableApiRequestParam,
): Promise<DeviceLostModeDisableResponse> => {
  /*eslint-disable*/
  const jsonParams = {
    imei: requestParams.imei,
    lockStatus: requestParams.lockStatus,
  };

  const response = callApiUtil
    .post<DeviceLostModeDisableResponse>(
      API_URL.DEVICE_LOST_MODE_DISABLE,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callDeviceLodtModeDisenableApi;
