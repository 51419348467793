import { useState } from 'react';
import {
  NotificationGetApiResponse,
  NotificationDetails,
} from '../types/apis/notificationGetApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import { fetchNotificationList } from '../utils/notification/fetchNotificationList';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import callNotificatioReadApi from '../apis/callNotificationReadApi';
import { NotificationReadApiResponse } from '../types/apis/notificationReadApi.d';

// eslint-disable-next-line no-shadow
enum NotificationCategory {
  activity = 'Activity',
  announcements = 'Announcement',
}

const useNotificationList = () => {
  const [isCallingApi, setIsCallingApi] = useState(false);
  const [notificationDataFromApi, setNotificationDataFromApi] = useState<
    NotificationDetails[]
  >([]);
  const [isCallingReadApi, setIsCallingReadApi] = useState(false);

  const [errorType, setErrorType] = useState('');
  const [categoryText, setCategoryText] = useState<NotificationCategory>(
    NotificationCategory.activity,
  );
  const [readflag, setReadFlag] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [endOfPage, setEndOfPage] = useState<boolean>(false);
  const [morePage, setMorePage] = useState(false);

  const rowLimit = 100;

  // Category Text change
  const changeActivityCategory = (status: boolean) => {
    if (status) {
      setCategoryText(NotificationCategory.activity);
    } else {
      setCategoryText(NotificationCategory.announcements);
    }
  };

  const callNotificationGetApi = () => {
    setIsCallingApi(true);
    fetchNotificationList(rowLimit, currentPage, categoryText, readflag)
      .then((result: NotificationGetApiResponse) => {
        if (result.details.notificationDetails.length === rowLimit) {
          setMorePage(true);
        } else {
          setMorePage(false);
        }
        if (result.message === SERVER_MESSAGE.FETCH_OK) {
          if (currentPage === 0) {
            setNotificationDataFromApi(result.details.notificationDetails);
          } else if (
            currentPage > 0 &&
            result.details.notificationDetails.length > 0
          ) {
            setNotificationDataFromApi([
              ...notificationDataFromApi,
              ...result.details.notificationDetails,
            ]);
          } else {
            setEndOfPage(true);
          }
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setErrorType(error);
        } else {
          setErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        }
      })
      .finally(() => {
        setIsCallingApi(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const callReadNotificationApi = (notificationId: number) => {
    setIsCallingReadApi(true);
    callNotificatioReadApi(notificationId)
      .then((result: NotificationReadApiResponse) => {
        if (result.message === SERVER_MESSAGE.UPDATE_OK) {
          //   return;
        }
      })
      .finally(() => {
        setIsCallingReadApi(false);
      });
  };

  return {
    callNotificationGetApi,
    notificationDataFromApi,
    changeActivityCategory,
    isCallingApi,
    errorType,
    setCurrentPage,
    currentPage,
    endOfPage,
    callReadNotificationApi,
    morePage,
    isCallingReadApi,
    readflag,
    setReadFlag,
    categoryText,
    setIsCallingApi,
  };
};

export default useNotificationList;
