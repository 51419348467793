import React from 'react';
import { Grid, Typography } from '@mui/material';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as NoDataIcon } from '../../images/icon/reports-no-data-icon.svg';

type Props = {
  searchText: string;
};
const ReportsNoDataCard = React.memo<Props>((searchText): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();

  return (
    <Grid container className={classes.noGroupDeviceContainer}>
      <>
        <NoDataIcon />
        <Typography className={classes.tableTransHistoryNoDataTextOne}>
          {t('common.error.noData')}
        </Typography>
        {searchText.searchText && (
          <Typography className={classes.tableTransHistorySearchText}>
            {t('common.error.tryAgain')}
          </Typography>
        )}
      </>
    </Grid>
  );
});

export default ReportsNoDataCard;
