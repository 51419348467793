import { useState } from 'react';
// import useAccessKey from './useAccessKey';
import { useNavigate } from 'react-router-dom';
import {
  DEVICE_LOST_MODE_ENABLE_ERROR_TYPE,
  DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE,
} from '../apis/callDeviceLostModeEnable';
import { lostModeEnable } from '../utils/devicelist/deviceLostModeEnable';
import {
  DeviceLostModeEnableApiRequestParam,
  DeviceLostModeEnableApiResponse,
} from '../types/apis/deviceLostModeEnableApi.d';
import useSnackbar from './useSnackbar';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import { PAGE_PATH_NAME, SERVER_MESSAGE } from '../constants/constants';

const useDeviceLostModeEnable = () => {
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [errorType, setErrorType] =
    useState<DEVICE_LOST_MODE_ENABLE_ERROR_TYPE | null>(null);
  // const { accessKey } = useAccessKey();
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();
  const { saveUpdateState } = useDeviceDetailUpdateState();
  const navigate = useNavigate();

  const onClickDeviceLostModeEnable = (
    imei: string,
    pin: string,
    lockStatus: boolean,
  ): void => {
    const requestParam: DeviceLostModeEnableApiRequestParam = {
      imei,
      pin,
      lockStatus,
    };
    // loading Start
    setIsCallingApi(true);

    /*eslint-disable*/
    lostModeEnable(requestParam).then(
      (result: DeviceLostModeEnableApiResponse) => {
        setErrorType(result.resultCode);
        if (result.resultCode === DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.OK) {
          setIsCallingApi(false);
          displaySnackbar({
            message: t('lostMode.message.activationSuccess'),
            timeout: 3001,
          });
          saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.SUCCESS);
          return;
        }
        if (
          result.resultCode ===
          DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.DEVICE_IN_LOST_MODE
        ) {
          setIsCallingApi(false);
          setErrorType(result.resultCode);
          displaySnackbar({
            message: t('lostMode.message.deviceAlreadyLostMode'),
            type: 'error',
            timeout: 3001,
          });
          return;
        }
        if (
          result.resultCode ===
          DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED
        ) {
          setIsCallingApi(false);
          setErrorType(result.resultCode);
          displaySnackbar({
            message: t('deviceInfo.devicePermissionApiError'),
            type: 'error',
          });
          navigate(PAGE_PATH_NAME.DEVICE_LIST);
          return;
        }
        if (
          result.resultCode === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode === SERVER_MESSAGE.USER_ROLE_CHANGED ||
          result.resultCode === SERVER_MESSAGE.INVALID_TOKEN ||
          result.resultCode === SERVER_MESSAGE.EXPIRED_TOKEN
        ) {
          setIsCallingApi(false);
          return;
        }
        setIsCallingApi(false);
        displaySnackbar({
          message: t('lostMode.message.activationFailed'),
          type: 'error',
          timeout: 3001,
        });
      },
    );
  };
  return {
    isCallingApi,
    onClickDeviceLostModeEnable,
    errorType,
  };
};

export default useDeviceLostModeEnable;
