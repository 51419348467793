// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process?.env?.REACT_APP_FIREBASE,
  projectId: process?.env?.REACT_APP_FIREBASE_PROJECT_ID,
};
// Initialize Firebase
export const fireBaseApp = initializeApp(firebaseConfig);

export const fireBaseAuth = getAuth(fireBaseApp);

export const fireBaseDB = getFirestore(fireBaseApp);
