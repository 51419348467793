import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { factoryReset } from '../utils/remote/factoryReset';
// import useAccessKey from './useAccessKey';
// import type { FactoryResetApiResponse } from '../types/apis/remoteFeatureFactoryReset.d';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

// import type { featureSetupDataType } from '../types/remote/featureSetupData';
import {
  FACTORY_RESET_API_ERROR_TYPE,
  FACTORY_RESET_API_RESULT_CODE,
} from '../apis/callRemoteFeatureFactoryReset';
import { isFactoryResetErrorType } from '../types/apis/apiErrorTypeGuard';
// import RedirectDialog from '../components/Modecules/RedirectDialog';
import useSnackbar from './useSnackbar';
// import useRedirectDialog from './useRedirectDialog';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import { PAGE_PATH_NAME } from '../constants/constants';

const useFactoryReset = () => {
  // const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  // const { displayRedirectDialog } = useRedirectDialog();
  const { t } = useSwitchLocaleLanguage();

  const [isCallingFactoryResetApi, setIsCallingFactoryResetApi] =
    useState<boolean>(false);
  // const factoryResetResult = useRef<FactoryResetApiResponse| null>();
  const [factoryResetErrorType, setFactoryResetErrorType] =
    useState<FACTORY_RESET_API_ERROR_TYPE | null>(null);
  // const { accessKey } = useAccessKey();

  // const onClickGroupList = () => {
  //   navigate(`/groups`, { replace: true });
  // };

  const { saveUpdateState } = useDeviceDetailUpdateState();

  const navigate = useNavigate();

  const onClickFactoryReset = (imei: number): void => {
    setIsCallingFactoryResetApi(true);
    factoryReset(imei)
      .then((result) => {
        switch (result.resultCode) {
          case FACTORY_RESET_API_RESULT_CODE.OK: {
            // Success Message
            setFactoryResetErrorType(result.resultCode);
            displaySnackbar({
              message: t('lostMode.message.factoryInit'),
              type: 'success',
              timeout: 3001,
            });
            saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.SUCCESS);
            break;
          }
          case FACTORY_RESET_API_RESULT_CODE.DEVICE_RESTORE_INITIATED: {
            // Success Message
            setFactoryResetErrorType(result.resultCode);
            displaySnackbar({
              message: t('lostMode.message.alreadyFactoryRestored'),
              type: 'success',
              timeout: 3002,
            });
            break;
          }
          case FACTORY_RESET_API_RESULT_CODE.ACCESS_PERMISSION_DENIED: {
            // Permission Denied
            setFactoryResetErrorType(result.resultCode);
            displaySnackbar({
              message: t('deviceInfo.devicePermissionApiError'),
              type: 'error',
            });
            navigate(PAGE_PATH_NAME.DEVICE_LIST);
            break;
          }
          default:
            displaySnackbar({
              message: t('lostMode.message.failedFactoryRestored'),
              type: 'error',
              timeout: 3002,
            });
            setFactoryResetErrorType(result.resultCode);
            throw result.resultCode;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingFactoryResetApi(false);
      })
      .catch((error: FACTORY_RESET_API_ERROR_TYPE | unknown) => {
        if (isFactoryResetErrorType(error)) {
          setFactoryResetErrorType(error);
        } else {
          setFactoryResetErrorType(FACTORY_RESET_API_RESULT_CODE.ERR_UNKNOWN);
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingFactoryResetApi(false);
      });
  };

  // const errorMessageRemoteFeatureSetupInfo = useMemo((): string => {
  //   if (!factoryResetErrorType) {
  //     return '';
  //   }
  //   switch (factoryResetErrorType) {
  //     case FACTORY_RESET_API_RESULT_CODE.INFO_NOTHING_KEY:
  //       return 'You are Not Authorized for access.';
  //     case FACTORY_RESET_API_RESULT_CODE.WARN_NO_AUTH_LIST:
  //       return 'URL not valid.';
  //     default:
  //       return 'Server Error';
  //   }
  // }, [factoryResetErrorType]);

  /*eslint-disable*/
  // useEffect(() => {
  //   onClickRemoteFeatureSetupInfo();
  // }, []);

  return {
    isCallingFactoryResetApi,
    factoryResetErrorType,
    onClickFactoryReset,
    // onClickGroupList,
  };
};

export default useFactoryReset;
