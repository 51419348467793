/* eslint-disable import/prefer-default-export */
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式の文字列をDate型に変換して返す
 *
 * @param dateStr Date型に変換したい文字列
 * @returns Date
 */
export const convertISO8601StringToDate = (dateStr: string): Date =>
  utcToZonedTime(dateStr, 'UTC');

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式のDate型をyyyy-MM-dd'T'HH:mm:ssXXX形式の文字列に変換して返す
 * @param date
 * @returns
 */
export const convertISO8601DateToString = (date: Date): string =>
  formatTZ(date, `yyyy-MM-dd'T'HH:mm:ssXXX`, {
    timeZone: 'UTC',
  });

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式のDate型をyyyy-MM-01'T'00:00:00XXX 形式の文字列に変換して返す
 * @param date
 * @returns
 */
export const convertISO8601DateToString0100 = (date: Date): string =>
  formatTZ(date, `yyyy-MM-01'T'00:00:00XXX`, {
    timeZone: 'UTC',
  });

/**
 * Date型の日付を yyyy-MM-dd'T'00:00:00Z(UTC) 形式の文字列で返す
 *
 * @param date 文字列に変換したい日付
 * @returns yyyy-MM-dd'T'00:00:00Z(UTC) 形式の文字列
 */
export const convertDateToStringyyyyMMddT00 = (date: Date): string => {
  const year = date.getFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getMonth() + 1}`.slice(-2);
  // 1桁の日付の場合10の位を0埋めする
  const day = `00${date.getDate()}`.slice(-2);

  return formatTZ(
    new Date(`${year}-${month}-${day}T00:00:00`),
    `yyyy-MM-dd'T'HH:mm:ssXXX`,
    { timeZone: 'UTC' },
  );
};

/**
 * yyyy-MM-dd'T'00:00:00Z 形式の現在日(UTC)を取得
 *
 * @returns
 */
export const fetchTodayUTCyyyyMMddT00 = (): Date =>
  utcToZonedTime(convertDateToStringyyyyMMddT00(new Date()), 'UTC');

/**
 * Date型の日付を yyyy-MM-01'T'00:00:00Z(UTC) 形式の文字列で返す
 *
 * @param date 文字列に変換したい日付
 * @returns yyyy-MM-01'T'00:00:00Z(UTC) 形式の文字列
 */
export const convertDateToStringyyyyMM01T00 = (date: Date): string => {
  const year = date.getUTCFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getUTCMonth() + 1}`.slice(-2);

  return formatTZ(
    new Date(`${year}-${month}-01T00:00:00`),
    `yyyy-MM-dd'T'HH:mm:ssXXX`,
    { timeZone: 'UTC' },
  );
};

/**
 * yyyy-MM-01'T'00:00:00Z 形式の現在日(UTC)を取得
 *
 * @returns 現在日(UTC)
 */
export const fetchTodayUTCyyyyMM01T00 = (): Date =>
  utcToZonedTime(convertDateToStringyyyyMM01T00(new Date()), 'UTC');

/**
 * Date型の日付を yyyy-MM-dd'T'23:59:59Z(UTC) 形式の文字列で返す
 *
 * @param date
 * @returns
 */
export const convertDateToStringyyyyMMddT235959 = (date: Date): string => {
  const year = date.getFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getMonth() + 1}`.slice(-2);
  // 1桁の日付の場合10の位を0埋めする
  const day = `00${date.getDate()}`.slice(-2);

  return formatTZ(
    new Date(`${year}-${month}-${day}T23:59:59`),
    `yyyy-MM-dd'T'HH:mm:ssXXX`,
    { timeZone: 'UTC' },
  );
};

/**
 * 渡された日付文字列をyyyy-MM-01'T'00:00:00Z(UTC)のDate型に変換して返す
 *
 * @param dateStr 変換する日付文字列
 * @returns yyyy-MM-01に変換した日付
 */
export const convertStrToDateyyyyMM01T00 = (dateStr: string): Date => {
  // 指定された文字列をDate型に変換
  const date = convertISO8601StringToDate(dateStr);
  const year = date.getFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getMonth() + 1}`.slice(-2);

  return utcToZonedTime(`${year}-${month}-01T00:00:00Z`, 'UTC');
};

/**
 * 渡された日付文字列をyyyy-MM-dd'T'00:00:00Z(UTC)のDate型に変換して返す
 *
 * @param dateStr 変換する日付文字列
 * @returns yyyy-MM-01に変換した日付
 */
export const convertStrToDateyyyyMMddT00 = (dateStr: string): Date => {
  // 指定された文字列をDate型に変換
  const date = convertISO8601StringToDate(dateStr);
  const year = date.getFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getMonth() + 1}`.slice(-2);
  // 1桁の日付の場合10の位を0埋めする
  const day = `00${date.getDate()}`.slice(-2);

  return utcToZonedTime(`${year}-${month}-${day}T00:00:00Z`, 'UTC');
};

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式の文字列を yyyy-MM-dd HH:mm:ss(UTC) 形式の文字列に変換して返す
 * 指定された日付文字列が空だった場合は空文字を返却
 *
 * @param dateStr 変換する日付文字列
 * @returns yyyy-MM-dd HH:mm:ss(UTC) 形式に変換した日付
 */
export const convertStrToStringyyyyMMddHHmmss = (dateStr: string): string => {
  if (!dateStr) {
    return '';
  }
  // 指定された文字列をDate型に変換
  const date = convertISO8601StringToDate(dateStr);
  const year = date.getFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getMonth() + 1}`.slice(-2);
  // 1桁の日付の場合10の位を0埋めする
  const day = `00${date.getDate()}`.slice(-2);
  // 時間(1桁の場合10の位を0埋めする)
  const hours = `00${date.getHours()}`.slice(-2);
  const minutes = `00${date.getMinutes()}`.slice(-2);
  const seconds = `00${date.getSeconds()}`.slice(-2);

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式の文字列をyyyy/MM/dd形式の文字列にして返す
 * 指定された日付文字列が空だった場合は空文字を返却
 *
 * @param dateStr 変換したい日付文字列
 * @returns yyyy/MM/dd形式(UTC)に変換した日付
 */
export const convertISO8601StrToyyyyMMdd = (dateStr: string): string => {
  if (!dateStr) {
    return '';
  }
  const date = convertISO8601StringToDate(dateStr);
  const year = date.getFullYear();
  // getMonth()で取得されるのは0-11の数字のためプラス1し、1桁の月の場合10の位を0埋めする
  const month = `00${date.getMonth() + 1}`.slice(-2);
  // 1桁の日付の場合10の位を0埋めする
  const day = `00${date.getDate()}`.slice(-2);

  return `${year}/${month}/${day}`;
};
