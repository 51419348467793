import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import {
  UpdateCorporateDetailsApiRequest,
  UpdateCorporateDetailsApiResponse,
} from '../types/apis/corporateDetailsApi.d';

/**
 * Update corporate details API Result Code
 */
export const UPDATE_CORPORATE_DETAILS_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_UPDATED: 'SUCCESSFULLY_UPDATED',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // User does not have access
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Input parameter not correct
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // No corporation found with the ID
  INFO_NO_CORPORATION: 'INFO_NO_CORPORATION',
  USER_IS_NOT_ADMIN: 'USER_IS_NOT_ADMIN',
  // Internal server side error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * Update corporate details error type
 */
export type UPDATE_CORPORATE_DETAILS_API_ERROR_TYPE =
  (typeof UPDATE_CORPORATE_DETAILS_API_RESULT_CODE)[keyof typeof UPDATE_CORPORATE_DETAILS_API_RESULT_CODE];

/**
 * update corporate details API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callUpdateCorporateDetailsApi = (
  requestParams: UpdateCorporateDetailsApiRequest,
): Promise<UpdateCorporateDetailsApiResponse> => {
  // Send request (POST)

  const response = callApiUtil
    .put<UpdateCorporateDetailsApiResponse>(
      API_URL.UPDATE_CORPORATE_DETAILS,
      requestParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
    }));

  return response;
};

export default callUpdateCorporateDetailsApi;
