import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { UserListApiResponse } from '../types/apis/userListApi';
import { API_URL } from '../constants/constants';
// import { Response } from '../helper/error';
/**
 * ユーザ一覧取得API 結果コード
 */
export const USER_LIST_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  NO_INTERNET: 'NO_INTERNET',
} as const;

/**
 * User list acquisition error type
 */
export type USER_LIST_API_ERROR_TYPE =
  (typeof USER_LIST_API_RESULT_CODE)[keyof typeof USER_LIST_API_RESULT_CODE];

/*eslint-disable*/
const callUserListApi = (
  rowLimitPerPage: number,
  currentPageNumber: number,
  sortDirection: string,
  searchText: string,
  colName: string,
): Promise<UserListApiResponse> => {
  const response = callApiUtil
    .get<UserListApiResponse>(
      `${
        API_URL.USER_LIST_API
      }?rowLimitPerPage=${rowLimitPerPage}&currentPageNumber=${currentPageNumber}&sortDirection=${sortDirection}&searchText=${encodeURIComponent(
        searchText,
      )}&colName=${colName}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    // .catch((error: Error) => getErrorResponse(error));
    .catch((error: AxiosError) => {
      if (error.response?.data) {
        return error.response?.data;
      } else {
        return error;
      }
    });

  return response;
};

export default callUserListApi;
