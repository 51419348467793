import { Skeleton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const NotificationsAnnouncementSkeleton = (): JSX.Element => (
  <>
    {Array.from({ length: 5 }, (_, k) => (
      <List
        key={k}
        sx={{
          mr: '15px',
        }}
      >
        <ListItem>
          <ListItemText
            primary={
              <Skeleton
                variant="text"
                sx={{ fontSize: '14', width: '250px' }}
              />
            }
            secondary={
              <>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '14', width: '300px' }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: '10',
                    width: '65px',
                  }}
                />
              </>
            }
          />
        </ListItem>
      </List>
    ))}
  </>
);

export default NotificationsAnnouncementSkeleton;
