import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { NumberOfTranslationsApiResponse } from '../types/apis/numberOfTranslationsApi.d';

export const NUMBER_OF_TRANSLATIONS_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  INFO_NO_DATA: 'INFO_NO_DATA',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  NO_INTERNET: 'NO_INTERNET',
} as const;

export type NUMBER_OF_TRANSLATIONS_ERROR_TYPE =
  (typeof NUMBER_OF_TRANSLATIONS_API_RESULT_CODE)[keyof typeof NUMBER_OF_TRANSLATIONS_API_RESULT_CODE];

const callNumberOfTranslationsApi = (
  dateRange: {
    firstDay: string;
    lastDay: string;
  },
  yearFlag: boolean,
): Promise<NumberOfTranslationsApiResponse> => {
  const response = callApiUtil
    .get<NumberOfTranslationsApiResponse>(
      `${API_URL.DASHBOARD_NUMBER_OF_TRANSLATIONS}?from=${
        dateRange.firstDay
      }&to=${dateRange.lastDay}&type=${yearFlag ? 'YEAR' : 'NOTYEAR'}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    /* eslint-disable */
    .catch((error: AxiosError) => {
      if (error.response?.data) {
        return error.response?.data;
      } else if (error.request) {
        return {
          message: NUMBER_OF_TRANSLATIONS_API_RESULT_CODE.NO_INTERNET,
        };
      }
    });

  return response;
};

export default callNumberOfTranslationsApi;
