/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import SimpleBarReact from 'simplebar-react';
import { VariableSizeList as VariableListWindow } from 'react-window';
import pixelWidth from 'string-pixel-width';
// import { styled, withStyles } from '@mui/material/styles';
import { Box, Typography, Tooltip } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { useStyles } from './DialogStyle';
import { ReactComponent as FilterIcon } from '../../images/icon/Filter_alt_light.svg';
import { ReactComponent as CheckMarkAddUser } from '../../images/icon/check_mark_add_user.svg';
import { ReactComponent as CheckMarkCross } from '../../images/icon/check_mark_cross.svg';
import { ReactComponent as GroupDevice } from '../../images/icon/no-device-latest.svg';
import { ReactComponent as CloseWhiteIcon } from '../../images/icon/cross-white.svg';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckBoxDisable } from '../../images/icon/checkbox_disable.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import ManageGroupSearch from '../Modecules/ManageGroupSearch';
import useManageGroupDeviceList from '../../hooks/useManageDevice';
import ManageDeviceSkeleton from './ManageDeviceSkeleton';
import useAccountInfo from '../../hooks/useAccountInfo';
import { DeviceInfo } from '../../types/Group/manageGroupDeviceList.d';
import { ReactComponent as ActiveIcon } from '../../images/icon/active-dot.svg';
import { MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE } from '../../apis/callManageGroupDeviceList';
import { MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE } from '../../apis/callManageUnGroupDeviceList';
// import WarningDialog from '../Atoms/WarningDialog';
import { MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE } from '../../apis/callManageGroupDeviceListUpdate';
import { sendGAEvent } from '../../hooks/useGAEvent';
// import { MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE } from '../../apis/callManageGroupDeviceListUpdate';
import { FEATURE_PERMISSION, regexJapanese } from '../../constants/constants';
import { LOCALE_CODE } from '../../hooks/useLocale';

type Props = {
  onClose: () => void;
  isManageDeviceActive: boolean;
  setAddedDeviceListPayload: React.Dispatch<React.SetStateAction<number[]>>;
  setRemovedDeviceListPayload: React.Dispatch<React.SetStateAction<number[]>>;
};

type TooltipProps = {
  title: string;
  children: React.ReactElement;
  dataWidth: number;
  divWidth: number;
};
const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};
const StyledTooltip = React.memo<TooltipProps>((props): JSX.Element => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={props.title}
      disableHoverListener={props.dataWidth <= props.divWidth}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        onClick: handleTooltipClick,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          className: classes.tooltipStyle,
        },
      }}
    >
      {props.children}
    </Tooltip>
  );
});

const ManageDeviceDialog = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  const { accountInfo } = useAccountInfo();
  const [isError, setIsError] = useState(false);
  const [forServerErrorMsg, setForServerErrorMsg] = useState(false);
  const [pageNumberForGroup, setPageNumberForGroupDevice] = useState(1);
  const [pageNumberForUnGroup, setPageNumberForUngroupDevice] = useState(1);
  const [searchTextForGroup, setSearchTextForGroupDevice] =
    useState<string>('');
  const [searchTextForUnGroup, setSearchTextForUngroupDevice] =
    useState<string>('');
  const [
    firstTimeFilterRenderForUngroupDevice,
    setFirstTimeFilterRenderForUngroupDevice,
  ] = useState<boolean>(true);

  const [
    firstTimeFilterRenderGroupedDevice,
    setFirstTimeFilterRenderGroupedDevice,
  ] = useState<boolean>(true);

  const {
    errorTypeGroup,
    errorTypeUnGroup,
    t,
    isCallingGroupDeviceApi,
    isCallingUnGroupDeviceApi,
    originalDeviceList,
    originalUnGroupDeviceList,
    totalUnGroupDevice,
    totalGroupDevice,
  } = useManageGroupDeviceList(
    pageNumberForGroup,
    searchTextForGroup,
    pageNumberForUnGroup,
    searchTextForUnGroup,
  );
  const {
    onClose,
    isManageDeviceActive,
    setAddedDeviceListPayload,
    setRemovedDeviceListPayload,
  } = props;

  const observer = useRef<IntersectionObserver | null>(null);

  const lastUngroupDeviceElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          totalUnGroupDevice > originalUnGroupDeviceList.length &&
          !isCallingUnGroupDeviceApi
        ) {
          setPageNumberForUngroupDevice((prevPageNumber) => prevPageNumber + 1);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (node) observer.current.observe(node);
    },

    [
      totalUnGroupDevice,
      originalUnGroupDeviceList.length,
      isCallingUnGroupDeviceApi,
    ],
  );

  const observer2 = useRef<IntersectionObserver | null>(null);

  const lastGroupDeviceElementRef = useCallback(
    (node) => {
      if (observer2.current) observer2.current.disconnect();
      observer2.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          totalGroupDevice > originalDeviceList.length &&
          !isCallingGroupDeviceApi
        ) {
          setPageNumberForGroupDevice((prevPageNumber) => prevPageNumber + 1);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (node) observer2.current.observe(node);
    },

    [totalGroupDevice, originalDeviceList.length, isCallingGroupDeviceApi],
  );

  const [isUngroupDeviceSearchOpen, setUngroupDeviceSearchOpen] =
    useState(false);
  const [isGroupDeviceSearchOpen, setIsGroupDeviceSearchOpen] = useState(false);
  const [checkedUnGroupAll, setCheckedUnGroupAll] = useState(false);
  const [checkedUnGroup, setCheckedUnGroup] = useState<DeviceInfo[]>([]);
  const [checkedGroupAll, setCheckedGroupAll] = useState(false);
  const [checkedGroup, setCheckedGroup] = useState<DeviceInfo[]>([]);
  // for device add btn or remove btn trigger skeleton
  const [deviceTransitionLoading, setDeviceTransitionLoading] = useState(false);

  // original and added Device list
  const [originalAndAddedDeviceList, setOriginalAndAddedDeviceList] =
    useState<DeviceInfo[]>(originalDeviceList);
  // original and removed Device list
  const [ungroupedAndRemovedDeviceList, setUngroupedAndRemovedDeviceList] =
    useState<DeviceInfo[]>(originalUnGroupDeviceList);
  // added device list
  const [addedDeviceList, setAddedDeviceList] = useState<DeviceInfo[]>([]);
  // removed device list from grouped
  const [removedDeviceList, setRemovedDeviceList] = useState<DeviceInfo[]>([]);
  // this state is for double line count for grouped devices
  const [doubleLineCountGroupDevice, setDoubleLineCountGroupDevice] =
    useState(0);
  // this state is for double line count for ungrouped devices
  const [doubleLineCountUnGroupDevice, setDoubleLineCountUnGroupDevice] =
    useState(0);
  const [originalDeviceListDataHeight, setOriginalDeviceListDataHeight] =
    useState(0);
  const [
    originalUnGroupDeviceListDataHeight,
    setOriginalUnGroupDeviceListDataHeight,
  ] = useState(0);

  const windowHeight =
    window.innerHeight > 800 ? 476 : window.innerHeight - 376;

  // comparing for grouped device sidebar
  useEffect(() => {
    setOriginalDeviceListDataHeight(
      (originalAndAddedDeviceList.length - doubleLineCountGroupDevice) * 86 +
        doubleLineCountGroupDevice * 105,
    );
  }, [originalAndAddedDeviceList, doubleLineCountGroupDevice]);

  // comparing for ungrouped device sidebar
  useEffect(() => {
    setOriginalUnGroupDeviceListDataHeight(
      (ungroupedAndRemovedDeviceList.length - doubleLineCountUnGroupDevice) *
        86 +
        doubleLineCountUnGroupDevice * 105,
    );
  }, [ungroupedAndRemovedDeviceList, doubleLineCountUnGroupDevice]);

  const modalListHeight =
    window.innerHeight > 800 ? 476 : window.innerHeight - 376;
  // Ungroup Device Check Toggle
  const handleUnGroupToggle = (value: number) => () => {
    if (
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.ADD,
      ) &&
      removedDeviceList.findIndex((device) => device.id === value) === -1
    ) {
      return;
    }

    const currentIndex = checkedUnGroup.find((device) => device.id === value);
    const deviceObject = ungroupedAndRemovedDeviceList.find(
      (device) => device.id === value,
    );
    if (!currentIndex && deviceObject) {
      setCheckedUnGroup([deviceObject, ...checkedUnGroup]);
      const addedDeviceCnt = addedDeviceList.filter((device) =>
        ungroupedAndRemovedDeviceList.some(
          (newDevice) => newDevice.id === device.id,
        ),
      );
      // handle select all
      if (
        ungroupedAndRemovedDeviceList.length + 1 !== 0 &&
        checkedUnGroup.length + 1 ===
          ungroupedAndRemovedDeviceList.length - addedDeviceCnt.length
      ) {
        setCheckedUnGroupAll(true);
      }

      // GA tag
      sendGAEvent('select_check', 'btn_name', 'select_device');
      sendGAEvent(
        'select_check',
        'select_device_value',
        (checkedUnGroup.length + 1).toString(),
      );
    } else if (currentIndex) {
      setCheckedUnGroupAll(false);
      const tempAddedDeviceList = checkedUnGroup.filter(
        (obj) => obj.id !== value,
      );
      setCheckedUnGroup(tempAddedDeviceList);
    }
  };

  const handleUnGroupSelectAllToggle = () => {
    setCheckedUnGroupAll(!checkedUnGroupAll);
    if (checkedUnGroupAll) {
      const currentUngroupedDevices = checkedUnGroup.filter(
        (device) =>
          !ungroupedAndRemovedDeviceList.some(
            (newDevice) => newDevice.id === device.id,
          ),
      );
      setCheckedUnGroup(currentUngroupedDevices);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.DELETE,
        ) &&
        !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.ADD,
        )
      ) {
        setCheckedUnGroup([...removedDeviceList]);
      } else {
        const currentUngroupedDevice = ungroupedAndRemovedDeviceList.filter(
          (device) =>
            !addedDeviceList.some((newDevice) => newDevice.id === device.id),
        );
        const filteredFromPreviousChecked = checkedUnGroup.filter(
          (device) =>
            !currentUngroupedDevice.some(
              (newDevice) => newDevice.id === device.id,
            ),
        );
        setCheckedUnGroup([
          ...currentUngroupedDevice,
          ...filteredFromPreviousChecked,
        ]);

        // GA tag
        sendGAEvent('select_check', 'btn_name', 'all_device');
        sendGAEvent(
          'select_check',
          'all_device_value',
          (
            currentUngroupedDevice.length + filteredFromPreviousChecked.length
          ).toString(),
        );
      }
    }
  };

  // group Check Toggle
  const handleGroupToggle = (value: number) => () => {
    if (
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.DELETE,
      ) &&
      addedDeviceList.findIndex((newDevice) => newDevice.id === value) === -1
    ) {
      return;
    }

    const currentIndex = checkedGroup.find((device) => device.id === value);
    // checking if the state is initial state or it has already some value
    const deviceObject = originalAndAddedDeviceList.find(
      (device) => device.id === value,
    );
    if (!currentIndex && deviceObject) {
      setCheckedGroup([deviceObject, ...checkedGroup]);
      const removedDeviceCnt = removedDeviceList.filter((device) =>
        originalAndAddedDeviceList.some(
          (newDevice) => newDevice.id === device.id,
        ),
      );

      // select all button handler
      if (
        originalAndAddedDeviceList.length + 1 !== 0 &&
        checkedGroup.length + 1 ===
          originalAndAddedDeviceList.length - removedDeviceCnt.length
      ) {
        setCheckedGroupAll(true);
      }

      // GA tag
      sendGAEvent('select_check', 'btn_name', 'selected_device');
      sendGAEvent(
        'select_check',
        'selected_device_value',
        (checkedGroup.length + 1).toString(),
      );
    } else if (currentIndex) {
      setCheckedGroupAll(false);
      const tempAddedDeviceList = checkedGroup.filter(
        (obj) => obj.id !== value,
      );
      setCheckedGroup(tempAddedDeviceList);
    }
  };

  const handleGroupSelectAllToggle = () => {
    setCheckedGroupAll(!checkedGroupAll);
    if (checkedGroupAll) {
      const currentGroupedDevices = checkedGroup.filter(
        (device) =>
          !originalAndAddedDeviceList.some(
            (newDevice) => newDevice.id === device.id,
          ),
      );
      setCheckedGroup(currentGroupedDevices);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.ADD,
        ) &&
        !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.DELETE,
        )
      ) {
        setCheckedGroup([...addedDeviceList]);
      } else {
        const currentGroupedDevice = originalAndAddedDeviceList.filter(
          (device) =>
            !removedDeviceList.some((newDevice) => newDevice.id === device.id),
        );
        const filteredFromPreviousSelected = checkedGroup.filter(
          (device) =>
            !currentGroupedDevice.some(
              (newDevice) => newDevice.id === device.id,
            ),
        );
        setCheckedGroup([
          ...currentGroupedDevice,
          ...filteredFromPreviousSelected,
        ]);

        // GA tag
        sendGAEvent('select_check', 'btn_name', 'all_selected_device');
        sendGAEvent(
          'select_check',
          'all_selected_device_value',
          (
            currentGroupedDevice.length + filteredFromPreviousSelected.length
          ).toString(),
        );
      }
    }
  };

  const handleSubmit = (e: string) => {
    setSearchTextForGroupDevice(e.trim());
    setPageNumberForGroupDevice(1);
  };
  const handleSubmitUngroup = (e: string) => {
    setSearchTextForUngroupDevice(e.trim());
    setPageNumberForUngroupDevice(1);
  };

  // handle add Device to group
  const handleAddUngrouped = () => {
    // setting skeleton
    setDeviceTransitionLoading(true);
    const newDeviceList = ungroupedAndRemovedDeviceList.filter((device) =>
      checkedUnGroup.some((newDevice) => newDevice.id === device.id),
    );
    // new add device
    const newAddDeviceList = checkedUnGroup.filter(
      (device) =>
        !removedDeviceList.some((newDevice) => newDevice.id === device.id),
    );
    setAddedDeviceList([...newAddDeviceList, ...addedDeviceList]);
    // delete from previously removed device
    const previouslyRemovedDevice = removedDeviceList.filter((device) =>
      newDeviceList.some((newDevice) => newDevice.id === device.id),
    );

    const modifiedRemovedDevice = removedDeviceList.filter(
      (device) =>
        !previouslyRemovedDevice.some(
          (newDevice) => newDevice.id === device.id,
        ),
    );
    setRemovedDeviceList(modifiedRemovedDevice);

    // update original and removed ungrouped Device list
    // const mergedArray = [...newAddDeviceList, ...originalUnGroupDeviceList];
    const mergedArray = [...newAddDeviceList, ...originalDeviceList];
    setOriginalAndAddedDeviceList(mergedArray);
    setCheckedUnGroup([]);
    setCheckedGroupAll(false);
    setCheckedUnGroupAll(false);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'add_device');
    sendGAEvent('press_btn', 'add_device_value', mergedArray.length.toString());

    // resetting timer for loading
    const timer = setTimeout(() => {
      setDeviceTransitionLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  // checking for search device
  const selectAllToggleForSearchGroupDevices = useCallback(() => {
    const chkGroupDeviceCnt = originalAndAddedDeviceList.filter((device) =>
      checkedGroup.some((newDevice) => newDevice.id === device.id),
    );

    if (
      chkGroupDeviceCnt.length !== 0 &&
      chkGroupDeviceCnt.length === originalAndAddedDeviceList.length
    ) {
      setCheckedGroupAll(true);
    } else if (
      addedDeviceList.length !== 0 &&
      chkGroupDeviceCnt.length === addedDeviceList.length &&
      accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.ADD,
      ) &&
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.DELETE,
      )
    ) {
      setCheckedGroupAll(true);
    } else {
      setCheckedGroupAll(false);
    }
  }, [
    originalAndAddedDeviceList,
    checkedGroup,
    accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL,
    addedDeviceList,
  ]);

  // merge two arrays when calling new api for searching
  useEffect(() => {
    selectAllToggleForSearchGroupDevices();
  }, [originalDeviceList, selectAllToggleForSearchGroupDevices]);

  // handle remove Device from group
  const handleRemove = () => {
    // setting for skeleton
    setDeviceTransitionLoading(true);
    const newDeviceList = originalAndAddedDeviceList.filter((device) =>
      checkedGroup.some((newDevice) => newDevice.id === device.id),
    );

    // new remove devices
    const newRemoveDeviceList = checkedGroup.filter(
      (device) =>
        !addedDeviceList.some((newDevice) => newDevice.id === device.id),
    );
    setRemovedDeviceList([...newRemoveDeviceList, ...removedDeviceList]);

    // removed from previously added device
    const previouslyAddedDevices = addedDeviceList.filter((device) =>
      newDeviceList.some((newDevice) => newDevice.id === device.id),
    );

    const modifiedAddedDevices = addedDeviceList.filter(
      (device) =>
        !previouslyAddedDevices.some((newDevice) => newDevice.id === device.id),
    );
    setAddedDeviceList(modifiedAddedDevices);

    // update original and added Device list
    const mergedArray = [...newRemoveDeviceList, ...originalUnGroupDeviceList];
    setUngroupedAndRemovedDeviceList(mergedArray);

    setCheckedGroup([]);
    setCheckedUnGroupAll(false);
    setCheckedGroupAll(false);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'delete_device');
    sendGAEvent(
      'press_btn',
      'delete_device_value',
      mergedArray.length.toString(),
    );
    // resetting timer for loading
    const timer = setTimeout(() => {
      setDeviceTransitionLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  // checking for search ungrouped device
  const selectAllToggleForSearchUngroup = useCallback(() => {
    const chkUngroupDeviceCnt = ungroupedAndRemovedDeviceList.filter((device) =>
      checkedUnGroup.some((newDevice) => newDevice.id === device.id),
    );

    if (
      ungroupedAndRemovedDeviceList.length !== 0 &&
      chkUngroupDeviceCnt.length === ungroupedAndRemovedDeviceList.length
    ) {
      setCheckedUnGroupAll(true);
    } else if (
      removedDeviceList.length !== 0 &&
      chkUngroupDeviceCnt.length === removedDeviceList.length &&
      accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.DELETE,
      ) &&
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.ADD,
      )
    ) {
      setCheckedUnGroupAll(true);
    } else {
      setCheckedUnGroupAll(false);
    }
  }, [
    ungroupedAndRemovedDeviceList,
    checkedUnGroup,
    accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL,
    removedDeviceList,
  ]);

  // merge two arrays when calling new api for searching
  useEffect(() => {
    selectAllToggleForSearchUngroup();
  }, [
    originalUnGroupDeviceList,
    selectAllToggleForSearchUngroup,
    addedDeviceList,
  ]);

  // Server Error
  useEffect(() => {
    if (
      (errorTypeGroup ===
        MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN &&
        !isCallingGroupDeviceApi) ||
      (errorTypeUnGroup ===
        MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN &&
        !isCallingUnGroupDeviceApi)
    ) {
      setIsError(true);
      setForServerErrorMsg(true);
    } else {
      setForServerErrorMsg(false);
    }
  }, [
    errorTypeGroup,
    errorTypeUnGroup,
    isCallingGroupDeviceApi,
    isCallingUnGroupDeviceApi,
  ]);

  // search function handling
  const searchDeviceBySearchKeyword = (
    array: DeviceInfo[],
    searchText: string,
  ) => {
    const lowerCaseSearchText = searchText?.toLowerCase();

    return array.filter((data: DeviceInfo) => {
      if (data.deviceName !== null) {
        return (
          data.deviceName.toLowerCase().indexOf(lowerCaseSearchText) !== -1 ||
          data.deviceModel?.toLowerCase().indexOf(lowerCaseSearchText) !== -1 ||
          data.imei?.toLowerCase().indexOf(lowerCaseSearchText) !== -1
        );
      }

      return (
        data.deviceModel?.toLowerCase().indexOf(lowerCaseSearchText) !== -1 ||
        data.imei?.toLowerCase().indexOf(lowerCaseSearchText) !== -1
      );
    });
  };

  const [loadingForUnGroupSearch, setLoadingForUnGroupSearch] =
    useState<boolean>(false);

  useEffect(() => {
    // skeleton loading
    setLoadingForUnGroupSearch(true);

    const ungroupDeviceListWithOutAddedDevices =
      originalUnGroupDeviceList.filter(
        (device) =>
          !addedDeviceList.some((newDevice) => newDevice.id === device.id),
      );
    const searchResFromUngroupDeviceList = searchDeviceBySearchKeyword(
      ungroupDeviceListWithOutAddedDevices,
      searchTextForUnGroup,
    );

    const searchResFromRemovedDeviceList = searchDeviceBySearchKeyword(
      removedDeviceList,
      searchTextForUnGroup,
    );
    const mergedArray = [
      ...searchResFromRemovedDeviceList,
      ...searchResFromUngroupDeviceList,
    ];
    setUngroupedAndRemovedDeviceList(mergedArray);
    // resetting timer for loading
    const timer = setTimeout(() => {
      setLoadingForUnGroupSearch(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [
    searchTextForUnGroup,
    originalUnGroupDeviceList,
    addedDeviceList,
    removedDeviceList,
  ]);

  const [loadingForGroupSearch, setLoadingForGroupSearch] =
    useState<boolean>(false);

  // this counter is for no device message rendering condition
  const [dataProcessingCounter, setDataProcessingCounter] = useState<number>(0);
  useEffect(() => {
    // for skeleton loading
    setLoadingForGroupSearch(true);

    const userListWithOutRemovedDevices = originalDeviceList.filter(
      (user) => !removedDeviceList.some((newUser) => newUser.id === user.id),
    );

    const searchResFromDeviceList = searchDeviceBySearchKeyword(
      userListWithOutRemovedDevices,
      searchTextForGroup,
    );

    const searchResFromAddedDeviceList = searchDeviceBySearchKeyword(
      addedDeviceList,
      searchTextForGroup,
    );
    const mergedArray = [
      ...searchResFromAddedDeviceList,
      ...searchResFromDeviceList,
    ];
    setOriginalAndAddedDeviceList(mergedArray);
    setDataProcessingCounter((data) => data + 1);

    // resetting timer for loading
    const timer = setTimeout(() => {
      setLoadingForGroupSearch(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [
    searchTextForGroup,
    originalDeviceList,
    removedDeviceList,
    addedDeviceList,
  ]);

  const listRef = useRef(null);
  const listRef2 = useRef(null);

  // for ungrouped
  const [rowSize, setRowSize] = useState<string[]>([]);

  useEffect(() => {
    if (ungroupedAndRemovedDeviceList.length > 0) {
      const nameSize = ungroupedAndRemovedDeviceList.map(
        (device) => device.deviceName,
      );

      // this array is for checking double line device name array
      const nameWidth = nameSize.filter((device) => {
        const widthCheck = pixelWidth(device, { size: 14 });
        if (device && widthCheck > 243) {
          return true;
        }

        return false;
      });

      setDoubleLineCountUnGroupDevice(nameWidth.length);

      setRowSize(nameSize);
    }
  }, [ungroupedAndRemovedDeviceList]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const getItemSize = (index: number) => {
    const isJapaneseText = regexJapanese.exec(rowSize[index]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const width = pixelWidth(rowSize[index], { size: 14 });

    const setWidth = isJapaneseText ? 128 : 243;

    if (rowSize[index] && width > setWidth) {
      return 105;
    }

    return 86;
  };

  // for grouped
  const [rowSize2, setRowSize2] = useState<string[]>([]);

  useEffect(() => {
    if (originalAndAddedDeviceList.length > 0) {
      const nameSize2 = originalAndAddedDeviceList.map(
        (device) => device.deviceName,
      );

      // this array is for checking double line device name array
      const nameWidth = nameSize2.filter((device) => {
        const widthCheck = pixelWidth(device, { size: 14 });
        if (device && widthCheck > 243) {
          return true;
        }

        return false;
      });

      setDoubleLineCountGroupDevice(nameWidth.length);

      setRowSize2(nameSize2);
    }
  }, [originalAndAddedDeviceList]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const getItemSize2 = (index: number) => {
    const isJapaneseText = regexJapanese.exec(rowSize2[index]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const width2 = pixelWidth(rowSize2[index], { size: 14 });

    const setWidth = isJapaneseText ? 128 : 243;
    if (rowSize2[index] && width2 > setWidth) {
      return 105;
    }

    return 86;
  };

  // for no device message Ungrouped
  const [noDeviceMessageUngrouped, setNoDeviceMessageUngrouped] =
    useState<string>(t('manageDevices.message.noDevice'));
  useEffect(() => {
    setNoDeviceMessageUngrouped(
      searchTextForUnGroup
        ? t('manageDevices.message.noDevice')
        : t('manageDevices.message.noDevice'),
    );
  }, [isUngroupDeviceSearchOpen, searchTextForUnGroup, t]);

  // for no device message grouped
  const [noDeviceMessageGrouped, setNoDeviceMessageGrouped] = useState<string>(
    t('manageDevices.message.noDevice'),
  );
  useEffect(() => {
    if (originalDeviceList.length !== 0 || searchTextForGroup) {
      setNoDeviceMessageGrouped(
        searchTextForGroup
          ? t('manageDevices.message.noDevice')
          : t('manageDevices.message.noDevice'),
      );
    } else {
      setNoDeviceMessageGrouped(t('manageDevices.message.noDevice'));
    }
  }, [isGroupDeviceSearchOpen, searchTextForGroup, originalDeviceList, t]);

  // set the payload
  useEffect(() => {
    const addedDeviceListPayload = addedDeviceList.map((device) => device.id);
    const removedDeviceListPayload = removedDeviceList.map(
      (device) => device.id,
    );

    setAddedDeviceListPayload(addedDeviceListPayload);
    setRemovedDeviceListPayload(removedDeviceListPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedDeviceList, removedDeviceList]);
  // end set the payload

  return (
    <Box sx={{ display: isManageDeviceActive ? '' : 'none' }}>
      <Box sx={{ position: 'relative' }}>
        {(errorTypeGroup ===
          MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
          errorTypeGroup ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
          errorTypeGroup ===
            MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH ||
          errorTypeUnGroup ===
            MANAGE_UNGROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP) &&
          onClose()}
        {/* Error Message inside MOdal */}
        {isError && (
          <Box className={classes.manageGroupErrorCard}>
            <Box className={classes.manageGroupErrorCardBox}>
              <ErrorSign />

              <Typography className={classes.manageGroupErrorCardBoxText}>
                {t('common.error.serverErr')}
              </Typography>
            </Box>

            <IconButton
              sx={{ m: '0', height: '28px' }}
              onClick={() => {
                setIsError(false);
              }}
            >
              <CloseWhiteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        className={`${classes.manageGroupDividerBox} manage-group-device-all-select-device-list`}
      >
        {/* Start Ungroup Card */}
        <Box className="manage-group-device-left" sx={{ width: '50%' }}>
          {!isUngroupDeviceSearchOpen && (
            <Box
              className={`${classes.manageGroupCountBlock} manage-group-device-count-block`}
            >
              <Typography className={classes.manageGroupCountBlockText}>
                {t('manageDevices.label.ungroupedDevices')} (
                {Math.max(
                  0,
                  totalUnGroupDevice -
                    addedDeviceList.length +
                    removedDeviceList.length,
                )}
                )
              </Typography>
              <IconButton
                onClick={() => {
                  setUngroupDeviceSearchOpen(true);

                  // GA tag
                  sendGAEvent('press_btn', 'btn_name', 'filter_device');
                }}
                disableRipple
                className={classes.manageGroupCountBlockBtn}
                aria-label="search"
              >
                <FilterIcon />
              </IconButton>
            </Box>
          )}
          {isUngroupDeviceSearchOpen && (
            <ManageGroupSearch
              placeholder={t('manageDevices.placeholder.searchDevice')}
              clickIcon={(val) => {
                setUngroupDeviceSearchOpen(val);
                setFirstTimeFilterRenderForUngroupDevice(true);
                if (searchTextForUnGroup) {
                  setPageNumberForUngroupDevice(1);
                }
                setSearchTextForUngroupDevice('');

                // GA tag
                sendGAEvent(
                  'press_btn',
                  'btn_name',
                  'input_search_device_remove',
                );
              }}
              clickFilterSearch={(val) => {
                if (!firstTimeFilterRenderForUngroupDevice) {
                  handleSubmitUngroup(val);

                  // GA tag
                  sendGAEvent(
                    'filter_device_txt',
                    'filter_txt',
                    'input_search_device',
                  );
                  sendGAEvent(
                    'filter_device_txt',
                    'input_search_device_value',
                    val.toString(),
                  );
                }
                setFirstTimeFilterRenderForUngroupDevice(false);
              }}
            />
          )}
          <Box
            sx={{
              border:
                ungroupedAndRemovedDeviceList.length === 0
                  ? '1px solid #93959880 !important'
                  : '1px solid #C5C5C5 !important',
              '@media screen and (min-width:900px)': {
                padding:
                  accountInfo.locale === LOCALE_CODE.FR
                    ? '6px 16px !important'
                    : '12px 16px',
              },
            }}
            className={`${classes.allSelectCard} manage-group-device-all-select-card `}
          >
            {/* Start UnGroup Select all card */}
            <ListItem
              // className={classes.allSelectCardList}
              secondaryAction={
                <IconButton
                  aria-label="Add device"
                  disabled={checkedUnGroup.length <= 0}
                  className={classes.allSelectCardAddBtn}
                  disableRipple
                  onClick={handleAddUngrouped}
                >
                  <CheckMarkAddUser />
                  <Typography className={classes.allSelectCardBtnText}>
                    {t('manageDevices.button.addDevice')}
                  </Typography>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                disabled={
                  accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                    FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.ADD,
                  )
                    ? ungroupedAndRemovedDeviceList.length === 0
                    : removedDeviceList.length === 0 ||
                      ungroupedAndRemovedDeviceList.length === 0
                }
                className={classes.allSelectCardBtn}
                sx={{
                  marginRight:
                    accountInfo.locale === LOCALE_CODE.JA
                      ? '158px'
                      : accountInfo.locale === LOCALE_CODE.FR
                      ? '173px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? '165px'
                      : '192px',
                }}
                disableRipple
                onClick={handleUnGroupSelectAllToggle}
              >
                <Checkbox
                  checked={checkedUnGroupAll}
                  icon={<CheckBox className={classes.checkBoxIconStyle} />}
                  checkedIcon={<CheckedCheckBox />}
                  sx={{
                    p: '0px',
                    mr: '8px',
                    '& svg': {
                      '& g': {
                        stroke: !checkedUnGroupAll
                          ? ungroupedAndRemovedDeviceList.length === 0
                            ? '#C5C5C580'
                            : '#C5C5C5'
                          : '#009de1',
                      },
                    },
                    // backgroundColor: '#FFFFFF',
                  }}
                />
                <ListItemText
                  primary={
                    <Typography className={classes.allSelectCardText}>
                      {t('manageDevices.label.selectDevices')}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Box>
          {/* end UnGroup Select all card */}

          {/* // Ungroup device List */}
          <List sx={{ p: '0px', mt: '6px' }}>
            {/* showing ungrouped deviceDATA */}

            {!(
              isCallingUnGroupDeviceApi ||
              deviceTransitionLoading ||
              loadingForUnGroupSearch
            ) &&
              ungroupedAndRemovedDeviceList.length !== 0 && (
                <Box style={{ position: 'relative' }}>
                  <SimpleBarReact
                    style={{ height: modalListHeight }}
                    autoHide={false}
                    className="manage-group-device-item-box"
                  >
                    {({ scrollableNodeRef, contentNodeRef }) => (
                      <VariableListWindow
                        className={`${classes.listWindowContainer} manage-group-device-list-container`}
                        itemCount={ungroupedAndRemovedDeviceList.length}
                        itemSize={getItemSize}
                        height={modalListHeight}
                        width={350}
                        outerRef={scrollableNodeRef}
                        innerRef={contentNodeRef}
                        ref={listRef}
                      >
                        {({ index, style }) => {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          const device = ungroupedAndRemovedDeviceList[index];
                          let deviceName = '';
                          if (
                            device.deviceName == null ||
                            device.deviceName === ''
                          ) {
                            deviceName = '-';
                          } else {
                            deviceName = device.deviceName;
                          }
                          const foundAdded = addedDeviceList.findIndex(
                            (obj) => obj.id === device.id,
                          );
                          if (foundAdded !== -1) {
                            return null;
                          }

                          const deviceNameWidth = pixelWidth(deviceName, {
                            font: 'open sans',
                            size: 14,
                          });

                          const isJapanese = regexJapanese.exec(deviceName);

                          return (
                            <Box
                              key={index}
                              style={style}
                              sx={{
                                minHeight: '84px',
                                '&:not(:last-child)': {
                                  marginBottom: '8px',
                                },
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  minHeight: '76px',
                                }}
                                className={`${
                                  checkedUnGroup.findIndex(
                                    (obj) => obj.id === device.id,
                                  ) !== -1
                                    ? clsx(
                                        classes.listItemButton,
                                        classes.listItemButtonActive,
                                      )
                                    : classes.listItemButton
                                } manage-group-device-item-list`}
                                onClick={handleUnGroupToggle(device.id)}
                                disableRipple
                                disabled={
                                  !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                                    FEATURE_PERMISSION.GROUPMANAGEMENT
                                      .GROUPDEVICEMODAL.ADD,
                                  ) &&
                                  removedDeviceList.findIndex(
                                    (newDevice) => newDevice.id === device.id,
                                  ) === -1
                                }
                              >
                                <ListItem
                                  sx={{
                                    p: '3px 16px',
                                  }}
                                  ref={
                                    originalUnGroupDeviceList.length ===
                                    index + 1
                                      ? lastUngroupDeviceElementRef
                                      : null
                                  }
                                >
                                  <Checkbox
                                    checked={
                                      checkedUnGroup.findIndex(
                                        (obj) => obj.id === device.id,
                                      ) !== -1
                                    }
                                    tabIndex={-1}
                                    icon={
                                      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                                        FEATURE_PERMISSION.GROUPMANAGEMENT
                                          .GROUPDEVICEMODAL.ADD,
                                      ) &&
                                      removedDeviceList.findIndex(
                                        (newDevice) =>
                                          newDevice.id === device.id,
                                      ) === -1 ? (
                                        <CheckBoxDisable
                                          className={classes.checkBoxIconStyle}
                                        />
                                      ) : (
                                        <CheckBox
                                          className={classes.checkBoxIconStyle}
                                        />
                                      )
                                    }
                                    checkedIcon={<CheckedCheckBox />}
                                    className={classes.checkBoxStyle}
                                  />
                                  <ListItemText
                                    primary={
                                      <Box className={classes.listItemTextBox}>
                                        <StyledTooltip
                                          title={deviceName}
                                          dataWidth={deviceNameWidth}
                                          divWidth={
                                            // isJapanese ? 247 : 243.05 * 2
                                            isJapanese ? 256 : 243.05 * 2
                                          }
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              lineHeight: '19px',
                                              color: '#313133',
                                              width: '86.19%',
                                            }}
                                            // className={classes.textBreak}
                                            className={
                                              classes.textBreakTwoLines
                                            }
                                          >
                                            {deviceName}
                                          </Typography>
                                        </StyledTooltip>

                                        <div
                                          style={{
                                            marginRight: '-6px',
                                            height: '19px',
                                            display:
                                              removedDeviceList.findIndex(
                                                (obj) => obj.id === device.id,
                                              ) === -1
                                                ? 'none'
                                                : 'flex',
                                          }}
                                        >
                                          <ActiveIcon />
                                        </div>
                                      </Box>
                                    }
                                    secondary={
                                      <Box
                                        sx={{
                                          dixplay: 'flex',
                                          flexDirection: 'coloum',
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            height: '17px',
                                            color: '#939598',
                                            fontWeight: 'light',
                                            mt: '2px',
                                            mb: '3px',
                                          }}
                                          className={classes.textEllipse}
                                        >
                                          {t('manageDevices.label.imei')} :
                                          {device.imei}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            height: '17px',
                                            color: '#939598',
                                            fontWeight: 'light',
                                          }}
                                          className={classes.textEllipse}
                                        >
                                          {t('manageDevices.label.model')} :
                                          {device.deviceModel}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              </ListItemButton>
                            </Box>
                          );
                        }}
                      </VariableListWindow>
                    )}
                  </SimpleBarReact>
                  {!isCallingUnGroupDeviceApi &&
                    originalUnGroupDeviceListDataHeight < windowHeight &&
                    !(
                      ungroupedAndRemovedDeviceList.length === 0 &&
                      dataProcessingCounter >= 2
                    ) && (
                      <Divider
                        orientation="vertical"
                        className={`${classes.divider} manage-group-device-no-divider`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }}
                      />
                    )}
                </Box>
              )}
            {/* no devices */}
            {!(
              isCallingUnGroupDeviceApi ||
              deviceTransitionLoading ||
              loadingForUnGroupSearch
            ) &&
              ungroupedAndRemovedDeviceList.length === 0 &&
              (dataProcessingCounter >= 2 ||
                errorTypeUnGroup ===
                  MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET) && (
                <Box
                  className="manage-group-device-no-device"
                  display="flex"
                  style={{ overflow: 'hidden' }}
                >
                  <Box
                    className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
                  >
                    <Box
                      className={`${classes.NoDataBox} manage-group-device-data-not-found-icon`}
                    >
                      <GroupDevice className={classes.noGroupDeviceIcon} />
                      <Typography className={classes.noDataMessage}>
                        {noDeviceMessageUngrouped}
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      className={`${classes.divider} manage-group-device-no-divider`}
                    />
                  </Box>
                </Box>
              )}
            {/* skeleton loading */}
            {(isCallingUnGroupDeviceApi ||
              deviceTransitionLoading ||
              loadingForUnGroupSearch) && (
              <Box
                className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
              >
                <Box
                  className={`${classes.NoDataSkeleletonBox} manage-group-device-data-not-found-icon`}
                >
                  <ManageDeviceSkeleton />
                </Box>
              </Box>
            )}
          </List>
        </Box>

        {/* Start group Card */}
        <Box
          className="manage-group-device-right"
          sx={{ width: '50%', mr: '-4px' }}
        >
          {/* group Card Search */}
          {!isGroupDeviceSearchOpen && (
            <Box
              className={`${classes.manageGroupCountBlock} manage-group-device-count-block`}
            >
              <Typography className={classes.manageGroupCountBlockText}>
                {t('manageDevices.label.groupDevices')} (
                {Math.max(
                  0,
                  totalGroupDevice -
                    removedDeviceList.length +
                    addedDeviceList.length,
                )}
                )
              </Typography>
              <IconButton
                onClick={() => {
                  setIsGroupDeviceSearchOpen(true);

                  // GA tag
                  sendGAEvent(
                    'press_btn',
                    'btn_name',
                    'filter_selected_device',
                  );
                }}
                disableRipple
                className={classes.manageGroupCountBlockBtn}
                aria-label="search"
              >
                <FilterIcon />
              </IconButton>
            </Box>
          )}
          {isGroupDeviceSearchOpen && (
            <ManageGroupSearch
              placeholder={t('manageDevices.placeholder.searchDevice')}
              clickIcon={(val) => {
                setIsGroupDeviceSearchOpen(val);
                setFirstTimeFilterRenderGroupedDevice(true);
                if (searchTextForGroup) {
                  setPageNumberForGroupDevice(1);
                }
                setSearchTextForGroupDevice('');
              }}
              clickFilterSearch={(val) => {
                if (!firstTimeFilterRenderGroupedDevice) {
                  handleSubmit(val);
                }
                setFirstTimeFilterRenderGroupedDevice(false);

                // GA Tag
                sendGAEvent(
                  'filter_selected_device_txt',
                  'filter_txt',
                  'input_search_selected_device',
                );
                sendGAEvent(
                  'filter_selected_user_txt',
                  'input_search_selected_device_value',
                  val,
                );
              }}
            />
          )}
          {/* group all select card */}
          <Box
            sx={{
              border:
                originalAndAddedDeviceList.length === 0
                  ? '1px solid #93959880 !important'
                  : '1px solid #C5C5C5 !important',
              '@media screen and (min-width:900px)': {
                padding:
                  accountInfo.locale === LOCALE_CODE.FR
                    ? '6px 16px !important'
                    : '12px 16px',
              },
            }}
            className={`${classes.allSelectCard} manage-group-device-all-select-card`}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  aria-label="Remove device"
                  disabled={checkedGroup.length <= 0}
                  className={classes.allSelectCardBtnRemove}
                  disableRipple
                  onClick={handleRemove}
                >
                  <CheckMarkCross />
                  <Typography className={classes.allSelectCardBtnText}>
                    {t('manageDevices.button.remove')}
                  </Typography>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                // disabled={originalAndAddedDeviceList.length === 0}
                disabled={
                  accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                    FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.DELETE,
                  )
                    ? originalAndAddedDeviceList.length === 0
                    : addedDeviceList.length === 0 ||
                      originalAndAddedDeviceList.length === 0
                }
                className={classes.allSelectCardBtn}
                sx={{
                  marginRight:
                    accountInfo.locale === LOCALE_CODE.JA
                      ? '158px'
                      : accountInfo.locale === LOCALE_CODE.FR
                      ? '173px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? '165px'
                      : '192px',
                }}
                disableRipple
                onClick={handleGroupSelectAllToggle}
              >
                <Checkbox
                  checked={checkedGroupAll}
                  icon={<CheckBox className={classes.checkBoxIconStyle} />}
                  checkedIcon={<CheckedCheckBox />}
                  sx={{
                    p: '0px',
                    mr: '8px',
                    '& svg': {
                      '& g': {
                        stroke: !checkedGroupAll
                          ? originalAndAddedDeviceList.length === 0
                            ? '#C5C5C580'
                            : '#C5C5C5'
                          : '#009de1',
                      },
                    },
                  }}
                />
                <ListItemText
                  primary={
                    <Typography className={classes.allSelectCardText}>
                      {t('manageDevices.label.selectDevices')}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Box>
          {/* group Device List */}
          <List sx={{ p: '0px', mt: '6px' }}>
            {!(
              isCallingGroupDeviceApi ||
              deviceTransitionLoading ||
              loadingForGroupSearch
            ) &&
              originalAndAddedDeviceList.length !== 0 && (
                <Box style={{ position: 'relative' }}>
                  <SimpleBarReact
                    style={{ height: modalListHeight }}
                    autoHide={false}
                    className="manage-group-device-item-box"
                  >
                    {({ scrollableNodeRef, contentNodeRef }) => (
                      <VariableListWindow
                        className={`${classes.listWindowContainer} manage-group-device-list-container`}
                        itemCount={originalAndAddedDeviceList.length}
                        itemSize={getItemSize2}
                        height={modalListHeight}
                        width={350}
                        outerRef={scrollableNodeRef}
                        innerRef={contentNodeRef}
                        ref={listRef2}
                      >
                        {({ index, style }) => {
                          const device = originalAndAddedDeviceList[index];
                          let deviceName = '';
                          if (
                            device.deviceName == null ||
                            device.deviceName === ''
                          ) {
                            deviceName = '-';
                          } else {
                            deviceName = device.deviceName;
                          }
                          const foundRemoved = removedDeviceList.findIndex(
                            (obj) => obj.id === device.id,
                          );
                          if (foundRemoved !== -1) {
                            return null;
                          }
                          const deviceNameWidth = pixelWidth(deviceName, {
                            font: 'open sans',
                            size: 14,
                          });

                          const isJapanese = regexJapanese.exec(deviceName);

                          return (
                            <Box
                              key={index}
                              style={style}
                              sx={{
                                minHeight: '84px',
                                '&:not(:last-child)': {
                                  marginBottom: '8px',
                                },
                              }}
                            >
                              <ListItemButton
                                className={`${
                                  checkedGroup.findIndex(
                                    (obj) => obj.id === device.id,
                                  ) !== -1
                                    ? clsx(
                                        classes.listItemButton,
                                        classes.listItemButtonActive,
                                      )
                                    : classes.listItemButton
                                } manage-group-device-item-list`}
                                onClick={handleGroupToggle(device.id)}
                                disableRipple
                                disabled={
                                  !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                                    FEATURE_PERMISSION.GROUPMANAGEMENT
                                      .GROUPDEVICEMODAL.DELETE,
                                  ) &&
                                  addedDeviceList.findIndex(
                                    (newDevice) => newDevice.id === device.id,
                                  ) === -1
                                }
                              >
                                <ListItem
                                  sx={{
                                    p: '3px 16px',
                                  }}
                                  ref={
                                    originalDeviceList.length === index + 1
                                      ? lastGroupDeviceElementRef
                                      : null
                                  }
                                >
                                  <Checkbox
                                    checked={
                                      checkedGroup.findIndex(
                                        (obj) => obj.id === device.id,
                                      ) !== -1
                                    }
                                    tabIndex={-1}
                                    icon={
                                      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                                        FEATURE_PERMISSION.GROUPMANAGEMENT
                                          .GROUPDEVICEMODAL.DELETE,
                                      ) &&
                                      addedDeviceList.findIndex(
                                        (newDevice) =>
                                          newDevice.id === device.id,
                                      ) === -1 ? (
                                        <CheckBoxDisable
                                          className={classes.checkBoxIconStyle}
                                        />
                                      ) : (
                                        <CheckBox
                                          className={classes.checkBoxIconStyle}
                                        />
                                      )
                                    }
                                    checkedIcon={<CheckedCheckBox />}
                                    className={classes.checkBoxStyle}
                                  />
                                  <ListItemText
                                    primary={
                                      <Box className={classes.listItemTextBox}>
                                        <StyledTooltip
                                          title={deviceName}
                                          dataWidth={deviceNameWidth}
                                          divWidth={
                                            // isJapanese ? 247 : 243.05 * 2
                                            // isJapanese ? 255.50000000000003 : 243.05 * 2
                                            isJapanese ? 256 : 243.05 * 2
                                          }
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              lineHeight: '19px',
                                              color: '#313133',
                                              width: '86.19%',
                                            }}
                                            // className={classes.textBreak}
                                            className={
                                              classes.textBreakTwoLines
                                            }
                                          >
                                            {deviceName}
                                          </Typography>
                                        </StyledTooltip>

                                        <div
                                          style={{
                                            marginRight: '-6px',
                                            height: '19px',
                                            display:
                                              addedDeviceList.findIndex(
                                                (obj) => obj.id === device.id,
                                              ) === -1
                                                ? 'none'
                                                : 'flex',
                                          }}
                                        >
                                          <ActiveIcon />
                                        </div>
                                      </Box>
                                    }
                                    secondary={
                                      <Box
                                        sx={{
                                          dixplay: 'flex',
                                          flexDirection: 'coloum',
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            height: '17px',
                                            color: '#939598',
                                            fontWeight: 'light',
                                            mt: '2px',
                                            mb: '3px',
                                          }}
                                          className={classes.textEllipse}
                                        >
                                          {t('manageDevices.label.imei')} :
                                          {device.imei}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            height: '17px',
                                            color: '#939598',
                                            fontWeight: 'light',
                                          }}
                                          className={classes.textEllipse}
                                        >
                                          {t('manageDevices.label.model')} :
                                          {device.deviceModel}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              </ListItemButton>
                            </Box>
                          );
                        }}
                      </VariableListWindow>
                    )}
                  </SimpleBarReact>
                  {!isCallingGroupDeviceApi &&
                    originalDeviceListDataHeight < windowHeight &&
                    !(
                      originalAndAddedDeviceList.length === 0 &&
                      dataProcessingCounter >= 2
                    ) && (
                      <Divider
                        orientation="vertical"
                        // flexItem
                        className={`${classes.divider} manage-group-device-no-divider`}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      />
                    )}
                </Box>
              )}
            {/* no devices */}
            {!(
              isCallingGroupDeviceApi ||
              deviceTransitionLoading ||
              loadingForGroupSearch
            ) &&
              originalAndAddedDeviceList.length === 0 &&
              (dataProcessingCounter >= 2 ||
                errorTypeGroup ===
                  MANAGE_GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET) && (
                <Box
                  className="manage-group-device-no-device"
                  display="flex"
                  style={{ overflow: 'hidden' }}
                >
                  <Box
                    className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
                  >
                    <Box
                      className={`${classes.NoDataBox} manage-group-device-data-not-found-icon`}
                    >
                      <GroupDevice className={classes.noGroupDeviceIcon} />
                      <Typography className={classes.noDataMessage}>
                        {forServerErrorMsg
                          ? t('deviceList.errors.noDevice')
                          : noDeviceMessageGrouped}
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      className={`${classes.divider} manage-group-device-no-divider`}
                    />
                  </Box>
                </Box>
              )}
            {/* skeleton loading */}
            {(isCallingGroupDeviceApi ||
              deviceTransitionLoading ||
              loadingForGroupSearch) && (
              <Box
                className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
              >
                <Box
                  className={`${classes.NoDataSkeleletonBox} manage-group-device-data-not-found-icon`}
                >
                  <ManageDeviceSkeleton />
                </Box>
              </Box>
            )}
          </List>
        </Box>
        {/* end Ungroup Card */}
      </Box>
    </Box>
  );
});

export default ManageDeviceDialog;
