/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, SERVER_MESSAGE } from '../constants/constants';

export const DevicesExportUrlList = {
  devicesExportData: {
    url: API_URL.DEVICE_LIST_DOWNLOAD_API,
    fileName: 'DeviceList.csv',
  }
};

type responseDownload = {
  data: {
    message: string;
  };
};

const devicesExportDataDownload = (
  src: { url: string; fileName: string },
) => {

  const response = callApiUtil
    .get(
      `${src.url}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => {
      if (apiResponse.status === 200) {
        const type = apiResponse.headers['content-type'];
        // Add BOM for UTF-8 encoding
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, ...apiResponse.data], {
          type,
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = src.fileName;
        link.click();

        return {
          message: SERVER_MESSAGE.FETCH_OK,
        };
      }
      const apiResponseDenied = apiResponse as responseDownload;
      if (apiResponseDenied?.data && apiResponseDenied?.data?.message) {
        return {
          message: apiResponseDenied?.data?.message,
        };
      }
      throw SERVER_MESSAGE.ERR_UNKNOWN;
    })
    .catch((error: AxiosError) => ({ message: error }));

  return response;
};

export default devicesExportDataDownload;
