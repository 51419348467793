import { CircularProgress } from '@mui/material';
import styles from './PageLoadingLayer.module.scss';

/**
 * ローディングバー コンポーネント
 * @returns
 */
const PageLoadingLayer = (): JSX.Element => (
  <div className={styles['page-loading-layer']}>
    <CircularProgress
      color="info"
      className={styles['page-loading-position']}
    />
  </div>
);

export default PageLoadingLayer;
