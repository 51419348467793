import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { ManageGroupDeviceListUpdateApiResponse } from '../types/apis/manageGroupDeviceListApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * device List update API Result Code
 */
export const MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE = {
  SUCCESSFULLY_UPDATED: 'SUCCESSFULLY_UPDATED',
  // No such group found in this corporation or device does not have access
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // No device exist in this group
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Input parameter not correct
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // Failed to update selected group device(s)
  ERR_UPDATE_DENIED: 'ERR_UPDATE_DENIED',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * device list update error type
 */
export type MANAGE_GROUP_DEVICE_LIST_UPDATE_API_ERROR_TYPE =
  typeof MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE[keyof typeof MANAGE_GROUP_DEVICE_LIST_UPDATE_API_RESULT_CODE];

/**
 * device list update API call
 *
 * @param accessKey access key
 * @returns Response from the device list update API
 */
const callManageGroupDeviceListUpdate = (
  // accessKey: string,
  groupId: number,
  add: number[],
  remove: number[],
): Promise<ManageGroupDeviceListUpdateApiResponse> => {
  const jsonParams = {
    groupId,
    add,
    remove,
  };

  // Send request (PUT)
  const response = callApiUtil
    .put<ManageGroupDeviceListUpdateApiResponse>(
      API_URL.MANAGE_GROUP_DEVICE_LIST_UPDATE_API,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError)=> ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          statusCode:  error.response?.data?.statusCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          message: error.response?.data?.message
        })
    );

  return response;
};

export default callManageGroupDeviceListUpdate;
