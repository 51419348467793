/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callRemoteFeatureSetupInfo, {
  REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE,
  REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureSetupInfoApi';
// import { PAGE_PATH_NAME } from '../../constants/constants';
import { isRemoteFeatureSetupInfoErrorType } from '../../types/apis/apiErrorTypeGuard';

import {
  RemoteFeatureSetupInfoApiResponse,
  RemoteFeatureSetupInfoResponse,
} from '../../types/apis/remoteFeatureSetupInfo.d';
// import { GroupDetailResult } from '../../types/Group/groupDetail.d';

export const featureSetupInfo = async (
  groupId: number,
): Promise<RemoteFeatureSetupInfoApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: RemoteFeatureSetupInfoResponse =
      await callRemoteFeatureSetupInfo(groupId);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_FETCHED':
        return {
          resultCode: REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.OK,
          /*eslint-disable*/
          groupPin: apiResponse.details.groupPin,
          sleepTime: apiResponse.details.sleepTime,
          saveInLocalFlag: apiResponse.details.saveInLocalFlag,
          dataDeleteTime: apiResponse.details.dataDeleteTime,
          remoteSettingModeId: apiResponse.details.remoteSettingModeId,
          mobileDataEditable: apiResponse.details.mobileDataEditable,
          wifiEditable: apiResponse.details.wifiEditable,
          wifiSettings: apiResponse.details.wifiSettings,
          updateDt: apiResponse.details.updateDt,
        };
      // Failure

      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH;
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NOTHING_KEY;
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        throw REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_NO_AUTH_LIST;
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NO_GROUP:
        throw REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.INFO_NO_GROUP;
      //   case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
      //     throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      default:
        throw REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: REMOTE_FEATURE_SETUP_INFO_API_ERROR_TYPE =
      REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.ERR_UNKNOWN;
    if (isRemoteFeatureSetupInfoErrorType(error)) {
      resultCode = error;
    }
    return {
      resultCode,
      groupPin: '',
      sleepTime: '',
      saveInLocalFlag: 0,
      dataDeleteTime: '',
      remoteSettingModeId: 0,
      mobileDataEditable: 1,
      wifiEditable: 1,
      wifiSettings: [],
      updateDt: '',
    };
  }
};

// .catch((error: GROUP_DETAIL_API_ERROR_TYPE | unknown) => {
//   if (isGroupDetailErrorType(error)) {
//     setGroupDetailErrorType(error);
//   } else {
//     setGroupDetailErrorType(GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN);
//   }

export const transToGroups = (navigate: NavigateFunction): void => {
  // Invalid groupId forward to Group list page
  navigate(`/groups`, { replace: true });
};
