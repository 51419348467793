import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import callApiUtil from '../utils/callApiUtil';
import type {
  DeviceDeregisterApiRequestParam,
  DeviceDeregisterResponse,
} from '../types/apis/deviceDeregisterApi';

export const DEVICE_DEREGISTER_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 指定された端末が見つからない
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  // 既に端末登録解除済み
  INFO_ALREADY_DEREGISTER: 'INFO_ALREADY_DEREGISTER',
  // アクセスキー無し(※端末登録解除API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※端末登録解除API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※端末登録解除API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * 端末登録解除エラータイプ
 */
export type DEVICE_DEREGISTER_ERROR_TYPE =
  typeof DEVICE_DEREGISTER_API_RESULT_CODE[keyof typeof DEVICE_DEREGISTER_API_RESULT_CODE];

/**
 * 端末登録解除API呼び出し
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 端末登録解除APIからのレスポンス
 */
const callDeviceDeregisterApi = (
  requestParams: DeviceDeregisterApiRequestParam,
): Promise<DeviceDeregisterResponse> => {
  const jsonParams = {
    imei: requestParams.imei,
  };

  const response = callApiUtil
    .post<DeviceDeregisterResponse>(API_URL.DEVICE_DEREGISTER_API, jsonParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callDeviceDeregisterApi;
