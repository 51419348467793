import React from 'react';
import { Container, CssBaseline, Box, Typography, Grid } from '@mui/material';
import { useStyles } from './CommonStyle';
import BreadcrumbDetailsPage from '../Modecules/BreadcrumbDetailsPage';

type Props = {
  children: React.ReactNode;
  titleText: string;
};

const DetailsTemplate = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      id="groupPageTemplateRoot"
      className={classes.root}
      style={{ position: 'relative', height: '100%' }}
    >
      <CssBaseline />

      <Container maxWidth="lg" className={classes.groupFeatureContainer}>
        <Grid container direction="column" pb="20px">
          <BreadcrumbDetailsPage />
          <Typography
            sx={{
              pt: '4px',
              fontSize: '20px',
              lineHeight: '20px',
              height: '29px',
              fontWeight: 'bold',
              display: 'flex',
            }}
          >
            {props.titleText}
          </Typography>
        </Grid>
        <Box
          component="main"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {props.children}
        </Box>
      </Container>
    </div>
  );
});

export default DetailsTemplate;
