import { FETCH_SIM_INFO_ERROR_TYPE } from '../../apis/callFetchSimInfoApi';
import type { DeviceInfo } from '../apis/deviceListApi.d';
import type { SimInfo } from '../apis/fetchSimInfoApi.d';
import type { DIRECTION_TYPE } from '../datatable/dataTable';
import { DEVICE_LIST_ERROR_TYPE } from '../../apis/callDeviceListApi';

/**
 * 端末情報一覧の取得結果 の型
 */
export type DeviceListResult = {
  resultCode: DEVICE_LIST_ERROR_TYPE;
  devices: DeviceInfoData[];
  totalDeviceCount:number
};

/**
 * 端末情報 の型
 */
export type DeviceInfoData = { [imei: string]: DeviceInfo };

/**
 * SIM情報 の型
 */
export type SimInfoData = {
  simInfo: SimInfo | null;
};

/**
 * 内蔵SIM期限日タイプ
 */
export type ExpireDtStateType = {
  // デフォルト(空欄)
  default: string;
  // 無期限
  indefinitely: string;
  // 利用開始前
  beforeUse: string;
};

/**
 * テーブルデータの表示幅
 */
// export const DEVICE_LIST_WIDTH_ARRAY: string[] = [
//   '180px', // IMEI
//   '', // 端末名
//   '160px', // 最終翻訳日
//   '180px', // 内蔵SIM期限
//   '195px', // 端末登録日
//   '170px', // 機種名
//   '210px', // ICCID
//   '300px', // Status
//   '305px',
// ];

export const DEVICE_LIST_WIDTH_ARRAY: string[] = [
  '7.4vw', // Group Name
  '6.1vw', // IMEI
  '8.18vw', // Device Name
  '5.83vw', // Device Model
  '9.74vw', // Mode
  '8.12vw', // Status
  '5.3125vw', // Sim Exp. Date
  '6.12vw', // Last Used
];



/* eslint-disable  */
export interface ICorporationResponse {
  corp_id: string
  corp_info_id: number
  color_code_name: string
  company: string
  location: string
  mail_address: string
  status_code: string
  telephone: string
  created_dt: string
}


export interface IDeviceImportResponse {
device_import_history_id: number,
corp_id: string,
company: string,
order_id: string,
number_of_devices: number,
status_code: string,
color_code_name: string,
created_dt: string,
download_file: string,
}


export interface IDeviceResponse{
color_code_name: string
company: string
corp_id: string
corp_info_id: number
deviceName: string
device_id: number
iccid: string
imei1: string
imei2: string
latest_trans_dt: string
product_type: string
sim_expiry_dt: string
status: string
}


export interface IUserResponse {
  user_id: number;
  first_name: string;
  last_name: string;
  username: string;
  mail_address: string;
  avatar_url: string;
  invitee_name: string;
  invited_dt: string;
  registered_dt: string;
  status_code: string;
  color_code_name: string;
  reinvite?: string
  delete?: string
}

export interface ITableRowProps {
  rows: IRowElementList
  columnProperties: IColumnProps
  isSuccess: boolean
  isReloading: boolean
  // cellDataGenerator: 
  //     ((cell: IColumnProp, row: ICorporationResponse)=>string|JSX.Element) | 
  //     ((cell: IColumnProp, row: IDeviceImportResponse)=>string|JSX.Element) | 
  //     ((cell: IColumnProp, row: IDeviceResponse)=>string|JSX.Element) | 
  //     ((cell: IColumnProp, row: IUserResponse)=>string|JSX.Element)
  cellDataGenerator: (cell: IColumnProp, row?: IUserResponse | IDeviceResponse | IDeviceImportResponse | ICorporationResponse) => string | JSX.Element
  hyperlink?: boolean
}