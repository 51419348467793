import { useEffect } from 'react';
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import useMonthRangePicker from '../../hooks/useMonthRangePicker';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import MonthRangePicker from '../Atoms/monthRangePicker';
import { ReactComponent as CalenderLogo } from '../../images/icon/calender.svg';
import useLocale from '../../hooks/useLocale';

interface propsType {
  onApply: (data: Date[]) => void;
  // eslint-disable-next-line react/require-default-props
  translationCountPage?: boolean;
  // eslint-disable-next-line react/require-default-props
  fromDate?: Date;
  // eslint-disable-next-line react/require-default-props
  toDate?: Date;
}
const MonthRangePickerInput = ({
  onApply,
  translationCountPage,
  fromDate,
  toDate,
}: propsType) => {
  const {
    handleSelectBoxClick,
    selectOpen,
    selectAnchor,
    handleSelectGroup,
    setSelectedDate,
    setSelectOpen,
    selectedDate,
  } = useMonthRangePicker();

  useEffect(() => {
    if (translationCountPage) {
      setSelectedDate([new Date(fromDate!), new Date(toDate!)]);
    }
    // eslint-disable-next-line
  }, []);

  const { localeLanguage } = useLocale();
  const { t } = useSwitchLocaleLanguage();

  return (
    <>
      <Box
        onClick={handleSelectBoxClick}
        className="select-group"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          border: selectOpen ? '1px solid #939598' : '1px solid #d9d9d9',
          // border: '1px solid transparent',
          borderRadius: '6px',
          opacity: '1',
          padding: '8px 10px',
          outline: 'none',
          zIndex: '1',
          width: '100%',
          fontSize: '14px',
          backgroundColor: '#ffffff',
          cursor: 'pointer',
          height: '100%',
          '&:hover': {
            border: '1px solid #939598',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            width: '178px',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre',
            height: '22px',
            opacity: selectedDate.length > 1 ? '1' : '0.5',
          }}
        >
          {selectedDate.length > 1
            ? `${selectedDate[0].toLocaleString(localeLanguage, {
                year: 'numeric',
                month: 'short',
              })} - ${selectedDate[1].toLocaleString(localeLanguage, {
                year: 'numeric',
                month: 'short',
              })}`
            : t('report.validation.minMonth')}
        </Typography>
        <CalenderLogo />
      </Box>
      <Popper
        placement="bottom-end"
        anchorEl={selectAnchor}
        open={selectOpen}
        sx={{ zIndex: '1300' }}
      >
        <ClickAwayListener onClickAway={handleSelectGroup}>
          <Paper sx={{ boxShadow: 'none' }}>
            <MonthRangePicker
              selectedDate={
                translationCountPage
                  ? [new Date(fromDate!), new Date(toDate!)]
                  : selectedDate
              }
              setRangeState={setSelectedDate}
              setOpenState={setSelectOpen}
              onApply={onApply}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default MonthRangePickerInput;
