import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ViewSwitchInput } from '../types/inputs/viewSwitchInput.d';
import { ViewType, VIEW_KEY } from '../types/viewswitch/viewSwitch.d';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

/**
 * 本カスタムフックからの返却値
 */
export type UseViewSwitchDropDownValue = {
  // [ビュー切替]ドロップダウンの表示内容
  viewMenuContents: ViewType;
  // 選択中のビュー
  viewSwitchInputData: ViewSwitchInput;
  // [ビュー切替]ドロップダウンがクリックされた
  clickViewSwitch: (key: string) => void;
};

/**
 * [ビュー切替]ドロップダウン hooks
 *
 * @param defaultViewKey ビュー切替のデフォルト
 * @returns
 */
const useViewSwitchDropDown = (
  defaultViewKey: string,
): UseViewSwitchDropDownValue => {
  // [ビュー切替]メニューの表示内容
  const [viewMenuContents, setViewMenuContents] = useState<ViewType>({});
  // 選択中のビュー get/set
  const [viewSwitchInputData, setViewSwitchInputData] =
    useState<ViewSwitchInput>({
      viewId: uuidv4(),
      viewKey: defaultViewKey,
    });

  // 言語切り替えhooks
  const { t, localeLanguage } = useSwitchLocaleLanguage();

  /**
   * [ビュー切替]ドロップダウンがクリックされた
   *
   * @param selectedViewKey 選択されたビュー
   */
  const clickViewSwitch = useCallback((selectedViewKey: string): void => {
    // 選択されたビューの値が空の場合はドロップダウン外がクリックされたと判定
    if (!selectedViewKey) {
      return;
    }

    const newInput: ViewSwitchInput = {
      viewId: uuidv4(),
      viewKey: selectedViewKey,
    };
    setViewSwitchInputData(newInput);
  }, []);

  /**
   * ロケール言語が変更された場合の処理
   */
  useEffect(() => {
    // [ビュー切替]メニューの表示内容を差し替え
    const newMenuContents: ViewType = {
      [VIEW_KEY.d]: t('viewSwitchMenu.d'),
      [VIEW_KEY.w]: t('viewSwitchMenu.w'),
      [VIEW_KEY.m]: t('viewSwitchMenu.m'),
    };

    setViewMenuContents(newMenuContents);
  }, [localeLanguage, t]);

  return {
    viewMenuContents,
    viewSwitchInputData,
    clickViewSwitch,
  };
};

export default useViewSwitchDropDown;
