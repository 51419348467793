import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REPORT_VIEW_TYPE } from '../../types/report/fromLangList.d';
import { DIRECTION_TYPE, SortConfig } from '../../types/datatable/dataTable.d';

export interface DeviceListState {
  fromDeviceDetailsPage?: boolean;
  searchText?: string;
  tableSortData?: SortConfig;
  fromDate?: Date;
  toDate?: Date;
  view: REPORT_VIEW_TYPE;
  tableRowNumber: number;
  pageNumber: number;
}

export const translationListInitialState: DeviceListState = {
  fromDeviceDetailsPage: false,
  searchText: '',
  fromDate: new Date(),
  toDate: new Date(),
  view: 'd',
  tableSortData: { key: '', direction: DIRECTION_TYPE.none },
  tableRowNumber: 100,
  pageNumber: 1,
};

export const translationListSlice = createSlice({
  name: 'snackbar',
  initialState: translationListInitialState,
  reducers: {
    addSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    addTableRowNumber: (state, action: PayloadAction<number>) => {
      state.tableRowNumber = action.payload;
    },
    addPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    addTableSortData: (state, action: PayloadAction<SortConfig>) => {
      state.tableSortData = action.payload;
    },
    isFromDeviceDetailsPage: (state, action: PayloadAction<boolean>) => {
      state.fromDeviceDetailsPage = action.payload;
    },
    addView: (state, action: PayloadAction<REPORT_VIEW_TYPE>) => {
      state.view = action.payload;
    },
    addToDate: (state, action: PayloadAction<Date>) => {
      state.toDate = action.payload;
    },
    addFromDate: (state, action: PayloadAction<Date>) => {
      state.fromDate = action.payload;
    },
    resetDeviceListState: (state) => {
      state.searchText = '';
      state.view = 'd';
      state.tableRowNumber = 100;
      state.pageNumber = 1;
      state.fromDate = new Date();
      state.toDate = new Date();
      state.fromDeviceDetailsPage = false;
      state.tableSortData = { key: '', direction: DIRECTION_TYPE.none };
    },
  },
});

export const DeviceListActions = translationListSlice.actions;
export const DeviceListReducer = translationListSlice.reducer;
