import React from 'react';
import { Typography, Container, CssBaseline, Box } from '@mui/material';
import { useStyles } from './CommonStyle';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // Mainコンテンツ内に表示する内容
  children: React.ReactNode;
  // 画面名
  title: string;
};

/**
 * ページテンプレート コンポーネント
 */
const PageTemplate = React.memo<Props>((props): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();

  return (
    <div
      id="pageTemplateRoot"
      className={classes.root}
      style={{ position: 'relative' }}
    >
      <CssBaseline />

      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Typography
            component="h2"
            variant="h5"
            color="textPrimary"
            noWrap
            className={classes.title}
          >
            {props.title}
          </Typography>

          <Box
            component="main"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              minHeight: '100%',
              pt: 2,
            }}
          >
            {props.children}
          </Box>
        </Container>
      </main>
    </div>
  );
});

export default PageTemplate;
