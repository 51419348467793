import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ClosedIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import useSnackbar from '../../hooks/useSnackbar';

import { ReactComponent as CheckFillIcon } from '../../images/icon/check_fill.svg';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import { useStyles } from '../Templates/CommonStyle';

const ToasteMessage = React.memo((): JSX.Element => {
  const classes = useStyles();

  const snackbar = useSelector((state: RootState) => state.snackbarState);
  const { clearSnackbar } = useSnackbar();

  const handleClose = (_: unknown, reason?: string) =>
    reason !== 'clickaway' && clearSnackbar();

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.snakebar}
    >
      <Alert
        variant="filled"
        severity={snackbar.type}
        icon={snackbar.type === 'error' ? <ErrorSign /> : <CheckFillIcon />}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <ClosedIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
});

export default ToasteMessage;
