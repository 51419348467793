import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { ManageGroupUserUpdateApiResponse } from '../types/apis/manageGroupUpdate';
import { API_URL } from '../constants/constants';

/**
 * Device & User list update API call
 *
 * @param accessKey access key
 * @returns Response from the user list update API
 */
const callManageGroupUpdate = (
  groupId: number,
  userAdd: number[],
  userRemove: number[],
  deviceAdd: number[],
  deviceRemove: number[],
): Promise<ManageGroupUserUpdateApiResponse> => {
  const jsonParams = {
    groupId,
    userAdd,
    userRemove,
    deviceAdd,
    deviceRemove,
  };

  // Send request (PUT)
  const response = callApiUtil
    .put<ManageGroupUserUpdateApiResponse>(
      API_URL.MANAGE_GROUP_DEVICE_N_USER_UPDATE_API,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => {
      if (error?.response) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          statusCode: error.response?.data?.statusCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          message: error.response?.data?.message,
        };
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        statusCode: 400,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        message: error?.message,
      };
    });

  return response;
};

export default callManageGroupUpdate;
