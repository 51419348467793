import { NavigateFunction } from 'react-router-dom';
import callGroupDetailApi, {
  GROUP_DETAIL_API_ERROR_TYPE,
  GROUP_DETAIL_API_RESULT_CODE,
} from '../../apis/callGroupDetailApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
// import { PAGE_PATH_NAME } from '../../constants/constants';
import {
  isErrorType,
  // isGroupDetailErrorType,
} from '../../types/apis/apiErrorTypeGuard';

import { GroupDetailApiResponse } from '../../types/apis/groupDetailApi.d';
import { GroupDetailResult } from '../../types/Group/groupDetail.d';

export const groupDetail = async (
  groupId: string,
): Promise<GroupDetailResult> => {
  // User list acquisition API call
  try {
    const apiResponse: GroupDetailApiResponse = await callGroupDetailApi(
      groupId,
    );
    switch (apiResponse.message) {
      // success
      case GROUP_DETAIL_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode: GROUP_DETAIL_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          groupId: apiResponse.details.groupId,
          groupName: apiResponse.details.groupName,
          groupCode: apiResponse.details.groupCode,
          createdDt: apiResponse.details.createdDt,
          createdUserId: apiResponse.details.createdUserId,
          updatedDt: apiResponse.details.updatedDt,
          updatedUserId: apiResponse.details.updatedUserId,
          totalDeviceCount: apiResponse.details.totalDeviceCount,
        };
      // Failure
      case GROUP_DETAIL_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GROUP_DETAIL_API_RESULT_CODE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP:
        throw GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP;
      case GROUP_DETAIL_API_RESULT_CODE.CORPORATION_ACCESS_DENIED:
        throw GROUP_DETAIL_API_RESULT_CODE.CORPORATION_ACCESS_DENIED;
      case GROUP_DETAIL_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw GROUP_DETAIL_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case GROUP_DETAIL_API_RESULT_CODE.AUTH_NOT_ALLOWED:
        throw GROUP_DETAIL_API_RESULT_CODE.AUTH_NOT_ALLOWED;
      case GROUP_DETAIL_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw GROUP_DETAIL_API_RESULT_CODE.WARN_INPUT_PARAM;
      case GROUP_DETAIL_API_RESULT_CODE.INVALID_TOKEN:
        throw GROUP_DETAIL_API_RESULT_CODE.INVALID_TOKEN;
      case GROUP_DETAIL_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw GROUP_DETAIL_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED
      default:
        throw GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: ALL_ERROR_TYPE = GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      groupId: 0,
      groupName: '',
      groupCode: 0,
      createdDt: '',
      createdUserId: 0,
      updatedDt: '',
      updatedUserId: 0,
      totalDeviceCount: 0,
    };
  }
};

// .catch((error: GROUP_DETAIL_API_ERROR_TYPE | unknown) => {
//   if (isGroupDetailErrorType(error)) {
//     setGroupDetailErrorType(error);
//   } else {
//     setGroupDetailErrorType(GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN);
//   }

export const transToGroups = (navigate: NavigateFunction): void => {
  // Invalid groupId forward to Group list page
  navigate(`/groups`, { replace: true });
};
