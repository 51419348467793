import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { fetchUngroupUserList } from '../utils/group/ungroupUserList';
// import useAccessKey from './useAccessKey';

import {
  UngroupUserListResult,
  UserInfo,
} from '../types/Group/ungroupUserList.d';
import {
  UNGROUP_USER_LIST_API_RESULT_CODE,
  UNGROUP_USER_LIST_API_ERROR_TYPE,
} from '../apis/callUnGroupUserListApi';
import { isUnGroupUserListErrorType } from '../types/apis/apiErrorTypeGuard';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
// import useRedirectDialog from './useRedirectDialog';
// import useLogout from './useLogout';

type IDParams = {
  groupId: string;
};

const useUnGroupUserList = (pageNumber: number, searchText: '' | string) => {
  // const { accessKey } = useAccessKey();
  // const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();

  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [originalUserList, setOriginalUserList] = useState<UserInfo[]>([]);
  const [errorType, setErrorType] = useState<string>('');
  const [moreUser, setMoreUser] = useState<boolean>(false);
  const totalUser = useRef(0);
  const { groupId } = useParams<IDParams>();
  const { t } = useSwitchLocaleLanguage();

  const callUngroupUserListApi = useCallback(() => {
    setIsCallingApi(true);
    /*eslint-disable*/
    fetchUngroupUserList(parseInt(groupId!), pageNumber, searchText)
      .then((result: UngroupUserListResult) => {
        totalUser.current = result.totalCount;
        if (
          result.resultCode !==
          UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          // Failed to acquire information
          setOriginalUserList([]);
          setIsCallingApi(false);
          setMoreUser(false);
          throw result.resultCode;
        }
        setErrorType('');
        if (result.users != null && pageNumber === 1) {
          setOriginalUserList(result.users);
          setMoreUser(totalUser.current < result.users.length);
          return;
        }
        if (
          result.users != null &&
          originalUserList.length > 0 &&
          !searchText &&
          pageNumber !== 1
        ) {
          setOriginalUserList((prevUserList) => [
            ...prevUserList,
            ...result.users,
          ]);
          setMoreUser(totalUser.current < originalUserList.length);

          return;
        }

        // if (searchText && pageNumber === 1) {
        //   setOriginalUserList(result.users);
        //   setMoreUser(totalUser.current < originalUserList.length);
        //   return;
        // }

        if (
          result.users != null &&
          originalUserList.length > 0 &&
          searchText &&
          pageNumber !== 1
        ) {
          setOriginalUserList((prevUserList) => [
            ...prevUserList,
            ...result.users,
          ]);
          setMoreUser(totalUser.current < originalUserList.length);

          return;
        }
      })
      .catch((error: UNGROUP_USER_LIST_API_ERROR_TYPE | unknown) => {
        if (isUnGroupUserListErrorType(error)) {
          setErrorType(error);
          if (error === UNGROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH) {
            // displayRedirectDialog({
            //   open: true,
            //   title: 'Not authorized',
            //   message: 'Your role has been changed. Please re-login.',
            //   routePath: onClickLogoff,
            // });
          }
        } else {
          setErrorType(UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN);
        }
      })
      .finally(() => {
        setIsCallingApi(false);
        setMoreUser(totalUser.current < originalUserList.length);
      });
    /*eslint-disable*/
  }, [pageNumber, searchText]);

  useEffect(() => {
    callUngroupUserListApi();
  }, [searchText]);

  useEffect(() => {
    if (pageNumber !== 1) {
      callUngroupUserListApi();
    }
  }, [pageNumber]);

  return {
    moreUser,
    totalUser: totalUser.current,
    t,
    isCallingApi,
    originalUserList,
    setOriginalUserList,
    callUngroupUserListApi,
    errorType,
  };
};

export default useUnGroupUserList;
