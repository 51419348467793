import { TRANS_HISTORY_LIST_ERROR_TYPE } from '../../apis/callTransHistoryListApi';
import type { TransHistoryInfo } from '../apis/transHistoryListApi.d';

/**
 * 翻訳履歴一覧の取得結果 の型
 */
export type TransHistoryListResult = {
  resultCode: TRANS_HISTORY_LIST_ERROR_TYPE;
  titleList: TableHeaderData[];
  dataList: TransHistoryInfo[];
};

/**
 * テーブルデータの表示幅
 */
export const HISTORY_LIST_WIDTH_ARRAY: string[] = [
  '240px', // 翻訳日時(UTC)
  '', // 翻訳元テキスト
  '', // 翻訳後テキスト
  '200px', // 翻訳元言語
  '200px', // 翻訳後言語
];
