import React from 'react';
import { useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';

type Props = {
  text: string;
};

const DisabledInputField = React.memo<Props>(({ text }): JSX.Element => {
  const classes = useStyles();
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const handleShouldShowTooltip = (event: React.MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    // Clone the element
    const clone = element.cloneNode(true) as HTMLElement;

    // Set styles for the clone
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    clone.style.width = 'auto';

    // Append the clone to the DOM
    element.parentNode?.appendChild(clone);

    // Set the element's overflow to visible
    element.style.overflow = 'visible';

    // Get the scroll width of the clone
    const scrollWidth = clone.getBoundingClientRect().width;

    // Remove the clone from the DOM
    element.parentNode?.removeChild(clone);

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';

    if (scrollWidth > clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  };

  return (
    <Box className={classes.disableInputField}>
      <Tooltip
        placement="top"
        title={text}
        disableHoverListener={!tooltipEnabled}
        onMouseEnter={handleShouldShowTooltip}
        arrow
        sx={{ marginTop: 0 }}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-7, -7],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            className: classes.tooltipStyle,
          },
        }}
      >
        <Typography className={classes.disableInputFieldText}>
          {text}
        </Typography>
      </Tooltip>
    </Box>
  );
});
export default DisabledInputField;
