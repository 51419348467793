/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
// import callDeviceLodtModeDisenableApi from '../../apis/callDeviceLostModeDisable';
import callDeviceLodtModeDisenableApi, {
  DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE,
  DEVICE_LOST_MODE_DISABLE_ERROR_TYPE,
} from '../../apis/callDeviceLostModeDisable';
// import { PAGE_PATH_NAME } from '../../constants/constants';

import {
  DeviceLostModeDisableApiRequestParam,
  DeviceLostModeDisableApiResponse,
  DeviceLostModeDisableResponse,
} from '../../types/apis/deviceLostModeDisableApi.d';
// import { GroupAddResult } from '../../types/Group/groupAdd.d';

export const lostModeDisable = async (
  // accessKey: string,
  requestParams: DeviceLostModeDisableApiRequestParam,
): Promise<DeviceLostModeDisableApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: DeviceLostModeDisableResponse =
      await callDeviceLodtModeDisenableApi(requestParams);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_UPDATED':
        return {
          resultCode: DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.OK,
        };
      case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.LOST_MODE_ALREADY_DISABLED:
        return {
          resultCode:
            DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.LOST_MODE_ALREADY_DISABLED,
        };
      // Failure
      case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.INFO_NOTHING_KEY;
      // case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.LOST_MODE_ALREADY_DISABLED:
      //   throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.LOST_MODE_ALREADY_DISABLED;
      case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.INFO_INVALID:
        throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.INFO_INVALID;
      case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.INFO_EXPIRED:
        throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.INFO_EXPIRED;
      case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.WARN_INVALID_AUTH;
      case DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      default:
        throw DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: DEVICE_LOST_MODE_DISABLE_ERROR_TYPE;
    if (
      error ===
      DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED
    ) {
      resultCode =
        DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
    } else {
      resultCode = DEVICE_LOST_MODE_DISABLE_API_RESULT_CODE.ERR_UNKNOWN;
    }
    // if (isGroupListErrorType(error)) {
    //   resultCode = error;
    // }

    return {
      resultCode,
    };
  }
};
/* eslint-disable */
export const transGroupDetail = (navigate: NavigateFunction, id: any): void => {
  // [ユーザー管理]画面に遷移

  navigate(`/groups/groupdetail/${id}`, { replace: true });
};
