import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WEB_STORAGE } from '../../constants/constants';
import { FeaturePermission } from '../../types/apis/loginApi.d';
/**
 * Account Information State returned from the login API
 */
type AccountInfoState = {
  locale: string;
  email: string;
  corpId: string;
  company: string;
  corpCreatedDt: string;
  roleCode: number;
  roleName: string;
  userName: string;
  userId: number;
  totalGroups: number;
  featurePermissions: FeaturePermission;
  roleChange: boolean;
  subcriptionChange: boolean;
  latestEulaConsented: boolean;
  imageUrl: string;
  reloginEulaState: boolean;
  eulaConsentedPreviously: boolean;
  planName: string;
  planCode: number;
  shopifyFeatureEnabled: boolean;
  notificationApiCallDone: boolean;
  userRoleChangeApiCall: boolean;
};

/**
 * Initial State
 */
const initialState: AccountInfoState = {
  locale: '',
  email: '',
  corpId: '',
  company: '',
  corpCreatedDt: '',
  roleCode: 0,
  roleName: '',
  userName: '',
  userId: 0,
  totalGroups: 0,
  roleChange: false,
  subcriptionChange: false,
  featurePermissions: {
    GROUPMANAGEMENT: {
      GROUPNAME: [],
      GROUPDEVICE: [],
      GROUPUSER: [],
      GROUPUSERMODAL: [],
      GROUPBASIC: [],
      REMOTECONTROLWIFI: [],
      GROUPDEVICEMODAL: [],
      REMOTECONTROLFEATURELIMIT: [],
      REMOTECONTROLDATAPROTECTION: [],
    },
    DEVICEMANAGEMENT: {
      DEVICEBASIC: [],
      DEVICELOSTMODE: [],
      DEVICENAME: [],
      DEVICEINITIALIZATION: [],
      DEVICETRANSLATIONHISTORY: [],
    },
    CORPORATEMANAGEMENT: {
      CORPORATEPROFILE: [],
      CORPORATEPLAN: [],
    },
    DASHBOARDMANAGEMENT: {
      DASHBOARDPERIODSWITCHING: [],
    },
    NOTIFICATIONMANAGEMENT: {
      NOTIFICATIONBASIC: [],
    },
    REPORTING: {
      TRANSLATIONCOUNTTO: [],
      TRANSLATIONCOUNT: [],
      TRANSLATIONCOUNTFROM: [],
      LOGDATA: [],
    },
    DICTIONARYMANAGEMENT: {
      DICTIONARYBASIC: [],
    },
    PROFILEMANAGEMENT: {
      USERPASSWORD: [],
      USERPROFILE: [],
      USERNAME: [],
    },
    ACCOUNTMANAGEMENT: {
      USERBASIC: [],
      USERINVITE: [],
      USERROLE: [],
    },
    SECURITYMANAGEMENT: {
      TRANSLATIONHISTORYCONTROL: [],
    },
  },
  latestEulaConsented: true,
  imageUrl: '',
  reloginEulaState: false,
  eulaConsentedPreviously: false,
  planName: '',
  planCode: 0,
  shopifyFeatureEnabled: false,
  notificationApiCallDone: false,
  userRoleChangeApiCall: false,
};

/**
 * Slice that manages account information returned from the login API after logging in
 */
const accountInfoSlice = createSlice({
  name: WEB_STORAGE.ACCOUNT_INFO,
  initialState,
  reducers: {
    saveLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    saveEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    saveCorpId: (state, action: PayloadAction<string>) => {
      state.corpId = action.payload;
    },
    saveCompany: (state, action: PayloadAction<string>) => {
      state.company = action.payload;
    },
    saveCorpCreatedDt: (state, action: PayloadAction<string>) => {
      state.corpCreatedDt = action.payload;
    },
    saveRoleCode: (state, action: PayloadAction<number>) => {
      // console.log(action.payload, 'action.payload');
      state.roleCode = action.payload;
    },
    saveRoleName: (state, action: PayloadAction<string>) => {
      state.roleName = action.payload;
    },
    saveUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    saveUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    saveTotalGroups: (state, action: PayloadAction<number>) => {
      state.totalGroups = action.payload;
    },
    saveRoleChange: (state, action: PayloadAction<boolean>) => {
      state.roleChange = action.payload;
    },
    saveSubcriptionChange: (state, action: PayloadAction<boolean>) => {
      state.subcriptionChange = action.payload;
    },
    saveLatestEulaConsented: (state, action: PayloadAction<boolean>) => {
      state.latestEulaConsented = action.payload;
    },
    saveReLoginEulaState: (state, action: PayloadAction<boolean>) => {
      state.reloginEulaState = action.payload;
    },
    saveEulaConsentedPreviously: (state, action: PayloadAction<boolean>) => {
      state.eulaConsentedPreviously = action.payload;
    },
    saveImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    savePlanName: (state, action: PayloadAction<string>) => {
      state.planName = action.payload;
    },
    savePlanCode: (state, action: PayloadAction<number>) => {
      state.planCode = action.payload;
    },
    saveFeaturePermissions: (
      state,
      action: PayloadAction<FeaturePermission>,
    ) => {
      state.featurePermissions = action.payload;
    },
    saveShopifyFeatureEnabled: (state, action: PayloadAction<boolean>) => {
      state.shopifyFeatureEnabled = action.payload;
    },
    saveNotificationApiCallDone: (state, action: PayloadAction<boolean>) => {
      state.notificationApiCallDone = action.payload;
    },
    saveUserRoleChangeApiCall: (state, action: PayloadAction<boolean>) => {
      state.userRoleChangeApiCall = action.payload;
    },
  },
});

export { accountInfoSlice };
export type { AccountInfoState };
