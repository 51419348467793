import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import HomeBreadcrumbTemplate from '../Templates/HomeBreadcrumbTemplate';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useSecuritySettings from '../../hooks/useSecuritySettings';
import useAccountInfo from '../../hooks/useAccountInfo';
import { ReactComponent as CorporateSecurityIcon } from '../../images/icon/corporate-security.svg';
import ToggleSwitch from '../Atoms/ToggleSwitch';
import SecurityToggleDialog from '../Atoms/SecurityToggleDialog';
import Loader from '../Atoms/Loader';
import { useStyles } from '../Templates/CommonStyle';
import { FEATURE_PERMISSION, PAGE_PATH_NAME } from '../../constants/constants';

const SecuritySettings = (): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();

  const { accountInfo } = useAccountInfo();

  // hooks
  const {
    isToggleActive,
    isCallingGetApi,
    isCallingUpdateApi,
    openSecurityToggleDialog,
    onClickToggleBtn,
    onClose,
    onClickYesBtn,
  } = useSecuritySettings();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accountInfo.featurePermissions?.SECURITYMANAGEMENT) {
      // && !accountInfo.subcriptionChange
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <HomeBreadcrumbTemplate titleText={t('SecuritySettings.title')}>
      <Box sx={{ width: '100%' }}>
        <Box className={classes.securitySettingsCard}>
          <Box className={classes.securitySettingsTitleSection}>
            <Typography
              sx={{
                minWidth: '24px',
              }}
            >
              <CorporateSecurityIcon />
            </Typography>

            <Typography className={classes.securitySettingsCardTitle}>
              {t('SecuritySettings.cardLabel')}
            </Typography>
          </Box>
          <Typography className={classes.securitySettingsCardText}>
            {t('SecuritySettings.cardText')}
          </Typography>

          <Button
            className={classes.securitySettingsBtn}
            onClick={onClickToggleBtn}
            disabled={
              isCallingGetApi ||
              isCallingUpdateApi ||
              !accountInfo.featurePermissions?.SECURITYMANAGEMENT?.TRANSLATIONHISTORYCONTROL?.includes(
                FEATURE_PERMISSION.SECURITYMANAGEMENT.TRANSLATIONHISTORYCONTROL
                  .EDIT,
              )
            }
            disableRipple
            sx={{
              display:
                !accountInfo.featurePermissions?.SECURITYMANAGEMENT?.TRANSLATIONHISTORYCONTROL?.includes(
                  FEATURE_PERMISSION.SECURITYMANAGEMENT
                    .TRANSLATIONHISTORYCONTROL.VIEWDETAILS,
                )
                  ? 'none'
                  : '',
            }}
          >
            {isCallingGetApi ? (
              <Loader colorCode="#b69696" />
            ) : (
              <ToggleSwitch switchStatus={isToggleActive} isBig />
            )}
          </Button>
        </Box>
        <SecurityToggleDialog
          open={openSecurityToggleDialog}
          onClose={onClose}
          onClickYesBtn={onClickYesBtn}
          isCallingApi={isCallingUpdateApi}
        />
      </Box>
    </HomeBreadcrumbTemplate>
  );
};

export default SecuritySettings;
