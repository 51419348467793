import {
  TransHistoryListApiParameter,
  TransHistoryListApiResponse,
} from '../types/apis/transHistoryListApi.d';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * 翻訳履歴取得API 結果コード
 */
export const TRANS_HISTORY_LIST_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 指定された端末が見つからない
  INFO_NO_DEVICE: 'INFO_NO_DEVICE',
  // 指定期間が前後している
  INFO_PERIOD_CONTRADICTION: 'INFO_PERIOD_CONTRADICTION',
  // アクセスキー無し(※翻訳履歴取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※翻訳履歴取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※翻訳履歴取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
  NO_INTERNET: 'NO_INTERNET',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
} as const;

/**
 * 翻訳履歴取得エラータイプ
 */
export type TRANS_HISTORY_LIST_ERROR_TYPE =
  (typeof TRANS_HISTORY_LIST_API_RESULT_CODE)[keyof typeof TRANS_HISTORY_LIST_API_RESULT_CODE];

/**
 * 翻訳履歴取得API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 翻訳履歴取得APIからのレスポンス
 */
const callTransHistoryListApi = (
  requestParams: TransHistoryListApiParameter,
  // accessKey: string,
): Promise<TransHistoryListApiResponse> => {
  // GET
  const response = callApiUtil
    .get<TransHistoryListApiResponse>(
      `${API_URL.TRANS_HISTORY_LIST_API}?imei=${requestParams.imei}&from=${requestParams.from}&to=${requestParams.to}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callTransHistoryListApi;
