/* eslint-disable import/no-cycle */
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import type { LoginApiResponse } from '../types/apis/loginApi';

/* eslint-disable */
const callRefreshTokenApi = (): Promise<LoginApiResponse> => {
  const response = callApiUtil
    .put<LoginApiResponse>(API_URL.REFRESH_TOKEN, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callRefreshTokenApi;
