import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';
import { GroupDeviceListApiResponse } from '../types/apis/groupDeviceListApi.d';

export type TableDataPayload = {
  groupId: string | undefined;
  searchText: string;
  currentPageNumber: number;
  rowLimitPerPage: number;
  colName: string;
  sortDirection: string;
};

/**
 * User List Acquisition API Result Code
 */
export const GROUP_DEVICE_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  NO_INTERNET: 'NO_INTERNET',
} as const;

/**
 * User list acquisition error type
 */
export type GROUP_DEVICE_LIST_API_ERROR_TYPE =
  typeof GROUP_DEVICE_LIST_API_RESULT_CODE[keyof typeof GROUP_DEVICE_LIST_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callGroupDeviceListApi = (
  // accessKey: string,
  tableDataPayload: TableDataPayload,
): Promise<GroupDeviceListApiResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};

  // Send request (GET)
  const {
    searchText,
    groupId,
    colName,
    sortDirection,
    rowLimitPerPage,
    currentPageNumber,
  } = tableDataPayload;
  const response = callApiUtil
    .get<GroupDeviceListApiResponse>(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${API_URL.GROUP_DEVICE_LIST_API}?groupId=${groupId}&searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    /* eslint-disable */
    .catch((error: AxiosError) => {
      if (error.response?.data) {
        return error.response?.data;
      } else if (error.request) {
        return {
          message: GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET,
        };
      }
    });

  return response;
};

export default callGroupDeviceListApi;
