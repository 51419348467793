import { useDispatch } from 'react-redux';
import {
  WelcomeDialogState,
  WelcomeDialogActions,
} from '../state/slice/welcomeDialog';

const useWelcomeDialog = () => {
  const dispatch = useDispatch();

  const displayWelcomeDialog = (welcomeDialog: WelcomeDialogState) => {
    dispatch(WelcomeDialogActions.openWelcomeDialog(welcomeDialog));
  };

  const closeWelcomeDialog = () => {
    dispatch(WelcomeDialogActions.closeWelcomeDialog());
  };

  return { displayWelcomeDialog, closeWelcomeDialog } as const;
};

export default useWelcomeDialog;
