/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import callTransHistoryListApi, {
  TRANS_HISTORY_LIST_API_RESULT_CODE,
  TRANS_HISTORY_LIST_ERROR_TYPE,
} from '../../apis/callTransHistoryListApi';
import { isTransHistoryListErrorType } from '../../types/apis/apiErrorTypeGuard';
import {
  TransHistoryInfo,
  TransHistoryListApiParameter,
  TransHistoryListApiResponse,
} from '../../types/apis/transHistoryListApi.d';
import {
  TableHeaderData,
  TRANS_HISTORY_TABLE_DATA_KEY,
} from '../../types/datatable/dataTable.d';
import {
  TransHistoryListResult,
  HISTORY_LIST_WIDTH_ARRAY,
} from '../../types/history/transHistoryList.d';
import { convertStrToStringyyyyMMddHHmmss } from '../dateUtil';

/**
 * 翻訳履歴取得APIから返却された翻訳履歴の列ヘッダ配列をTableHeaderData型に変換
 *
 * @param targetHeaders
 * @param tableHeaderKey
 * @returns
 */
const convertStringArrayToTableHeaderColumns = (
  targetHeaders: string[],
): TableHeaderData[] => {
  if (targetHeaders === null || targetHeaders.length <= 0) {
    return [];
  }

  // テーブルヘッダーキー配列を作成(翻訳履歴取得APIから返却されるタイトル順)
  const tableHeaderKeys: string[] = [
    TRANS_HISTORY_TABLE_DATA_KEY.TRANSDT,
    TRANS_HISTORY_TABLE_DATA_KEY.FROM_TEXT,
    TRANS_HISTORY_TABLE_DATA_KEY.TO_TEXT,
    TRANS_HISTORY_TABLE_DATA_KEY.FROM_LANG,
    TRANS_HISTORY_TABLE_DATA_KEY.TO_LANG,
  ];

  // ヘッダ変換
  const titleHeaderColumns: TableHeaderData[] = [];
  for (let i = 0; i < targetHeaders.length; i += 1) {
    titleHeaderColumns.push({
      key: tableHeaderKeys[i],
      value: targetHeaders[i],
      isSort: true, // 全項目ソート可能
      width: HISTORY_LIST_WIDTH_ARRAY[i],
    });
  }

  return titleHeaderColumns;
};

/**
 * 翻訳履歴取得APIにリクエスト送信して、一覧を取得
 *
 * @param accessKey アクセスキー
 * @param imei IMEI
 * @param from 翻訳履歴の取得範囲(from)
 * @param to 翻訳履歴の取得範囲(to)
 * @returns
 */
export const fetchTransHistoryList = async (
  // accessKey: string,
  imei: string,
  from: string,
  to: string,
): Promise<TransHistoryListResult> => {
  // リクエストパラメータ
  const requestParam: TransHistoryListApiParameter = {
    imei,
    from,
    to,
  };
  // 翻訳履歴取得API呼び出し
  try {
    const apiResponse: TransHistoryListApiResponse =
      await callTransHistoryListApi(requestParam);
    // 成功
    if (apiResponse.message === 'SUCCESSFULLY_FETCHED') {
      // テーブルのヘッダー作成
      const tableHeader: TableHeaderData[] =
        convertStringArrayToTableHeaderColumns(apiResponse.details.titleList);

      // APIから取得した翻訳日時を「yyyy/MM/dd HH:mm:ss」形式に変換
      const historyList: TransHistoryInfo[] = [];
      apiResponse.details.dataList.forEach((info: TransHistoryInfo) => {
        info.transDt = convertStrToStringyyyyMMddHHmmss(info.transDt);
        historyList.push(info);
      });

      return {
        resultCode: TRANS_HISTORY_LIST_API_RESULT_CODE.OK,
        titleList: tableHeader,
        dataList: historyList,
      };
    }
    // 取得失敗
    switch (apiResponse.message) {
      case TRANS_HISTORY_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NO_DEVICE:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NO_DEVICE;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_PERIOD_CONTRADICTION:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_PERIOD_CONTRADICTION;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_NOTHING_KEY;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_INVALID:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_INVALID;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_EXPIRED:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.INFO_EXPIRED;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED;
      case TRANS_HISTORY_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      case  TRANS_HISTORY_LIST_API_RESULT_CODE.USER_ROLE_CHANGED:
        throw  TRANS_HISTORY_LIST_API_RESULT_CODE.USER_ROLE_CHANGED;
      default:
        throw TRANS_HISTORY_LIST_API_RESULT_CODE.ERR_UNKNOWN; // ログイン認証エラー以外はすべてERR_UNKNOWN扱い
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err?.message === TRANS_HISTORY_LIST_API_RESULT_CODE.NO_INTERNET) {
      const message: TRANS_HISTORY_LIST_ERROR_TYPE =
        err.message as TRANS_HISTORY_LIST_ERROR_TYPE;

      return {
        resultCode: message,
        titleList: [],
        dataList: [],
      };
    }
    let resultCode: TRANS_HISTORY_LIST_ERROR_TYPE =
      TRANS_HISTORY_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isTransHistoryListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      titleList: [],
      dataList: [],
    };
  }
};
