import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { SecuritySettingsApiResponse } from '../types/apis/securitySettingsApi.d';

const callSecuritySettingsGetApi =
  (): Promise<SecuritySettingsApiResponse> => {
    /* eslint-disable */
    const response = callApiUtil
      .get<SecuritySettingsApiResponse>(API_URL.GET_TRANS_HISTORY_PREVENTION_API, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((apiResponse) => apiResponse.data)
      .catch((error) => error.response.data);

    return response;
  };

export default callSecuritySettingsGetApi;
