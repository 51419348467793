import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useAccountInfo from './useAccountInfo';
import useCheckTransition from './useCheckTransition';
import {
  ALL_ERROR_TYPE,
  PAGE_PATH_NAME,
  SERVER_MESSAGE,
} from '../constants/constants';
import callUserInfoApi, {
  USER_INFO_API_RESULT_CODE,
} from '../apis/callUserInfoApi';
import type { UserInfoApiParameter } from '../types/apis/userInfoApi';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import callAuthListApi, {
  AUTH_LIST_API_RESULT_CODE,
} from '../apis/callAuthListApi';
import type { UserDetailInput } from '../types/inputs/userDetailInput';
import {
  UserListTransState,
  UserInfoProsessResultState,
  UserListState,
  USER_INFO_PROSESS_RESULT_CODE,
  USER_LIST_TRANS_METHOD_CODE,
} from '../types/user/userList.d';
import type { UserUpdateApiParameter } from '../types/apis/userUpdateApi';
import callUserUpdateApi, {
  USER_UPDATE_API_RESULT_CODE,
} from '../apis/callUserUpdateApi';
import type { AuthInfo } from '../types/apis/authListApi';
import type { UserDetailState, UserInfo } from '../types/user/userDetail';
import type {
  PasswordReminderSendMailApiRequestParam,
  PasswordReminderSendMailApiResponse,
} from '../types/apis/passwordReminderSendMailApi';
import callPasswordReminderSendMailAuthApi, {
  PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE,
  PW_REMINDER_SEND_MAIL_AUTH_ERROR_TYPE,
} from '../apis/callPasswordReminderSendMailAuthApi';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import { sendGAEvent } from './useGAEvent';
// エラーに関する情報
export type UserDetailError = {
  userInfoErrorType: ALL_ERROR_TYPE | null;
  authListErrorType: ALL_ERROR_TYPE | null;
  userUpdateErrorType: ALL_ERROR_TYPE | null;
  pwReminderSendMailerrorType: ALL_ERROR_TYPE | null;
  errorMessage: string;
  onClickLogoutButton: () => void;
};

// パスワードリマインダーメール送信完了ダイアログに関する情報
export type SendMailComplete = {
  isOpenCompleteDialog: boolean;
  onClickCloseCompleteDialog: () => void;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseUserDetailValue = {
  // APIコール中か否か
  isCallingApi: boolean;
  isCallingSaveApi: boolean;
  // [戻る]ボタン押下時処理
  onClickGoBackButton: () => void;
  // [保存]ボタン押下時処理
  onClickSaveButton: (inpudData: UserDetailInput) => void;
  // [パスワード再設定]ボタン押下時処理
  onClickPasswordResetButton: () => void;
  // ユーザ情報
  // userInfo: UserInfo;
  // 権限一覧
  authList: AuthInfo[];
  // エラーに関する情報
  userDetailError: UserDetailError;
  // ユーザー権限変更ダイアログ表示
  isOpenUserAuthChangeDialog: boolean;
  // パスワードリマインダーメール送信完了ダイアログに関する情報
  sendMailComplete: SendMailComplete;
  // [保存]ボタンをEnabledにするか否か
  isSaveEnabled: boolean;
  // ユーザー名の変更を検知
  handleOnChangeUserName: (changeValue: string) => void;
  // 権限の変更を検知
  handleOnChangeAuthId: (changeValue: string) => void;
  userInfoData: UserInfo;
  registeredAlreadyStatusFetch: (registerStatus: string) => void;
  setUpdateInfo: (updateInfo: boolean)=>void;
};

/**
 * ユーザー詳細(ユーザー情報編集)画面 hooks
 *
 * @returns
 */
const useUserDetail = (): UseUserDetailValue => {
  const { displaySnackbar } = useSnackbar();
  // アクセスキー
  // const { accessKey } = useAccessKey();
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();

  // ユーザ取得エラータイプ
  const [userInfoErrorType, setUserInfoErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);
  // 権限一覧取得エラータイプ
  const [authListErrorType, setAuthListErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);
  // ユーザ更新エラータイプ
  const [userUpdateErrorType, setUserUpdateErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);
  // パスワードリマインダーメール送信エラータイプ
  const [pwReminderSendMailerrorType, setPwReminderSendMailErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);

  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [isCallingSaveApi, setIsCallingSaveApi] = useState<boolean>(false);

  // navigate(画面遷移)
  const navigate = useNavigate();
  // URLパラメータ取得(stateが無い場合は空文字指定)
  const location = useLocation();

  if(!location.state){
    navigate(PAGE_PATH_NAME.DASHBOARD);
  }
  
  const [userDetailState] = useState<UserDetailState>(
    location.state
      ? (location.state as UserDetailState)
      : { mailAddress: '', accountStatus: '' },
  );
  const { accountInfo } = useAccountInfo();
  // ユーザ名
  const [userName, setUserName] = useState<string>('');
  // 権限ID
  const [roleCode, setAuthId] = useState<number>(0);
  // 権限名(ユーザー削除ダイアログ表示用)
  // const [roleName, setAuthName] = useState<string>('');
  const [userInfoData, setUserInfoData] = useState<UserInfo>({
    mailAddress: '',
    imageUrl: '',
    userName: '',
    roleName: '',
    roleCode: 0,
    registeredDt: '',
    invitedDt: '',
    statusColor: '',
    accountStatus: '',
    groups: [],
    lastLoginDt: '',
  });
  // 権限一覧リスト
  const [authList, setAuthList] = useState<AuthInfo[]>([]);

  // ユーザー権限変更ダイアログを表示
  /*eslint-disable*/
  const [isOpenUserAuthChangeDialog, setIsOpenUserAuthChangeDialog] =
    useState<boolean>(false);
  // パスワード再設定メール送信完了ダイアログを表示
  const [isOpenCompleteDialog, setIsOpenCompleteDialog] =
    useState<boolean>(false);

  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  // 登録済のユーザー名から変更があるか否か(true=変更あり)
  const [isChangeUserName, setIsChangeUserName] = useState<boolean>(false);
  // 登録済の権限から変更があるか否か(true=変更あり)
  const [isChangeAuthId, setIsChangeAuthId] = useState<boolean>(false);

  /**
   * 権限一覧取得処理
   * @param auth ユーザ取得処理で取得した権限名
   */
  const fetchAuthList = (auth: string) => {
    void callAuthListApi()
      .then((apiResponse) => {
        if (
          apiResponse.message === SERVER_MESSAGE.WARN_INVALID_AUTH ||
          apiResponse.message === SERVER_MESSAGE.AUTH_NOT_ALLOWED
        ) {
          // displayRedirectDialog({
          //   open: true,
          //   title: 'Not authorized',
          //   message: 'Your role has been changed. Please re-login.',
          //   routePath: onClickLogoff,
          // });

          return;
        }

        if (
          apiResponse.message !== AUTH_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          throw apiResponse.message;
        }

        // OKの場合は権限一覧をセット
        setAuthList(apiResponse.details);

        /*eslint-disable*/
        // ユーザ取得処理で取得した権限名と一致するauthInfoを権限一覧から取得する
        const findAuth = apiResponse.details.find(
          (info) => info.roleName === auth,
        );
        if (findAuth) {
          // 取得できた場合roleCodeをセット
          setAuthId(findAuth?.roleCode);
        }

        // loadingBar非表示
        setIsCallingApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setAuthListErrorType(error);
        } else {
          setAuthListErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        }
        // loadingBar非表示
        setIsCallingApi(false);
      });
  };
  const [updateInfo, setUpdateInfo] = useState(false);
  /**
   * ユーザー取得処理
   */
  const fetchUserDetail = (registerStatus?: string) => {
    const requestParam: UserInfoApiParameter = {
      mailAddress: encodeURIComponent(userDetailState.mailAddress),
      accountStatus: registerStatus
        ? registerStatus
        : userDetailState.accountStatus,
    };

    void callUserInfoApi(requestParam)
      .then((apiResponse) => {
        if (
          apiResponse.message === SERVER_MESSAGE.WARN_INVALID_AUTH ||
          apiResponse.message === SERVER_MESSAGE.AUTH_NOT_ALLOWED
        ) {
          return;
        } else if (apiResponse.message === SERVER_MESSAGE.INFO_NO_USER) {
          displayRedirectDialog({
            open: true,
            title: t('common.error.invalidUser'),
            message: t('userDetail.userInvalidApiError'),
            btnTitle: 'OK',
            routePath: () => navigate(`${PAGE_PATH_NAME.USER_LIST}`),
          });
        } else if (
          apiResponse.message !== USER_INFO_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          // ユーザ情報の取得に失敗
          throw apiResponse.message;
        }

        // OKの場合はユーザ情報をセット
        setUserInfoData(apiResponse.details);
        setUserName(apiResponse.details.userName);
        setUpdateInfo(false)
        // setAuthName(apiResponse.details.roleName);

        // 権限一覧取得処理(loadingBarは表示したまま)
        fetchAuthList(apiResponse.details.roleName);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        // ユーザ取得エラーorログイン認証エラー
        if (error === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED) {
          navigate('/');
        }
        if (isErrorType(error)) {
          setUserInfoErrorType(error);
        } else {
          setUserInfoErrorType(USER_INFO_API_RESULT_CODE.ERR_UNKNOWN);
        }
        // loadingBar非表示
        setIsCallingApi(false);
      });
  };

  const registeredAlreadyStatusFetch = (registerStatus: string) => {
    setIsCallingApi(true);
    resetErrorType();
    fetchUserDetail(registerStatus);
  };

  /**
   * 各種エラータイプを初期化
   */
  const resetErrorType = (): void => {
    setUserInfoErrorType(null);
    setAuthListErrorType(null);
    setUserUpdateErrorType(null);
    setPwReminderSendMailErrorType(null);
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return;
    }

    // loadingBar表示
    setIsCallingApi(true);

    // 各種エラータイプ初期化
    resetErrorType();
    // ユーザー取得処理
    fetchUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.locale]);
  /**
   * When Deactivate User
   */
  useEffect(()=>{
    if(updateInfo){
      fetchUserDetail();
    }

  }, [updateInfo])

  /**
   * [ユーザー管理]画面への遷移処理
   * @param transState 画面遷移時に渡すstate
   */
  const transUserList = (transState: UserListTransState) => {
    navigate(PAGE_PATH_NAME.USER_LIST, {
      state: transState,
    });
  };

  /**
   * [戻る]ボタン押下時処理
   */
  const onClickGoBackButton = () => {
    const userListState: UserListState = {
      transMethod: USER_LIST_TRANS_METHOD_CODE.BACK_BUTTON,
    };

    const userInfoProcState: UserInfoProsessResultState = {
      userInfoProcessResult: USER_INFO_PROSESS_RESULT_CODE.CANCEL,
    };

    const transState: UserListTransState = {
      userListState,
      userInfoProcState,
    };

    transUserList(transState);
  };

  /**
   * ユーザ更新APIに渡すリクエストパラメータ作成
   *
   * @param inpudData 入力内容
   * @returns リクエストパラメータ
   */
  const createUserUpdateApiReqestParam = (
    inpudData: UserDetailInput,
  ): UserUpdateApiParameter => {
    let requestParam: UserUpdateApiParameter = {
      mailAddress: userDetailState.mailAddress,
    };
    if (inpudData.userName !== userName) {
      requestParam = {
        mailAddress: userDetailState.mailAddress,
        userName: inpudData.userName, // ユーザ名の変更がある場合のみパラメータに指定
      };
    }
    if (inpudData.roleCode !== roleCode) {
      requestParam = {
        mailAddress: userDetailState.mailAddress,
        userName: inpudData.userName,
        roleCode: inpudData.roleCode, // 権限の変更がある場合のみパラメータに指定
      };
    }

    return requestParam;
  };

  /**
   * [保存]ボタン押下時処理
   */
  const onClickSaveButton = (inpudData: UserDetailInput) => {
    // loadingBar表示
    setIsCallingSaveApi(true);
    // 各種エラータイプ初期化
    resetErrorType();
    // ユーザ情報更新処理
    callUserUpdateApi(createUserUpdateApiReqestParam(inpudData))
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.UPDATE_OK: {
            // Give a success message and transition to the [User Management] screen
            const userListState: UserListState = {
              transMethod: USER_LIST_TRANS_METHOD_CODE.SAVE_BUTTON,
            };

            const userInfoProcState: UserInfoProsessResultState = {
              userInfoProcessResult:
                USER_INFO_PROSESS_RESULT_CODE.UPDATE_SUCCESS,
            };

            const transState: UserListTransState = {
              userListState,
              userInfoProcState,
            };
            if (userDetailState.mailAddress !== accountInfo.email) {
              displaySnackbar({
                message: t('userDetail.message.updateSuccess'),
                timeout: 3002,
              });
            }
            transUserList(transState);
            break;
          }
          case SERVER_MESSAGE.ACCESS_PERMISSION_DENIED: {
            navigate(-1);
            break;
          }
          case SERVER_MESSAGE.AUTH_NOT_ALLOWED: {
            displaySnackbar({
              message: t('userDetail.message.notAllowed'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.ROLE_UPDATE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN: {
            displaySnackbar({
              message: t('userDeleteDialog.message.mustNeedOneAdmin'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.ROLE_UPDATE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST: {
            displaySnackbar({
              message: t('userDetail.message.corporateContactEmail'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.INFO_NO_USER: {
            displaySnackbar({
              message: t('userDeleteDialog.message.userNotAvailable'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
            break;
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
            break;
          case SERVER_MESSAGE.NO_INTERNET:
            break;
          case SERVER_MESSAGE.USER_ROLE_CHANGED:
            break;
          case SERVER_MESSAGE.INVALID_TOKEN:
            break;
          case SERVER_MESSAGE.EXPIRED_TOKEN:
            break;
          default:
            throw apiResponse.message;
        }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingSaveApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setUserUpdateErrorType(error);
          displaySnackbar({
            message: error,
            type: 'error',
          });
        } else {
          setUserUpdateErrorType(USER_UPDATE_API_RESULT_CODE.ERR_UNKNOWN);
          displaySnackbar({
            message: t('common.error.serverErr'),
            type: 'error',
          });
        }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingSaveApi(false);
      });

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'save');
    sendGAEvent('press_btn', 'save_value', userInfoData.mailAddress);
  };

  /**
   * [パスワード再設定]ボタン押下時処理
   */
  const onClickPasswordResetButton = () => {
    // loadingBar表示
    setIsCallingApi(true);
    // 各種エラータイプ初期化
    resetErrorType();

    const requestParam: PasswordReminderSendMailApiRequestParam = {
      mailAddress: userDetailState.mailAddress,
    };

    callPasswordReminderSendMailAuthApi(requestParam)
      .then((apiResponse: PasswordReminderSendMailApiResponse) => {
        if (
          apiResponse.message ===
          PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.SUCCESSFULLY_CREATED
        ) {
          // loadingBar非表示
          setIsCallingApi(false);

          // パスワード再設定メール送信完了ダイアログを表示
          setIsOpenCompleteDialog(true);

          return;
        }

        throw apiResponse.message;
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setPwReminderSendMailErrorType(error);
        } else {
          setPwReminderSendMailErrorType(
            PW_REMINDER_SEND_MAIL_AUTH_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
        // loadingBar非表示
        setIsCallingApi(false);
      });
  };

  /**
   * パスワード再設定メール送信完了ダイアログ
   * [閉じる]ボタン押下時処理
   */
  const onClickCloseCompleteDialog = () => {
    setIsOpenCompleteDialog(false);
  };

  /**
   * ユーザ詳細エラーメッセージ
   */
  const errorMessage = useMemo(
    (): string => {
      if (
        !userInfoErrorType &&
        !authListErrorType &&
        !userUpdateErrorType &&
        !pwReminderSendMailerrorType
      ) {
        return ''; // エラーコンポーネント自体非表示にする
      }

      // ユーザ取得エラー
      if (userInfoErrorType) {
        switch (userInfoErrorType) {
          case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.EXPIRED_TOKEN:
          case SERVER_MESSAGE.WARN_INVALID_AUTH:
            return ''; // ログイン認証エラー、権限不正エラー時はアクセスキー不正エラーダイアログを表示するのでエラーメッセージは表示しない
          default:
            return t('userDetail.userInfoApiError');
        }
      }

      // 権限一覧取得エラー
      if (authListErrorType) {
        switch (authListErrorType) {
          case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.EXPIRED_TOKEN:
          case SERVER_MESSAGE.WARN_INVALID_AUTH:
            return '';
          default:
            return t('userDetail.authListApiError');
        }
      }

      // ユーザ更新エラー
      if (userUpdateErrorType) {
        switch (userUpdateErrorType) {
          // case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
            return t('userDetail.message.notAllowed');
          case SERVER_MESSAGE.EXPIRED_TOKEN:
          case SERVER_MESSAGE.WARN_INVALID_AUTH:
            return ''; // ログイン認証エラー、権限不正エラー時はアクセスキー不正エラーダイアログを表示するのでエラーメッセージは表示しない
          case SERVER_MESSAGE.INVALID_TOKEN:
            return t('userDetail.userUpdateApiError.infoInvalid');
          case SERVER_MESSAGE.USER_NOT_FOUND:
            return t('userDetail.userUpdateApiError.infoNoUser');
          default:
            return t('userDetail.userUpdateApiError.other');
        }
      }

      // パスワード再設定メール送信エラー
      if (pwReminderSendMailerrorType) {
        switch (pwReminderSendMailerrorType) {
          case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.DELETE_NOT_ALLOWED:
          case SERVER_MESSAGE.EXPIRED_TOKEN:
          case SERVER_MESSAGE.WARN_INVALID_AUTH:
            return ''; // ログイン認証エラー、権限不正エラー時はアクセスキー不正エラーダイアログを表示するのでエラーメッセージは表示しない
          case SERVER_MESSAGE.WARN_INPUT_PARAM:
            return t('userDetail.passwordReminderSendMailError.warnInputParam');
          default:
            return t('userDetail.passwordReminderSendMailError.other');
        }
      }

      return t('userDetail.userInfoApiError');
    },
    [
      userInfoErrorType,
      authListErrorType,
      userUpdateErrorType,
      pwReminderSendMailerrorType,
      t,
    ], // エラータイプが変わる度にエラーメッセージが返却される
  );

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutButton = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    resetErrorType();
  };

  /**
   * ユーザー名の変更を検知
   *
   * @param changedValue 変更後の値
   * @returns
   */
  const handleOnChangeUserName = (changedValue: string): void => {
    if (userName !== changedValue) {
      setIsChangeUserName(true);

      return;
    }

    setIsChangeUserName(false);
  };

  /**
   * 権限の変更を検知
   *
   * @param changedValue 変更後の値
   * @returns
   */
  const handleOnChangeAuthId = (changedValue: string): void => {
    if (roleCode !== Number(changedValue)) {
      setIsChangeAuthId(true);

      return;
    }
    setIsChangeAuthId(false);
  };

  /**
   * [保存]ボタンをEnabledにするか否か
   */
  const isSaveEnabled = useMemo((): boolean => {
    if (isChangeUserName || isChangeAuthId) {
      return true; // 登録済の内容から変更があった場合のみ[保存]ボタンをEnabledで表示
    }

    return false;
  }, [isChangeAuthId, isChangeUserName]);

  return {
    isCallingApi,
    isCallingSaveApi,
    onClickGoBackButton,
    onClickSaveButton,
    onClickPasswordResetButton,
    userInfoData,
    authList,
    userDetailError: {
      userInfoErrorType,
      authListErrorType,
      userUpdateErrorType,
      pwReminderSendMailerrorType,
      errorMessage,
      onClickLogoutButton,
    },
    isOpenUserAuthChangeDialog,
    sendMailComplete: {
      isOpenCompleteDialog,
      onClickCloseCompleteDialog,
    },
    isSaveEnabled,
    handleOnChangeUserName,
    handleOnChangeAuthId,
    registeredAlreadyStatusFetch,
    setUpdateInfo

  };
};

export default useUserDetail;
