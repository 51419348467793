import { useEffect } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import useLocale from './useLocale';

/**
 * クラスの型(publicにする関数などを指定)
 */
export type UseSwitchLocaleLanguage = {
  // 言語切り替え(フレームワーク)
  t: TFunction;
  // 現在の言語
  localeLanguage: string;
};

/**
 * 言語切り替え hooks
 *
 * @returns
 */
const useSwitchLocaleLanguage = (): UseSwitchLocaleLanguage => {
  // 言語切り替え(フレームワーク)
  const { t, i18n } = useTranslation();
  // ロケール(言語)hooks
  const { localeLanguage } = useLocale();

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 言語設定
    void i18n.changeLanguage(localeLanguage);
  }, [i18n, localeLanguage]);

  return {
    t,
    localeLanguage,
  };
};

export default useSwitchLocaleLanguage;
