/* eslint-disable */
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Box, Tooltip, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../images/icon/delete_white.svg';
import { ReactComponent as CrossWhite } from '../../images/icon/cross-white.svg';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import DeleteDialog from './DeleteDialog';
import Loader from './Loader';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

interface DropFileUploadProps {
  onChange: (file: File | null | { name: string; base64File: string }) => void;
  title: string;
  titleMiddle: string;
  titleBottom: string;
  disabled: boolean;
  defValue: File | null | { name: string; base64File: string };
  acceptedFilesTypes?: string[];
  inputFieldMaxWidth?: string;
  inputFieldMaxFileSize?: number;
  inputTextHolderMaxWidth?: string;
  multipleFilesError?: string;
  fileTypeError?: string;
  fileSizeError?: string;
  isRequired?: boolean;
  requiredMessage?: string;
  base64Encode?: boolean;
  name?: string;
}

const DropFileUpload: React.FC<DropFileUploadProps> = ({
  onChange,
  disabled,
  title,
  defValue,
  titleMiddle,
  titleBottom,
  acceptedFilesTypes = [],
  inputFieldMaxWidth = '100%',
  inputFieldMaxFileSize = 16384,
  inputTextHolderMaxWidth = '100%',
  multipleFilesError,
  fileTypeError,
  fileSizeError,
  isRequired = false,
  requiredMessage,
  base64Encode = false,
  name = '',
}) => {
  const [file, setFile] = useState<
    File | null | { name: string; base64File: string }
  >(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState({
    isError: false,
    message: '',
    canCancel: true,
  });
  const fileNameRef = useRef<HTMLDivElement>(null);
  const { t } = useSwitchLocaleLanguage();

  useLayoutEffect(() => {
    if (defValue) setFile(defValue);
    else setFile(null);
  }, [defValue]);

  const handleOpenDeleteDialog = () => {
    if (file) {
      setDeleteDialogOpen(true);
    }
  };

  useEffect(() => {
    setFileError({
      isError: false,
      message: '',
      canCancel: false,
    });
  }, [name]);

  useEffect(() => {
    if (isRequired)
      setFileError({
        isError: isRequired,
        message: requiredMessage || 'Required: File is required.',
        canCancel: false,
      });
    else
      setFileError({
        isError: false,
        message: '',
        canCancel: false,
      });
  }, [isRequired, requiredMessage]);

  const handleSetError = (isError: boolean, message: string) => {
    if (isRequired && !isError) {
      setFileError({
        isError: true,
        message: requiredMessage || 'Required: File is required.',
        canCancel: false,
      });
    } else {
      setFileError({
        isError,
        message,
        canCancel: true,
      });
    }
  };

  const deleteHandleModal = () => {
    if (base64Encode) {
      setFile({
        name: '',
        base64File: '',
      });
      onChange({
        name: '',
        base64File: '',
      });
    } else {
      setFile(null);
    }
    setDeleteDialogOpen(false);
    setFileError({
      isError: false,
      message: '',
      canCancel: true,
    });
    setIsUploading(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setIsUploading(true);
    const uploadedFile = acceptedFiles[0];

    if (acceptedFiles.length !== 1) {
      handleSetError(
        true,
        multipleFilesError || 'Error: Only one file can be uploaded',
      );
      setIsUploading(false);

      return;
    }

    const fileNameParts = uploadedFile.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (
      acceptedFilesTypes.length > 0 &&
      !acceptedFilesTypes.includes(fileExtension)
    ) {
      handleSetError(true, fileTypeError || 'Error: Inappropriate data type');
      setIsUploading(false);

      return;
    }

    if (uploadedFile.size > inputFieldMaxFileSize) {
      handleSetError(
        true,
        fileSizeError || 'Error: File size should be less than 5MB.',
      );
      setIsUploading(false);

      return;
    }

    if (fileExtension === 'pem' || fileExtension === 'p12') {
      if (uploadedFile.size === 0) {
        handleSetError(true, t('remoteDevice.message.dropFileUploadNoContent'));
        setIsUploading(false);

        return;
      }
    }

    if (base64Encode && uploadedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        let base64Data = reader.result?.toString()?.split(',')[1];

        if (base64Data) {
          if (base64Data.startsWith('77u_')) {
            base64Data = base64Data.slice(4);
          }

          let fileContent = atob(base64Data);
          if (fileContent.startsWith('ï»¿')) {
            fileContent = fileContent.slice(3);
          }

          const utf8Bom = '\uFEFF';
          if (fileContent.startsWith(utf8Bom)) {
            fileContent = fileContent.slice(utf8Bom.length);
          }

          if (!fileContent || !/\S/.test(fileContent.trim())) {
            handleSetError(
              true,
              t('remoteDevice.message.dropFileUploadNoContent'),
            );
            return;
          }

          const fileObject = {
            name: uploadedFile?.name ? uploadedFile?.name : '',
            base64File: base64Data,
          };
          setFile(fileObject);
          onChange(fileObject);
        }
      };
    } else {
      setFile(uploadedFile);
      onChange(uploadedFile);
    }

    setFileError({ isError: false, message: '', canCancel: true });
    setIsUploading(false);
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    disabled,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  return (
    <Box>
      {isUploading ||
      (file && typeof file === 'object' && 'base64File' in file && file.name) ||
      (file && file instanceof File && file.name) ? (
        <Box
          sx={{
            width: '100%',
            maxWidth: inputTextHolderMaxWidth,
            height: '34px',
            border: '1px solid #D9D9D9',
            borderRadius: '3px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '4px',
            opacity: isUploading ? 0.5 : 1,
          }}
        >
          {isUploading && (
            <Box
              sx={{
                display: 'flex',
                gap: '5.8px',
                paddingLeft: '6px',
                alignItems: 'center',
              }}
            >
              <Loader colorCode="#313133 " />
              <Typography
                sx={{
                  color: '#313133',
                  fontSize: '14px',
                  fontFamily: 'Noto Sans JP',
                  fontWeight: 'normal',
                }}
              >
                Uploading
              </Typography>
            </Box>
          )}

          {!isUploading && (
            <Tooltip
              title={file?.name}
              placement="top-start"
              arrow
              sx={{ marginTop: 0 }}
            >
              <Typography
                variant="body1"
                component="p"
                ref={fileNameRef}
                sx={{
                  maxWidth: '300px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  paddingLeft: '6px',
                  fontSize: '14px',
                  fontFamily: 'Noto Sans JP',
                  fontWeight: 'normal',
                }}
              >
                {file?.name}
              </Typography>
            </Tooltip>
          )}

          <Box
            sx={{
              width: '48px',
              height: '26px',
              backgroundColor: '#C5C5C5 ',
              borderRadius: '1px 4px 4px 1px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: file ? 'pointer' : 'default',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: '#E1341E',
              },
            }}
            onClick={handleOpenDeleteDialog}
          >
            <DeleteIcon style={{ color: '#24ABE5' }} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: isDragActive ? '#F3FCFF' : '#FAFAFB',
            color: 'gray',
            border: '2px dashed #D9D9D9',
            outline: 'none',
            transition: 'background-color 0.2s ease',
            padding: '4px',
            borderRadius: '3px',
            maxWidth: inputFieldMaxWidth,
          }}
        >
          {fileError.isError ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '38px',
                backgroundColor: '#E1341E',
                color: 'white',
                borderRadius: '3px',
                paddingX: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <ErrorSign color="white" />
                <Typography variant="body2" component="p">
                  {fileError.message}
                </Typography>
              </Box>
              {fileError.canCancel && (
                <CrossWhite
                  style={{ cursor: 'pointer' }}
                  color="white"
                  onClick={() => {
                    handleSetError(false, '');
                  }}
                />
              )}
            </Box>
          ) : null}
          <Box
            // eslint-disable-next-line
            {...getRootProps({
              onClick: (e) => {
                e.stopPropagation();
              },
            })}
            sx={{
              height: '108px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <input
              type="file"
              // eslint-disable-next-line
              {...getInputProps()}
            />
            <Typography
              variant="body2"
              component="p"
              sx={{
                font: 'normal normal bold 14px/20px Noto Sans JP',
                letterSpacing: '0.1px',
                color: '#313133',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#313133',
              }}
              variant="body1"
              component="p"
            >
              {titleMiddle}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              // eslint-disable-next-line
              {...getRootProps()}
              sx={{
                color: '#009DE1',
                fontSize: '14px',
                cursor: 'pointer',
                '&:hover': {
                  color: '#007EB4',
                },
              }}
            >
              {titleBottom}
            </Typography>
          </Box>
        </Box>
      )}

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onSubmit={deleteHandleModal}
        icon="helllos"
        title={t('remoteDevice.message.deleteCaCertification')}
        subTitle={t('groupDetail.label.deleteCaution')}
        btnBgColor="#E1341E"
        btnBgColorHover="#CC240F"
        btnTextNegative={t('groupDetail.label.cancelBtn')}
        btnTextPositive={t('groupDetail.label.deleteBtn')}
      />
    </Box>
  );
};

DropFileUpload.defaultProps = {
  acceptedFilesTypes: [],
  inputFieldMaxWidth: '100%',
  inputFieldMaxFileSize: 16384,
  inputTextHolderMaxWidth: '100%',
  multipleFilesError: 'Error: Only one file can be uploaded',
  fileTypeError: 'Error: Inappropriate data type',
  fileSizeError: 'Error: File size should be less than 5MB.',
  isRequired: false,
  requiredMessage: 'Required: File is required.',
  base64Encode: false,
};

export default DropFileUpload;
