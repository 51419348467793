import useAccountInfo from './useAccountInfo';
import { AppDispatch, useAppDispatch } from '../state/store';
import useAccessKey from './useAccessKey';
import { accessKeySlice } from '../state/slice/accessKeySlice';
import { AccountInfoState } from '../state/slice/accountInfoSlice';

type persistData = {
  accountInfo?: string;
};

const useRefreshRedux = () => {
  const {
    accountInfo,
    // saveLocale,
    saveEmail,
    saveCorpId,
    // saveCompany,
    saveCorpCreatedDt,
    saveRoleCode,
    saveRoleName,
    saveUserName,
    saveUserId,
    saveTotalGroups,
    saveLatestEulaConsented,
    // saveImageUrl,
    savePlanName,
    savePlanCode,
    saveShopifyFeatureEnabled,
    saveEulaConsentedPreviously,
    saveFeaturePermissions,
  } = useAccountInfo();

  const dispatch: AppDispatch = useAppDispatch();
  const { accessKeyRedux } = useAccessKey();
  const { updateToken } = accessKeySlice.actions;

  const refreshRedux = () => {
    const serializedState = localStorage.getItem('persist:root');
    if (serializedState !== null) {
      const state = JSON.parse(serializedState) as persistData;
      if (state.accountInfo) {
        const updateAccountInfo = JSON.parse(
          state.accountInfo,
        ) as AccountInfoState;
        // if (
        //   updateAccountInfo.company &&
        //   accountInfo.company &&
        //   updateAccountInfo.company !== accountInfo.company
        // ) {
        //   saveCompany(updateAccountInfo.company);
        // }
        // if (updateAccountInfo.imageUrl !== accountInfo.imageUrl) {
        //   saveImageUrl(updateAccountInfo.imageUrl);
        // }
        if (
          updateAccountInfo.userName &&
          accountInfo.userName &&
          updateAccountInfo.userName !== accountInfo.userName
        ) {
          saveUserName(updateAccountInfo.userName);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const tokenLive = JSON.parse(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          JSON.parse(localStorage.getItem('persist:root')!)
            .iotAccessKey as string,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        )!.iotAccessKey as string;
        if (tokenLive !== accessKeyRedux && !!tokenLive && !!accessKeyRedux) {
          dispatch(updateToken(tokenLive));
          // saveLocale(updateAccountInfo?.locale);
          saveEmail(updateAccountInfo?.email);
          saveCorpId(updateAccountInfo?.corpId);
          // saveCompany(updateAccountInfo?.company);
          saveCorpCreatedDt(updateAccountInfo?.corpCreatedDt);
          saveRoleCode(updateAccountInfo?.roleCode);
          saveRoleName(updateAccountInfo?.roleName);
          saveUserName(updateAccountInfo?.userName);
          saveUserId(updateAccountInfo?.userId);
          saveTotalGroups(updateAccountInfo?.totalGroups);
          saveLatestEulaConsented(updateAccountInfo?.latestEulaConsented);
          // saveImageUrl(updateAccountInfo?.imageUrl);
          savePlanName(updateAccountInfo?.planName);
          savePlanCode(updateAccountInfo?.planCode);
          saveShopifyFeatureEnabled(updateAccountInfo?.shopifyFeatureEnabled);
          saveEulaConsentedPreviously(
            updateAccountInfo?.eulaConsentedPreviously,
          );
          saveFeaturePermissions(updateAccountInfo?.featurePermissions);
        }
      }
    }
  };

  return {
    refreshRedux,
  };
};

export default useRefreshRedux;
