import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { DashboardDeviceInfoApiResponse } from '../types/apis/dashboardDeviceInfo.d';

const callDashboardDeviceInfoApi =
  (): Promise<DashboardDeviceInfoApiResponse> => {
    /* eslint-disable */
    const response = callApiUtil
      .get<DashboardDeviceInfoApiResponse>(API_URL.DASHBOARD_DEVICE_INFO, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((apiResponse) => apiResponse.data)
      .catch((error) => error.response.data);

    return response;
  };

export default callDashboardDeviceInfoApi;
