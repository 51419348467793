import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DIRECTION_TYPE, SortConfig } from '../../types/datatable/dataTable.d';

export interface GroupDetailsState {
  searchText?: string;
  tableRowNumber: number;
  pageNumber: number;
  tableSortData?: SortConfig;
}

export const groupDetailsInitialState: GroupDetailsState = {
  searchText: '',
  tableRowNumber: 100,
  pageNumber: 1,
  tableSortData: { key: '', direction: DIRECTION_TYPE.none },
};

export const groupDetailsSlice = createSlice({
  name: 'groupDetails',
  initialState: groupDetailsInitialState,
  reducers: {
    addSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    tableRowNumber: (state, action: PayloadAction<number>) => {
      state.tableRowNumber = action.payload;
    },
    pageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    addTableSortData: (state, action: PayloadAction<SortConfig>) => {
      state.tableSortData = action.payload;
    },
    resetGroupDetailsState: (state) => {
      state.searchText = '';
      state.tableSortData = { key: '', direction: DIRECTION_TYPE.none };
      state.tableRowNumber = 100;
      state.pageNumber = 1;
    },
  },
});

export const GroupDetailsActions = groupDetailsSlice.actions;
export const GroupDetailsReducer = groupDetailsSlice.reducer;
