import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WEB_STORAGE } from '../../constants/constants';
import { DEVICE_DETAIL_UPDATE_STATE } from '../../types/devicedetail/deviceDetail.d';

/**
 * 端末情報の更新状態の型
 */
type DeviceDetailUpdateState = {
  deviceDetailUpdateState: DEVICE_DETAIL_UPDATE_STATE;
};

/**
 * 初期State
 */
const initialState: DeviceDetailUpdateState = {
  deviceDetailUpdateState: DEVICE_DETAIL_UPDATE_STATE.PENDING,
};

/**
 * 端末情報の更新状態を管理
 */
const deviceDetailUpdateStateSlice = createSlice({
  name: WEB_STORAGE.DEVICE_DETAIL_UPDATE_STATE,
  initialState,
  reducers: {
    saveUpdateState: (
      state,
      action: PayloadAction<DEVICE_DETAIL_UPDATE_STATE>,
    ) => {
      state.deviceDetailUpdateState = action.payload;
    },
  },
});

export { deviceDetailUpdateStateSlice };
export type { DeviceDetailUpdateState };
