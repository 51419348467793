import callGroupCheckApi, {
  GROUP_CHECK_API_ERROR_TYPE,
  GROUP_CHECK_API_RESULT_CODE,
} from '../../apis/callGroupCheckApi';
import { isGroupCheckErrorType } from '../../types/apis/apiErrorTypeGuard';

import {
  GroupCheckApiParams,
  GroupCheckApiResponse,
  GroupCheckApiUtilResponse,
} from '../../types/apis/groupCheckApi.d';

// eslint-disable-next-line import/prefer-default-export
export const groupCheck = async (
  // accessKey: string,
  requestParams: GroupCheckApiParams,
): Promise<GroupCheckApiUtilResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: GroupCheckApiResponse = await callGroupCheckApi(
      requestParams,
    );
    switch (apiResponse.message) {
      // success
      case GROUP_CHECK_API_RESULT_CODE.WARN_GROUP_NAME_EXIST:
        return {
          resultCode: GROUP_CHECK_API_RESULT_CODE.WARN_GROUP_NAME_EXIST,
        };
      case GROUP_CHECK_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode: GROUP_CHECK_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
        };
      // Failure
      default:
        throw GROUP_CHECK_API_RESULT_CODE.ERR_UNKNOWN;
    }
  } catch (error) {
    let resultCode: GROUP_CHECK_API_ERROR_TYPE =
      GROUP_CHECK_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGroupCheckErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
    };
  }
};
