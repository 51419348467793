import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { ManageGroupUserListUpdateApiResponse } from '../types/apis/manageGroupUserListApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * User List update API Result Code
 */
export const MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE = {
  SUCCESSFULLY_UPDATED: 'SUCCESSFULLY_UPDATED',

  // No such group found in this corporation or user does not have access
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // No user exist in this group
  INFO_NO_USER: 'INFO_NO_USER',
  // Access key is empty or not registered or expired
  WARN_INVALID_ACCESS_KEY: 'WARN_INVALID_ACCESS_KEY',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Input parameter not correct
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // Failed to update selected group user(s)
  ERR_UPDATE_DENIED: 'ERR_UPDATE_DENIED',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',
} as const;

/**
 * User list update error type
 */
export type MANAGE_GROUP_USER_LIST_UPDATE_API_ERROR_TYPE =
  (typeof MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE)[keyof typeof MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE];

/**
 * User list update API call
 *
 * @param accessKey access key
 * @returns Response from the user list update API
 */
const callManageGroupUserListUpdate = (
  // accessKey: string,
  groupId: number,
  add: number[],
  remove: number[],
): Promise<ManageGroupUserListUpdateApiResponse> => {
  const jsonParams = {
    groupId,
    add,
    remove,
  };

  // Send request (PUT)
  const response = callApiUtil
    .put<ManageGroupUserListUpdateApiResponse>(
      API_URL.MANAGE_GROUP_USER_LIST_UPDATE_API,
      jsonParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
    }));

  return response;
};

export default callManageGroupUserListUpdate;
