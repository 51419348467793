import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DIRECTION_TYPE, SortConfig } from '../../types/datatable/dataTable.d';

export interface GroupListState {
  fromGroupDetailsPage?: boolean;
  searchText?: string;
  tableSortData?: SortConfig;
}

export const groupListInitialState: GroupListState = {
  fromGroupDetailsPage: false,
  searchText: '',
  tableSortData: { key: '', direction: DIRECTION_TYPE.none },
};

export const groupListSlice = createSlice({
  name: 'snackbar',
  initialState: groupListInitialState,
  reducers: {
    addSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    addTableSortData: (state, action: PayloadAction<SortConfig>) => {
      state.tableSortData = action.payload;
    },
    isFromGroupDetailsPage: (state, action: PayloadAction<boolean>) => {
      state.fromGroupDetailsPage = action.payload;
    },
    resetGroupListState: (state) => {
      state.searchText = '';
      state.fromGroupDetailsPage = false;
      state.tableSortData = { key: '', direction: DIRECTION_TYPE.none };
    },
  },
});

export const GroupListActions = groupListSlice.actions;
export const GroupListReducer = groupListSlice.reducer;
