import { Box, Skeleton } from '@mui/material';
import styles from './TableBodyContainer.module.scss';

type Props = {
  numberOfColumns: number;
};

const TableBodyReportListSkeleton = ({
  numberOfColumns,
}: Props): JSX.Element => (
  <>
    {Array.from({ length: 5 }, (_, k) => (
      <tr key={k} className={styles.tableDataRowSkeletonDevice}>
        {Array.from({ length: numberOfColumns }, (l, s) => (
          <td key={s}>
            <Box sx={{
              display: 'flex',
              width: '100%',
              justifyContent: s === numberOfColumns - 1 ? 'flex-end' : 'flex-start',
            }}>
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: '3px', width: '100%' }}
                height={20}
              />
            </Box>
          </td>
        ))}
      </tr>
    ))}
  </>
);

export default TableBodyReportListSkeleton;
