// import useSwitchLocaleLanguage from "../../hooks/useSwitchLocaleLanguage";
import { TFunction } from 'react-i18next';
import { translationsOptionsObject } from "../dashboardConstants";

const translationOptionsLocale = (optionText: string, t: TFunction) => {
    // const { t } = useSwitchLocaleLanguage();
    let localeOptionText ='' ;
  
    if (optionText === translationsOptionsObject.ALL) {
        localeOptionText =  t('dashboard.translationsOptions.all');
    } else if (optionText === translationsOptionsObject.PREVIOUS_WEEK) {
      localeOptionText = t('dashboard.translationsOptions.previousWeek');
    } else if (optionText === translationsOptionsObject.THIS_WEEK) {
      localeOptionText = t('dashboard.translationsOptions.thisWeek');
    } else if (optionText === translationsOptionsObject.PREVIOUS_MONTH) {
        localeOptionText = t('dashboard.translationsOptions.previousMonth');
    } else if (optionText === translationsOptionsObject.THIS_MONTH) {
        localeOptionText = t('dashboard.translationsOptions.thisMonth');
    } else if (optionText === translationsOptionsObject.PREVIOUS_YEAR) {
        localeOptionText = t('dashboard.translationsOptions.previousYear');
    } else if (optionText === translationsOptionsObject.THIS_YEAR) {
        localeOptionText = t('dashboard.translationsOptions.thisYear');
    }
  
    return localeOptionText;
  };
  
  export default translationOptionsLocale;