/* eslint-disable import/prefer-default-export */
import callUngroupUserListApi, {
  UNGROUP_USER_LIST_API_ERROR_TYPE,
  UNGROUP_USER_LIST_API_RESULT_CODE,
} from '../../apis/callUnGroupUserListApi';

import { UnGroupUserListApiResponse } from '../../types/apis/ungroupUserListApi.d';
import { UngroupUserListResult } from '../../types/Group/ungroupUserList.d';
import { isUnGroupUserListErrorType } from '../../types/apis/apiErrorTypeGuard';

export const fetchUngroupUserList = async (
  // accessKey: string,
  groupId: number,
  pageNumber: number,
  searchtext: string,
): Promise<UngroupUserListResult> => {
  // User list acquisition API call
  try {
    const apiResponse: UnGroupUserListApiResponse =
      await callUngroupUserListApi(groupId, pageNumber, searchtext);
    switch (apiResponse.message) {
      // success
      case UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode: UNGROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          users: apiResponse.details.users,
          totalCount: apiResponse.details.totalCount,
        };
      // Failure
      case UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER:
        return {
          resultCode: UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_USER,
          users: apiResponse.details.users,
          totalCount: apiResponse.details.totalCount,
        };
      case UNGROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw UNGROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH;

      default:
        throw UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: UNGROUP_USER_LIST_API_ERROR_TYPE =
      UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isUnGroupUserListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      users: [],
      totalCount: 0,
    };
  }
};
