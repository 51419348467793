/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import {
  Button,
  Container,
  Box,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import type { DeviceDetailInput } from '../../types/inputs/deviceDetailInput';
import PageLoadingLayer from '../Templates/PageLoadingLayer';
import PageTemplate from '../Templates/PageTemplate';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import styles from './DeviceDeregister.module.scss';
import useDeviceDeregister from '../../hooks/useDeviceDeregister';

const DeviceDeregister = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  const {
    isCallingApi,
    onClickDeregisterButton,
    onClickGoBackButton,
    simInfo,
    deviceDeregisterError,
  } = useDeviceDeregister();

  // バリデーションフォーム作成
  const { handleSubmit } = useForm<DeviceDetailInput>({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  return (
    <>
      {isCallingApi && <PageLoadingLayer />}
      <PageTemplate title={t('deviceDeregister.viewName')}>
        <Container>
          <form
            className={styles['device-deregister-form']}
            onSubmit={handleSubmit(onClickDeregisterButton)}
          >
            <div className={styles['device-deregister-container']}>
              <div>
                <div className={styles['device-deregister-message']}>
                  <p>{t('deviceDeregister.label.message1')} </p>
                  <p>{t('deviceDeregister.label.message2')} </p>
                  <p>{t('deviceDeregister.label.message3')} </p>
                </div>
                <div>
                  <Table className={classes.deviceDeregisterTable}>
                    <tbody>
                      <TableRow>
                        <TableCell variant="head">
                          {t('deviceDeregister.label.deviceName')} :
                        </TableCell>
                        <TableCell>{simInfo.deviceName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('deviceDeregister.label.productType')} :
                        </TableCell>
                        <TableCell>{simInfo.productType}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('deviceDeregister.label.imei')} :
                        </TableCell>
                        <TableCell>{simInfo.imei}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          {t('deviceDeregister.label.iccid')} :
                        </TableCell>
                        <TableCell>{simInfo.iccid}</TableCell>
                      </TableRow>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            <Box sx={{ pt: 5 }}>
              <div className={styles['deregister-button-container']}>
                <div className={styles['button-display-left']}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className={classes.deviceDetailButton}
                    style={{ textTransform: 'none' }}
                    onClick={onClickGoBackButton}
                  >
                    {t('deviceDeregister.label.goBackButton')}
                  </Button>
                </div>
                <div className={styles['button-display-right']}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    id="deregister"
                    className={classes.deviceDetailButton}
                    style={{ textTransform: 'none' }}
                    type="submit"
                    disabled={
                      deviceDeregisterError.deviceInfoErrorType !== null
                    }
                  >
                    {t('deviceDeregister.label.deregisterButton')}
                  </Button>
                </div>
              </div>
            </Box>
          </form>
        </Container>
      </PageTemplate>
    </>
  );
};

export default DeviceDeregister;
