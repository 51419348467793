import { useSelector } from 'react-redux';
import {
  DrawerActions,
  DrawerState
} from '../state/slice/drawerStateSlice';
import { AppDispatch, RootState, useAppDispatch } from '../state/store';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDrawerInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const drawerInfo = useSelector<RootState, DrawerState>(
    (state) => state.drawerState,
  );
  const {
    openDrawer,
    closeDrawer,
    setDrawerState,
  } = DrawerActions;

  return {
    isDrawerMenuOpen:drawerInfo.open,
    closeDrawer: () => dispatch(closeDrawer()),
    openDrawer: () => dispatch(openDrawer()),
    setIsDrawerMenuOpen:(state:boolean)=>dispatch(setDrawerState(state))
  };
};

export default useDrawerInfo;
