import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DrawerState {
  open: boolean;
}

export const drawerInitialState: DrawerState = {
  open: false,
};

export const drawerOpen = createSlice({
  name: 'drawerOpen',
  initialState: drawerInitialState,
  reducers: {
    openDrawer: () => ({ open: true }),
    closeDrawer: () => ({ open: false }),
    setDrawerState: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const DrawerActions = drawerOpen.actions;
export const DrawerReducer = drawerOpen.reducer;
