import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { DeviceGroupListApiResponse } from '../types/apis/deviceGroupListApi';

export const DEVICE_GROUP_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  OK: 'OK',
  INFO_NO_DATA: 'INFO_NO_DATA',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
} as const;

export type DEVICE_GROUP_LIST_ERROR_TYPE =
  (typeof DEVICE_GROUP_LIST_API_RESULT_CODE)[keyof typeof DEVICE_GROUP_LIST_API_RESULT_CODE];

const callDeviceGroupListApi = (
  rowNumber: number,
  pageNumber: number,
  searchText: string,
): Promise<DeviceGroupListApiResponse> => {
  const response = callApiUtil
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    .get<DeviceGroupListApiResponse>(
      `${API_URL.DEVICE_GROUP_LIST}?rowLimitPerPage=${rowNumber}&currentPageNumber=${pageNumber}&searchText=${searchText}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    /* eslint-disable */
    .catch((error: AxiosError) => error.response?.data);

  return response;
};

export default callDeviceGroupListApi;
