import type {
  UserPreAddApiRequestParameter,
  UserPreAddApiResponse,
} from '../types/apis/userPreAddApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * ユーザ仮登録API 結果コード
 */
export const USER_PREADD_API_RESULT_CODE = {
  // OK
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA',
  USER_ALREADY_REGISTERED: 'USER_ALREADY_REGISTERED',
  USER_NOT_FOUND: 'INFO_NO_USER',
  LANGUAGE_NOT_FOUND: 'INFO_NO_LANGUAGE',
  KEY_CREATION_FAILED: 'ONE_TIME_KEY_CREATION_FAILED',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
} as const;

/**
 * ユーザ仮登録API エラータイプ
 */
export type USER_PREADD_API_ERROR_TYPE =
  typeof USER_PREADD_API_RESULT_CODE[keyof typeof USER_PREADD_API_RESULT_CODE];

/**
 * ユーザ仮登録API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @returns ユーザ仮登録APIからのレスポンス
 */

/*eslint-disable*/
const callUserPreAddApi = (
  requestParams: UserPreAddApiRequestParameter,
): Promise<UserPreAddApiResponse> => {
  // リクエスト送信(POST)
  const response = callApiUtil
    .post<UserPreAddApiResponse>(API_URL.USER_PREADD_API, requestParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callUserPreAddApi;
