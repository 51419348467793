import { USER_LIST_API_ERROR_TYPE } from '../../apis/callUserListApi';
import { UserInfo } from '../apis/userListApi.d';
import { SERVER_MESSAGE } from '../../constants/constants';
/**
 * ユーザー一覧の取得結果 型
 */
export type UserListResult = {
  message: SERVER_MESSAGE;
  details: {
    totalUserCount:number;
    totalUserCountPerPage:number;
    users:UserInfo[];
  };
  // statusCode?: number;
};

/**
 * [ユーザー管理]画面に遷移する際に受け取る値
 * ※URL直打ちを制御するために使用
 */
export type UserListState = {
  // [ユーザー管理]画面への遷移方法
  transMethod: USER_LIST_TRANS_METHOD_TYPE;
};

/**
 * [ユーザー管理]画面への遷移方法（ドロワーメニューから遷移 or ［戻る］ボタンから遷移）
 */
export const USER_LIST_TRANS_METHOD_CODE = {
  // ドロワーメニューから遷移してきた
  DRAWER_MENU: 'DRAWER_MENU',
  // [ユーザー詳細]画面の[戻る]ボタン、[ユーザー招待メール送信]画面の[戻る]ボタンから遷移してきた
  BACK_BUTTON: 'BACK_BUTTON',
  // [ユーザー詳細]画面の[保存]ボタンから遷移してきた
  SAVE_BUTTON: 'SAVE_BUTTON',
  // [ユーザー招待メール送信]画面の[送信]ボタンから遷移してきた
  SEND_BUTTON: 'SEND_BUTTON',
  // [ユーザー削除]ダイアログの[削除]ボタンから遷移してきた
  DELETE_BUTTON: 'DELETE_BUTTON',
} as const;

/**
 * [ユーザー管理]画面への遷移方法タイプ
 */
export type USER_LIST_TRANS_METHOD_TYPE =
  typeof USER_LIST_TRANS_METHOD_CODE[keyof typeof USER_LIST_TRANS_METHOD_CODE];

/**
 * ユーザー情報の操作結果を受け取る
 */
export type UserInfoProsessResultState = {
  userInfoProcessResult: USER_INFO_PROSESS_RESULT_TYPE;
};

/**
 * ユーザー情報の操作結果
 */
export const USER_INFO_PROSESS_RESULT_CODE = {
  // アカウント登録メール送信に成功
  SEND_MAIL_SUCCESS: 'sendMailSuccess',
  // ユーザー情報の更新に成功
  UPDATE_SUCCESS: 'updateSuccess',
  // ユーザーの削除に成功
  DELETE_SUCCESS: 'deleteSuccess',
  // キャンセル
  CANCEL: 'cancel',
  // 操作無し(メニューから遷移)
  NONE: 'none',
} as const;

/**
 * ユーザー情報の操作結果タイプ
 */
export type USER_INFO_PROSESS_RESULT_TYPE =
  typeof USER_INFO_PROSESS_RESULT_CODE[keyof typeof USER_INFO_PROSESS_RESULT_CODE];

/**
 * 画面遷移時に受け取るstateの型
 */
export type UserListTransState = {
  userListState: UserListState;
  userInfoProcState: UserInfoProsessResultState;
};

/**
 * テーブルデータの表示幅
 */
export const USER_WIDTH_ARRAY: string[] = [
  '', // ユーザ－名
  '', // メールアドレス
  '250px', // 権限
];
