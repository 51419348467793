import ReactGA from 'react-ga4';

export const useGAEvent = (category = 'Category') => {
  const trackEvent = (action = 'action', label = 'label') => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return trackEvent;
};

export const sendPageView = ({
  pagetitle = 'Pocketalk Ventana',
  pageLocation = '/',
}) =>
  ReactGA.gtag('event', 'page_view', {
    page_title: pagetitle,
    page_location: pageLocation,
  });

export const sendGAEvent = (
  event_name = '',
  event_params = '',
  event_value = '',
) => {
  ReactGA.gtag('event', event_name, {
    [event_params]: event_value,
  });
};
