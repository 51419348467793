import type { ToLangInfo } from '../apis/toLangListApi.d';
import { TO_LANG_LIST_ERROR_TYPE } from '../../apis/callToLangListApi';
import { TableHeaderData } from '../datatable/dataTable.d';

/**
 * 翻訳後言語一覧の取得結果 の型
 */
export type ToLangListResult = {
  resultCode: TO_LANG_LIST_ERROR_TYPE;
  toLangInfos: ToLangInfo[] | null;
  tableHeader: TableHeaderData[];
  viewType: string;
};

/**
 * テーブルデータの表示幅
 */
export const TO_LANG_WIDTH_ARRAY: string[] = [
  '190px', // 言語
  '110px', // 利用回数
];
