import { isErrorType } from '../../types/apis/apiErrorTypeGuard';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
import callTotalTranslationsApi from '../../apis/callTotalTranslationsApi';
import {
  TotalTranslationsApiResponse,
  AvgTranslationsApiResponse,
} from '../../types/apis/totalTranslationsApi.d';
import callAverageTranslationsApi from '../../apis/callAverageTranslationsApi';
import { NumberOfTranslationsApiResponse } from '../../types/apis/numberOfTranslationsApi.d';
import callNumberOfTranslationsApi from '../../apis/callNumberOfTranslationsApi';
import { LanguageFromTranslationApiResponse } from '../../types/apis/languageFromTranslationApi.d';
import callLanguageFromTranslationApi from '../../apis/callLanguageFromTranslationApi';
import callLanguageToTranslationApi from '../../apis/callLanguageToTranslationApi';

export const fetchTotalTranslations = async (dateRange: {
  firstDay: string;
  lastDay: string;
}): Promise<TotalTranslationsApiResponse> => {
  try {
    const apiResponse: TotalTranslationsApiResponse =
      await callTotalTranslationsApi(dateRange);

    switch (apiResponse.message) {
      case SERVER_MESSAGE.FETCH_OK:
        return apiResponse;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        return apiResponse;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        return apiResponse;

      // 失敗
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
        throw SERVER_MESSAGE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;

      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      statusCode: 500,
      details: { totalTranslations: '0' },
    };
  }
};

export const fetchAverageTranslations = async (dateRange: {
  firstDay: string;
  lastDay: string;
}): Promise<AvgTranslationsApiResponse> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const apiResponse: AvgTranslationsApiResponse =
      await callAverageTranslationsApi(dateRange);
    // eslint-disable @typescript-eslint/no-unsafe-member-access
    switch (apiResponse.message) {
      case SERVER_MESSAGE.FETCH_OK:
        return apiResponse;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        return apiResponse;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        return apiResponse;
      // 失敗
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
        throw SERVER_MESSAGE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      statusCode: 500,
      details: { averageTranslation: '0' },
    };
  }
};

export const fetchNumberOfTranslations = async (
  dateRange: {
    firstDay: string;
    lastDay: string;
  },
  yearFlag: boolean,
): Promise<NumberOfTranslationsApiResponse> => {
  try {
    const apiResponse: NumberOfTranslationsApiResponse =
      await callNumberOfTranslationsApi(dateRange, yearFlag);

    switch (apiResponse.message) {
      case SERVER_MESSAGE.FETCH_OK:
        return apiResponse;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        return apiResponse;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        return apiResponse;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
        throw SERVER_MESSAGE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      statusCode: 500,
      details: { noOfTranslations: [] },
    };
  }
};

export const fetchLanguageFromTranslation = async (dateRange: {
  firstDay: string;
  lastDay: string;
}): Promise<LanguageFromTranslationApiResponse> => {
  try {
    const apiResponse: LanguageFromTranslationApiResponse =
      await callLanguageFromTranslationApi(dateRange);

    switch (apiResponse.message) {
      case SERVER_MESSAGE.FETCH_OK:
        return apiResponse;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        return apiResponse;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        return apiResponse;
      // 失敗
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
        throw SERVER_MESSAGE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      statusCode: 500,
      details: { translationCounts: [] },
    };
  }
};

export const fetchLanguageToTranslation = async (dateRange: {
  firstDay: string;
  lastDay: string;
}): Promise<LanguageFromTranslationApiResponse> => {
  try {
    const apiResponse: LanguageFromTranslationApiResponse =
      await callLanguageToTranslationApi(dateRange);

    switch (apiResponse.message) {
      case SERVER_MESSAGE.FETCH_OK:
        return apiResponse;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        return apiResponse;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.EXPIRED_TOKEN;
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        throw SERVER_MESSAGE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.AUTH_NOT_ALLOWED:
        throw SERVER_MESSAGE.AUTH_NOT_ALLOWED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw SERVER_MESSAGE.ERR_UNKNOWN;
    }
  } catch (error) {
    let message: ALL_ERROR_TYPE = SERVER_MESSAGE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      message = error;
    }

    return {
      message,
      statusCode: 500,
      details: { translationCounts: [] },
    };
  }
};
