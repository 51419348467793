import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_PATH_NAME, URL_PARAMETER_KEY } from '../constants/constants';
import type {
  DeviceInfoApiRequestParam,
  DeviceInfoApiResponse,
} from '../types/apis/deviceInfoApi';
// import useAccessKey from './useAccessKey';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import callDeviceInfoApi, {
  DEVICE_INFO_API_RESULT_CODE,
  DEVICE_INFO_ERROR_TYPE,
} from '../apis/callDeviceInfoApi';
import {
  isDeviceDeregisterErrorType,
  isDeviceInfoErrorType,
} from '../types/apis/apiErrorTypeGuard';
import useCheckTransition from './useCheckTransition';
import type {
  DeviceDeregisterApiRequestParam,
  DeviceDeregisterResponse,
} from '../types/apis/deviceDeregisterApi';
import callDeviceDeregisterApi, {
  DEVICE_DEREGISTER_API_RESULT_CODE,
  DEVICE_DEREGISTER_ERROR_TYPE,
} from '../apis/callDeviceDeregisterApi';

// 端末詳細に関する情報
export type SimInfo = {
  deviceName: string;
  productType: string;
  imei: string;
  iccid: string;
};

// エラーに関する情報
export type DeviceDeregisterError = {
  deviceInfoErrorType: DEVICE_INFO_ERROR_TYPE | null;
  deviceDeregisterErrorType: DEVICE_DEREGISTER_ERROR_TYPE | null;
  errorMessage: string;
  onClickLogoutModal: () => void;
};

/**
 * [端末詳細]画面から遷移時に受け取る値
 */
export type DeviceDeregisterState = {
  imei: string;
  transViewName: string;
};

/**
 * [端末登録解除完了]画面に遷移時に渡す値
 */
export type DeviceDeregisterCompleteState = {
  deviceName: string;
  productType: string;
  imei: string;
  iccid: string;
  transViewName: string;
};

// 本カスタムフックの返り値
export type UseDeviceDeregisterValue = {
  isCallingApi: boolean;
  onClickDeregisterButton: () => void;
  onClickGoBackButton: () => void;
  simInfo: SimInfo;
  deviceDeregisterError: DeviceDeregisterError;
};

const useDeviceDeregister = (): UseDeviceDeregisterValue => {
  // アクセスキー
  // const { accessKey } = useAccessKey();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // APIコール中かどうか
  const [isCallingApi, setIsCallingApi] = useState<boolean>(true); // 画面表示時にloadingBarを表示

  // 端末名
  const [deviceName, setDeviceName] = useState<string>('');
  // 機種名
  const [productType, setProductType] = useState<string>('');
  // IMEI
  const [imei, setImei] = useState<string>('');
  // ICCID
  const [iccid, setIccid] = useState<string>('');

  // 端末情報取得エラータイプ
  const [deviceInfoErrorType, setDeviceInfoErrorType] =
    useState<DEVICE_INFO_ERROR_TYPE | null>(null);

  // 端末登録解除エラータイプ
  const [deviceDeregisterErrorType, setDeviceDeregisterErrorType] =
    useState<DEVICE_DEREGISTER_ERROR_TYPE | null>(null);

  // 画面遷移時に渡されたstateの取得
  const location = useLocation();
  const [paginationState] = useState<DeviceDeregisterState>(
    location.state as DeviceDeregisterState,
  );

  // navigate(画面遷移)
  const navigate = useNavigate();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();

  /**
   * 端末情報取得API呼び出し
   */
  const fetchDeviceInfo = (): void => {
    if (paginationState) {
      const deviceInfoApiRequestParam: DeviceInfoApiRequestParam = {
        imei: paginationState.imei,
      };
      callDeviceInfoApi(deviceInfoApiRequestParam)
        .then((apiResponse: DeviceInfoApiResponse) => {
          if (apiResponse.statusCode !== 200) {
            // 端末情報の取得に失敗
            throw apiResponse.statusCode;
          }

          // OKの場合は端末情報をセット
          setDeviceName(apiResponse.details.deviceInfo.deviceName);
          setProductType(apiResponse.details.deviceInfo.deviceModel);
          setImei(apiResponse.details.deviceInfo.imei);
          setIccid(apiResponse.details.deviceInfo.iccid);
        })
        .catch((error: DEVICE_INFO_ERROR_TYPE | unknown) => {
          // 端末情報取得エラーorログイン認証エラー
          if (isDeviceInfoErrorType(error)) {
            setDeviceInfoErrorType(error);
          } else {
            setDeviceInfoErrorType(DEVICE_INFO_API_RESULT_CODE.ERR_UNKNOWN);
          }
        })
        .finally(() => {
          // loadingBar非表示
          setIsCallingApi(false);
        });
    }
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return;
    }

    // エラータイプ初期化
    setDeviceInfoErrorType(null);
    setDeviceDeregisterErrorType(null);

    // 端末情報取得API呼び出し
    fetchDeviceInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * [解除]ボタン押下時処理
   *
   * @param inputData
   */
  const onClickDeregisterButton = () => {
    // loadingBar表示
    setIsCallingApi(true);

    // エラータイプ初期化
    setDeviceInfoErrorType(null);
    setDeviceDeregisterErrorType(null);

    // 端末登録解除処理
    const deviceDeregisterApiRequestParam: DeviceDeregisterApiRequestParam = {
      imei,
    };

    void callDeviceDeregisterApi(deviceDeregisterApiRequestParam)
      .then((apiResponse: DeviceDeregisterResponse) => {
        if (apiResponse.message !== 'SUCCESSFULLY_UPDATED') {
          setIsCallingApi(false);

          throw apiResponse.message;
        }

        // loadingBar非表示
        // finallyで実行すると警告が出るためここで実行する。
        setIsCallingApi(false);

        // 端末登録解除完了画面に遷移
        const deviceDeregisterCompleteState: DeviceDeregisterCompleteState = {
          deviceName,
          productType,
          imei,
          iccid,
          transViewName: paginationState.transViewName,
        };

        navigate(PAGE_PATH_NAME.DEVICE_DEREGISTER_COMPLETE, {
          state: deviceDeregisterCompleteState,
        });
      })
      .catch((error: DEVICE_DEREGISTER_ERROR_TYPE | unknown) => {
        // 端末情報更新エラーorログイン認証エラー
        if (isDeviceDeregisterErrorType(error)) {
          setDeviceDeregisterErrorType(error);
        } else {
          setDeviceDeregisterErrorType(
            DEVICE_DEREGISTER_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }

        // loadingBar非表示
        setIsCallingApi(false);
      });
  };

  /**
   * [戻る]ボタン押下処理
   */
  const onClickGoBackButton = () => {
    // [端末詳細]画面に遷移
    navigate(
      `${PAGE_PATH_NAME.DEVICE_DETAIL}?${URL_PARAMETER_KEY.imei}=${paginationState.imei}`,
      { state: { transViewName: paginationState.transViewName } },
    );
  };

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!deviceInfoErrorType && !deviceDeregisterErrorType) {
      return '';
    }

    if (deviceInfoErrorType) {
      return t('deviceDeregister.deviceInfoApiError');
    }
    if (deviceDeregisterErrorType) {
      return t('deviceDeregister.deviceDeregisterApiError');
    }

    return t('deviceDeregister.deviceInfoApiError');
  }, [deviceInfoErrorType, deviceDeregisterErrorType, t]);

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutModal = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setDeviceInfoErrorType(null);
    setDeviceDeregisterErrorType(null);
  };

  return {
    isCallingApi,
    onClickDeregisterButton,
    onClickGoBackButton,
    simInfo: {
      deviceName,
      productType,
      imei,
      iccid,
    },
    deviceDeregisterError: {
      deviceInfoErrorType,
      deviceDeregisterErrorType,
      errorMessage,
      onClickLogoutModal,
    },
  };
};
export default useDeviceDeregister;
