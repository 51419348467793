import { Tooltip } from '@mui/material';
import { FC } from 'react';

export type IconWithTooltipType = {
  Icon: FC;
  tooltipTitle: string;
};

const IconWithTooltip = ({ Icon, tooltipTitle }: IconWithTooltipType) => (
  <Tooltip
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-0, -11],
          },
        },
      ],
    }}
    title={tooltipTitle}
    placement="top"
    sx={{
      marginTop: 0,
    }}
    componentsProps={{
      tooltip: {
        sx: { padding: '3px 7px', fontSize: '9px' },
      },
    }}
    arrow
  >
    <Icon />
  </Tooltip>
);

export default IconWithTooltip;
