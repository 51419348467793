/* eslint-disable */
import { Button, Dialog, DialogActions } from '@mui/material';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { useStyles } from './CommonStyle';
import Loader from '../Atoms/Loader';

type Props = {
  isModalOpen: boolean;
  onClickClose: () => void;
};

/**
 * お知らせポップアップ コンポーネント
 *
 * @param param0
 * @returns
 */
const NoticeDialog = ({ isModalOpen, onClickClose }: Props): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  return (
    <Dialog
      open={isModalOpen}
      PaperProps={{
        style: { 
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      }}
    >
      {/* <div className={classes.noticeDialogMessage}>
        <p>{t('noticeDialog.label.message1')}</p>
        <p>{t('noticeDialog.label.message2')}</p>
      </div>

      <div className={classes.dialogButtonContainer}>
        <DialogActions className={classes.cancelDialogButton}>
          <Button
            color="primary"
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={onClickClose}
            className={classes.deviceDetailButton}
          >
            {t('noticeDialog.label.closeButton')}
          </Button>
        </DialogActions>
      </div> */}
      <Loader colorCode="#ffffff" size="medium" />
    </Dialog>
  );
};
export default NoticeDialog;
