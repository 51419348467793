/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Container,
  Box,
  MenuItem,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import PageTemplate from '../Templates/PageTemplate';
import { useStyles } from '../Templates/CommonStyle';
import PageLoadingLayer from '../Templates/PageLoadingLayer';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useUserAdd from '../../hooks/useUserAdd';
import type { UserAddInput } from '../../types/inputs/userAddInput';
import type { AuthInfo } from '../../types/apis/authListApi';
import { EMAIL_CHECK_PATTERN } from '../../constants/constants';
// import { USER_PREADD_API_RESULT_CODE } from '../../apis/callUserPreAddApi';
// import { AUTH_LIST_API_RESULT_CODE } from '../../apis/callAuthListApi';
import styles from './UserAdd.module.scss';

/**
 * ユーザー招待メール送信(ユーザー追加)画面 コンポーネント
 *
 * @returns
 */
const UserAdd = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  // ユーザー招待メール送信(ユーザー追加)画面 hooks
  const {
    userAddError,
    isCallingApi,
    authList,
    onClickBackButton,
    onClickSendButton,
  } = useUserAdd();

  // バリデーションフォーム作成
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<UserAddInput>({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  useEffect(() => {
    // 画面表示時に権限リストのデフォルト値設定
    let roleCodeValue = 0;
    if (authList.length > 0) {
      roleCodeValue = authList[0].roleCode;
    }
    setValue('roleCode', roleCodeValue);
    // setValueに変更があった場合無限にレンダリングが走ってしまう恐れがあるため除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authList]);

  return (
    <>
      {isCallingApi && <PageLoadingLayer />}
      <PageTemplate title={t('userAdd.viewName')}>
        <Container maxWidth="sm">
          <Typography
            align="left"
            variant="body1"
            style={{ whiteSpace: 'pre-line' }}
          >
            {t('userAdd.label.message')}
          </Typography>
          <form onSubmit={handleSubmit(onClickSendButton)}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: EMAIL_CHECK_PATTERN,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('userAdd.label.email')}
                  type="text"
                  fullWidth
                  value={field.value || ''}
                  margin="normal"
                  placeholder={t('userAdd.placeholder.email')}
                  error={Boolean(errors.email)}
                  helperText={
                    (errors.email?.types?.required &&
                      t('userAdd.validationError.email.empty')) ||
                    (errors.email?.types?.pattern &&
                      t('userAdd.validationError.email.invalid'))
                  }
                  disabled={userAddError.authListErrorType !== null}
                  inputProps={{ maxLength: 200 }}
                  autoFocus
                  className={classes.notLoginTextInput}
                />
              )}
            />

            <Controller
              control={control}
              name="userName"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('userAdd.label.userName')}
                  type="text"
                  value={field.value || ''}
                  fullWidth
                  margin="normal"
                  placeholder={t('userAdd.placeholder.userName')}
                  error={Boolean(errors.userName)}
                  helperText={
                    errors.userName?.types?.required &&
                    t('userAdd.validationError.userName.empty')
                  }
                  disabled={userAddError.authListErrorType !== null}
                  inputProps={{ maxLength: 60 }}
                  className={classes.notLoginTextInput}
                />
              )}
            />

            <div className={styles['user-add-auth-list']}>
              <Controller
                control={control}
                name="roleCode"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={clsx(
                      classes.notLoginTextInput,
                      classes.authList,
                    )}
                    label={t('userAdd.label.auth')}
                    value={field.value || ''}
                    select
                    error={Boolean(errors.roleCode)}
                    helperText={
                      errors.roleCode?.types?.required &&
                      t('userAdd.validationError.auth.empty')
                    }
                    disabled={userAddError.authListErrorType !== null}
                  >
                    {authList.map((info: AuthInfo) => (
                      <MenuItem key={info.roleCode} value={info.roleCode}>
                        {info.roleName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>

            <Box sx={{ pt: 5 }}>
              <div className={styles['user-add-button-container']}>
                <div
                  className={clsx(
                    styles['button-display-left'],
                    classes.goBackButton,
                  )}
                >
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className={classes.deviceDetailButton}
                    style={{ textTransform: 'none' }}
                    onClick={onClickBackButton}
                  >
                    {t('userAdd.label.backButton')}
                  </Button>
                </div>
                <div
                  className={clsx(
                    styles['button-display-right'],
                    classes.submitButton,
                  )}
                >
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className={classes.deviceDetailButton}
                    style={{ textTransform: 'none' }}
                    type="submit"
                    disabled={userAddError.authListErrorType !== null}
                  >
                    {t('userAdd.label.sendButton')}
                  </Button>
                </div>
              </div>
            </Box>
          </form>
        </Container>
      </PageTemplate>
    </>
  );
};

export default UserAdd;
