import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type {
  GroupCheckApiResponse,
  GroupCheckApiParams,
} from '../types/apis/groupCheckApi';
import { API_URL } from '../constants/constants';

/**
 * 権限一覧取得API 結果コード
 */
export const GROUP_CHECK_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  WARN_GROUP_NAME_EXIST: 'WARN_GROUP_NAME_EXIST',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
};

/**
 * Group Name Validation
 */
export type GROUP_CHECK_API_ERROR_TYPE =
  typeof GROUP_CHECK_API_RESULT_CODE[keyof typeof GROUP_CHECK_API_RESULT_CODE];

const callGroupCheckApi = (
  requestParams: GroupCheckApiParams,
): Promise<GroupCheckApiResponse> => {
  const response = callApiUtil
    .get<GroupCheckApiResponse>(
      `${API_URL.GROUP_CHECK_API}?groupName=${encodeURIComponent(decodeURIComponent(requestParams.groupName))}&corpId=${requestParams.corpId}`,
      {
        data: {},
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError)=> 
       ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        statusCode:  error.response?.data?.statusCode,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        message: error.response?.data?.message
      })
    );

  return response;
};

export default callGroupCheckApi;
