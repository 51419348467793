import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAccountInfo from '../hooks/useAccountInfo';
import { ROLE_CODE, PAGE_PATH_NAME } from '../constants/constants';

/**
 * Only Admin & User can access
 * @returns
 */
const UserPrivateRoute = () => {
  const { accountInfo } = useAccountInfo();
  const isAuthenticated =
    accountInfo.roleCode === ROLE_CODE.ADMIN ||
    accountInfo.roleCode === ROLE_CODE.USER;

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={PAGE_PATH_NAME.DASHBOARD} />
  );
};

export default UserPrivateRoute;
