import React from 'react';
import { Container, CssBaseline, Box, Typography, Grid } from '@mui/material';
import { useStyles } from './CommonStyle';
import Breadcrumb from '../Modecules/Breadcrumb';
import GroupFeatureHeaderRight from '../Modecules/GroupFeatureHeaderRight';

type Props = {
  children: React.ReactNode;
  titleText: string;
  isUser?: boolean;
  handleClick?: React.MouseEventHandler<HTMLElement>;
  isUserUpdate: boolean;
  disabledGroupUser?: boolean;
  disabledUnGroupUser?: boolean;
};

const GroupFeatureTemplate = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      id="groupPageTemplateRoot"
      className={classes.root}
      style={{ position: 'relative', height: '100%' }}
    >
      <CssBaseline />

      <Container maxWidth="lg" className={classes.groupFeatureContainer}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid>
            <Grid container direction="column" pb="22px">
              <Breadcrumb />
              <Typography
                sx={{
                  pt: '4px',
                  fontSize: '20px',
                  lineHeight: '29px',
                  fontWeight: 'bold',
                  display: 'flex',
                }}
              >
                {props.titleText}
              </Typography>
            </Grid>
          </Grid>
          {props.isUser && (
            <Grid>
              <GroupFeatureHeaderRight
                handleClick={props.handleClick}
                isUserUpdate={props.isUserUpdate}
                disabledGroupUser={props.disabledGroupUser}
                disabledUnGroupUser={props.disabledUnGroupUser}
              />
            </Grid>
          )}
        </Grid>
        <Box
          component="main"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {props.children}
        </Box>
      </Container>
    </div>
  );
});

export default GroupFeatureTemplate;
