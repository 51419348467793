/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callDeviceLostModeEnable, {
  DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE,
  DEVICE_LOST_MODE_ENABLE_ERROR_TYPE,
} from '../../apis/callDeviceLostModeEnable';
// import { PAGE_PATH_NAME } from '../../constants/constants';

import {
  DeviceLostModeEnableApiRequestParam,
  DeviceLostModeEnableApiResponse,
  DeviceLostModeEnableResponse,
} from '../../types/apis/deviceLostModeEnableApi.d';
import { isErrorType } from '../../types/apis/apiErrorTypeGuard';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
// import { GroupAddResult } from '../../types/Group/groupAdd.d';

export const lostModeEnable = async (
  // accessKey: string,
  requestParams: DeviceLostModeEnableApiRequestParam,
): Promise<DeviceLostModeEnableApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: DeviceLostModeEnableResponse =
      await callDeviceLostModeEnable(requestParams);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_CREATED':
        return {
          resultCode: DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.OK,
          /*eslint-disable*/
          //   group_id: apiResponse.groupId,
          //   group_name: apiResponse.groupName,
          //   corp_id: apiResponse.corpId,
        };
      // Failure
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.INFO_NOTHING_KEY;
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.INFO_INVALID:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.INFO_INVALID;
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.INFO_EXPIRED:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.INFO_EXPIRED;
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.WARN_INVALID_AUTH;
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ERR_REQUEST:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ERR_REQUEST;
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.DEVICE_IN_LOST_MODE:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.DEVICE_IN_LOST_MODE;
      case DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.EXPIRED_TOKEN:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      default:
        throw DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: ALL_ERROR_TYPE =
      DEVICE_LOST_MODE_ENABLE_API_RESULT_CODE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      resultCode = error;
    }
    return {
      resultCode,
    };
  }
};
export const transGroupDetail = (navigate: NavigateFunction, id: any): void => {
  // [ユーザー管理]画面に遷移
  navigate(`/groups/groupdetail/${id}`, { replace: true });
};
