import callApiUtil from '../utils/callApiUtil';
import type { LanguageListApiResponse } from '../types/apis/languageListApi';
import { API_URL } from '../constants/constants';

/**
 * Authority list API result code
 */
export const LANGUAGE_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',

  // Common
  ERR_REQUEST: 'ERR_REQUEST',
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  // Only Auth user
  INVALID_TOKEN: 'INVALID_TOKEN',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  USER_DEACTIVATED : 'USER_DEACTIVATED',
};

/**
 * Acquisition of language listing error type
 */
export type LANGUAGE_LIST_API_ERROR_TYPE =
  typeof LANGUAGE_LIST_API_RESULT_CODE[keyof typeof LANGUAGE_LIST_API_RESULT_CODE];

/**
 * Approximately acquisition of Language API calls
 *
 * @returns Response from the API API
 */

/*eslint-disable*/
const callLanguageListApi = (): Promise<LanguageListApiResponse> => {
  const response = callApiUtil
    .get<LanguageListApiResponse>(API_URL.LANGUAGE_LIST_API, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data)
    .catch((error) => error.response.data);

  return response;
};

export default callLanguageListApi;
