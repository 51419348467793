import React from 'react';
import { Box, Typography, Skeleton, IconButton } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ExportCorporate } from '../../images/icon/export_corporate.svg';

type Props = {
  title: string;
  icon: React.ReactNode;
  btnText: string;
  onClickBtn: () => void;
  isCallingApi: boolean;
  isDisable?: boolean;
  shopifyFeatureEnabled: boolean;
};

const CorporatePageBtnCard = React.memo<Props>(
  ({
    title,
    icon,
    btnText,
    onClickBtn,
    isCallingApi,
    isDisable,
    shopifyFeatureEnabled,
  }): JSX.Element => {
    // common style
    const classes = useStyles();

    return (
      <Box
        className={classes.corporatePageBtnCard}
        sx={{ display: shopifyFeatureEnabled ? '' : 'none !important' }}
      >
        <Box className={classes.corporatePageBtnCardHeader}>
          {icon}
          <Typography className={classes.corporateProfilePageCardHeaderTitle}>
            {title}
          </Typography>
        </Box>
        {isCallingApi && (
          <Skeleton
            variant="text"
            animation="wave"
            sx={{
              p: '0px',
              height: '20px',
              width: '100px',
            }}
          />
        )}
        {!isCallingApi && (
          <IconButton
            type="button"
            aria-label="view user"
            className={classes.corporatePageIconBtn}
            onClick={onClickBtn}
            disableRipple
            sx={{ pointerEvents: isDisable ? 'none' : 'auto' }}
          >
            <Typography className={classes.corporatePageBtnCardBtnText}>
              {btnText}
            </Typography>
            <ExportCorporate style={{ width: '13.01px', height: '14.01px' }} />
          </IconButton>
        )}
      </Box>
    );
  },
);

export default CorporatePageBtnCard;
