/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callFactoryResetApi, {
  FACTORY_RESET_API_ERROR_TYPE,
  FACTORY_RESET_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureFactoryReset';
// import { PAGE_PATH_NAME } from '../../constants/constants';
import { isFactoryResetErrorType } from '../../types/apis/apiErrorTypeGuard';

import {
  FactoryResetApiResponse,
  FactoryResetResponse,
} from '../../types/apis/remoteFeatureFactoryReset.d';
// import { GroupDetailResult } from '../../types/Group/groupDetail.d';

export const factoryReset = async (
  imei: number,
): Promise<FactoryResetApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: FactoryResetResponse = await callFactoryResetApi(imei);
    switch (apiResponse.message) {
      // success
      case FACTORY_RESET_API_RESULT_CODE.OK_CREATED:
        return {
          resultCode: FACTORY_RESET_API_RESULT_CODE.OK,
        };
      // Failure

      case FACTORY_RESET_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw FACTORY_RESET_API_RESULT_CODE.INFO_NOTHING_KEY;
      case FACTORY_RESET_API_RESULT_CODE.DEVICE_RESTORE_INITIATED:
        throw FACTORY_RESET_API_RESULT_CODE.DEVICE_RESTORE_INITIATED;
      case FACTORY_RESET_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        throw FACTORY_RESET_API_RESULT_CODE.WARN_NO_AUTH_LIST;
      case FACTORY_RESET_API_RESULT_CODE.ACCESS_PERMISSION_DENIED:
        throw FACTORY_RESET_API_RESULT_CODE.ACCESS_PERMISSION_DENIED;
      //   case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
      //     throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      default:
        throw FACTORY_RESET_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: FACTORY_RESET_API_ERROR_TYPE =
      FACTORY_RESET_API_RESULT_CODE.ERR_UNKNOWN;
    if (isFactoryResetErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
    };
  }
};

// .catch((error: GROUP_DETAIL_API_ERROR_TYPE | unknown) => {
//   if (isGroupDetailErrorType(error)) {
//     setGroupDetailErrorType(error);
//   } else {
//     setGroupDetailErrorType(GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN);
//   }

export const transToGroups = (navigate: NavigateFunction): void => {
  // Invalid groupId forward to Group list page
  navigate(`/groups`, { replace: true });
};
