import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  styled,
  Box,
  Theme,
  CSSObject,
  Tooltip,
  Typography,
} from '@mui/material';
import MainMenu from '../Modecules/MainMenu';
import LogDataDownloadDialog from './LogDataDownloadDialog';
import NoticeDialog from './NoticeDialog';
import useMainMenu from '../../hooks/useMainMenu';
import useAccessKey from '../../hooks/useAccessKey';
import useAccountInfo from '../../hooks/useAccountInfo';
import useLogDataDownloadDialog from '../../hooks/useLogDataDownloadDialog';
import { useStyles } from './CommonStyle';
import { FEATURE_PERMISSION, PAGE_PATH_NAME } from '../../constants/constants';

// assets
import { ReactComponent as Icon } from '../../images/icon.svg';
import { ReactComponent as SettingIcon } from '../../images/icon/setting.svg';
import { ReactComponent as CollapseLeftArrow } from '../../images/icon/collapse-left-arrow.svg';
import { ReactComponent as CollapseRightArrow } from '../../images/icon/collapse-right-arrow.svg';

// components
import Notifications from '../Atoms/Notifications';
import Account from '../Atoms/Account';

const drawerWidth = 250;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: '0px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '80px',
  border: '0px',
});
const MyDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
const Header = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  const location = useLocation();
  const [showDrawer, setShowDrawer] = useState<boolean>(true);
  useEffect(() => {
    if (
      location.pathname.toLowerCase() === PAGE_PATH_NAME.CORPORATE_PROFILE ||
      location.pathname.toLowerCase() === PAGE_PATH_NAME.PERSONAL_SETTING
    ) {
      setShowDrawer(false);
    } else {
      setShowDrawer(true);
    }
  }, [location.pathname]);

  // アクセスキー hooks
  const { accessKey } = useAccessKey();
  // ログイン後のアカウント情報 hooks
  const { accountInfo } = useAccountInfo();
  // メインメニュー hooks
  const {
    isDrawerMenuOpen,
    onClickMenuButton,
    t,
    onClickSettingMenuList,
    onClickUsersMenu,
    isSettingMenuOpen,
    onClickOpenDrawer,
    sidebarRef,
  } = useMainMenu();
  // ログデータダウンロードダイアログ hooks
  const {
    isLogDataDonwloadDialogDisplay,
    openLogDataDownloadDialog,
    closeLogDataDownloadDialog,
    onClickDownload,
    dateRangeValue,
    downloadDate,
    alertType,
    errorMessage,
    onClickCloseNoticeDialog,
    isNoticeDialogDisplay,
    isCallingReportApi,
  } = useLogDataDownloadDialog();

  // company name tooltip handle
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShowTooltip = (event: React.MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    // Set the element's overflow to visible
    element.style.overflow = 'visible';
    element.style.maxWidth = 'fit-content';

    // Get the scroll width
    const scrollWidth = element.getBoundingClientRect().width;

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';
    element.style.maxWidth = '200px';

    if (scrollWidth > clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.appBar}>
        <AppBar position="fixed" elevation={0}>
          <Toolbar className={classes.appbarToolbar}>
            {!accessKey ? (
              <Icon className={classes.loginScreenLogo} />
            ) : (
              <Link to="/dashboard">
                <Icon className={classes.logo} />
              </Link>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: '7px',
              }}
            >
              {accessKey &&
                showDrawer &&
                accountInfo.featurePermissions?.NOTIFICATIONMANAGEMENT?.NOTIFICATIONBASIC?.includes(
                  FEATURE_PERMISSION.NOTIFICATIONMANAGEMENT.NOTIFICATIONBASIC
                    .VIEWLIST,
                ) && <Notifications />}
              {accessKey &&
                showDrawer &&
                // eslint-disable-next-line
                (accountInfo.featurePermissions?.ACCOUNTMANAGEMENT?.USERBASIC?.includes(
                  FEATURE_PERMISSION.ACCOUNTMANAGEMENT.USERBASIC.VIEWLIST,
                ) ||
                  accountInfo.featurePermissions?.SECURITYMANAGEMENT) && (
                  <Tooltip
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    }}
                    /*eslint-disable*/
                    title={t('profileMenu.settingTooltip') as string}
                    placement="bottom"
                    sx={{ marginTop: 0 }}
                    arrow
                    componentsProps={{
                      tooltip: {
                        className: classes.iconTooltipStyle,
                      },
                    }}
                  >
                    <IconButton
                      size="large"
                      aria-label="settings"
                      color="inherit"
                      className={classes.headerIcon}
                      onClick={() => {
                        onClickUsersMenu();

                        onClickSettingMenuList('setting');
                      }}
                    >
                      <SettingIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {accessKey && (
                <>
                  <Tooltip
                    placement="bottom"
                    title={accountInfo.company}
                    disableHoverListener={!tooltipEnabled}
                    onMouseEnter={handleShouldShowTooltip}
                    arrow
                    sx={{ marginTop: 0 }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        className: classes.headerCompanyTooltipStyle,
                      },
                    }}
                  >
                    <Typography
                      className={
                        showDrawer
                          ? classes.accountName
                          : classes.accountNameForNoDrawer
                      }
                    >
                      {accountInfo.company}
                    </Typography>
                  </Tooltip>

                  <Account
                    accountInfo={
                      accountInfo.userName?.length
                        ? accountInfo.userName?.toString()
                        : 'A'
                    }
                    accountImage={
                      accountInfo?.imageUrl && accountInfo?.imageUrl
                    }
                  />
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </div>
      {accessKey && showDrawer && (
        <MyDrawer
          variant="permanent"
          anchor="left"
          open={!isDrawerMenuOpen}
          className="sidebar-drawer"
          ref={sidebarRef}
        >
          <Box className={classes.drawerBox}>
            <MainMenu
              closeMainMenu={onClickMenuButton}
              onClickLogDataMenu={openLogDataDownloadDialog}
              closeSettingMenu={onClickSettingMenuList}
              isSettingMenuOpen={isSettingMenuOpen}
              isDrawerMenuOpen={isDrawerMenuOpen}
              onClickOpenDrawer={onClickOpenDrawer}
            />

            <IconButton
              onClick={onClickMenuButton}
              disableRipple
              className={classes.drawerBottomToggle}
            >
              {isDrawerMenuOpen ? (
                <CollapseRightArrow />
              ) : (
                <CollapseLeftArrow />
              )}
            </IconButton>
          </Box>
        </MyDrawer>
      )}
      <LogDataDownloadDialog
        isModalOpen={isLogDataDonwloadDialogDisplay}
        closeModal={closeLogDataDownloadDialog}
        onClickDownload={onClickDownload}
        dateRangeValue={dateRangeValue}
        downloadDate={downloadDate}
        alertType={alertType}
        errorMessage={errorMessage}
        isCallingReportApi={isCallingReportApi}
      />
      <NoticeDialog
        isModalOpen={isNoticeDialogDisplay}
        onClickClose={onClickCloseNoticeDialog}
      />
    </>
  );
};

export default Header;
