import type {
  FetchSimInfoApiRequestParameter,
  FetchSimInfoApiResponse,
} from '../types/apis/fetchSimInfoApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * SIM情報取得API 結果コード
 */
export const FETCH_SIM_INFO_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // パラメータのsim_accountと一致するSORACOMアカウントがない
  WARN_INVALID_TYPE: 'WARN_INVALID_TYPE',
  // iccid登録済み
  WARN_ICCID_REGISTERD: 'WARN_ICCID_REGISTERD',
  // データが存在しない
  INFO_NOT_FOUND: 'INFO_NOT_FOUND',
  // DBにSIMが存在しない
  INFO_NOT_FOUND_DB: 'INFO_NOT_FOUND_DB',
  // アクセスキー無し(※SIM情報取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // 未登録のアクセスキー(※SIM情報取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_INVALID: 'INFO_INVALID',
  // 有効期限切れ(※SIM情報取得API内でコールしたログイン認証APIから返却されるコード)
  INFO_EXPIRED: 'INFO_EXPIRED',
  // SORACOM認証API失敗
  ERR_AUTH_FAILED: 'ERR_AUTH_FAILED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
/**
 * SIM情報取得エラータイプ
 */
export type FETCH_SIM_INFO_ERROR_TYPE =
  typeof FETCH_SIM_INFO_API_RESULT_CODE[keyof typeof FETCH_SIM_INFO_API_RESULT_CODE];

/**
 * SIM情報取得API 呼び出し
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns SIM情報取得APIからのレスポンス
 */
const callFetchSimInfoApi = (
  requestParams: FetchSimInfoApiRequestParameter,
  // accessKey: string,
): Promise<FetchSimInfoApiResponse> => {
  // リクエストパラメータ
  const jsonParams = {
    imei: requestParams.imei,
  };
  // リクエスト送信(POST)
  const response = callApiUtil
    .get<FetchSimInfoApiResponse>(
      `${API_URL.FETCH_SIM_INFO_API}?imei=${jsonParams.imei}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callFetchSimInfoApi;
