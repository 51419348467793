/* eslint-disable import/prefer-default-export */
import callGroupDeviceListApi, {
  TableDataPayload,
  GROUP_DEVICE_LIST_API_ERROR_TYPE,
  GROUP_DEVICE_LIST_API_RESULT_CODE,
} from '../../apis/callGroupDeviceListApi';
import { SERVER_MESSAGE } from '../../constants/constants';
import { isGroupDetailDeviceListErrorType } from '../../types/apis/apiErrorTypeGuard';
import { GroupDeviceListApiResponse } from '../../types/apis/groupDeviceListApi.d';

import { GroupDeviceListResult } from '../../types/Group/groupDeviceList.d';

export const fetchGroupDeviceList = async (
  // accessKey: string,
  tableDataPayload: TableDataPayload,
): Promise<GroupDeviceListResult> => {
  // User list acquisition API call
  try {
    const apiResponse: GroupDeviceListApiResponse =
      await callGroupDeviceListApi(tableDataPayload);
    switch (apiResponse.message) {
      // success
      case GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode: GROUP_DEVICE_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          groupDeviceList: apiResponse.details.devices,
          /* tslint:disable-next-line */
          totalCount: apiResponse.details.totalCount,

          // paginationData: apiResponse.paginationData
        };
      // Failure
      case GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP:
        throw GROUP_DEVICE_LIST_API_RESULT_CODE.INFO_NO_GROUP;
      case GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED:
        throw GROUP_DEVICE_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED;
      case GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GROUP_DEVICE_LIST_API_RESULT_CODE.WARN_INVALID_AUTH;
      case GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET:
        throw GROUP_DEVICE_LIST_API_RESULT_CODE.NO_INTERNET;
      case SERVER_MESSAGE.USER_CONSENT_REQUIRED:
        throw SERVER_MESSAGE.USER_CONSENT_REQUIRED;
      default:
        throw GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: GROUP_DEVICE_LIST_API_ERROR_TYPE =
      GROUP_DEVICE_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isGroupDetailDeviceListErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      groupDeviceList: [],
      totalCount: 0,
    };
  }
};
