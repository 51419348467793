import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';
import { LanguageFromTranslationApiResponse } from '../types/apis/languageFromTranslationApi.d';

export const LANGUAGE_FROM_TRANSLATION_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  INFO_NO_DATA: 'INFO_NO_DATA',
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  INFO_INVALID: 'INFO_INVALID',
  INFO_EXPIRED: 'INFO_EXPIRED',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  NO_INTERNET: 'NO_INTERNET',
} as const;

export type LANGUAGE_FROM_TRANSLATION_ERROR_TYPE =
  (typeof LANGUAGE_FROM_TRANSLATION_API_RESULT_CODE)[keyof typeof LANGUAGE_FROM_TRANSLATION_API_RESULT_CODE];

const callLanguageFromTranslationApi = (dateRange: {
  firstDay: string;
  lastDay: string;
}): Promise<LanguageFromTranslationApiResponse> => {
  const response = callApiUtil
    .get<LanguageFromTranslationApiResponse>(
      `${API_URL.DASHBOARD_LANGUAGE_FROM_TRANSLATIONS}?from=${dateRange.firstDay}&to=${dateRange.lastDay}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    /* eslint-disable */
    .catch((error: AxiosError) => {
      if (error.response?.data) {
        return error.response?.data;
      } else if (error.request) {
        return {
          message: LANGUAGE_FROM_TRANSLATION_API_RESULT_CODE.NO_INTERNET,
        };
      }
    });

  return response;
};

export default callLanguageFromTranslationApi;
