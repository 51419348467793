import callApiUtil from '../utils/callApiUtil';
import type { UnGroupUserListApiResponse } from '../types/apis/ungroupUserListApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * User List Acquisition API Result Code
 */
export const UNGROUP_USER_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  // Invalid permission error
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // No user Found
  INFO_NO_USER: 'INFO_NO_USER',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * User list acquisition error type
 */
export type UNGROUP_USER_LIST_API_ERROR_TYPE =
  typeof UNGROUP_USER_LIST_API_RESULT_CODE[keyof typeof UNGROUP_USER_LIST_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callUngroupUserListApi = (
  // accessKey: string,
  groupId: number,
  pageNumber: number,
  searchText: string,
): Promise<UnGroupUserListApiResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};

  // Send request (GET)
  const response = callApiUtil
    .get<UnGroupUserListApiResponse>(
      `${API_URL.UNGROUP_USER_LIST_API}?groupId=${groupId}&currentPageNumber=${
        pageNumber - 1
      }&searchText=${searchText}&rowLimitPerPage=10`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data);

  return response;
};

export default callUngroupUserListApi;
