import { Divider, Skeleton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
// import { useStyles } from './UserDataDialogStyle';

const UserDialogListSkeleton = (): JSX.Element => (
  <>
    {Array.from({ length: 5 }, (_, k) => (
      <List key={k} sx={{ mr: '15px' }}>
        <ListItem
          secondaryAction={
            <Skeleton
              variant="text"
              sx={{
                fontSize: '14',
                lineHeight: '24px',
                width: '46px',
                marginBottom: '10px',
              }}
            />
          }
        >
          <ListItemAvatar>
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Skeleton
                variant="text"
                sx={{ fontSize: '14', width: '130px' }}
              />
            }
            secondary={
              <Skeleton
                variant="text"
                sx={{ fontSize: '14', width: '185px' }}
              />
            }
          />
        </ListItem>
        {k !== 4 && <Divider light sx={{ mr: '10px', color: '#D9D9D9' }} />}
      </List>
    ))}
  </>
);

export default UserDialogListSkeleton;
