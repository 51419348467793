import { useDispatch } from 'react-redux';
import { SnackbarActions, SnackbarState } from '../state/slice/snackbarSlice';
import { sendGAEvent } from './useGAEvent';

const useSnackbar = () => {
  const dispatch = useDispatch();

  const displaySnackbar = (snackbar: SnackbarState) => {
    dispatch(SnackbarActions.addSnackbar(snackbar));
  };

  const clearSnackbar = () => {
    dispatch(SnackbarActions.clearSnackbar());
    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'message_exit');
  };

  return { displaySnackbar, clearSnackbar } as const;
};

export default useSnackbar;
