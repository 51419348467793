import { useEffect, useState, useRef } from 'react';
import { Dayjs } from 'dayjs';
import LinkedCalendar from '../../utils/daterangepickerreactindex/index';
import './DateRange.scss';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

interface ApplyFunc {
  startDate: Dayjs;
  endDate: Dayjs;
}
type propsType = {
  state: (data: string) => void;
  value: string;
  parentElementState: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line react/require-default-props
  maxDate?: Date;
};
const DateRangeReact = ({
  state,
  value,
  parentElementState,
  maxDate,
}: propsType) => {
  // console.log(LinkedCalendar)
  const [dateRangeOpen, setDateRangeOpen] = useState(false);
  const docBody = useRef(document.body);
  const inputField = useRef<HTMLElement | null>();
  const rangePicker = useRef<HTMLElement | null>();
  useEffect(() => {
    docBody.current.addEventListener('click', (e: MouseEvent) => {
      inputField.current = document.getElementById('dateRangeInput');
      rangePicker.current = document.getElementById('dateRangePickerUI');
      if (
        !rangePicker.current ||
        rangePicker.current === null ||
        (inputField.current !== null &&
          inputField.current.contains(e.target as Node))
      ) {
        return;
      }
      if (
        !(
          rangePicker.current &&
          rangePicker.current !== null &&
          rangePicker.current.contains(e.target as Node)
        )
      ) {
        setDateRangeOpen(false);
        parentElementState(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  const onDatesChange = ({ startDate, endDate }: ApplyFunc) => {
    state(
      `${startDate.format('YYYY/MM/DD')} - ${endDate.format('YYYY/MM/DD')}`,
    );
  };
  const { t } = useSwitchLocaleLanguage();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        height: '37px',
      }}
    >
      <input
        id="dateRangeInput"
        placeholder={t('report.validation.noDate')}
        className="rangepickerinput"
        readOnly
        value={value}
        onClick={() => setDateRangeOpen(!dateRangeOpen)}
        style={{
          border: '1px solid transparent',
          borderRadius: '6px',
          opacity: '1',
          padding: '8px 10px',
          outline: 'none',
          zIndex: '1',
          width: '100%',
          fontSize: '14px',
          backgroundColor: 'transparent',
          cursor: 'pointer',
          height: '100%',
        }}
      />
      {dateRangeOpen && (
        <LinkedCalendar
          showPicker={dateRangeOpen}
          showPickerChange={setDateRangeOpen}
          onDatesChange={onDatesChange}
          setDateValue={state}
          showDropdowns={false}
          parentElement={parentElementState}
          maxDate={maxDate}
          apply={t('common.calenderBtn.apply')}
          cancel={t('common.calenderBtn.cancel')}
        />
      )}
    </div>
  );
};

export default DateRangeReact;
