import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type {
  GroupTopUserApiResponse,
  // GroupTopUserApiRequestParam,
} from '../types/apis/groupTopUserApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

export const GROUP_TOP_USER_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  INFO_NO_DATA: 'INFO_NO_DATA',
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  CORPORATION_ACCESS_DENIED: 'CORPORATION_ACCESS_DENIED',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
} as const;

/**
 * Group Top User acquisition error type
 */
export type GROUP_TOP_USER_ERROR_TYPE =
  (typeof GROUP_TOP_USER_API_RESULT_CODE)[keyof typeof GROUP_TOP_USER_API_RESULT_CODE];

/**
 * Group Top USer acquisition API call
 *
 * @param requestParams リクエストパラメータ
 * @param accessKey アクセスキー
 * @returns 端末一覧取得APIからのレスポンス
 */
const callGroupTopUserApi = (
  // accessKey: string,
  // requestParams: GroupTopUserApiRequestParam,
  groupId: number,
): Promise<GroupTopUserApiResponse> => {
  // const jsonParams = {
  //   corpId: requestParams.corpId,
  //   groupId: requestParams.groupId,
  // };
  // Request transmission (post)
  const response = callApiUtil
    .get<GroupTopUserApiResponse>(
      `${API_URL.GROUP_TOP_USER_API}?groupId=${groupId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      statusCode: error.response?.data?.statusCode,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error.response?.data?.message,
      details: {
        numberOfUsers: 0,
        users: [],
        groupCode: 10,
      },
    }));

  return response;
};

export default callGroupTopUserApi;
