import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { FactoryResetResponse } from '../types/apis/remoteFeatureFactoryReset.d';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * User List Acquisition API Result Code
 */
export const FACTORY_RESET_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  OK_CREATED: 'SUCCESSFULLY_CREATED',

  // Failed to get authorization list
  WARN_NO_AUTH_LIST: 'WARN_NO_AUTH_LIST',
  // No Access Key
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // No user Found
  INFO_NO_USER: 'INFO_NO_USER',
  // Invalid remote setting mode
  INFO_NO_REMOTE_SETTING_MODE: 'INFO_NO_REMOTE_SETTING_MODE',
  DEVICE_RESTORE_INITIATED: 'DEVICE_RESTORE_INITIATED',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
} as const;

/**
 * User list acquisition error type
 */
export type FACTORY_RESET_API_ERROR_TYPE =
  (typeof FACTORY_RESET_API_RESULT_CODE)[keyof typeof FACTORY_RESET_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callFactoryResetApi = (
  // accessKey: string,
  imei: number,
  //   requestParams: RemoteFeatureSetupApiRequestParam,
): Promise<FactoryResetResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};

  // Send request (GET)
  const response = callApiUtil
    .post<FactoryResetResponse>(
      `${API_URL.REMOTE_FEATURE_FACTORY_RESET_API}?imei=${imei}`,
      {},
      //   requestParams,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error: AxiosError) => error.response?.data as FactoryResetResponse);

  return response;
};

export default callFactoryResetApi;
