import { Skeleton } from '@mui/material';
import styles from './TableBodyContainer.module.scss';

const TableBodyUserSkeleton = (): JSX.Element => (
  <>
    {Array.from({ length: 7 }, (_, k) => (
      <tr key={k} className={styles.tableDataRowSkeletonUser}>
        <td style={{ width: '23%' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '45%' }}
            height={20}
          />
        </td>
        <td style={{ width: '23%' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '45%' }}
            height={20}
          />
        </td>
        <td style={{ width: '15%' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '45%' }}
            height={20}
          />
        </td>
        <td style={{ width: '15%' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '45%' }}
            height={20}
          />
        </td>
        <td style={{ width: '13%' }}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '3px', width: '45%' }}
            height={20}
          />
        </td>
        <td
          style={{ width: '11%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <div style={{ width: '53px', marginRight: '25px' }}>
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: '3px', width: '70%' }}
              height={20}
            />
          </div>
        </td>
      </tr>
    ))}
  </>
);

export default TableBodyUserSkeleton;
