import { useState, useEffect } from 'react';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import callLanguageListApi from '../apis/callLanguageListApi';
import type {
  LanguageListApiResponse,
  ModifiedLanguageInfo,
} from '../types/apis/languageListApi';

export type UseLocaleListValue = {
  localeList: ModifiedLanguageInfo[];
  isCallingLocaleListApi: boolean;
  localeListApiError: ALL_ERROR_TYPE | null;
  callLocaleList: () => void;
};
const useLocaleList = (): UseLocaleListValue => {
  const [localeList, setLocaleList] = useState<ModifiedLanguageInfo[]>([]);
  const [isCallingLocaleListApi, setIsCallingLocaleListApi] =
    useState<boolean>(false);
  const [localeListApiError, setLocaleListApiError] =
    useState<ALL_ERROR_TYPE | null>(null);

  const callLocaleList = (): void => {
    setIsCallingLocaleListApi(true);
    setLocaleListApiError(null);

    callLanguageListApi()
      .then((result: LanguageListApiResponse) => {
        if (result.message === SERVER_MESSAGE.FETCH_OK) {
          // Modify locale list api response according to react select obj
          const ModifiedlocaleList = result.details.map(
            ({ localeName: label, localeCode: value }) => ({
              label,
              value,
            }),
          );
          setLocaleList(ModifiedlocaleList);

          return;
        }

        setLocaleList([]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setLocaleListApiError(result.message);
      })
      .finally(() => {
        setIsCallingLocaleListApi(false);
      });
  };

  useEffect(() => {
    callLocaleList();
  }, []);

  return {
    callLocaleList,
    localeList,
    isCallingLocaleListApi,
    localeListApiError,
  };
};

export default useLocaleList;
